"use strict";
var Main = (function () {
  var $html = $("html"),
    $win = $(window),
    wrap = $(".app-aside"),
    MEDIAQUERY = {},
    app = $("#app");

  MEDIAQUERY = {
    desktopXL: 1200,
    desktop: 992,
    tablet: 768,
    mobile: 480,
  };
  $(".current-year").text(new Date().getFullYear());
  //sidebar
  var sidebarHandler = function () {
    var eventObject = isTouch() ? "click" : "mouseenter",
      elem = $("#sidebar"),
      ul = "",
      menuTitle,
      _this;

    elem.on("click", "a", function (e) {
      _this = $(this);
      if (
        isSidebarClosed() &&
        !isSmallDevice() &&
        !_this.closest("ul").hasClass("sub-menu")
      )
        return;

      _this
        .closest("ul")
        .find(".open")
        .not(".active")
        .children("ul")
        .not(_this.next())
        .parent(".open")
        .removeClass("open");
      if (_this.next().is("ul") && _this.parent().toggleClass("open")) {
        _this.next().slideToggle(200, function () {
          $win.trigger("resize");
        });
        e.stopPropagation();
        e.preventDefault();
      } else {
        //_this.parent().addClass("active");
      }
    });
    elem.on(eventObject, "a", function (e) {
      if (!isSidebarClosed() || isSmallDevice()) return;
      _this = $(this);

      if (
        !_this.parent().hasClass("hover") &&
        !_this.closest("ul").hasClass("sub-menu")
      ) {
        wrapLeave();
        _this.parent().addClass("hover");
        menuTitle = _this.find(".item-inner").clone();
        if (_this.parent().hasClass("active")) {
          menuTitle.addClass("active");
        }
        var offset = $("#sidebar").position().top;
        var itemTop = isSidebarFixed()
          ? _this.parent().position().top + offset
          : _this.parent().position().top;
        menuTitle
          .css({
            position: isSidebarFixed() ? "fixed" : "absolute",
            height: _this.outerHeight(),
            top: itemTop,
          })
          .appendTo(wrap);
        if (_this.next().is("ul")) {
          ul = _this.next().clone(true);

          ul.appendTo(wrap).css({
            top: menuTitle.position().top + _this.outerHeight(),
            position: isSidebarFixed() ? "fixed" : "absolute",
          });
          if (
            _this.parent().position().top +
              _this.outerHeight() +
              offset +
              ul.height() >
              $win.height() &&
            isSidebarFixed()
          ) {
            ul.css("bottom", 0);
          } else {
            ul.css("bottom", "auto");
          }

          wrap
            .children()
            .first()
            .scroll(function () {
              if (isSidebarFixed()) wrapLeave();
            });

          setTimeout(function () {
            if (!wrap.is(":empty")) {
              $(document).on("click tap", wrapLeave);
            }
          }, 300);
        } else {
          ul = "";
          return;
        }
      }
    });
    wrap.on("mouseleave", function (e) {
      $(document).off("click tap", wrapLeave);
      $(".hover", wrap).removeClass("hover");
      $("> .item-inner", wrap).remove();
      $("> ul", wrap).remove();
    });
  };
  // navbar collapse
  var navbarHandler = function () {
    var navbar = $(".navbar-collapse > .nav");
    var pageHeight = $win.innerHeight() - $("header").outerHeight();
    var collapseButton = $("#menu-toggler");
    if (isSmallDevice()) {
      navbar.css({
        height: pageHeight,
      });
    } else {
      navbar.css({
        height: "auto",
      });
    }
    $(document).on("mousedown touchstart", toggleNavbar);
    function toggleNavbar(e) {
      if (
        navbar.has(e.target).length === 0 && //checks if descendants of $box was clicked
        !navbar.is(e.target) && //checks if the $box itself was clicked
        navbar.parent().hasClass("collapse in")
      ) {
        collapseButton.trigger("click");
        //$(document).off("mousedown touchstart", toggleNavbar);
      }
    }
  };
  // tooltips handler
  var tooltipHandler = function () {
    $('[data-toggle="tooltip"]').tooltip();
  };
  // popovers handler
  var popoverHandler = function () {
    $('[data-toggle="popover"]').popover();
  };
  // perfect scrollbar
  var perfectScrollbarHandler = function () {
    var pScroll = $(".perfect-scrollbar");

    if (!isMobile() && pScroll.length) {
      pScroll.perfectScrollbar({
        suppressScrollX: true,
      });
      pScroll.on("mousemove", function () {
        $(this).perfectScrollbar("update");
      });
    }
  };
  //toggle class
  var toggleClassOnElement = function () {
    var toggleAttribute = $("*[data-toggle-class]");
    toggleAttribute.each(function () {
      var _this = $(this);
      var toggleClass = _this.attr("data-toggle-class");
      var outsideElement;
      var toggleElement;
      typeof _this.attr("data-toggle-target") !== "undefined"
        ? (toggleElement = $(_this.attr("data-toggle-target")))
        : (toggleElement = _this);
      _this.on("click", function (e) {
        if (
          _this.attr("data-toggle-type") !== "undefined" &&
          _this.attr("data-toggle-type") == "on"
        ) {
          toggleElement.addClass(toggleClass);
        } else if (
          _this.attr("data-toggle-type") !== "undefined" &&
          _this.attr("data-toggle-type") == "off"
        ) {
          toggleElement.removeClass(toggleClass);
        } else {
          toggleElement.toggleClass(toggleClass);
        }
        e.preventDefault();
        if (_this.attr("data-toggle-click-outside")) {
          outsideElement = $(_this.attr("data-toggle-click-outside"));
          $(document).on("mousedown touchstart", toggleOutside);
        }
      });

      var toggleOutside = function (e) {
        if (
          outsideElement.has(e.target).length === 0 && //checks if descendants of $box was clicked
          !outsideElement.is(e.target) && //checks if the $box itself was clicked
          !toggleAttribute.is(e.target) &&
          toggleElement.hasClass(toggleClass)
        ) {
          toggleElement.removeClass(toggleClass);
          $(document).off("mousedown touchstart", toggleOutside);
        }
      };
    });
  };
  //switchery
  var switcheryHandler = function () {
    var elems = Array.prototype.slice.call(
      document.querySelectorAll(".js-switch")
    );

    elems.forEach(function (html) {
      var switchery = new Switchery(html);
    });
  };
  //search form
  var searchHandler = function () {
    var elem = $(".search-form");
    var searchForm = elem.children("form");
    var formWrap = elem.parent();

    $(".s-open").on("click", function (e) {
      searchForm.prependTo(wrap);
      e.preventDefault();
      $(document).on("mousedown touchstart", closeForm);
    });
    $(".s-remove").on("click", function (e) {
      searchForm.appendTo(elem);
      e.preventDefault();
    });
    var closeForm = function (e) {
      if (!searchForm.is(e.target) && searchForm.has(e.target).length === 0) {
        $(".s-remove").trigger("click");
        $(document).off("mousedown touchstart", closeForm);
      }
    };
  };
  // settings
  var settingsHandler = function () {
    var clipSetting = new Object(),
      appSetting = new Object();
    clipSetting = {
      fixedHeader: true,
      fixedSidebar: true,
      closedSidebar: false,
      fixedFooter: false,
      theme: "theme-1",
    };
    if ($.cookie) {
      if ($.cookie("clip-setting")) {
        appSetting = jQuery.parseJSON($.cookie("clip-setting"));
      } else {
        appSetting = clipSetting;
      }
    }

    appSetting.fixedHeader
      ? app.addClass("app-navbar-fixed")
      : app.removeClass("app-navbar-fixed");
    appSetting.fixedSidebar
      ? app.addClass("app-sidebar-fixed")
      : app.removeClass("app-sidebar-fixed");
    appSetting.closedSidebar
      ? app.addClass("app-sidebar-closed")
      : app.removeClass("app-sidebar-closed");
    appSetting.fixedFooter
      ? app.addClass("app-footer-fixed")
      : app.removeClass("app-footer-fixed");
    app.hasClass("app-navbar-fixed")
      ? $("#fixed-header").prop("checked", true)
      : $("#fixed-header").prop("checked", false);
    app.hasClass("app-sidebar-fixed")
      ? $("#fixed-sidebar").prop("checked", true)
      : $("#fixed-sidebar").prop("checked", false);
    app.hasClass("app-sidebar-closed")
      ? $("#closed-sidebar").prop("checked", true)
      : $("#closed-sidebar").prop("checked", false);
    app.hasClass("app-footer-fixed")
      ? $("#fixed-footer").prop("checked", true)
      : $("#fixed-footer").prop("checked", false);
    $("#skin_color").attr(
      "href",
      "assets/css/themes/" + appSetting.theme + ".css"
    );
    $('input[name="setting-theme"]').each(function () {
      $(this).val() == appSetting.theme
        ? $(this).prop("checked", true)
        : $(this).prop("checked", false);
    });

    $("#fixed-header").change(function () {
      $(this).is(":checked")
        ? app.addClass("app-navbar-fixed")
        : app.removeClass("app-navbar-fixed");
      appSetting.fixedHeader = $(this).is(":checked");
      $.cookie("clip-setting", JSON.stringify(appSetting));
    });
    $("#fixed-sidebar").change(function () {
      $(this).is(":checked")
        ? app.addClass("app-sidebar-fixed")
        : app.removeClass("app-sidebar-fixed");
      appSetting.fixedSidebar = $(this).is(":checked");
      $.cookie("clip-setting", JSON.stringify(appSetting));
    });
    $("#closed-sidebar").change(function () {
      $(this).is(":checked")
        ? app.addClass("app-sidebar-closed")
        : app.removeClass("app-sidebar-closed");
      appSetting.closedSidebar = $(this).is(":checked");
      $.cookie("clip-setting", JSON.stringify(appSetting));
    });
    $("#fixed-footer").change(function () {
      $(this).is(":checked")
        ? app.addClass("app-footer-fixed")
        : app.removeClass("app-footer-fixed");
      appSetting.fixedFooter = $(this).is(":checked");
      $.cookie("clip-setting", JSON.stringify(appSetting));
    });

    function defaultSetting() {
      $("#fixed-header").prop("checked", true);
      $("#fixed-sidebar").prop("checked", true);
      $("#closed-sidebar").prop("checked", false);
      $("#fixed-footer").prop("checked", false);
      $("#skin_color").attr("href", "assets/css/themes/theme-1.css");
      $(".navbar-brand img").attr("src", "assets/images/logo.png");
    }
  };
  // function to allow a button or a link to open a tab
  var showTabHandler = function (e) {
    if ($(".show-tab").length) {
      $(".show-tab").on("click", function (e) {
        e.preventDefault();
        var tabToShow = $(this).attr("href");
        if ($(tabToShow).length) {
          $('a[href="' + tabToShow + '"]').tab("show");
        }
      });
    }
  };
  // function to enable panel scroll with perfectScrollbar
  var panelScrollHandler = function () {
    var panelScroll = $(".panel-scroll");
    if (panelScroll.length && !isMobile()) {
      panelScroll.perfectScrollbar({
        suppressScrollX: true,
      });
    }
  };
  //function to activate the panel tools
  var panelToolsHandler = function () {
    // panel close
    $("body").on("click", ".panel-close", function (e) {
      var panel = $(this).closest(".panel");

      destroyPanel();

      function destroyPanel() {
        var col = panel.parent();
        panel.fadeOut(300, function () {
          $(this).remove();
          if (col.is('[class*="col-"]') && col.children("*").length === 0) {
            col.remove();
          }
        });
      }
      e.preventDefault();
    });
    // panel refresh
    $("body").on("click", ".panel-refresh", function (e) {
      var $this = $(this),
        csspinnerClass = "csspinner",
        panel = $this.parents(".panel").eq(0),
        spinner = $this.data("spinner") || "load1";
      panel.addClass(csspinnerClass + " " + spinner);

      window.setTimeout(function () {
        panel.removeClass(csspinnerClass);
      }, 1000);
      e.preventDefault();
    });
    // panel collapse
    $("body").on("click", ".panel-collapse", function (e) {
      e.preventDefault();
      var el = $(this);
      var panel = jQuery(this).closest(".panel");
      var bodyPanel = panel.children(".panel-body");
      bodyPanel.slideToggle(200, function () {
        panel.toggleClass("collapses");
      });
    });
  };
  // function to activate the Go-Top button
  var goTopHandler = function (e) {
    $(".go-top").on("click", function (e) {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        "slow"
      );
      e.preventDefault();
    });
  };
  var customSelectHandler = function () {
    [].slice
      .call(document.querySelectorAll("select.cs-select"))
      .forEach(function (el) {
        new SelectFx(el);
      });
  };
  // Window Resize Function
  var resizeHandler = function (func, threshold, execAsap) {
    $(window).resize(function () {
      navbarHandler();
    });
  };
  function wrapLeave() {
    wrap.trigger("mouseleave");
  }

  function isTouch() {
    return $html.hasClass("touch");
  }

  function isSmallDevice() {
    return $win.width() < MEDIAQUERY.desktop;
  }

  function isSidebarClosed() {
    return $(".app-sidebar-closed").length;
  }

  function isSidebarFixed() {
    return $(".app-sidebar-fixed").length;
  }

  function isMobile() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  function markActive() {
    var pathname = location.origin + location.pathname;
    //var li = $('ul.main-navigation-menu li').removeClass('open');
    $("ul.main-navigation-menu li").removeClass("active");
    var nodes = $("ul.main-navigation-menu li");
    nodes.each(function (idx, item) {
      var a = $("a", item);
      a.each(function (aidx, aitem) {
        if (aitem.href == pathname.substring(0, aitem.href.length)) {
          if ($(aitem).parent().data("level") == "2") {
            $(aitem).parent().addClass("active");
            $(aitem).parent().parent().addClass("open");
            $(aitem).parent().parent().show();
            $(aitem)
              .parent()
              .parent()
              .parent()
              .parent()
              .parent()
              .addClass("active");
          } else {
            $(aitem).parent().addClass("active");
            $(aitem).parent().parent().parent().addClass("active");
            $(aitem).parent().parent().parent().addClass("open");
          }
        }
      });
    });
  }

  function initDatepickers() {
    //$('.datepicker').datepicker();
  }

  function initToasterDefaults() {
    toastr.options = {
      closeButton: true,
      positionClass: "toast-top-right",
      onclick: null,
      showDuration: "1000",
      hideDuration: "1000",
      timeOut: "5000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
    };
  }

  function initExpoDropdown() {
    $(document).ready(function () {
      $("#exposSelectionDropdown").change(function (e) {
        var id = $(this).val();
        $.cookie("current-expo", id, { path: "/" });
        $(".main-navigation-menu a.expo").attr("href", function () {
          return $(this).data("url") + "?" + id;
        });
        location.href = location.origin + location.pathname + "?" + id;
      });
    });
  }
  function setInitialExpoValue() {
    var expo = $.cookie("current-expo");
    if (expo) {
      $("#exposSelectionDropdown").val(expo);
    } else {
      expo = $("#exposSelectionDropdown").val();
      $.cookie("current-expo", expo, { path: "/" });
      console.log("set init cookie:" + expo);
    }

    $(".main-navigation-menu a.expo").attr("href", function () {
      return $(this).data("url") + "?" + expo;
    });
  }

  function getQueryParams() {
    var s1 = location.search.substring(1, location.search.length).split("&"),
      r = {},
      s2,
      i;
    for (i = 0; i < s1.length; i += 1) {
      s2 = s1[i].split("=");
      r[decodeURIComponent(s2[0]).toLowerCase()] = decodeURIComponent(s2[1]);
    }
    return r;
  }

  return {
    init: function () {
      markActive();
      initDatepickers();
      settingsHandler();
      toggleClassOnElement();
      sidebarHandler();
      navbarHandler();
      searchHandler();
      tooltipHandler();
      popoverHandler();
      perfectScrollbarHandler();
      switcheryHandler();
      resizeHandler();
      showTabHandler();
      panelScrollHandler();
      panelToolsHandler();
      goTopHandler();
      initToasterDefaults();
      initExpoDropdown();
    },
    setInitialExpoValue: setInitialExpoValue,
    getQueryParams: getQueryParams,
  };
})();
