var IndividualsApi = {
  get: function (id, fnSuccess) {
    var url = "/api/individuals/" + id;
    ajax.get(url, function (data) {
      fnSuccess(data);
    });
  },

  getClientCustomerList: function (id, fnSuccess) {
    var url = "/api/individuals/" + id + "/client_customer_list_membership";
    ajax.get(url, function (data) {
      fnSuccess(data);
    });
  },

  find: function (expoId, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/individuals/expo/" + expoId + query_string;
    ajax.get(url, fnSuccess);
  },

  findContact: function (expoId, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/individuals/expo/contact/" + expoId + query_string;
    ajax.get(url, fnSuccess);
  },

  update: function (id, data, fnSuccess) {
    var url = "/api/individuals/" + id;
    ajax.post(url, data, fnSuccess);
  },

  delete: function (id, fnSuccess) {
    var url = "/api/individuals/" + id;
    ajax.delete(url, {}, fnSuccess);
  },

  getAttendanceData: function (id, fnSuccess) {
    var url = "/api/attendance/individual/" + id;
    ajax.get(url, fnSuccess);
  },

  findDuplicates: function (expo_id, search, fnSuccess) {
    var url = "/api/individuals/" + expo_id + "/duplicates";
    if (search) {
      url = url + "?search=" + search;
    }
    ajax.get(url, fnSuccess);
  },

  search: function (expo_id, options, fnSuccess) {
    var url =
      "/api/individuals/" + expo_id + "/search" + "?" + $.param(options);

    ajax.get(url, fnSuccess);
  },
  getIndividualsByCategory: function (expoId, category, fnSuccess) {
    var query_string = "?q=" + category;
    var url =
      "/api/individuals/expo/" + expoId + "/search_by_category" + query_string;
    ajax.get(url, fnSuccess);
  },
  getIndividualsByDemographics: function (expoId, demographics, fnSuccess) {
    var query_string = "?q=" + demographics;
    var url =
      "/api/individuals/expo/" +
      expoId +
      "/search_by_demographics" +
      query_string;
    ajax.get(url, fnSuccess);
  },
  getEmailHistory: function (individual_id, email, expo_id, fnSuccess) {
    var url = "/api/individuals/" + individual_id + "/email_history";
    var data = {
      individual_id,
      email,
      expo_id,
    };
    ajax.post(url, data, fnSuccess);
  },
  getSubscriptionPreferences: function (id, fnSuccess) {
    var url = "/api/individuals/" + id + "/email_subscription_preferences";
    ajax.get(url, fnSuccess);
  },
  setSubscriptionPreferences: function (id, data, fnSuccess) {
    var url = "/api/individuals/" + id + "/set_subscription_preferences";
    ajax.post(url, data, fnSuccess);
  },
  getLeads: function (id, fnSuccess) {
    var url = "/api/individuals/" + id + "/leads";
    ajax.get(url, fnSuccess);
  },
  getEmailStats: function (id, fnSuccess) {
    var url = "https://expolytics.net/api/events/individual_stats/" + id;
    ajax.get(url, fnSuccess);
  },

  searchByIds: function (id, data, fnSuccess) {
    var url = `/api/individuals/searchByIds/${id}`;
    ajax.post(url, data, fnSuccess);
  },
  loadChangeHistory: function (id, fnSuccess) {
    var url = "/api/individuals/history/" + id;
    ajax.get(url, fnSuccess);
  },
  changeSubscriptionStatus: function (id, fnSuccess) {
    var url = `/api/individuals/${id}/change_subscription`;
    const data = {};
    ajax.post(url, data, fnSuccess);
  },

  changeExhibitorEmailsSubscriptionStatus: function (id, fnSuccess) {
    var url = `/api/individuals/${id}/change_exchibitor_emails_subscription`;
    const data = {};
    ajax.post(url, data, fnSuccess);
  },

  addNote: function (id, data, fnSuccess) {
    var url = "/api/individuals/notes/individual/" + id;
    ajax.post(url, data, fnSuccess);
  },

  getNote: function (id, fnSuccess) {
    var url = "/api/individuals/notes/" + id;
    ajax.get(url, function (data) {
      fnSuccess(data);
    });
  },
  findNotes: function (id, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/individuals/" + id + "/notes/" + query_string;
    ajax.get(url, fnSuccess);
  },
};
