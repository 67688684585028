var ChangePasswordViewModel = function () {
  var self = this;
  self.email = ko.observable();
  self.password = ko.observable();
  self.confirmPassword = ko.observable();
  self.activationCode = ko.observable();

  self.oldPassword = ko.observable();
  baseViewModel.call(self);

  self.login = function () {
    if (!$(".form-login").valid()) {
      return false;
    }
    authenticator.login(self.email(), self.password());
  };

  self.forgotPassword = function () {
    if (!$(".form-forgot").valid()) {
      return false;
    }
    authenticator.forgotPassword(self.email());
  };

  self.completeRegistration = function () {
    if (!$(".form-complete").valid()) {
      return false;
    }
    authenticator.completeRegistration(
      self.email(),
      self.password(),
      self.activationCode()
    );
  };

  self.changePassword = function (model) {
    if (!$("#changepassword-form").valid()) {
      return false;
    }
    ajax.post(
      "/api/change_password",
      { old_password: self.oldPassword(), new_password: self.password() },
      function () {
        toastr.options.onHidden = function () {
          authenticator.logout();
        };
        utils.showSuccess(
          "You successfully changed your password. Please login again, to use the new password."
        );
      }
    );
  };

  self.cancelChangePassword = function () {
    location.href = "/expo/information";
  };

  self.init = function () {};

  self.init();
};
