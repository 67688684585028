var UsersApi = {
  get: function (id, fnSuccess) {
    var url = "/api/user/" + id;
    ajax.get(url, function (data) {
      fnSuccess(new Exposync.UserModel(data));
    });
  },

  find: function (options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/user" + query_string;
    ajax.get(url, fnSuccess);
  },

  update: function (id, user, fnSuccess) {
    var url = "/api/user/" + id;
    ajax.post(url, user.toJSON(), fnSuccess);
  },

  delete: function (id, fnSuccess) {
    var url = "/api/user/" + id;
    ajax.delete(url, fnSuccess);
  },
  cancelInvitation: function (id, fnSuccess) {
    var url = "/api/cancel_invitation/" + id;
    ajax.post(url, {}, fnSuccess);
  },

  assignAsMarketerToCompanies: function (id, data, fnSuccess) {
    var url = "/api/user/" + id + "/assign_multiple_companies";
    ajax.post(url, data, fnSuccess);
  },
};
