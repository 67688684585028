var GuideSettingsViewModel = function (expoId, expo) {
  var self = this;
  self.expoId = ko.observable(expoId || "");
  self.guide_title = ko.observable(
    "Personal Guide - {{name_first}} {{name_last}}"
  );
  self.guide_for = ko.observable(
    "Personal Guide for {{name_first}} {{name_last}}"
  );
  self.events_for = ko.observable("Events for {{name_first}} {{name_last}}");
  self.guide_intro = ko.observable(
    '<p>This Personal Guide was created to <span class="text-bold">help you plan your visit</span> to {{expo_name}} in {{expo.expo_city}}.<br />Pick Categories that describe your needs - then click Create My Guide.<br />Your Guide will feature ALL matching exhibitors.<br />Click exhibitors of Interest and Remove others.  <span class="text-bold">It’s fast and easy.</span></p>'
  );
  self.marketplace_closed = ko.observable(false);
  self.marketplace_closed_message = ko.observable("");

  self.show_booth_location = ko.observable(false);
  self.show_booth_number = ko.observable(false);
  self.booth_prefix = ko.observable("Booth: ");
  self.show_events = ko.observable(false);
  self.guide_main_color = ko.observable("#ff585d");
  self.events_main_color = ko.observable("#ff585d");
  self.messages = ko.observableArray([]);
  self.messagesLoaded = ko.observable(false);
  self.guide_message_id = ko.observable();

  self.messages.subscribe(function () {
    const settings = expo.guide_settings();
    if (settings.guide_message_id)
      self.guide_message_id(settings.guide_message_id);
    else self.messagesLoaded(true);
  });

  self.guide_message_id.subscribe(function () {
    self.messagesLoaded(true);
  });

  self.saveSettings = function () {
    // body...
    var data = {
      guide_settings: {
        guide_title: self.guide_title(),
        guide_for: self.guide_for(),
        events_for: self.events_for(),
        guide_intro: self.guide_intro(),
        show_booth_location: self.show_booth_location(),
        show_booth_number: self.show_booth_number(),
        show_events: self.show_events(),
        booth_prefix: self.booth_prefix(),
        guide_main_color: self.guide_main_color(),
        events_main_color: self.events_main_color(),
        guide_message_id: self.guide_message_id(),
        marketplace_closed: self.marketplace_closed(),
        marketplace_closed_message: self.marketplace_closed_message(),
      },
    };

    ExpoApi.update(self.expoId(), data, function () {
      utils.showSuccess("Guide Settings were successfully updated.");
      $("#guideSettingsModal").modal("hide");
    });
  };

  self.loadSettings = function (id) {
    // body...
  };

  function getExpoMessages() {
    var options = {
      q: "",
      p: 0,
      ps: 1000,
    };
    MessagesApi.find(self.expoId(), options, function (response) {
      if (response.data && response.data.length) self.messages(response.data);
      else self.messagesLoaded(true);
    });
  }

  function init() {
    if (expo) {
      var settings = expo.guide_settings();
      if (settings.guide_title) self.guide_title(settings.guide_title);
      if (settings.guide_for) self.guide_for(settings.guide_for);
      if (settings.events_for) self.events_for(settings.events_for);
      if (settings.guide_intro) self.guide_intro(settings.guide_intro);
      if (settings.marketplace_closed)
        self.marketplace_closed(settings.marketplace_closed);
      if (settings.marketplace_closed_message)
        self.marketplace_closed_message(settings.marketplace_closed_message);
      if (settings.show_booth_location)
        self.show_booth_location(settings.show_booth_location);
      if (settings.show_booth_number)
        self.show_booth_number(settings.show_booth_number);
      if (settings.booth_prefix) self.booth_prefix(settings.booth_prefix);
      if (settings.show_events) self.show_events(settings.show_events);
      if (settings.guide_main_color)
        self.guide_main_color(settings.guide_main_color);
      if (settings.events_main_color)
        self.events_main_color(settings.events_main_color);

      getExpoMessages();
    }
  }

  init();
};
