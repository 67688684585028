var LoginValidator = (function () {
  "use strict";

  var setDefaultValidation = function () {
    $.validator.setDefaults({
      errorElement: "span", // contain the error msg in a small tag
      errorClass: "help-block",
      errorPlacement: function (error, element) {
        // render error placement for each input type
        if (
          element.attr("type") == "radio" ||
          element.attr("type") == "checkbox"
        ) {
          // for chosen elements, need to insert the error after the chosen container
          error.insertAfter(
            $(element).closest(".form-group").children("div").children().last()
          );
        } else if (
          element.attr("name") == "card_expiry_mm" ||
          element.attr("name") == "card_expiry_yyyy"
        ) {
          error.appendTo($(element).closest(".form-group").children("div"));
        } else {
          error.insertAfter(element);
          // for other inputs, just perform default behavior
        }
      },
      ignore: ":hidden",
      success: function (label, element) {
        label.addClass("help-block valid");
        // mark the current input as valid and display OK icon
        $(element).closest(".form-group").removeClass("has-error");
      },
      highlight: function (element) {
        $(element).closest(".help-block").removeClass("valid");
        // display OK icon
        $(element).closest(".form-group").addClass("has-error");
        // add the Bootstrap error class to the control group
      },
      unhighlight: function (element) {
        // revert the change done by hightlight
        $(element).closest(".form-group").removeClass("has-error");
        // set error class to the control group
      },
    });
  };
  var setLoginValidation = function () {
    var form = $(".form-login");
    var errorHandler = $(".errorHandler", form);
    form.validate({
      rules: {
        email: {
          required: true,
        },
        password: {
          minlength: 6,
          required: true,
        },
      },
      submitHandler: function (form) {
        errorHandler.hide();
        form.submit();
      },
      invalidHandler: function (event, validator) {
        //display error alert on form submit
        errorHandler.show();
      },
    });
  };
  var setForgotValidation = function () {
    var form2 = $(".form-forgot");
    var errorHandler2 = $(".errorHandler", form2);
    form2.validate({
      rules: {
        email: {
          required: true,
        },
      },
      submitHandler: function (form) {
        errorHandler2.hide();
        form2.submit();
      },
      invalidHandler: function (event, validator) {
        //display error alert on form submit
        errorHandler2.show();
      },
    });
  };

  var setCompleteRegistrationValidation = function () {
    var form3 = $(".form-complete");
    var errorHandler3 = $(".errorHandler", form3);
    form3.validate({
      rules: {
        password: {
          required: true,
        },
        confirm_password: {
          required: true,
          equalTo: "#password",
        },
      },
      submitHandler: function (form) {
        errorHandler3.hide();
        form3.submit();
      },
      invalidHandler: function (event, validator) {
        //display error alert on form submit
        errorHandler3.show();
      },
    });
  };

  return {
    //main function to initiate template pages
    init: function () {
      setDefaultValidation();
      setLoginValidation();
      setForgotValidation();
      setCompleteRegistrationValidation();
    },
  };
})();
