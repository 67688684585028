var EmailTemplatesApi = {
  get: function (id, fnSuccess) {
    var url = "/api/templates/" + id;
    ajax.get(url, function (data) {
      fnSuccess(data);
    });
  },

  find: function (expoId, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/templates/expo/" + expoId + query_string;
    ajax.get(url, fnSuccess);
  },

  update: function (id, data, fnSuccess) {
    var url = "/api/templates/" + id;
    ajax.post(url, data, fnSuccess);
  },

  save: function (expoId, data, fnSuccess) {
    var url = "/api/templates/expo/" + expoId;
    ajax.post(url, data, fnSuccess);
  },

  delete: function (id, fnSuccess) {
    var url = "/api/templates/" + id;
    ajax.delete(url, {}, fnSuccess);
  },
};
