var LeadConversionViewModel = function () {
  var self = this;
  self.exhibitor = ko.observable();
  self.exhibitorId = ko.observable();
  self.expo = ko.observable();

  self.getExhibitor = function (id, fnSuccess) {
    var url = "/public_api/lead_conversion/exhibitor/" + id;
    ajax.get(
      url,
      function (data) {
        // success
        self.exhibitor(new Exposync.CompanyModel(data));
        self.expo(new Exposync.ExpoModel(data.expo));

        // if (data.nexus_status == 'prospect')
        // {

        //   var updateData = {}
        //   updateData.nexus_status = 'lead';
        //   ajax.post(url, updateData, function () {
        //     // success
        //   },
        //   function (req, status, err) {
        //     // error
        //   })
        // }
      },
      function function_name(data) {
        // error
        utils.showError("No exhibitor found with ID: " + id);
      }
    );
  };

  self.getExpo = function (id) {
    ExpoApi.get(id, function (expo) {
      self.expo(expo);
    });
  };

  self.init = function () {
    self.exhibitor(Exposync.CompanyModel({}));
    self.expo(Exposync.ExpoModel({}));
  };
  self.init();
};
