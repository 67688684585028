var EventsApi = {
  get: function (id, fnSuccess) {
    var url = "/api/events/" + id;
    ajax.get(url, function (data) {
      fnSuccess(new Exposync.EventModel(data));
    });
  },
  find: function (expoId, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/events/expo/" + expoId + query_string;
    ajax.get(url, fnSuccess);
  },

  update: function (id, data, fnSuccess) {
    var url = "/api/events/" + id;
    ajax.post(url, data, fnSuccess);
  },

  delete: function (id, fnSuccess) {
    var url = "/api/events/" + id;
    ajax.delete(url, fnSuccess);
  },

  attendance: function (id, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/events/" + id + "/attendance" + query_string;
    ajax.get(url, function (data) {
      fnSuccess(data);
    });
  },

  getManyByCode: function (id, codes, fnSuccess) {
    var url = "/api/events/expo/" + id + "/many_by_code/" + codes.join(",");
    ajax.get(url, fnSuccess);
  },

  loadChangeHistory: function (id, fnSuccess) {
    var url = "/api/events/history/" + id;
    ajax.get(url, fnSuccess);
  },
};
