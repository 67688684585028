var RegisterEventViewModel = function () {
  var self = this;
  self.event = ko.observable();
  self.individual = ko.observable();
  self.individualId = ko.observable();
  self.expo = ko.observable();

  self.getEvent = function (expoId, evid) {
    PublicApi.getEventByEventId(expoId, evid, function (data) {
      self.event(new Exposync.EventModel(data));
    });
  };

  self.getIndividual = function (id, fnSuccess) {
    BuyersGuideApi.getIndividual(id, function (data) {
      self.individual(new Exposync.IndividualModel(data));
      if (typeof fnSuccess === "function") {
        fnSuccess(data);
      }
    });
  };

  self.init = function () {
    self.event(Exposync.EventModel({}));
    self.expo(Exposync.ExpoModel({}));
    self.individual(Exposync.IndividualModel({}));
  };
  self.init();
};
