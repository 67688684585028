var ClientListsViewModel = function () {
  var self = this;

  self.listId = ko.observable();
  self.searchCriteria = ko.observableArray([]);
  self.engagementCriteria = ko.observableArray([]);
  self.fields = ko.observableArray([]);
  self.messages = ko.observableArray([]);
  self.matchCount = ko.observable(0);
  self.operators = ko.observableArray([]);
  self.searchTemplates = ko.observableArray([]);
  self.lists = ko.observableArray([]);
  self.editListModel = ko.observable();
  self.viewListModel = ko.observable();
  self.startFrom = ko.observable("blank");
  self.startFromTemplate = ko.observable();
  self.startFromList = ko.observable();

  self.selectedNewMatches = ko.observableArray([]);
  self.selectedApprovedMatches = ko.observableArray([]);
  self.selectedOmittedMatches = ko.observableArray([]);

  self.searchIndividualName = ko.observable();
  self.searchIndividualsResult = ko.observableArray([]);
  self.searchExhibitorName = ko.observable();
  self.searchExhibitorsResult = ko.observableArray([]);
  self.engagementCriteriaAdded = ko.observable(false);
  self.engagementCriteriaModified = ko.observable(false);

  self.isExhibitorList = ko.observable();

  self.auto_approve = ko.observable();

  self.auto_approve.subscribe((value) => {
    var data = {
      auto_approve: value,
    };
    ListApi.updateExhibitorList(self.listId(), data, function (result) {});
  });

  self.list_entries_count = ko.observable({
    approved: 0,
    new: 0,
    omitted: 0,
  });

  //export
  self.selectedExportFields = ko.observableArray([
    "name_first",
    "name_last",
    "phone_first",
    "email_first",
    "address1_street1",
    "address1_street2",
    "address1_city",
    "address1_state",
    "address1_zip",
    "address1_country",
    "business_name",
    "business_title",
  ]);
  self.exportFields = ko.observableArray([
    "id",
    "registration_ids",
    "name_prefix",
    "name_first",
    "name_middle",
    "name_last",
    "name_suffix",
    "name_salutation",
    "address1_street1",
    "address1_street2",
    "address1_street3",
    "address1_city",
    "address1_state",
    "address1_zip",
    "address1_country",
    "address2_street1",
    "address2_street2",
    "address2_street3",
    "address2_city",
    "address2_state",
    "address2_zip",
    "address2_country",
    "business_name",
    "business_name2",
    "business_title",
    "business_role",
    "customer_status",
    "phone_first",
    "phone_second",
    "phone_fax",
    "phone_cell1",
    "phone_cell2",
    "email_first",
    "email_domain",
    "email_second",
    "email_status",
    "email_unsubscribed",
    "exhibitor_emails",
    "failed_emails",
    "guide_created",
    "exhibitor_id",
    "exhibitors_interested",
    "exhibitors_maybe",
    "exhibitors_notinterested",
    "exhibitors_clicked",
    "expo_roles",
    "exhibitor_roles",
    "expo_history",
    "list_member",
    "keywords",
    "demographics",
    "categories",
    "events_registered",
    "housing_id",
    "hotel_name",
    "housing_block",
    "hotel_check_in",
    "hotel_check_out",
    "hotel_rate",
    "hotel_num_nights",
  ]);

  self.selectedExhibitorsExportFields = ko.observableArray([
    "exhibitor_id",
    "exhibitor_name",
    "exhibitor_website",
    "booth_location",
    "booth_number",
    "exh_address_street1",
    "exh_address_street2",
    "exh_address_city",
    "exh_address_state",
    "exh_address_zip",
    "exh_address_country",
  ]);
  self.exhibitorsExportFields = ko.observableArray([
    "id",
    "exhibitor_id",
    "exhibitor_name",
    "exhibitor_website",
    "exhibitor_description",
    "booth_location",
    "booth_number",
    "guide_name_first",
    "guide_name_last",
    "guide_contact_title",
    "guide_email",
    "guide_phone",
    "guide_fax",
    "exh_address_street1",
    "exh_address_street2",
    "exh_address_city",
    "exh_address_state",
    "exh_address_zip",
    "exh_address_country",
    "nexus_status",
    "client_type",
    "demographics",
    "categories",
    "primary_categories",
    "suppressed",
    "facebook_url",
    "twitter_url",
    "linkedin_url",
    "instagram_url",
    "pinterest_url",
    "snapchat_url",
    "youtube_url",
    "list_member",
    "company_domains",
    "expo_status",
    "expo_history",
    "company_type",
    "company_logo",
  ]);

  self.selectedCustomExportFields = ko.observableArray();
  self.selectedStatuses = ko.observableArray(["new", "approved", "omitted"]);

  baseViewModel.call(self);
  baseClientsViewModel.call(self);

  self.customExportFields = ko.computed(function () {
    if (self.expo()) {
      return self
        .expo()
        .customIndividualFields()
        .map(function (item) {
          return item.value;
        });
    } else return [];
  });

  self.customExhibitorExportFields = ko.computed(function () {
    if (self.expo()) {
      return self
        .expo()
        .customCompanyFields()
        .map(function (item) {
          return item.value;
        });
    } else return [];
  });

  self.db_fields = ko.computed(function () {
    return ko.utils.arrayFilter(self.fields(), function (item) {
      if (item.name) return item.name.indexOf("engagement:") != 0;
      return false;
    });
  });

  self.engagement_fields = ko.computed(function () {
    return ko.utils.arrayFilter(self.fields(), function (item) {
      if (item.name) return item.name.indexOf("engagement:") === 0;
      return false;
    });
  });

  self.goToListView = function () {
    location.href =
      "/services/clients/lists/?id=" +
      self.clientId() +
      "&eid=" +
      self.expoId();
  };

  self.listsDataSource = function (options, callback) {
    options.exhibitor_id = self.clientId();
    ListApi.find(self.expoId(), options, function (response) {
      var lists = response.data;

      if (lists.length > 0) {
        var ids = ko.utils.arrayMap(lists, function (item) {
          return item.id;
        });

        ListApi.getListsCount({ ids: ids }, function (data) {
          var counts = {};
          ko.utils.arrayForEach(data, function (item) {
            if (!counts[item.list_id]) counts[item.list_id] = {};
            counts[item.list_id][item.status] = item.total_count;
          });

          ko.utils.arrayForEach(lists, function (item) {
            item["approved"] =
              counts[item.id] !== undefined
                ? counts[item.id]["approved"] !== undefined
                  ? counts[item.id]["approved"]
                  : 0
                : 0;
            item["new_matches"] =
              counts[item.id] !== undefined
                ? counts[item.id]["new"] !== undefined
                  ? counts[item.id]["new"]
                  : 0
                : 0;
            item["omitted"] =
              counts[item.id] !== undefined
                ? counts[item.id]["omitted"] !== undefined
                  ? counts[item.id]["omitted"]
                  : 0
                : 0;
          });

          response.data = lists;
          callback(response);
        });
      } else {
        callback(response);
      }
    });
  };

  self.exhibitorListsDataSource = function (options, callback) {
    options.exhibitor_id = self.clientId();
    ListApi.findExhibitorList(self.expoId(), options, function (response) {
      var lists = response.data;

      if (lists.length > 0) {
        var ids = ko.utils.arrayMap(lists, function (item) {
          return item.id;
        });

        ListApi.getExhibitorListsCount({ ids: ids }, function (data) {
          var counts = {};
          ko.utils.arrayForEach(data, function (item) {
            if (!counts[item.list_id]) counts[item.list_id] = {};
            counts[item.list_id][item.status] = item.total_count;
          });

          ko.utils.arrayForEach(lists, function (item) {
            item["approved"] =
              counts[item.id] !== undefined
                ? counts[item.id]["approved"] !== undefined
                  ? counts[item.id]["approved"]
                  : 0
                : 0;
            item["new_matches"] =
              counts[item.id] !== undefined
                ? counts[item.id]["new"] !== undefined
                  ? counts[item.id]["new"]
                  : 0
                : 0;
            item["omitted"] =
              counts[item.id] !== undefined
                ? counts[item.id]["omitted"] !== undefined
                  ? counts[item.id]["omitted"]
                  : 0
                : 0;
          });

          response.data = lists;
          callback(response);
        });
      } else {
        callback(response);
      }
    });
  };

  self.listsTableRowCallback = function (row, data) {
    $(row).attr("style", "cursor: pointer");
    $(row).click(function (e) {
      location.href =
        "/services/clients/lists/view/?id=" +
        data.exhibitor_id +
        "&lid=" +
        data.id +
        "&eid=" +
        data.expo_id;
    });
  };

  self.exhibitorListsTableRowCallback = function (row, data) {
    $(row).attr("style", "cursor: pointer");
    $(row).click(function (e) {
      location.href =
        "/services/clients/lists/view/exhibitor/?id=" +
        data.exhibitor_id +
        "&lid=" +
        data.id +
        "&eid=" +
        data.expo_id;
    });
  };

  self.newMatchesDataSource = function (options, callback) {
    options.status = "new";
    dataSource(options, callback);
  };

  self.approvedDataSource = function (options, callback) {
    options.status = "approved";
    dataSource(options, callback);
  };

  self.omittedDataSource = function (options, callback) {
    options.status = "omitted";
    dataSource(options, callback);
  };

  function dataSource(options, callback) {
    var status = options.status;
    if (self.isExhibitorList()) {
      ListApi.findExhibitorListEntries(
        self.listId(),
        options,
        function (response) {
          var companies = [];
          var totals = self.list_entries_count();
          totals[status] = response.recordsTotal;
          self.list_entries_count(totals);

          response.data.forEach(function (item) {
            var company = item.company;
            company.status = item.status;
            company.entry_id = item.id;
            company.business_name = company.exhibitor_name;
            companies.push(company);
          });
          response.data = companies;
          callback(response);
        }
      );
    } else {
      ListApi.findEntries(self.listId(), options, function (response) {
        var individuals = [];
        var totals = self.list_entries_count();
        totals[status] = response.recordsTotal;
        self.list_entries_count(totals);
        response.data.forEach(function (item) {
          var individual = item.individual;
          individual.status = item.status;
          individual.entry_id = item.id;
          individual.business_name = item.company
            ? item.company.exhibitor_name
            : individual.business_name;
          individuals.push(individual);
        });
        response.data = individuals;
        callback(response);
      });
    }
  }

  self.entriesTableRowCallback = function (row, data) {
    $(row).attr("style", "cursor: pointer");
    $(row).click(function (e) {
      if (e.target.type == "checkbox") return;
      else if ($(e.target).find('input[type="checkbox"]').length > 0) {
        var selectBox = $(e.target).find('input[type="checkbox"]')[0];
        $(selectBox).trigger("click");
        return;
      } else {
        //get expo role
        if (self.isInRole("admin,manager"))
          window.open(
            "/expo/individuals/view/?id=" + data.id + "&eid=" + data.expo_id,
            "_blank"
          );
      }
    });
  };

  self.exhibitorEntriesTableRowCallback = function (row, data) {
    $(row).attr("style", "cursor: pointer");
    $(row).click(function (e) {
      if (e.target.type == "checkbox") return;
      else if ($(e.target).find('input[type="checkbox"]').length > 0) {
        var selectBox = $(e.target).find('input[type="checkbox"]')[0];
        $(selectBox).trigger("click");
        return;
      } else {
        //get expo role
        if (self.isInRole("admin,manager"))
          window.open(
            "/services/clients/view/?id=" + data.id + "&eid=" + data.expo_id,
            "_blank"
          );
      }
    });
  };

  self.preview = function () {
    var l = Ladda.create(document.querySelector("#preview-btn"));
    l.start();
    var data = {};
    data.is_template = false;
    data.name = null;
    data.criteria = [];
    data.engagement_criteria = [];
    data.client_id = self.clientId();
    ko.utils.arrayForEach(
      self.editListModel().search().criteria(),
      function (item, idx) {
        if (item.isValid()) {
          var entry = item.toJSON();
          if (idx == 0) entry.linkage = null;
          data.criteria.push(entry);
        }
      }
    );

    if (self.editListModel().search().engagement_criteria().length > 0) {
      ko.utils.arrayForEach(
        self.editListModel().search().engagement_criteria()[0].criteria(),
        function (item, idx) {
          if (item.isEngagementCriteriaValid()) {
            var entry = item.toJSON();
            entry.linkage = "and";
            entry.message_id = self
              .editListModel()
              .search()
              .engagement_criteria()[0]
              .message_id();
            data.criteria.push(entry);
          }
        }
      );
    }
    l.start();
    if (self.isExhibitorList()) {
      SearchApi.previewExhibitorSearch(
        self.expoId(),
        data,
        function (data) {
          self.matchCount(data.total);
          l.stop();
        },
        () => {
          l.stop();
        }
      );
    } else {
      SearchApi.preview(
        self.expoId(),
        data,
        function (data) {
          l.stop();
          self.matchCount(data.total);
        },
        () => {
          l.stop();
        }
      );
    }
  };

  self.getList = function (id) {
    if (self.isExhibitorList()) {
      ListApi.getExhibitorList(id, function (data) {
        self.auto_approve(data.auto_approve);
        self.getFields(function () {
          self.viewListModel(new Exposync.ListModel(data, self.fields()));
        });
      });
    } else {
      ListApi.get(id, function (data) {
        self.getFields(function () {
          self.viewListModel(new Exposync.ListModel(data, self.fields()));
        });
      });
    }
  };

  self.getExpo = function (id, fnSuccess) {
    ExpoApi.get(id, function (expo) {
      self.expo(expo);
      if (typeof fnSuccess === "function") {
        fnSuccess(expo);
      }
    });
  };

  self.getLists = function (expoId, clientId) {
    var options = utils.defaultQueryOptions;
    options.ps = 1000;
    options.exhibitor_id = clientId;
    ListApi.find(expoId, options, function (response) {
      self.lists(response.data);
    });
  };

  self.getFields = function (fnSuccess) {
    if (self.isExhibitorList()) {
      SearchApi.getExhibitorFields(self.expoId(), function (data) {
        var fields = $.map(data, function (type, name) {
          return { name: name, type: type };
        });

        self.fields(fields.sort(compare));
        if (typeof fnSuccess === "function") fnSuccess();
      });
    } else {
      SearchApi.getFields(self.expoId(), function (data) {
        var fields = $.map(data, function (type, name) {
          return { name: name, type: type };
        });
        self.fields(fields.sort(compare));
        if (typeof fnSuccess === "function") fnSuccess();
      });
    }
  };

  self.editList = function () {
    if (self.isExhibitorList()) {
      ListApi.getExhibitorList(self.listId(), function (data) {
        var list = new Exposync.ListModel(data, self.fields());

        list.mode("update");
        self.editListModel(list);
        $("#createListModal").modal("show");
      });
    } else {
      ListApi.get(self.listId(), function (data) {
        var list = new Exposync.ListModel(data, self.fields());
        list.mode("update");
        self.editListModel(list);
        $("#createListModal").modal("show");
      });
    }
  };

  self.refreshList = function () {
    var l = Ladda.create(document.querySelector("#refresh-btn"));
    l.start();
    if (self.isExhibitorList()) {
      ListApi.refreshExhibitorList(
        self.listId(),
        function () {
          // location.href = '/services/clients/lists/view/exhibitor/?id=' + self.clientId() + '&lid=' + self.listId() + '&eid=' + self.expoId()
          utils.showSuccess("Refresh is running in background");
          l.stop();
        },
        function () {
          l.stop();
        }
      );
    } else {
      ListApi.refresh(
        self.listId(),
        function () {
          // location.href = '/services/clients/lists/view/?id=' + self.clientId() + '&lid=' + self.listId() + '&eid=' + self.expoId()
          utils.showSuccess("Refresh is running in background");
          l.stop();
        },
        function () {
          l.stop();
        }
      );
    }
  };

  self.newList = function () {
    var list = new Exposync.ListModel({});
    list.mode("new");
    self.editListModel(list);
    self.engagementCriteriaAdded(false);
    self.getFields(function () {
      if (self.startFrom() == "blank") {
        $("#startCreateListModal").modal("hide");
        $("#createListModal").modal("show");
        self.addCriteria();
      } else if (self.startFrom() == "template") {
        if (self.startFromTemplate()) {
          $("#startCreateListModal").modal("hide");
          SearchApi.get(self.startFromTemplate(), function (data) {
            self.editListModel().name(data.name);
            data.criteria.forEach(function (item) {
              if (item.field.indexOf("engagement:") === 0) {
                item.is_engagement = true;
                item.fields = self.engagement_fields();
              } else {
                item.fields = self.db_fields();
              }
              item.expo_id = self.expoId();
              item.client_id = self.clientId();
              self
                .editListModel()
                .search()
                .criteria.push(new Exposync.CriteriaModel(item));
            });

            $("#createListModal").modal("show");
          });
        }
      } else if (self.startFrom() == "list") {
        if (self.startFromList()) {
          $("#startCreateListModal").modal("hide");
          ListApi.get(self.startFromList(), function (data) {
            var list = new Exposync.ListModel(data, self.fields());
            list.mode("new");
            list.id("");
            list.search_id("");
            list.search().id("");
            self.editListModel(list);
          });
          $("#createListModal").modal("show");
        }
      } else if (self.startFrom() === "exhibitor") {
        self.isExhibitorList(true);
        self.getFields(function () {
          $("#startCreateListModal").modal("hide");
          $("#createListModal").modal("show");
          self.addCriteria();
        });
      }
    });
  };

  self.startNewList = function () {
    self.isExhibitorList(false);
    var options = utils.defaultQueryOptions;
    options.ps = 1000;
    SearchApi.find(self.expoId(), options, function (response) {
      self.searchTemplates(response.data);
    });
    $("#startCreateListModal").modal("show");
  };

  self.addCriteria = function () {
    var expoId = self.expoId();
    self.searchCriteria = self.editListModel().search().criteria;
    self.searchCriteria.push(
      new Exposync.CriteriaModel({
        expo_id: expoId,
        client_id: self.clientId(),
        fields: self.db_fields(),
        isExhibitorList: self.isExhibitorList(),
      })
    );
  };

  self.addEngagementCriteria = function () {
    var expoId = self.expoId();
    self.engagementCriteria = self.editListModel().search().engagement_criteria;
    var data = {
      message_id: ko.observable(),
      criteria: ko.observableArray([]),
    };
    self.engagementCriteria.push(data);
    self.engagementCriteriaAdded(true);
    self.engagementCriteriaModified(false);
  };

  self.removeEngagementCriteria = function (item) {
    self.engagementCriteria = self.editListModel().search().engagement_criteria;
    self.engagementCriteria.remove(item);
    self.engagementCriteriaAdded(true);
    self.engagementCriteriaModified(false);
  };

  self.removeCriteria = function (item) {
    self.searchCriteria = self.editListModel().search().criteria;
    self.searchCriteria.remove(item);
  };

  self.addEngagementCriteriaRow = function (item) {
    var expoId = self.expoId();
    item.criteria.push(
      new Exposync.CriteriaModel({
        expo_id: expoId,
        client_id: self.clientId(),
        message_id: item.message_id(),
        fields: self.engagement_fields(),
        is_engagement: true,
      })
    );
    self.engagementCriteriaModified(true);
  };

  self.removeEngagementCriteriaRow = function (item) {
    var expoId = self.expoId();
    self.engagementCriteria = self.editListModel().search().engagement_criteria;
    self.engagementCriteria()[0].criteria.remove(item);
    self.engagementCriteriaModified(true);
  };

  self.has_engagement_criteria = ko.computed(function () {
    var added = self.engagementCriteriaAdded();
    return added && self.engagementCriteria().length === 1;
  });

  self.has_engagement_criteria_row = ko.computed(function () {
    var modified = self.engagementCriteriaModified();
    return (
      modified &&
      self.engagementCriteria().length === 1 &&
      self.engagementCriteria()[0].criteria().length > 0
    );
  });

  self.updateStatuses = function (data) {
    if (self.isExhibitorList()) {
      ListApi.updateStatusesExhibitorList(self.listId(), data, function () {
        utils.showSuccess("Statuses have been updated.");
        setTimeout(function () {
          location.href =
            "/services/clients/lists/view/exhibitor/?id=" +
            self.client().id() +
            "&lid=" +
            self.listId() +
            "&eid=" +
            self.expoId();
        }, 2000);
      });
    } else {
      ListApi.updateStatuses(self.listId(), data, function () {
        utils.showSuccess("Statuses have been updated.");
        setTimeout(function () {
          location.href =
            "/services/clients/lists/view/?id=" +
            self.client().id() +
            "&lid=" +
            self.listId() +
            "&eid=" +
            self.expoId();
        }, 2000);
      });
    }
  };

  self.approveAll = function () {
    if (!confirm("Are you sure you want to approve all New entries?")) return;
    if (self.isExhibitorList()) {
      ListApi.approveAllExhibitorList(self.listId(), function (data) {
        utils.showSuccess(data.num_approved + " entries have been approved.");
        if (data.num_approved > 0) {
          setTimeout(function () {
            location.href =
              "/services/clients/lists/view/exhibitor/?id=" +
              self.client().id() +
              "&lid=" +
              self.listId() +
              "&eid=" +
              self.expoId();
          }, 2000);
        }
      });
    } else {
      ListApi.approveAll(self.listId(), function (data) {
        utils.showSuccess(data.num_approved + " entries have been approved.");
        if (data.num_approved > 0) {
          setTimeout(function () {
            location.href =
              "/services/clients/lists/view/?id=" +
              self.client().id() +
              "&lid=" +
              self.listId() +
              "&eid=" +
              self.expoId();
          }, 2000);
        }
      });
    }
  };

  self.approveNewMatches = function () {
    var entries = self.selectedNewMatches();
    var data = [];
    entries.forEach(function (item) {
      data.push({ id: item, status: "approved" });
    });
    self.updateStatuses(data);
  };

  self.omittNewMatches = function () {
    var entries = self.selectedNewMatches();
    var data = [];
    entries.forEach(function (item) {
      data.push({ id: item, status: "omitted" });
    });
    self.updateStatuses(data);
  };

  self.omittApprovedEntries = function () {
    var entries = self.selectedApprovedMatches();
    var data = [];
    entries.forEach(function (item) {
      data.push({ id: item, status: "omitted" });
    });
    self.updateStatuses(data);
  };

  self.approveOmittedEntries = function () {
    var entries = self.selectedOmittedMatches();
    var data = [];
    entries.forEach(function (item) {
      data.push({ id: item, status: "approved" });
    });
    self.updateStatuses(data);
  };

  self.updateListName = function (model) {
    var data = {};

    var list = model.viewListModel();
    data.name = list.name();
    ListApi.update(list.id(), data, function () {});
  };

  self.saveList = function () {
    if (!$("#add-list-form").valid()) {
      return false;
    }

    var searchData = self.editListModel().search().toJSON();
    searchData.is_template = false;

    if (searchData.criteria.length > 0) searchData.criteria[0].linkage = null;

    if (self.editListModel().mode() == "new") {
      saveSearch(searchData);
    } else {
      updateSearch(searchData);
    }
  };

  function saveSearch(searchData) {
    if (
      searchData.criteria.length == 0 &&
      searchData.engagement_criteria.length == 0
    ) {
      utils.showError("At least one criteria line is required.");
      return;
    }

    searchData.name = self.editListModel().name() + "_search";
    searchData.client_id = self.clientId();

    SearchApi.save(self.expoId(), searchData, function (searchResult) {
      var listData = self.editListModel().toJSON();

      //listData.assigned_marketer_id = self.currentUser().id;
      listData.search_id = searchResult.id;
      listData.exhibitor_id = self.clientId();
      if (self.isExhibitorList()) {
        ListApi.saveExhibitorList(self.expoId(), listData, function (data) {
          utils.showSuccess("List has been successfully created.");
          $("#createListModal").modal("hide");
          ListApi.refreshExhibitorList(data.id, function () {
            l.stop();
          });
          setTimeout(function () {
            location.href =
              "/services/clients/lists/?id=" +
              self.clientId() +
              "&eid=" +
              self.expoId();
          }, 2000);
        });
      } else {
        ListApi.save(self.expoId(), listData, function (data) {
          utils.showSuccess("List has been successfully created.");
          $("#createListModal").modal("hide");
          ListApi.refresh(
            data.id,
            function () {},
            function () {
              l.stop();
            }
          );
          setTimeout(function () {
            location.href =
              "/services/clients/lists/?id=" +
              self.clientId() +
              "&eid=" +
              self.expoId();
          }, 2000);
        });
      }
    });
  }

  function updateSearch(data) {
    if (data.criteria.length == 0) {
      utils.showError("At least one criteria line is required.");
      return;
    }
    if (self.isExhibitorList()) {
      SearchApi.update(self.editListModel().search_id(), data, function (data) {
        var listData = self.editListModel().toJSON();
        ListApi.updateExhibitorList(self.listId(), listData, function (data) {
          utils.showSuccess("List has been successfully updated.");
          $("#createListModal").modal("hide");

          ListApi.refreshExhibitorList(self.listId(), function () {});
          setTimeout(function () {
            location.href =
              "/services/clients/lists/view/exhibitor/?id=" +
              self.clientId() +
              "&lid=" +
              self.listId() +
              "&eid=" +
              self.expoId();
          }, 5000);
        });
      });
    } else {
      SearchApi.update(self.editListModel().search_id(), data, function (data) {
        var listData = self.editListModel().toJSON();
        ListApi.update(self.listId(), listData, function (data) {
          utils.showSuccess("List has been successfully updated.");
          $("#createListModal").modal("hide");

          ListApi.refresh(
            self.listId(),
            function () {},
            function () {
              l.stop();
            }
          );
          setTimeout(function () {
            location.href =
              "/services/clients/lists/view/?id=" +
              self.clientId() +
              "&lid=" +
              self.listId() +
              "&eid=" +
              self.expoId();
          }, 2000);
        });
      });
    }
  }

  self.deleteList = function () {
    if (self.isExhibitorList()) {
      ListApi.deleteExhibitorList(self.listId(), function () {
        utils.showSuccess("List was successfully deleted.");
        setTimeout(function () {
          self.goToListView();
        }, 2000);
      });
    } else {
      ListApi.delete(self.listId(), function () {
        utils.showSuccess("List was successfully deleted.");
        setTimeout(function () {
          self.goToListView();
        }, 2000);
      });
    }
  };

  self.searchIndividuals = function () {
    var expoId = self.expoId();
    var options = utils.defaultQueryOptions;
    options.q = self.searchIndividualName();
    IndividualsApi.find(expoId, options, function (result) {
      self.searchIndividualsResult(result.data);
    });
  };

  self.searchExhibitors = function () {
    var expoId = self.expoId();
    var options = utils.defaultQueryOptions;
    options.q = self.searchExhibitorName();
    ExhibitorsApi.find(expoId, options, function (result) {
      self.searchExhibitorsResult(result.data);
    });
  };

  self.addListEntry = function (individual) {
    var listId = self.listId();
    var data = {};
    data.id = individual.id;
    ListApi.addEntry(listId, data, function (d) {
      utils.showSuccess("Individual was add the the list.");
      $("#addListEntryModal").modal("hide");
      location.href =
        "/services/clients/lists/view/?id=" +
        self.clientId() +
        "&lid=" +
        listId +
        "&eid=" +
        self.expoId();
    });
  };

  self.addExhibitorListEntry = function (exhibitor) {
    var listId = self.listId();
    var data = {};
    data.id = exhibitor.id;
    ListApi.addExhibitorListEntry(listId, data, function (d) {
      utils.showSuccess("Exhibitor was add the the list.");
      $("#addExhibitorListEntryModal").modal("hide");
      setTimeout(() => {
        location.href =
          "/services/clients/lists/view/exhibitor/?id=" +
          self.clientId() +
          "&lid=" +
          listId +
          "&eid=" +
          self.expoId();
      }, 2000);
    });
  };

  self.showExportModal = function () {
    // body...
    $("#exportListModal").modal("show");
  };

  self.showExportExhibitorsModal = function () {
    // body...
    $("#exportExhibitorsListModal").modal("show");
  };

  self.isExportExhibitorsVisible = function () {
    return true;
  };

  self.exportToCsv = function () {
    $("#export_list_entries").attr(
      "action",
      "/api/lists/export/" + self.listId()
    );
    var statuses = $('input[name="statuses"]:checked');
    var fields = $('input[name="fields"]:checked');
    var custom_fields = $('input[name="custom_fields"]:checked');

    if (statuses.length > 0 && fields.length > 0) {
      $("#export_list_entries").submit();
    } else {
      utils.showError("Please select at least one status and one field!");
    }
  };

  self.exportExhibitorsToCsv = function () {
    $("#export_exhibitor_list_entries").attr(
      "action",
      "/api/lists/export_exhibitors/" + self.listId()
    );
    var statuses = $('input[name="e_statuses"]:checked');
    var fields = $('input[name="e_fields"]:checked');
    var custom_fields = $('input[name="e_custom_fields"]:checked');

    if (statuses.length > 0 && fields.length > 0) {
      $("#export_exhibitor_list_entries").submit();
    } else {
      utils.showError("Please select at least one status and one field!");
    }
  };

  self.exportExhibitorListToCsv = function () {
    $("#export_exhibitor_list_entries").attr(
      "action",
      "/api/lists/export_exhibitor_list/" + self.listId()
    );
    var statuses = $('input[name="e_statuses"]:checked');
    var fields = $('input[name="e_fields"]:checked');
    var custom_fields = $('input[name="e_custom_fields"]:checked');

    if (statuses.length > 0 && fields.length > 0) {
      $("#export_exhibitor_list_entries").submit();
    } else {
      utils.showError("Please select at least one status and one field!");
    }
  };

  self.getMessages = function () {
    var options = utils.defaultQueryOptions;
    options.ps = 100;
    options.client_id = self.clientId();
    MessagesApi.find(self.expoId(), options, function (response) {
      self.messages(response.data);
    });
  };

  self.init = function () {
    self.viewListModel(new Exposync.ListModel({}));
    self.editListModel(new Exposync.ListModel({}));
  };
  self.init();

  function compare(a, b) {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  }
};
