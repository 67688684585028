var SentEmailsBacklogViewModel = function () {
  var self = this;
  self.emails = ko.observableArray([]);
  self.error = ko.observable();
  self.expos = ko.observable();
  self.campaigns = ko.observableArray([]);
  self.expoId = ko.observable();
  self.email = ko.observable();
  self.campaignId = ko.observable();
  self.campaignName = ko.observable("All Campaigns");
  self.start = ko.observable(new moment().add(-7, "days"));
  self.end = ko.observable(new moment());
  self.rule = ko.observable("");
  self.email_event = ko.observable("all");
  self.total_emails = ko.observable(0);
  self.emailDetailedEvents = ko.observableArray([]);
  self.emailDetailFor = ko.observable();
  self.individual = ko.observable();
  self.email_stats = ko.observable({
    last_open: null,
    last_bounced: null,
    bounce: 0,
    open: 0,
    sent: 0,
    processed: 0,
    delivered: 0,
    delivered_percent: "0%",
    opened_percent: "0%",
  });
  self.email_history = ko.observableArray([]);
  baseViewModel.call(self);
  var emailsTable;
  var table;

  var tableOptions = Object.assign(utils.dataTableOptions, {
    searchDelay: 100,
    buttons: [],
    dom: '<"top"f><"pull-right"i>rt<"bottom"lp><"clear">',
  });
  //var table = $("#emails-table").DataTable(tableOptions);
  self.getAllExpos = function () {
    var options = {
      q: "",
      p: 0,
      ps: 1000,
    };
    ExpoApi.find(options, function (response) {
      var filtered = ko.utils.arrayFilter(response.data, function (item) {
        return item.archived == false;
      });
      var expos = $.map(filtered, function (item) {
        return new Exposync.ExpoModel(item);
      });
      self.expos(expos.sort(expoCompare));
    });
  };

  self.exportBacklog = function () {
    const campaign = self.campaigns().find((x) => x.id === self.campaignId());
    if (campaign) {
      self.campaignName(
        campaign.message_name + " (" + campaign.client.exhibitor_name + ")"
      );
    }
    $("#export_emails_form").attr("action", "/api/messages/backlog/export");
    self.error("");
    if (!self.expoId()) {
      self.error("You must select an expo before running search.");
      return;
    }
    $("#export_emails_form").submit();
  };

  self.sendExportBacklogToEmail = function () {
    const campaign = self.campaigns().find((x) => x.id === self.campaignId());
    if (campaign) {
      self.campaignName(
        campaign.message_name + " (" + campaign.client.exhibitor_name + ")"
      );
    }

    self.error("");
    if (!self.expoId()) {
      self.error("You must select an expo before running search.");
      return;
    }
    const data = {
      expo_id: self.expoId(),
      email_event: self.email_event(),
      message_id: self.campaignId(),
      email: self.email(),
    };
    MessagesApi.sendExportBacklogToEmail(data, function (data) {
      utils.showSuccess("The report will be delivered to your email.");
    });
  };

  self.searchEmailBacklog = function () {
    self.error("");
    var data = {};
    if (!self.expoId()) {
      self.error("You must select an expo before running search.");
      return;
    }

    utils.showLoader("#emails-table");

    data.expo_id = self.expoId();
    data.email_event = self.email_event();

    if (self.campaignId()) {
      data.message_id = self.campaignId();
    }

    if (self.email()) {
      data.email = self.email();
    }

    self.emails([]);
    utils.showLoader("#page_container");
    MessagesApi.sentEmailsSearch(data, function (result) {
      //self.total_emails(result.length);
      self.emails(result);
      if (!table) {
        // utils.dataTableOptions.order = [
        //   [1, "desc"]
        // ]
        table = $("#emails-table").dataTable(tableOptions);
      }
      utils.hideLoader("#emails-table");
      utils.hideLoader("#page_container");
    });
  };

  self.emailStatusReport = function () {
    self.error("");
    var data = {};
    if (!self.expoId()) {
      self.error("You must select an expo to generate report.");
      return;
    }
    data.expo_id = self.expoId();
    if (self.campaignId()) {
      data.message_id = self.campaignId();
    }

    MessagesApi.emailStatusReport(data, function (result) {
      utils.showSuccess("The report will be delivered to your email.");
    });
  };

  self.showEmailDetails = function (model) {
    utils.showLoader(".modal-body");
    self.emailDetailedEvents([]);
    self.emailDetailFor(model.email);
    MessagesApi.sentEmailEvents(model._id, function (response) {
      const mapped = response.map(({ event, timestamp, ip, url, reason }) => ({
        event,
        timestamp,
        ip: ip || "",
        url: url || "",
        reason: reason || "",
      }));
      self.emailDetailedEvents(mapped);
      if (!emailsTable) {
        utils.dataTableOptions.order = [[1, "desc"]];
        emailsTable = $("#email-logs-table").dataTable(utils.dataTableOptions);
      }
      utils.hideLoader(".modal-body");
    });

    $("#emailDetailsModal").modal("show");
  };

  self.editIndividual = function (model) {
    $("#updateIndividualModal").modal("show");
    self.getIndividual(model.individual_id);
  };

  self.updateIndividual = function (model) {
    const categories = model.categories();
    const url = "/api/individuals/" + model.id();
    var data = model.toJSON();

    ajax.post(url, data, function (data) {
      utils.showSuccess(success);
    });
  };

  self.removeBounce = function (model) {
    const data = {
      expo_id: model.expo_id(),
      individual_id: model.id(),
      email: model.email_first(),
    };
    MessagesApi.removeBounce(data, function (result) {
      self.getIndividual(model.id());
    });
  };

  self.getIndividual = function (id, fnSuccess) {
    var url = "/api/individuals/" + id;
    ajax.get(url, function (result) {
      self.viewEmailStats(id);
      self.viewEmailLogs(id, result.email_first, result.expo_id);
      self.individual(new Exposync.IndividualModel(result));
      if (typeof fnSuccess === "function") {
        fnSuccess(result);
      }
    });
  };

  self.viewEmailStats = function (id) {
    utils.showLoader($("#stats-table"));
    var email_stats = {
      last_open: "",
      last_bounced: "",
      soft_bounce: 0,
      hard_bounce: 0,
      open: 0,
      sent: 0,
      processed: 0,
      delivered: 0,
      unique_opens: 0,
      unique_clicks: 0,
      delivered_percent: "0%",
      opened_percent: "0%",
    };
    self.email_stats(email_stats);
    IndividualsApi.getEmailStats(id, function (ret) {
      ret.forEach(function (item) {
        email_stats[item._id] = item.count || 0;
        if (item._id === "open") email_stats.last_open = item.latest;
        if (item._id === "hard_bounce" || item._id === "soft_bounce")
          email_stats.last_bounced = item.latest;
      });

      if (email_stats.processed !== 0 && email_stats.delivered !== 0)
        email_stats.delivered_percent =
          ((email_stats.delivered / email_stats.processed) * 100).toFixed(2) +
          "%";
      if (email_stats.unique_opens !== 0 && email_stats.delivered !== 0)
        email_stats.opened_percent =
          ((email_stats.unique_opens / email_stats.delivered) * 100).toFixed(
            2
          ) + "%";

      self.email_stats(email_stats);
      utils.hideLoader($("#stats-table"));
    });
  };

  self.viewEmailLogs = function (id, email, expo_id) {
    self.email_history([]);
    // utils.showLoader($('#history-table'))
    IndividualsApi.getEmailHistory(id, email, expo_id, function (data) {
      var mapped = data.map((r) => {
        var events = r.events.map((e) => {
          let event = e.event;
          if (event === "bounce") {
            event = e.type === "bounce" ? "hard_bounce" : "soft_bounce";
          }
          return {
            date: e.date,
            event,
            reason: e.reason || "",
          };
        });
        return {
          email: r.email,
          subject: r.subject,
          _id: r._id,
          events: events,
        };
      });

      self.email_history(mapped);
      // utils.hideLoader($('#history-table'))
    });
  };

  var uniqueArray = function (arrArg) {
    return arrArg.filter(function (elem, pos, arr) {
      return arr.indexOf(elem) == pos;
    });
  };

  self.expoId.subscribe(function (value) {
    var options = {
      q: "",
      p: 0,
      ps: 1000,
    };
    MessagesApi.find(value, options, function (response) {
      self.campaigns(response.data);
    });
  });
};
