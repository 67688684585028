"use strict";
var TypeAhead = (function () {
  var initIndividuals = function (el, expo_id) {
    var individuals = new Bloodhound({
      datumTokenizer: function (d) {
        return Bloodhound.tokenizers.whitespace(d.value);
      },
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: "/api/individuals/expo/" + expo_id + "?q=%QUERY&ps=10",

        filter: function (individuals) {
          return $.map(individuals.data, function (individual) {
            if (individual.email_first) {
              return {
                id: individual.id,
                name: individual.name_first + " " + individual.name_last,
                email: individual.email_first,
              };
            }
          });
        },
        wildcard: "%QUERY",
      },
    });

    $(el)
      .typeahead(
        {
          hint: false,
          highlight: true,
          minLength: 2,
        },
        {
          limit: 20,
          displayKey: "name",
          source: individuals,
          templates: {
            empty: '<div class="padding-left-5">No matches.</div>',
          },
        }
      )
      .on("typeahead:selected", function ($e, datum) {
        var id = $($e.target).data("element");
        var test_email_input = $($e.target).data("email");
        $("#" + id).val(datum["id"]);
        $("#" + id).trigger("change");
        $("#" + test_email_input).val(datum["email"]);
        $("#" + test_email_input).trigger("change");
      });

    $(el).keydown(function (event) {
      if (event.keyCode == 13) {
        event.preventDefault();
        return false;
      }
    });
  };

  var initUsers = function (el) {
    var users = new Bloodhound({
      datumTokenizer: function (d) {
        return Bloodhound.tokenizers.whitespace(d.value);
      },
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: "/api/user/?q=%QUERY",
        //rateLimitBy: 'throttle',
        filter: function (users) {
          return $.map(users.data, function (user) {
            var userRoleFilter = "";
            if (userRoleFilter) {
              if (user.roles.indexOf(userRoleFilter) > -1) {
                return {
                  id: user.id,
                  name: user.name_first + " " + user.name_last,
                };
              }
            } else {
              return {
                id: user.id,
                name: user.name_first + " " + user.name_last,
              };
            }
          });
        },
        wildcard: "%QUERY",
      },
      limit: 10,
    });

    users.initialize();
    $(el)
      .typeahead(null, {
        displayKey: "name",
        limit: 10,
        source: users.ttAdapter(),
        templates: {
          empty: '<div class="padding-left-5">No matches.</div>',
        },
      })
      .on("typeahead:selected", function ($e, datum) {
        var id = $($e.target).data("element");
        $("#" + id).val(datum["id"]);
        $("#" + id).trigger("change");
      });

    $(el).keydown(function (event) {
      if (event.keyCode == 13) {
        event.preventDefault();
        return false;
      }
    });
  };

  var initExpos = function (el) {
    var expos = new Bloodhound({
      datumTokenizer: function (datum) {
        return Bloodhound.tokenizers.whitespace(datum.value);
      },
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: "/api/expo/?q=%QUERY",
        filter: function (expos) {
          return $.map(expos.data, function (expo) {
            if (!expo.archived) {
              return {
                id: expo.id,
                name: expo.expo_name,
              };
            }
          });
        },
        wildcard: "%QUERY",
      },
      limit: 10,
    });

    expos.initialize();
    $(el)
      .typeahead(null, {
        displayKey: "name",
        source: expos.ttAdapter(),
      })
      .on("typeahead:selected", function ($e, datum) {
        var id = $($e.target).data("element");
        $("#" + id).val(datum["id"]);
        $("#" + id).trigger("change");
      });

    $(el).keydown(function (event) {
      if (event.keyCode == 13) {
        event.preventDefault();
        return false;
      }
    });
  };

  return {
    users: initUsers,
    expos: initExpos,
    individuals: initIndividuals,
  };
})();
