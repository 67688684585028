var SchedulerViewModel = function () {
  var self = this;
  self.jobId = ko.observable();
  self.interval_type = ko.observable("once");
  self.send_date = ko.observable();
  self.schedule_start_date = ko.observable();
  self.schedule_end_date = ko.observable();
  self.timezone = ko.observable("America/Los_Angeles");

  self.interval = ko.observable("day");
  self.day_frequency = ko.observableArray(["MON", "TUE", "WED", "THU", "FRI"]);
  self.daily_send_time = ko.observable();
  self.hour_frequency = ko.observable(1);
  self.minute_frequency = ko.observable(30);

  self.completed_jobs = ko.observableArray([]);
  self.scheduled_jobs = ko.observableArray([]);
  self.showingCompleted = ko.observable(true);
  baseViewModel.call(self);

  function parseSchedulingOptions() {
    var data = {};

    data.interval_type = self.interval_type();
    data.job_id = self.jobId();

    if (data.interval_type == "now") {
      //no other data needed.
    } else if (data.interval_type == "once") {
      data.send_date = moment
        .tz(self.send_date().format("YYYY-MM-DD HH:mm"), self.timezone())
        .format(); //self.send_date().format('YYYY-MM-DD h:mm a') //.isValid() ? self.send_date().format() : null
      data.timezone = self.timezone();
    } else if (data.interval_type == "repeat") {
      data.timezone = self.timezone();

      //Updates
      data.interval = self.interval();
      if (data.interval == "day") {
        data.day_frequency = self.day_frequency();
        data.daily_send_time = moment
          .tz(
            self.daily_send_time().format("YYYY-MM-DD HH:mm"),
            self.timezone()
          )
          .format(); //self.daily_send_time()
      } else if (data.interval == "hour") {
        data.hour_frequency = self.hour_frequency();
      } else if (data.interval == "minute") {
        data.minute_frequency = self.minute_frequency();
      }
    }
    return data;
  }

  self.getAllExpos = function () {
    var options = {
      q: "",
      p: 0,
      ps: 1000,
    };
    ExpoApi.find(options, function (response) {
      var filtered = ko.utils.arrayFilter(response.data, function (item) {
        return item.archived == false;
      });
      var expos = $.map(filtered, function (item) {
        return new Exposync.ExpoModel(item);
      });
      self.expos(expos.sort(expoCompare));
    });
  };
  self.showCompleted = function () {
    self.showingCompleted(true);
  };
  self.showScheduled = function () {
    self.showingCompleted(false);
  };

  self.scheduleJob = function () {
    $("#schedulingModal").modal("show");
  };

  self.searchEmailBacklog = function () {
    self.error("");
    var data = {};
    if (!self.expoId()) {
      self.error("You must select an expo before running search.");
      return;
    }
    data.expo_id = self.expoId();
    if (self.campaignId()) {
      data.campaign_id = self.campaignId();
    }
    if (self.rule()) {
      data.rule = self.rule();
    }
    if (self.processed_status() !== "all") {
      data.status = self.processed_status();
    }
    self.emails([]);
    utils.showLoader("#page_container");
    MessagesApi.emailLogSearch(data, function (result) {
      self.emails(result);
      utils.hideLoader("#page_container");
    });
  };

  self.findAllJobs = function () {
    ExpoApi.findJobs(function (data) {
      self.completed_jobs(data.completedJobs);
      self.scheduled_jobs(data.scheduledJobs);
    });
  };

  self.createOrUpdateJob = function () {
    var data = parseSchedulingOptions();

    JobsApi.save(data, function () {
      $("#schedulingModal").modal("hide");
      utils.showSuccess("Job succcessfully updated.");
    });
  };

  self.init = function () {
    self.findAllJobs();
  };
};
