var ListApi = {
  get: function (id, fnSuccess) {
    var url = "/api/lists/" + id;
    ajax.get(url, function (data) {
      fnSuccess(data);
    });
  },

  getExhibitorList: function (id, fnSuccess) {
    var url = "/api/lists/exhibitor/" + id;
    ajax.get(url, function (data) {
      fnSuccess(data);
    });
  },

  find: function (expoId, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/lists/expo/" + expoId + query_string;
    ajax.get(url, fnSuccess);
  },

  findExhibitorList: function (expoId, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/lists/expo/exhibitor/" + expoId + query_string;
    ajax.get(url, fnSuccess);
  },

  findEntries: function (id, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/lists/" + id + "/entries" + query_string;
    ajax.get(url, fnSuccess);
  },

  findExhibitorListEntries: function (id, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/lists/exhibitor/" + id + "/entries" + query_string;
    ajax.get(url, fnSuccess);
  },

  update: function (id, data, fnSuccess) {
    var url = "/api/lists/" + id;
    ajax.post(url, data, fnSuccess);
  },

  updateExhibitorList: function (id, data, fnSuccess) {
    var url = "/api/lists/exhibitor/" + id;
    ajax.post(url, data, fnSuccess);
  },

  save: function (expoId, data, fnSuccess) {
    var url = "/api/lists/create/expo/" + expoId;
    ajax.post(url, data, fnSuccess);
  },

  saveExhibitorList: function (expoId, data, fnSuccess) {
    var url = "/api/lists/create_exhibitor/expo/" + expoId;
    ajax.post(url, data, fnSuccess);
  },

  delete: function (id, fnSuccess) {
    var url = "/api/lists/" + id;
    ajax.delete(url, {}, fnSuccess);
  },

  deleteExhibitorList: function (id, fnSuccess) {
    var url = "/api/lists/exhibitor/" + id;
    ajax.delete(url, {}, fnSuccess);
  },

  refresh: function (id, fnSuccess, fnError) {
    var url = "/api/lists/" + id + "/refresh";
    ajax.post(url, {}, fnSuccess, fnError);
  },

  refreshExhibitorList: function (id, fnSuccess, fnError) {
    var url = "/api/lists/" + id + "/refresh_exhibitor";
    ajax.post(url, {}, fnSuccess, fnError);
  },

  addEntry: function (id, data, fnSuccess) {
    var url = "/api/lists/" + id + "/entries/add";
    ajax.post(url, data, fnSuccess);
  },

  addExhibitorListEntry: function (id, data, fnSuccess) {
    var url = "/api/lists/" + id + "/entries/exhibitor/add";
    ajax.post(url, data, fnSuccess);
  },

  updateStatuses: function (id, data, fnSuccess) {
    var url = "/api/lists/" + id + "/entries/statuses";
    ajax.post(url, data, fnSuccess);
  },
  updateStatusesExhibitorList: function (id, data, fnSuccess) {
    var url = "/api/lists/" + id + "/entries/exhibitor/statuses";
    ajax.post(url, data, fnSuccess);
  },
  approveAll: function (id, fnSuccess) {
    var url = "/api/lists/" + id + "/entries/approve_all";
    ajax.post(url, {}, fnSuccess);
  },
  approveAllExhibitorList: function (id, fnSuccess) {
    var url = "/api/lists/" + id + "/entries/exhibitor/approve_all";
    ajax.post(url, {}, fnSuccess);
  },
  getListsCount: function (ids, fnSuccess) {
    var url = "/api/lists/many/count";
    ajax.post(url, ids, function (data) {
      fnSuccess(data);
    });
  },
  getExhibitorListsCount: function (ids, fnSuccess) {
    var url = "/api/lists/exhibitor/many/count";
    ajax.post(url, ids, function (data) {
      fnSuccess(data);
    });
  },
  getListsDistinctCount: function (data, fnSuccess) {
    var url = "/api/lists/many/distinct_count";
    ajax.post(url, data, function (response) {
      fnSuccess(response);
    });
  },
};
