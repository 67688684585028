var EmailsBacklogViewModel = function () {
  var self = this;
  self.emails = ko.observableArray([]);
  self.error = ko.observable();
  self.expos = ko.observable();
  self.campaigns = ko.observableArray([]);
  self.expoId = ko.observable();
  self.campaignId = ko.observable();
  self.start = ko.observable(new moment().add(-7, "days"));
  self.end = ko.observable(new moment());
  self.rule = ko.observable("");
  self.processed_status = ko.observable("DEFERRED_EMAIL");

  baseViewModel.call(self);

  self.getAllExpos = function () {
    var options = {
      q: "",
      p: 0,
      ps: 1000,
    };
    ExpoApi.find(options, function (response) {
      var filtered = ko.utils.arrayFilter(response.data, function (item) {
        return item.archived == false;
      });
      var expos = $.map(filtered, function (item) {
        return new Exposync.ExpoModel(item);
      });
      self.expos(expos.sort(expoCompare));
    });
  };

  self.searchEmailBacklog = function () {
    self.error("");
    var data = {};
    if (!self.expoId()) {
      self.error("You must select an expo before running search.");
      return;
    }
    data.expo_id = self.expoId();
    if (self.campaignId()) {
      data.campaign_id = self.campaignId();
    }
    if (self.rule()) {
      data.rule = self.rule();
    }
    if (self.processed_status() !== "all") {
      data.status = self.processed_status();
    }
    self.emails([]);
    utils.showLoader("#page_container");
    MessagesApi.emailLogSearchWithCampaignName(data, function (result) {
      self.emails(result);
      utils.hideLoader("#page_container");
    });
  };

  self.expoId.subscribe(function (value) {
    var options = {
      q: "",
      p: 0,
      ps: 1000,
    };
    if (value)
      MessagesApi.find(value, options, function (response) {
        self.campaigns(response.data);
      });
  });
};
