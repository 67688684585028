var ExpoViewModel = function () {
  var self = this;
  self.expos = ko.observableArray([]);
  self.error = ko.observable();

  self.exhibitors = ko.observableArray([]);
  self.individuals = ko.observableArray([]);
  self.events = ko.observableArray([]);
  self.categories = ko.observableArray([]);
  self.team = ko.observableArray();

  self.managerToAdd = ko.observable();
  self.synchronizerToAdd = ko.observable();
  self.marketerToAdd = ko.observable();

  self.createExpoFrom = ko.observable();
  self.copyFromExpoId = ko.observable();

  self.unassignUserName = ko.observable();
  self.unassignRole = ko.observable();
  self.unassignUserId = ko.observable();
  self.unassignedTransferWorkload = ko.observable();

  self.contacts = ko.observableArray([]);
  self.searchIndividualsResult = ko.observableArray([]);

  self.marketersList = ko.observableArray([]);
  self.managersList = ko.observableArray([]);
  self.synchronizersList = ko.observableArray([]);

  self.marketersAll = ko.observableArray([]);
  self.managersAll = ko.observableArray([]);
  self.synchronizersAll = ko.observableArray([]);

  self.reassignmentUserList = ko.observableArray([]);

  //Add Expo Contact
  self.searchIndividualName = ko.observable();
  self.uploadProgress = ko.observable(0);
  self.uploadedExpoLogo = ko.observable();

  self.dashboardData = ko.observable({
    individuals: 0,
    guides_created: 0,
    individuals_unsubscribed: 0,
    exhibitors: 0,
    exhibitors_suppressed: 0,
    clients: 0,
    events: 0,
    categories: 0,
    eu_contacts: 0,
    canada_contacts: 0,
  });

  self.last25campaigns = ko.observableArray([]);
  self.next25campaigns = ko.observableArray([]);

  self.guideSettings = ko.observable();

  baseViewModel.call(self);

  self.uploadExpoLogo = function () {
    $.blockUI({ baseZ: 9999, message: $("#upload-message") });

    var file = document.getElementById("upload-file").files[0];
    if (file != undefined && file != null) {
      var name =
        "expo_" + Math.floor(1000000000 * Math.random()) + "_" + file.name;
      UploadsApi.upload(
        file,
        name,
        function () {
          $.unblockUI();
          self.uploadedExpoLogo(name);
        },
        function (msg) {
          $.unblockUI();
          utils.showError(msg);
        },
        function (progress) {
          self.uploadProgress(Math.round(progress * 100));
        }
      );
    } else {
      utils.showError(
        "Please select an image before clicking the upload button."
      );
      $.unblockUI();
    }
  };

  self.updateExpoLogo = function () {
    var expoId = self.expo().id();
    var data = {};
    data.expo_logo_url = self.uploadedExpoLogo();
    ExpoApi.update(expoId, data, function () {
      self.expo().expo_logo_url(data.expo_logo_url);
      self.uploadedExpoLogo("");
      $("#expoLogoModal").modal("hide");
    });
  };

  self.activeExposDataSource = function (options, callback) {
    ExpoApi.find(options, function (response) {
      var filtered = ko.utils.arrayFilter(response.data, function (item) {
        return item.archived == false;
      });
      response.data = filtered;
      callback(response);
    });
  };

  self.activeExposTableRowCallback = function (row) {
    $(row).attr("style", "cursor: pointer");
  };

  self.archivedExposDataSource = function (options, callback) {
    ExpoApi.find(options, function (response) {
      var filtered = ko.utils.arrayFilter(response.data, function (item) {
        return item.archived == true;
      });
      response.data = filtered;
      callback(response);
    });
  };

  self.archivedExposTableRowCallback = function (row) {};

  ko.computed(function () {
    var role = self.unassignRole();
    var userId = self.unassignUserId();

    self.reassignmentUserList([]);

    switch (role) {
      case "manager":
        ko.utils.arrayForEach(self.managersAll(), function (item) {
          if (item.id != self.unassignUserId()) {
            self.reassignmentUserList.push(item);
          }
        });
        break;
      case "marketer":
        ko.utils.arrayForEach(self.marketersAll(), function (item) {
          if (item.id != self.unassignUserId()) {
            self.reassignmentUserList.push(item);
          }
        });
        break;
      case "synchronizer":
        ko.utils.arrayForEach(self.synchronizersAll(), function (item) {
          if (item.id != self.unassignUserId()) {
            self.reassignmentUserList.push(item);
          }
        });
        break;
    }
  });
  ko.computed(function () {
    var team = self.team();

    if (!team) return;
    self.managersAll([]);
    self.marketersAll([]);
    self.synchronizersAll([]);

    ExpoApi.getManagers("", function (list) {
      self.managersList([]);
      self.managersAll(list);
      ko.utils.arrayForEach(list, function (item) {
        var found = ko.utils.arrayFirst(self.managers(), function (manager) {
          return manager.user_id == item.id;
        });
        if (!found) {
          self.managersList.push(item);
        }
      });
    });

    ExpoApi.getMarketers("", function (list) {
      self.marketersAll(list);
      self.marketersList([]);
      ko.utils.arrayForEach(list, function (item) {
        var found = ko.utils.arrayFirst(self.marketers(), function (marketer) {
          return marketer.user_id == item.id;
        });
        if (!found) {
          self.marketersList.push(item);
        }
      });
    });

    ExpoApi.getSynchronizers("", function (list) {
      self.synchronizersAll(list);
      self.synchronizersList([]);
      ko.utils.arrayForEach(list, function (item) {
        var found = ko.utils.arrayFirst(
          self.synchronizers(),
          function (synchronizer) {
            return synchronizer.user_id == item.id;
          }
        );
        if (!found) {
          self.synchronizersList.push(item);
        }
      });
    });
  }, self);

  var expoModelMapping = {
    ignore: [
      "id",
      "formattedAddress",
      "customCompanyFields",
      "customIndividualFields",
    ],
    start_date: {
      create: function (options) {
        return options.data.format();
      },
    },
  };

  self.activeExpos = ko.computed(function () {
    return ko.utils.arrayFilter(self.expos(), function (item) {
      return item.archived() == false;
    });
  });

  self.archivedExpos = ko.computed(function () {
    return ko.utils.arrayFilter(self.expos(), function (item) {
      return item.archived() == true;
    });
  });

  self.managers = ko.computed(function () {
    return ko.utils.arrayFilter(self.team(), function (item) {
      return item.role == "manager";
    });
  });

  self.synchronizers = ko.computed(function () {
    return ko.utils.arrayFilter(self.team(), function (item) {
      return item.role == "synchronizer";
    });
  });

  self.marketers = ko.computed(function () {
    return ko.utils.arrayFilter(self.team(), function (item) {
      return item.role == "marketer";
    });
  });

  self.organizers = ko.computed(function () {
    return ko.utils.arrayFilter(self.team(), function (item) {
      return item.role.startsWith("organizer_");
    });
  });

  self.newExpo = function () {
    location.href = "/admin/expos/edit/";
  };

  self.addExpo = function () {
    if (self.createExpoFrom() == "blank") {
      location.href = "/admin/expos/edit/";
    } else {
      location.href = "/admin/expos/copy/?" + self.copyFromExpoId();
    }
  };

  self.editExpo = function (expo) {
    location.href = "/admin/expos/edit/?" + expo.id;
  };

  self.editDetails = function (expo) {
    location.href = "/expo/information/edit/?" + expo.id();
  };

  self.cancelEditDetails = function (model) {
    var expo = model.expo();
    location.href = "/expo/information/?" + expo.id();
  };

  (self.deleteExpo = function (model) {
    var expo = model.expo();
    ExpoApi.delete(expo.id(), function () {
      utils.showSuccess("Expo was successfully deleted.");
      setTimeout(function () {
        location.href = "/admin/expos/";
      }, 2000);
    });
  }),
    (self.archiveExpo = function (model) {
      var expo = model.expo();
      var data = { archived: true };
      ExpoApi.update(expo.id(), data, function () {
        utils.showSuccess("Expo was successfully archived.");
        setTimeout(function () {
          location.href = "/admin/expos/";
        }, 2000);
      });
    }),
    (self.getExpo = function (id) {
      if (!id) return;
      ExpoApi.get(id, function (expo) {
        self.expo(expo);
        ExpoApi.getContacts(id, function (data) {
          self.expo().contacts(data);
        });
      });
    });

  self.cancelEdit = function () {
    location.href = "/admin/expos/";
  };

  self.updateExpo = function (model) {
    if (!$("#edit_expo_form").valid()) {
      return false;
    }
    var expo = model.expo();
    url = "/api/expo/" + expo.id();
    ajax.post(url, expo.toJSON(), function (data) {
      utils.showSuccess("Successfully updated expo.");
      var arr = location.href.split("/");
      var return_url = "/expo/information";

      if ($.inArray("admin", arr) > -1) {
        return_url = "/admin/expos/";
      }
      setTimeout(function () {
        location.href = return_url;
      }, 2000);
    });
  };

  self.getTeam = function (id) {
    if (!id) return;
    url = "/api/expo_perms/expo/" + id + "?q=&p=0&ps=10000&sort=";
    ajax.get(url, function (data) {
      self.team(data);
    });
  };

  self.startUnassignFromExpo = function (expoPerms) {
    self.unassignUserName(
      expoPerms.user.name_first + " " + expoPerms.user.name_last
    );
    self.unassignRole(expoPerms.role);
    self.unassignUserId(expoPerms.user_id);
    $("#unassignExpoModal").modal();
  };

  self.removeAssignment = function (member) {
    var userId = self.unassignUserId();
    var role = self.unassignRole();
    var transferTo = self.unassignedTransferWorkload();
    var expoId = self.expo().id();

    var data = {};
    data.reassignments = [{ expo_id: expoId, role: role, user_id: transferTo }];

    if (userId && expoId) {
      ajax.delete(
        "/api/expo_perms/user/" + userId + "/expo/" + expoId + "/role/" + role,
        data,
        function () {
          utils.showSuccess("User was been successfully removed.");
          self.getTeam(expoId);
        }
      );
    }
  };

  self.addCustomField = function () {
    self.expo().customExpoFields.push({ key: "", value: "" });
  };

  self.removeCustomField = function (item) {
    self.expo().customExpoFields.remove(item);
  };

  self.removeExhibitorCustomField = function (item) {
    self.expo().customCompanyFields.remove(item);
  };

  self.removeIndividualCustomField = function (item) {
    self.expo().customIndividualFields.remove(item);
  };

  self.addExhibitorCustomField = function () {
    self.expo().customCompanyFields.push({ value: "" });
  };

  self.addIndividualCustomField = function () {
    self.expo().customIndividualFields.push({ value: "" });
  };

  self.addManager = function () {
    self.addTeam(self.managerToAdd(), self.expo().id(), "manager");
  };

  self.addMarketer = function () {
    self.addTeam(self.marketerToAdd(), self.expo().id(), "marketer");
  };

  self.addSynchronizer = function () {
    self.addTeam(self.synchronizerToAdd(), self.expo().id(), "synchronizer");
  };

  self.addTeam = function (userId, expoId, role) {
    if (userId && expoId && role) {
      ajax.post(
        "/api/expo_perms/user/" + userId + "/expo/" + expoId + "/role/" + role,
        {},
        function () {
          utils.showSuccess("User was successfully added.");
          self.getTeam(expoId);
        },
        function (req, status, err) {},
        "PUT"
      );
    }
  };

  self.searchIndividuals = function () {
    var expoId = self.expo().id();
    var options = utils.defaultQueryOptions;
    options.q = self.searchIndividualName();
    IndividualsApi.find(expoId, options, function (result) {
      self.searchIndividualsResult(result.data);
    });
  };

  self.addExpoContact = function (individual) {
    var expoId = self.expo().id();
    var id = individual.id;
    url = "/api/individuals/" + id;
    var data = {};
    data.is_expo_contact = true;
    ajax.post(url, data, function (data) {
      utils.showSuccess("Individual has been added as expo contact.");
      ExpoApi.getContacts(expoId, function (data) {
        self.expo().contacts(data);
      });
    });
  };

  self.addNewIndividual = function () {
    var expoId = self.expo().id();
    location.href = "/expo/individuals/edit/?eid=" + expoId + "&ec=1";
  };

  self.getDashboardData = function (id) {
    utils.showLoader("#quick-summary-table");
    utils.showLoader("#campaigns-table");

    ExpoApi.getDashboardData(id, function (data) {
      self.dashboardData(data);
      utils.hideLoader("#quick-summary-table");
    });

    ExpoApi.getLastCampaignsData(id, function (data) {
      var next25 = data.next25campaigns.map(function (item) {
        return {
          client_id: item.data.client_id,
          client_name: item.data.client_name,
          email_subject: item.data.email_subject,
          message_name: item.data.message_name,
          nextRunAt: item.nextRunAt,
          lastRunAt: item.lastRunAt || "",
          message_id: item.message_id,
          expo_id: item.data.expo_id,
        };
      });
      self.last25campaigns(data.last25campaigns);
      self.next25campaigns(next25);
      utils.hideLoader("#campaigns-table");
    });
  };

  self.goToMessage = function (batch) {
    window.open(
      "/services/messages/view/?id=" +
        batch.message_id +
        "&eid=" +
        batch.expo_id,
      "_blank"
    );
  };

  self.init = function () {
    self.expo(new Exposync.ExpoModel({}));
  };
  self.init();
};
