"use strict";
var EventsViewModel = function () {
  var self = this;
  self.eventId = ko.observable();
  self.expoEvent = ko.observable();
  self.events = ko.observableArray([]);
  self.error = ko.observable();
  self.uploadProgress = ko.observable(0);
  self.uploadedEventLogo = ko.observable();
  self.uploadedEventDocuments = ko.observableArray([]);
  self.document_to_delete = ko.observable();
  self.histories = ko.observableArray([]);
  self.exhibitors = ko.observableArray([]);
  var historiesTable;

  baseViewModel.call(self);

  self.uploadEventLogo = function () {
    $.blockUI({ baseZ: 9999, message: $("#upload-message") });

    var file = document.getElementById("upload-file").files[0];
    if (file != undefined && file != null) {
      var name =
        "event_" + Math.floor(1000000000 * Math.random()) + "_" + file.name;
      UploadsApi.upload(
        file,
        name,
        function () {
          $.unblockUI();
          self.uploadedEventLogo(name);
        },
        function (msg) {
          $.unblockUI();
          utils.showError(msg);
        },
        function (progress) {
          self.uploadProgress(Math.round(progress * 100));
        }
      );
    } else {
      utils.showError(
        "Please select an image before clicking the upload button."
      );
      $.unblockUI();
    }
  };

  self.updateEventLogo = function () {
    var eventId = self.eventId();
    var data = {};
    data.event_logo_url =
      "https://s3.amazonaws.com/exposync-uploads/" + self.uploadedEventLogo();
    EventsApi.update(eventId, data, function () {
      self.expoEvent().event_logo_url(data.event_logo_url);
      self.uploadedEventLogo("");
      $("#eventLogoModal").modal("hide");
    });
  };

  self.uploadEventDocuments = function () {
    $.blockUI({ baseZ: 9999, message: $("#upload-message") });

    var filenames = [];
    var event_documents = self.expoEvent().event_documents().slice();
    var file = document.getElementById("upload-documents").files[0];
    var files = document.getElementById("upload-documents").files;

    if (file != undefined && file != null) {
      UploadsApi.uploadMultiple(
        files,
        function (name) {
          event_documents.push(name);
          filenames.push(name);
          if (filenames.length == files.length) {
            $.unblockUI();
            self.uploadedEventDocuments(filenames);
          }
        },
        function (msg) {
          $.unblockUI();
          utils.showError(msg);
        },
        function (progress) {
          self.uploadProgress(Math.round(progress * 100));
        }
      );
    } else {
      utils.showError(
        "Please select a file before clicking the upload button."
      );
      $.unblockUI();
    }
  };

  self.updateEventDocuments = function () {
    var eventId = self.eventId();
    var data = {};
    data.event_documents = self
      .expoEvent()
      .event_documents()
      .concat(self.uploadedEventDocuments());
    EventsApi.update(eventId, data, function () {
      self.expoEvent().event_documents(data.event_documents);
      self.uploadedEventDocuments([]);
      $("#eventDocumentsModal").modal("hide");
    });
  };

  self.removeDocumentConfirmModal = function (document) {
    self.document_to_delete(document);
    $("#confirmRemoveDocumentModal").modal("show");
  };

  self.removeDocument = function () {
    var document = self.document_to_delete();

    var documents = ko.utils.arrayFilter(
      self.expoEvent().event_documents(),
      function (item) {
        return document !== item;
      }
    );
    var data = {};
    data.event_documents = documents;

    EventsApi.update(self.eventId(), data, function (data) {
      self.expoEvent().event_documents(documents);
      utils.showSuccess("Image has been successfully deleted.");
      $("#confirmRemoveDocumentModal").modal("hide");
    });
  };

  self.eventsDataSource = function (options, callback) {
    EventsApi.find(self.expoId(), options, function (data) {
      callback(data);
    });
  };

  self.eventsTableRowCallback = function (row) {
    $(row).attr("style", "cursor: pointer");
  };

  self.eventAttendanceDataSource = function (options, callback) {
    EventsApi.attendance(self.eventId(), options, function (data) {
      callback(data);
    });
  };

  self.eventAttendedDataSource = function (options, callback) {
    options.attended = 1;
    EventsApi.attendance(self.eventId(), options, function (data) {
      callback(data);
    });
  };

  self.eventAttendanceTableRowCallback = function (row) {
    $(row).attr("style", "cursor: pointer");
  };

  self.viewEvent = function (ev) {
    location.href = "/expo/events/view/?id=" + ev.id + "&eid=" + ev.expo_id;
  };

  self.viewIndividual = function (individual) {
    window.open(
      "/expo/individuals/view/?id=" +
        individual.id +
        "&eid=" +
        individual.expo_id,
      "_blank"
    );
  };

  self.editEvent = function (ev) {
    location.href = "/expo/events/edit/?id=" + ev.id() + "&eid=" + ev.expo_id();
  };

  self.newEvent = function () {
    location.href = "/expo/events/edit/?eid=" + self.expoId();
  };

  self.cancelEdit = function (model) {
    var _event = model.expoEvent();
    if (_event.id())
      location.href =
        "/expo/events/view/?id=" + _event.id() + "&eid=" + _event.expo_id();
    else location.href = "/expo/events/";
  };

  self.cancelView = function () {
    location.href = "/expo/events/";
  };

  self.updateEvent = function (model) {
    if (!$("#events_edit_form").valid()) {
      return false;
    }
    var _event = model.expoEvent();
    var url = "";
    var success = "";
    var expoId = model.expoId();
    if (_event.id()) {
      url = "/api/events/" + _event.id();
      success = "Event was successfully updated";
    } else {
      url = "/api/events/expo/" + expoId;
      success = "Event was successfully created.";
    }

    let redirectUrl = "/expo/events/view/?id=";

    ajax.post(url, _event.toJSON(), function (event) {
      utils.showSuccess(success);
      redirectUrl += event.id + "&eid=" + expoId;
      setTimeout(function () {
        location.href = redirectUrl;
      }, 2000);
    });
  };

  self.exportEvents = function () {
    $("#export_events").attr(
      "action",
      "/api/events/export_events/" + self.expoId()
    );
    $("#export_events").submit();
  };

  self.showHistory = function (event) {
    EventsApi.loadChangeHistory(event.id(), function (response) {
      self.histories(response);
      if (!historiesTable) {
        utils.dataTableOptions.order = [[0, "desc"]];
        historiesTable = $("#history-table").dataTable(utils.dataTableOptions);
      }
    });

    $("#historyModal").modal("show");
  };

  self.getEvent = function (id) {
    var url = "/api/events/" + id;
    ajax.get(url, function (result) {
      self.expoEvent(new Exposync.EventModel(result));
    });
  };

  self.getExhibitors = function (id) {
    ExhibitorsApi.getExhibitorsByExpoId(self.expoId(), function (result) {
      self.exhibitors(result);
    });
  };

  self.getExpo = function (id, fnSuccess) {
    ExpoApi.get(id, function (expo) {
      self.expo(expo);

      if (typeof fnSuccess === "function") {
        fnSuccess(expo);
      }
    });
  };

  self.init = function () {
    self.expoEvent(new Exposync.EventModel({}));
  };

  self.init();
};
