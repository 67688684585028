var PublicReplyDataViewModel = function () {
  var self = this;

  self.individualId = ko.observable();
  self.messageId = ko.observable();
  self.individual = ko.observable();
  self.exhibitor = ko.observable();

  self.expoId = ko.observable();
  self.expo = ko.observable();
  self.replyData = ko.observable();
  self.version = ko.observable("v1");
  self.includeData = ko.observable("no");
  self.individual_data = ko.observable();
  self.exhibitor_data = ko.observable();
  self.questions = ko.observableArray([]);
  self.categories = ko.observableArray([]);
  self.selectedCategories = ko.observableArray([]);
  self.showConfirmation = ko.observable(false);
  self.groupedCategories = ko.observableArray([]);

  self.uploadProgress = ko.observable(0);

  self.category_tree = $(document).on(
    "click",
    "#categories_tree a",
    function (event, data) {
      $.jstree.reference(this).open_all(this, false, false);
      $(event.target).find(".jstree-checkbox").click();
    }
  );

  self.selectionChanged = function (value) {
    if (self.selectedCategories().indexOf(value.item.path()) > -1) {
      self.selectedCategories.remove(value.item.path());
    } else self.selectedCategories.push(value.item.path());
  };

  ko.computed(function () {
    var exhibitor_data = self.exhibitor_data();
    var individual_data = self.individual_data();
    var replyData = self.replyData();

    if (self.includeData() == "yes") {
      if (replyData && exhibitor_data) {
        ko.utils.arrayForEach(
          replyData.questions().filter(function (q) {
            return q.field().indexOf("exhibitor:") === 0;
          }),
          function (item) {
            let field = item.field().split("exhibitor:")[1].trim();
            if (field.startsWith("custom:")) {
              field = item.field().split("custom:")[1].trim();
              item.value(exhibitor_data.custom_fields[field]);
            } else item.value(exhibitor_data[field]);
            if (item.field() === "exhibitor: primary_categories")
              item.checkedValues(exhibitor_data["primary_categories"]);
          }
        );
      }

      if (replyData && individual_data) {
        ko.utils.arrayForEach(
          replyData.questions().filter(function (q) {
            return q.field().indexOf("exhibitor:") !== 0;
          }),
          function (item) {
            let field = item.field();
            if (field.startsWith("custom:")) {
              const customFields = individual_data.custom_fields || {};
              field = item.field().split("custom:")[1].trim();
              item.value(customFields[field]);
            } else item.value(individual_data[item.field()]);
          }
        );
      }
    }
  });

  ko.computed(function (argument) {
    var exhibitor_data = self.exhibitor_data();
    var individual_data = self.individual_data();
    var replyData = self.replyData();
    var expo = self.expo();

    if (self.includeData() == "no") return;

    if (self.expo() && self.individual()) {
      if (
        ko.utils.arrayFilter(self.replyData().questions(), function (item) {
          return item.field() == "categories";
        }).length > 0
      ) {
        self.groupedCategories(
          NestableList.toTreeFormat(
            self.categories(),
            self.individual().categories()
          )
        );
        ko.utils.arrayForEach(self.replyData().questions(), function (item) {
          if (item.field() == "exhibitor: primary_categories") {
            item.selections(self.expo().categories());
          }
        });
        return;
      }
    }

    if (self.expo() && self.exhibitor()) {
      if (
        ko.utils.arrayFilter(self.replyData().questions(), function (item) {
          return item.field() == "exhibitor: categories";
        }).length > 0
      ) {
        self.groupedCategories(
          NestableList.toTreeFormat(
            self.categories(),
            self.exhibitor().categories()
          )
        );
        ko.utils.arrayForEach(self.replyData().questions(), function (item) {
          if (item.field() == "exhibitor: primary_categories") {
            item.selections(self.expo().categories());
          }
        });
        return;
      }
    }

    if (self.expo()) {
      self.groupedCategories(NestableList.toTreeFormat(self.categories()));
      ko.utils.arrayForEach(self.replyData().questions(), function (item) {
        if (item.field() == "exhibitor: primary_categories") {
          item.selections(self.expo().categories());
        }
      });
      return;
    }
  });

  self.getIndividual = function (id, fnSuccess) {
    PublicApi.getIndividual(id, function (data) {
      self.individual_data(data);
      self.individual(new Exposync.IndividualModel(data));
      if (typeof fnSuccess === "function") {
        fnSuccess(data);
      }
    });
  };

  self.getExpo = function (id, fnSuccess) {
    PublicApi.getExpo(id, function (data) {
      self.expo(new Exposync.ExpoModel(data));
      self.categories(data.categories);
      if (typeof fnSuccess === "function") {
        fnSuccess(data);
      }
    });
  };

  self.getExhibitor = function (expo_id, id, fnSuccess) {
    PublicApi.getExhibitor(expo_id, id, function (data) {
      self.exhibitor_data(data);
      self.exhibitor(new Exposync.CompanyModel(data));
      if (typeof fnSuccess === "function") {
        fnSuccess(data);
      }
    });
  };

  self.getReplyData = function (id, fnSuccess) {
    PublicApi.getReplyData(id, function (data) {
      self.replyData(new Exposync.ReplyDataModel(data));
      if (typeof fnSuccess === "function") {
        fnSuccess(data);
      }

      if (self.includeData() == "yes") {
      }
    });
  };

  self.submitAnswers = function () {
    var questions = self.replyData().questions();
    var data = {};

    if (!$("#reply-data-form").valid()) {
      return;
    }

    if (self.exhibitor()) data.company_id = self.exhibitor().id();

    ko.utils.arrayForEach(questions, function (item) {
      if (item.type() == "multiple-selection") {
        data[item.field()] = item
          .checkedValues()
          .map(function (s) {
            return s.trim();
          })
          .join(";");
      } else if (item.type() == "multiple-items") {
        data[item.field()] = item.checkedValues();
      } else if (item.type() == "date") {
        data[item.field()] = item.value().isValid()
          ? item.value().format("MM/DD/YYYY")
          : null;
      } else if (item.type() == "tree") {
        if (item.field().indexOf("categories") !== -1) {
          data[item.field()] = $("#categories_tree").jstree().get_selected();
        }
      } else if (item.type() === "url") {
        // remove http:// and/or other protocols
        data[item.field()] = item.value().replace(/(^\w+:|^)\/\//, "");
      } else {
        data[item.field()] = item.value();
      }
    });

    PublicApi.updateIndividual(
      self.individualId(),
      {
        data: data,
        message_id: self.messageId(),
      },
      function () {
        self.showConfirmation(true);
      }
    );
  };

  self.onFileChange = (data, event) => {
    const formatFileName = (name) => {
      const extension = name.match(/\.\w+$/)[0];
      return (
        name.slice(-name.length, -extension.length) +
        "_" +
        Math.floor(100 * Math.random()) +
        extension
      );
    };

    $.blockUI({ baseZ: 9999, message: $("#upload-message") });
    var file = event.target.files[0];
    if (file) {
      var name = formatFileName(file.name);
      UploadsApi.publicUpload(
        file,
        name,
        function () {
          $.unblockUI();
          data.value("https://s3.amazonaws.com/exposync-uploads/" + name);
        },
        function (msg) {
          $.unblockUI();
          utils.showError(msg);
        },
        function (progress) {
          self.uploadProgress(Math.round(progress * 100));
        }
      );
    } else {
      data.uploaded = undefined;
    }
  };
};
