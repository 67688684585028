var ListsViewModel = function () {
  var self = this;
  self.viewListModel = ko.observable();
  self.editListModel = ko.observable();
  self.listId = ko.observable();
  self.searchModel = ko.observable();
  self.lists = ko.observableArray([]);
  self.client = ko.observable();
  self.clients = ko.observableArray([]);
  self.error = ko.observable();
  self.searchCriteria = ko.observableArray([]);
  self.fields = ko.observableArray([]);
  self.matchCount = ko.observable(0);
  self.searchTemplates = ko.observable([]);
  self.isExhibitorList = ko.observable(false);

  self.selectedNewMatches = ko.observableArray([]);
  self.selectedApprovedMatches = ko.observableArray([]);
  self.selectedOmittedMatches = ko.observableArray([]);

  self.startFrom = ko.observable("blank");
  self.startFromTemplate = ko.observable();
  self.startFromList = ko.observable();

  self.list_entries_count = ko.observable({
    approved: 0,
    new: 0,
    omitted: 0,
  });
  //export
  self.selectedExportFields = ko.observableArray([
    "name_first",
    "name_last",
    "phone_first",
    "email_first",
    "address1_street1",
    "address1_street2",
    "address1_city",
    "address1_state",
    "address1_zip",
    "address1_country",
    "business_name",
    "business_title",
  ]);
  self.exportFields = ko.observableArray([
    "id",
    "registration_ids",
    "name_prefix",
    "name_first",
    "name_middle",
    "name_last",
    "name_suffix",
    "name_salutation",
    "address1_street1",
    "address1_street2",
    "address1_street3",
    "address1_city",
    "address1_state",
    "address1_zip",
    "address1_country",
    "address2_street1",
    "address2_street2",
    "address2_street3",
    "address2_city",
    "address2_state",
    "address2_zip",
    "address2_country",
    "business_name",
    "business_name2",
    "business_title",
    "business_role",
    "phone_first",
    "phone_second",
    "phone_fax",
    "phone_cell1",
    "phone_cell2",
    "email_first",
    "email_domain",
    "email_second",
    "email_status",
    "email_unsubscribed",
    "exhibitor_emails",
    "failed_emails",
    "guide_created",
    "exhibitor_id",
    "expo_roles",
    "exhibitor_roles",
    "expo_history",
    "list_member",
    "keywords",
    "demographics",
    "categories",
    "events_registered",
    "exhibitors_interested",
    "exhibitors_maybe",
    "exhibitors_notinterested",
    "exhibitors_clicked",
    "housing_id",
    "hotel_name",
    "housing_block",
    "hotel_check_in",
    "hotel_check_out",
    "hotel_rate",
    "hotel_num_nights",
  ]);

  self.selectedExhibitorsExportFields = ko.observableArray([
    "exhibitor_id",
    "exhibitor_name",
    "exhibitor_website",
    "booth_location",
    "booth_number",
    "exh_address_street1",
    "exh_address_street2",
    "exh_address_city",
    "exh_address_state",
    "exh_address_zip",
    "exh_address_country",
  ]);
  self.exhibitorsExportFields = ko.observableArray([
    "id",
    "exhibitor_id",
    "exhibitor_name",
    "exhibitor_website",
    "exhibitor_description",
    "booth_location",
    "booth_number",
    "guide_name_first",
    "guide_name_last",
    "guide_contact_title",
    "guide_email",
    "guide_phone",
    "guide_fax",
    "exh_address_street1",
    "exh_address_street2",
    "exh_address_city",
    "exh_address_state",
    "exh_address_zip",
    "exh_address_country",
    "nexus_status",
    "demographics",
    "categories",
    "primary_categories",
    "suppressed",
    "facebook_url",
    "twitter_url",
    "linkedin_url",
    "instagram_url",
    "pinterest_url",
    "snapchat_url",
    "youtube_url",
    "list_member",
    "company_domains",
    "expo_status",
    "expo_history",
    "company_type",
    "company_logo",
  ]);

  self.selectedCustomExportFields = ko.observableArray();
  self.selectedStatuses = ko.observableArray(["new", "approved", "omitted"]);

  baseViewModel.call(self);

  self.auto_approve = ko.observable();

  self.auto_approve.subscribe((value) => {
    var data = {
      auto_approve: value,
    };
    ListApi.updateExhibitorList(self.listId(), data, function (result) {});
  });

  self.customExportFields = ko.computed(function () {
    if (self.expo()) {
      return self
        .expo()
        .customIndividualFields()
        .map(function (item) {
          return item.value;
        });
    } else return [];
  });

  self.customExhibitorExportFields = ko.computed(function () {
    if (self.expo()) {
      return self
        .expo()
        .customCompanyFields()
        .map(function (item) {
          return item.value;
        });
    } else return [];
  });

  self.renderView = function (client, view) {
    location.href =
      "/services/clients/" +
      view +
      "/?id=" +
      client.id() +
      "&eid=" +
      client.expo_id();
  };

  self.listsDataSource = function (options, callback) {
    ListApi.find(self.expoId(), options, function (response) {
      var lists = response.data;

      if (lists.length > 0) {
        var ids = ko.utils.arrayMap(lists, function (item) {
          return item.id;
        });

        ListApi.getListsCount({ ids: ids }, function (data) {
          var counts = {};
          ko.utils.arrayForEach(data, function (item) {
            if (!counts[item.list_id]) counts[item.list_id] = {};
            counts[item.list_id][item.status] = item.total_count;
          });

          ko.utils.arrayForEach(lists, function (item) {
            item["approved"] =
              counts[item.id] !== undefined
                ? counts[item.id]["approved"] !== undefined
                  ? counts[item.id]["approved"]
                  : 0
                : 0;
            item["new_matches"] =
              counts[item.id] !== undefined
                ? counts[item.id]["new"] !== undefined
                  ? counts[item.id]["new"]
                  : 0
                : 0;
            item["omitted"] =
              counts[item.id] !== undefined
                ? counts[item.id]["omitted"] !== undefined
                  ? counts[item.id]["omitted"]
                  : 0
                : 0;
          });

          response.data = lists;
          callback(response);
        });
      } else {
        callback(response);
      }
    });
  };

  self.exhibitorListsDataSource = function (options, callback) {
    ListApi.findExhibitorList(self.expoId(), options, function (response) {
      var lists = response.data;

      if (lists.length > 0) {
        var ids = ko.utils.arrayMap(lists, function (item) {
          return item.id;
        });

        ListApi.getExhibitorListsCount({ ids: ids }, function (data) {
          var counts = {};
          ko.utils.arrayForEach(data, function (item) {
            if (!counts[item.list_id]) counts[item.list_id] = {};
            counts[item.list_id][item.status] = item.total_count;
          });

          ko.utils.arrayForEach(lists, function (item) {
            item["approved"] =
              counts[item.id] !== undefined
                ? counts[item.id]["approved"] !== undefined
                  ? counts[item.id]["approved"]
                  : 0
                : 0;
            item["new_matches"] =
              counts[item.id] !== undefined
                ? counts[item.id]["new"] !== undefined
                  ? counts[item.id]["new"]
                  : 0
                : 0;
            item["omitted"] =
              counts[item.id] !== undefined
                ? counts[item.id]["omitted"] !== undefined
                  ? counts[item.id]["omitted"]
                  : 0
                : 0;
          });

          response.data = lists;
          callback(response);
        });
      } else {
        callback(response);
      }
    });
  };

  self.listsTableRowCallback = function (row, data) {
    $(row).attr("style", "cursor: pointer");
    $(row).click(function (e) {
      location.href =
        "/services/lists/view/?id=" + data.id + "&eid=" + data.expo_id;
    });
  };

  self.exhibitorListsTableRowCallback = function (row, data) {
    $(row).attr("style", "cursor: pointer");
    $(row).click(function (e) {
      location.href =
        "/services/lists/view/exhibitor/?id=" +
        data.id +
        "&eid=" +
        data.expo_id;
    });
  };

  self.searchTemplatesDataSource = function (options, callback) {
    SearchApi.find(self.expoId(), options, function (data) {
      callback(data);
    });
  };

  self.searchTemplatesTableRowCallback = function (row, data) {
    $(row).attr("style", "cursor: pointer");
    $(row).click(function (e) {
      self.getSearch(data.id, function (data) {
        var searchModel = new Exposync.SearchModel(data, self.fields());
        searchModel.mode("update");
        self.searchModel(searchModel);

        $("#createSearchTemplateModal").modal("show");
      });
    });
  };

  self.newMatchesDataSource = function (options, callback) {
    options.status = "new";
    dataSource(options, callback);
  };

  self.entriesTableRowCallback = function (row, data) {
    $(row).attr("style", "cursor: pointer");
    $(row).click(function (e) {
      if (e.target.type == "checkbox") return;
      else if ($(e.target).find('input[type="checkbox"]').length > 0) {
        var selectBox = $(e.target).find('input[type="checkbox"]')[0];
        $(selectBox).trigger("click");
        return;
      } else {
        //get expo role
        if (self.isInRole("admin,manager"))
          window.open(
            "/expo/individuals/view/?id=" + data.id + "&eid=" + data.expo_id,
            "_blank"
          );
      }
    });
  };

  self.exhibitorEntriesTableRowCallback = function (row, data) {
    $(row).attr("style", "cursor: pointer");
    $(row).click(function (e) {
      if (e.target.type == "checkbox") return;
      else if ($(e.target).find('input[type="checkbox"]').length > 0) {
        var selectBox = $(e.target).find('input[type="checkbox"]')[0];
        $(selectBox).trigger("click");
        return;
      } else {
        //get expo role
        if (self.isInRole("admin,manager"))
          window.open(
            "/services/clients/view/?id=" + data.id + "&eid=" + data.expo_id,
            "_blank"
          );
      }
    });
  };

  self.approvedDataSource = function (options, callback) {
    options.status = "approved";
    dataSource(options, callback);
  };

  self.omittedDataSource = function (options, callback) {
    options.status = "omitted";
    dataSource(options, callback);
  };

  function dataSource(options, callback) {
    var status = options.status;

    if (self.isExhibitorList()) {
      ListApi.findExhibitorListEntries(
        self.listId(),
        options,
        function (response) {
          var companies = [];
          var totals = self.list_entries_count();
          totals[status] = response.recordsTotal;
          self.list_entries_count(totals);

          response.data.forEach(function (item) {
            var company = item.company;
            company.status = item.status;
            company.entry_id = item.id;
            company.business_name = company.exhibitor_name;
            companies.push(company);
          });
          response.data = companies;
          callback(response);
        }
      );
    } else {
      ListApi.findEntries(self.listId(), options, function (response) {
        var individuals = [];
        var totals = self.list_entries_count();
        totals[status] = response.recordsTotal;
        self.list_entries_count(totals);

        response.data.forEach(function (item) {
          var individual = item.individual;
          individual.status = item.status;
          individual.entry_id = item.id;
          individual.business_name = item.company
            ? item.company.exhibitor_name
            : individual.business_name;
          individuals.push(individual);
        });
        response.data = individuals;
        callback(response);
      });
    }
  }

  self.startNewList = function () {
    self.isExhibitorList(false);
    var options = utils.defaultQueryOptions;
    options.ps = 1000;
    SearchApi.find(self.expoId(), options, function (response) {
      self.searchTemplates(response.data);
    });
    $("#startCreateListModal").modal("show");
  };

  self.newList = function () {
    var list = new Exposync.ListModel({});
    list.mode("new");
    self.editListModel(list);
    self.getFields(function () {
      if (self.startFrom() == "blank") {
        $("#startCreateListModal").modal("hide");
        $("#createListModal").modal("show");
        self.addCriteria();
      } else if (self.startFrom() == "template") {
        if (self.startFromTemplate()) {
          $("#startCreateListModal").modal("hide");
          SearchApi.get(self.startFromTemplate(), function (data) {
            self.editListModel().name(data.name);
            data.criteria.forEach(function (item) {
              item.fields = self.fields();
              item.expo_id = self.expoId();

              self
                .editListModel()
                .search()
                .criteria.push(new Exposync.CriteriaModel(item));
            });

            $("#createListModal").modal("show");
          });
        }
      } else if (self.startFrom() == "list") {
        if (self.startFromList()) {
          $("#startCreateListModal").modal("hide");
          ListApi.get(self.startFromList(), function (data) {
            var list = new Exposync.ListModel(data, self.fields());
            list.mode("new");
            list.id("");
            list.search_id("");
            list.search().id("");
            self.editListModel(list);
          });
          $("#createListModal").modal("show");
        }
      } else if (self.startFrom() === "exhibitor") {
        self.isExhibitorList(true);
        self.getFields(function () {
          $("#startCreateListModal").modal("hide");
          $("#createListModal").modal("show");
          self.addCriteria();
        });
      }
    });
  };

  self.newSearchTemplate = function () {
    self.searchModel(new Exposync.SearchModel({}));
    self.searchModel().mode("new");
    self.addSearchCriteria();
    $("#createSearchTemplateModal").modal("show");
  };

  self.editList = function () {
    if (self.isExhibitorList()) {
      ListApi.getExhibitorList(self.listId(), function (data) {
        var list = new Exposync.ListModel(data, self.fields());

        list.mode("update");
        self.editListModel(list);
        $("#createListModal").modal("show");
      });
    } else {
      ListApi.get(self.listId(), function (data) {
        var list = new Exposync.ListModel(data, self.fields());

        list.mode("update");
        self.editListModel(list);
        $("#createListModal").modal("show");
      });
    }
  };

  self.deleteList = function (model) {
    var list = model.viewListModel();
    if (self.isExhibitorList()) {
      ListApi.deleteExhibitorList(list.id(), function () {
        utils.showSuccess("List was successfully deleted.");
        setTimeout(function () {
          location.href = "/services/lists/";
        }, 2000);
      });
    } else {
      ListApi.delete(list.id(), function () {
        utils.showSuccess("List was successfully deleted.");
        setTimeout(function () {
          location.href = "/services/lists/";
        }, 2000);
      });
    }
  };

  self.cancelListView = function () {
    location.href = "/services/lists/?" + self.expoId();
  };

  self.getFields = function (fnSuccess) {
    if (self.isExhibitorList()) {
      SearchApi.getExhibitorFields(self.expoId(), function (data) {
        var fields = $.map(data, function (type, name) {
          return { name: name, type: type };
        });

        self.fields(fields.sort(compare));
        if (typeof fnSuccess === "function") fnSuccess();
      });
    } else {
      SearchApi.getFields(self.expoId(), function (data) {
        var fields = $.map(data, function (type, name) {
          return { name: name, type: type };
        });

        self.fields(fields.sort(compare));
        if (typeof fnSuccess === "function") fnSuccess();
      });
    }
  };

  self.preview = function () {
    var l = Ladda.create(document.querySelector("#preview-btn"));
    l.start();
    var data = {};
    data.is_template = false;
    data.name = null;
    data.criteria = [];
    data.client_id = self.editListModel().exhibitor_id();
    var error = false;
    ko.utils.arrayForEach(
      self.editListModel().search().criteria(),
      function (item, idx) {
        if (item.isValid()) {
          if (item.field() == "customer_status" && !data.client_id) {
            utils.showError(
              "Client is required when querying with customer_status field!"
            );
            return;
          }
          if (item.field().indexOf("engagement:") === 0 && !data.client_id) {
            utils.showError(
              "Client is required when querying with engagement fields!"
            );
            return;
          }
          var entry = item.toJSON();
          if (idx == 0) entry.linkage = null;
          data.criteria.push(entry);
        } else {
          error = true;
        }
      }
    );

    if (error) {
      utils.showError("Please enter all the required values!");
      return;
    }
    if (data.criteria && data.criteria.length > 0) {
      l.start();
      if (self.isExhibitorList()) {
        SearchApi.previewExhibitorSearch(
          self.expoId(),
          data,
          function (data) {
            self.matchCount(data.total);
            l.stop();
          },
          () => {
            l.stop();
          }
        );
      } else {
        SearchApi.preview(
          self.expoId(),
          data,
          function (data) {
            self.matchCount(data.total);
            l.stop();
          },
          () => {
            l.stop();
          }
        );
      }
    } else utils.showError("Please add at least one criteria row.");
  };

  self.previewSearch = function () {
    var data = {};
    data.is_template = false;
    data.name = null;
    data.criteria = [];
    data.expo_id = self.expoId();
    data.client_id = self.editListModel().exhibitor_id();
    ko.utils.arrayForEach(self.searchModel().criteria(), function (item, idx) {
      if (item.isValid()) {
        var entry = item.toJSON();
        if (idx == 0) entry.linkage = null;
        data.criteria.push(entry);
      }
    });

    SearchApi.preview(self.expoId(), data, function (data) {
      self.matchCount(data.total);
    });
  };

  self.saveList = function () {
    if (!$("#add-list-form").valid()) {
      return false;
    }

    var searchData = self.editListModel().search().toJSON();
    var listModel = self.editListModel().toJSON();
    if (!listModel.exhibitor_id) {
      utils.showError("Please select a exhibitor before saving the list.");
      return;
    }

    searchData.is_template = false;
    searchData.client_id = self.editListModel().exhibitor_id();
    if (searchData.criteria.length > 0) searchData.criteria[0].linkage = null;

    if (self.editListModel().mode() == "new") {
      saveSearch(searchData);
    } else {
      updateSearch(searchData);
    }
  };

  self.updateListName = function (model) {
    var data = {};

    var list = model.viewListModel();
    data.name = list.name();
    ListApi.update(list.id(), data, function () {});
  };

  function updateSearch(data) {
    if (data.criteria.length == 0) {
      utils.showError("At least one criteria line is required.");
      return;
    }
    if (self.isExhibitorList()) {
      SearchApi.update(self.editListModel().search_id(), data, function (data) {
        var listData = self.editListModel().toJSON();
        ListApi.updateExhibitorList(self.listId(), listData, function (data) {
          utils.showSuccess("List has been successfully updated.");
          $("#createListModal").modal("hide");

          ListApi.refreshExhibitorList(self.listId(), function () {});
          setTimeout(function () {
            location.href =
              "/services/lists/view/exhibitor/?id=" +
              self.listId() +
              "&eid=" +
              self.expoId();
          }, 5000);
        });
      });
    } else {
      SearchApi.update(self.editListModel().search_id(), data, function (data) {
        var listData = self.editListModel().toJSON();
        ListApi.update(self.listId(), listData, function (data) {
          utils.showSuccess("List has been successfully updated.");
          $("#createListModal").modal("hide");

          ListApi.refresh(self.listId(), function () {});
          setTimeout(function () {
            location.href =
              "/services/lists/view/?id=" +
              self.listId() +
              "&eid=" +
              self.expoId();
          }, 5000);
        });
      });
    }
  }

  function saveSearch(searchData) {
    if (searchData.criteria.length == 0) {
      utils.showError("At least one criteria line is required.");
      return;
    }

    searchData.name = self.editListModel().name() + "_search";

    SearchApi.save(self.expoId(), searchData, function (searchResult) {
      var listData = self.editListModel().toJSON();
      listData.search_id = searchResult.id;
      if (self.isExhibitorList()) {
        ListApi.saveExhibitorList(self.expoId(), listData, function (data) {
          utils.showSuccess("List has been successfully created.");
          $("#createListModal").modal("hide");
          ListApi.refreshExhibitorList(data.id, function () {});
          setTimeout(function () {
            location.href = "/services/lists/?" + self.expoId();
          }, 2000);
        });
      } else {
        ListApi.save(self.expoId(), listData, function (data) {
          utils.showSuccess("List has been successfully created.");
          $("#createListModal").modal("hide");
          ListApi.refresh(data.id, function () {});
          setTimeout(function () {
            location.href = "/services/lists/?" + self.expoId();
          }, 2000);
        });
      }
    });
  }

  self.saveTemplate = function () {
    if (!$("#add-template-form").valid()) {
      return false;
    }

    var searchData = self.searchModel().toJSON();
    searchData.is_template = true;

    if (searchData.criteria.length > 0) searchData.criteria[0].linkage = null;

    if (searchData.criteria.length == 0) {
      utils.showError("At least one criteria line is required.");
      return;
    }
    delete searchData.client_id;

    if (self.searchModel().mode() == "new") {
      //NEW
      SearchApi.save(self.expoId(), searchData, function (data) {
        utils.showSuccess("Criteria template has been successfully saved.");
        $("#createSearchTemplateModal").modal("hide");
        setTimeout(function () {
          location.href = "/services/lists/";
        }, 2000);
      });
    } else {
      //UPDATE
      SearchApi.update(self.searchModel().id(), searchData, function (data) {
        utils.showSuccess("Criteria template has been successfully saved.");
        $("#createSearchTemplateModal").modal("hide");
        setTimeout(function () {
          location.href = "/services/lists/";
        }, 2000);
      });
    }
  };

  self.updateStatuses = function (data) {
    //approve
    if (self.isExhibitorList()) {
      ListApi.updateStatusesExhibitorList(self.listId(), data, function () {
        utils.showSuccess("Statuses have been updated.");
        setTimeout(function () {
          location.href =
            "/services/lists/view/exhibitor/?id=" +
            self.listId() +
            "&eid=" +
            self.expoId();
        }, 2000);
      });
    } else {
      ListApi.updateStatuses(self.listId(), data, function () {
        utils.showSuccess("Statuses have been updated.");
        setTimeout(function () {
          location.href =
            "/services/lists/view/?id=" +
            self.listId() +
            "&eid=" +
            self.expoId();
        }, 2000);
      });
    }
  };

  self.approveNewMatches = function () {
    var entries = self.selectedNewMatches();
    var data = [];
    entries.forEach(function (item) {
      data.push({ id: item, status: "approved" });
    });
    self.updateStatuses(data);
  };

  self.approveAll = function () {
    if (!confirm("Are you sure you want to approve all New entries?")) return;
    if (self.isExhibitorList()) {
      ListApi.approveAllExhibitorList(self.listId(), function (data) {
        utils.showSuccess(data.num_approved + " entries have been approved.");
        if (data.num_approved > 0) {
          setTimeout(function () {
            location.href =
              "/services/lists/view/exhibitor/?id=" +
              self.listId() +
              "&eid=" +
              self.expoId();
          }, 2000);
        }
      });
    } else {
      ListApi.approveAll(self.listId(), function (data) {
        utils.showSuccess(data.num_approved + " entries have been approved.");
        if (data.num_approved > 0) {
          setTimeout(function () {
            location.href =
              "/services/lists/view/?id=" +
              self.listId() +
              "&eid=" +
              self.expoId();
          }, 2000);
        }
      });
    }
  };

  self.omittNewMatches = function () {
    var entries = self.selectedNewMatches();
    var data = [];
    entries.forEach(function (item) {
      data.push({ id: item, status: "omitted" });
    });
    self.updateStatuses(data);
  };

  self.omittApprovedEntries = function () {
    var entries = self.selectedApprovedMatches();
    var data = [];
    entries.forEach(function (item) {
      data.push({ id: item, status: "omitted" });
    });
    self.updateStatuses(data);
  };

  self.approveOmittedEntries = function () {
    var entries = self.selectedOmittedMatches();
    var data = [];
    entries.forEach(function (item) {
      data.push({ id: item, status: "approved" });
    });
    self.updateStatuses(data);
  };

  self.addCriteria = function () {
    var expoId = self.expoId();
    self.searchCriteria = self.editListModel().search().criteria;
    self.searchCriteria.push(
      new Exposync.CriteriaModel({
        expo_id: expoId,
        fields: self.fields(),
        isExhibitorList: self.isExhibitorList(),
      })
    );
  };

  self.removeCriteria = function (item) {
    self.searchCriteria = self.editListModel().search().criteria;
    self.searchCriteria.remove(item);
  };

  self.addSearchCriteria = function () {
    var expoId = self.expoId();
    self
      .searchModel()
      .criteria.push(
        new Exposync.CriteriaModel({ expo_id: expoId, fields: self.fields() })
      );
  };

  self.removeSearchCriteria = function (item) {
    self.searchModel().criteria.remove(item);
  };

  self.getClients = function (id) {
    var options = utils.defaultQueryOptions;
    options.ps = 1000;
    options.status = "client";
    options.company_type = "exhibitor_company";
    ExhibitorsApi.findAll(id, options, function (response) {
      self.clients(response.data);
    });
  };

  self.getClient = function (id) {
    ExhibitorsApi.get(id, function (data) {
      self.client(new Exposync.CompanyModel(data));
    });
  };

  self.getExpo = function (id, fnSuccess) {
    ExpoApi.get(id, function (expo) {
      self.expo(expo);
      if (typeof fnSuccess === "function") {
        fnSuccess(expo);
      }
    });
  };

  self.getList = function (id) {
    if (self.isExhibitorList()) {
      ListApi.getExhibitorList(id, function (data) {
        self.auto_approve(data.auto_approve);
        self.getFields(function () {
          self.viewListModel(new Exposync.ListModel(data, self.fields()));
        });
      });
    } else {
      ListApi.get(id, function (data) {
        self.getFields(function () {
          self.viewListModel(new Exposync.ListModel(data, self.fields()));
        });
      });
    }
  };

  self.getLists = function (expoId) {
    var options = utils.defaultQueryOptions;
    options.ps = 1000;
    ListApi.find(expoId, options, function (response) {
      self.lists(response.data);
    });
  };

  self.refreshList = function (model) {
    var l = Ladda.create(document.querySelector("#refresh-btn"));
    l.start();
    if (self.isExhibitorList()) {
      ListApi.refreshExhibitorList(model.id(), function () {
        location.href =
          "/services/lists/view/exhibitor/?id=" +
          model.id() +
          "&eid=" +
          self.expoId();
      });
    } else {
      ListApi.refresh(model.id(), function () {
        location.href =
          "/services/lists/view/?id=" + model.id() + "&eid=" + self.expoId();
      });
    }
  };

  self.getSearch = function (id, fnSuccess) {
    SearchApi.get(id, fnSuccess);
  };

  self.showExportModal = function () {
    // body...
    $("#exportListModal").modal("show");
  };

  self.showExportExhibitorsModal = function () {
    // body...
    $("#exportExhibitorsListModal").modal("show");
  };

  self.isExportExhibitorsVisible = function () {
    return true;
  };

  self.exportToCsv = function () {
    $("#export_list_entries").attr(
      "action",
      "/api/lists/export/" + self.listId()
    );
    var statuses = $('input[name="statuses"]:checked');
    var fields = $('input[name="fields"]:checked');
    var custom_fields = $('input[name="custom_fields"]:checked');

    if (statuses.length > 0 && fields.length > 0) {
      $("#export_list_entries").submit();
    } else {
      utils.showError("Please select at least one status and one field!");
    }
  };

  self.exportExhibitorsToCsv = function () {
    $("#export_exhibitor_list_entries").attr(
      "action",
      "/api/lists/export_exhibitors/" + self.listId()
    );
    var statuses = $('input[name="e_statuses"]:checked');
    var fields = $('input[name="e_fields"]:checked');
    var custom_fields = $('input[name="e_custom_fields"]:checked');

    if (statuses.length > 0 && fields.length > 0) {
      $("#export_exhibitor_list_entries").submit();
    } else {
      utils.showError("Please select at least one status and one field!");
    }
  };

  self.exportExhibitorListToCsv = function () {
    $("#export_exhibitor_list_entries").attr(
      "action",
      "/api/lists/export_exhibitor_list/" + self.listId()
    );
    var statuses = $('input[name="e_statuses"]:checked');
    var fields = $('input[name="e_fields"]:checked');
    var custom_fields = $('input[name="e_custom_fields"]:checked');

    if (statuses.length > 0 && fields.length > 0) {
      $("#export_exhibitor_list_entries").submit();
    } else {
      utils.showError("Please select at least one status and one field!");
    }
  };

  self.companyMarketers = function (company_marketers = []) {
    if (company_marketers.length === 0) return "None";
    return company_marketers
      .map((cm) => `${cm.user.name_first} ${cm.user.name_last}`)
      .join(", ");
  };

  self.init = function () {
    self.viewListModel(new Exposync.ListModel({}));
    self.editListModel(new Exposync.ListModel({}));
  };

  function compare(a, b) {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  }
};
