var ReportsApi = {
  getAccountSummary: function (id, fnSuccess) {
    var url = "/api/companies/financials/expo/" + id + "?q=&p=0&ps=10000&sort=";
    ajax.get(url, fnSuccess);
  },

  getCategoryCount: function (id, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/expo/" + id + "/categories" + query_string;
    ajax.get(url, fnSuccess);
  },

  getDemographicsCount: function (id, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/expo/" + id + "/demographics" + query_string;
    ajax.get(url, fnSuccess);
  },

  getGuideInsights: function (id, fnSuccess) {
    var url = "/api/companies/expo/" + id + "/guide_insights";
    ajax.get(url, fnSuccess);
  },
  getIntegrationLogs: function (id, fnSuccess) {
    var url = "/api/reports/integration_logs/" + id;
    ajax.get(url, fnSuccess);
  },
  getLeads: function (id, fnSuccess) {
    var url =
      "/public_api/leads/expo/" + id + "?q=&p=0&ps=100000&sort=business_name";
    ajax.get(url, fnSuccess);
  },
  getExpoStats: function (id, fnSuccess) {
    var url = "https://expolytics.net/api/events/expo_stats/" + id;
    ajax.get(url, fnSuccess);
  },
  emailDomainStats: function (id, data, fnSuccess) {
    var url = `https://expolytics.net/api/events/domain_stats/${id}`;
    ajax.post(url, data, fnSuccess);
  },
  guideProspects: function (id, fnSuccess) {
    var url = "/api/reports/guide_prospects/" + id;
    ajax.get(url, fnSuccess);
  },
};
