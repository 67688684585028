var NestableList = {
  fromNestable: function (arr) {
    var list = [];
    function visit(nodes, context) {
      if (nodes && typeof nodes === "object") {
        if (!(nodes.constructor === Array)) return;
      }
      nodes.forEach(function (node) {
        var prefix = "";
        if (context.length > 0) prefix = context.join(":") + ":";
        list.push(prefix + node.id);
        if (node.children) {
          visit(node.children, context.concat([node.id]));
        }
      });
    }

    visit(arr, []);
    return list;
  },

  fromSelectableNestable: function (arr) {
    var list = [];

    function formatArrayCheckedFix(nodes) {
      // Format selectable array by inverting checked value of the parent if it's set to true but not all its children are selected
      // or if they are all selected but parent is set to false, we repeat this process if a checked value changes.
      nodes.forEach(function recursive(node) {
        if (nodes && typeof nodes === "object") {
          if (!(nodes.constructor === Array)) return;
        }

        if (node.children) {
          let selected = false;
          node.children.forEach((child) => (selected |= child.checked));

          if (selected && !node.checked) {
            node.checked = !node.checked;
            return formatArrayCheckedFix(nodes);
          }

          node.children.forEach(recursive);
        }
      });
    }

    function visit(nodes, context) {
      if (nodes && typeof nodes === "object") {
        if (!(nodes.constructor === Array)) return;
      }

      nodes.forEach(function (node) {
        var prefix = "";
        if (context.length > 0) prefix = context.join(":") + ":";
        if (node.checked) {
          list.push(prefix + node.id);
        }
        if (node.children) {
          visit(node.children, context.concat([node.id]));
        }
      });
    }

    formatArrayCheckedFix(arr);
    visit(arr, []);
    return list;
  },

  toNestable: function (categories, selected) {
    var tree = [];
    var list = [];
    categories = categories.sort();
    var isSelected = false;

    categories.forEach(function (item) {
      isSelected = false;
      var nodes = item.split(":");
      var processedNodes = [];
      var parentNode = "";

      if (selected) {
        if (selected.indexOf(item) > -1) {
          isSelected = true;
        }
      }
      nodes.forEach(function (node) {
        var category = "";
        parentNode = "";

        if (processedNodes.length > 0) {
          parentNode = processedNodes.join(":");
          category = processedNodes.join(":") + ":" + node;
        } else category = node;

        var matches = filterNodes(tree, category);

        if (matches.length === 0) {
          if (!parentNode) {
            next = { id: node, category: category, selected: isSelected };
            tree.push(next);
          } else {
            matches = filterNodes(tree, parentNode);
            if (matches.length > 0) {
              if (node != category) {
                if (matches[0].children == undefined) {
                  matches[0].children = [];
                }
                matches[0].children.push({
                  id: node,
                  category: category,
                  selected: isSelected,
                });
              }
            }
          }
        }

        processedNodes.push(node);
      });
    });

    return tree;
  },

  toTreeFormat: function (categories, selected) {
    var tree = [];
    var list = [];
    categories = categories.sort();
    var isSelected = false;

    categories.forEach(function (item) {
      isSelected = false;
      var nodes = item.split(":");
      var processedNodes = [];
      var parentNode = "";

      if (selected) {
        if (selected.indexOf(item) > -1) {
          isSelected = true;
        }
      }
      nodes.forEach(function (node) {
        var category = "";
        parentNode = "";

        if (processedNodes.length > 0) {
          parentNode = processedNodes.join(":");
          category = processedNodes.join(":") + ":" + node;
        } else category = node;

        var matches = filterNodes(tree, category);

        if (matches.length === 0) {
          if (!parentNode) {
            next = {
              id: category,
              text: node,
              category: category,
              state: { selected: isSelected },
            };
            tree.push(next);
          } else {
            matches = filterNodes(tree, parentNode);
            if (matches.length > 0) {
              if (node != category) {
                if (matches[0].children == undefined) {
                  matches[0].children = [];
                }
                matches[0].children.push({
                  id: category,
                  text: node,
                  category: category,
                  state: { selected: isSelected },
                });
              }
            }
          }
        }

        processedNodes.push(node);
      });
    });

    return tree;
  },
};

function filterNodes(nodes, value) {
  var list = [];
  nodes.forEach(function (node) {
    if (node.category == value) {
      list.push(node);
    }
    if (node.children) {
      list = list.concat(filterNodes(node.children, value));
    }
  });

  return list;
}
