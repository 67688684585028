var ConfirmViewModel = function (data) {
  var self = this;

  self.action = ko.observable(data.action);
  self.exhibitor = ko.observable();
  self.individual = ko.observable();
  self.event = ko.observable();
  self.description = ko.observable();
  self.redirectUrl = ko.observable();

  self.exhibitor.subscribe(function (value) {
    self.getDescription();
    self.getRedirectUrl();
  });

  self.event.subscribe(function (value) {
    self.getDescription();
  });

  self.getIndividual = function (id, fnSuccess) {
    BuyersGuideApi.getIndividual(id, function (data) {
      self.individual(new Exposync.IndividualModel(data));
      if (typeof fnSuccess === "function") {
        fnSuccess(data);
      }
    });
  };

  self.getExhibitor = function (expoId, exhid) {
    PublicApi.getExhibitorByExhibitorId(expoId, exhid, function (data) {
      self.exhibitor(new Exposync.CompanyModel(data));
    });
  };

  self.getEvent = function (expoId, evid) {
    PublicApi.getEventByEventId(expoId, evid, function (data) {
      self.event(new Exposync.EventModel(data));
    });
  };

  self.getDescription = function () {
    switch (self.action()) {
      case "interested":
        self.description(
          "You selected that you are interested in " + self.exhibitor().name()
        );
        break;
      case "not_interested":
        self.description(
          "You selected that you are not interested in " +
            self.exhibitor().name()
        );
        break;
      case "convert_to_lead":
        self.description(
          "You selected that you are interested in Nexus services"
        );
        break;
      case "register_event":
        self.description(
          "You would like to register for " + self.event().name()
        );
        break;
      default:
        break;
    }
  };

  self.getRedirectUrl = function () {
    switch (data.action) {
      case "interested":
        if (self.exhibitor())
          self.redirectUrl(
            "/public_api/reply_data/individual/" +
              data.individualId +
              "/interested/" +
              self.exhibitor().id()
          );
        break;
      case "not_interested":
        if (self.exhibitor())
          self.redirectUrl(
            "/public_api/reply_data/individual/" +
              data.individualId +
              "/not_interested/" +
              self.exhibitor().id()
          );
        break;
      case "convert_to_lead":
        if (self.exhibitor())
          self.redirectUrl(
            "/public_api/reply_data/companies/" +
              self.exhibitor().id() +
              "/" +
              data.messageId
          );
        break;
      case "register_event":
        self.redirectUrl(
          "/public_api/reply_data/individual/" +
            data.individualId +
            "/register_event/" +
            data.eventId
        );
        break;
      default:
        break;
    }
  };

  self.init = function () {
    if (data.individualId) self.getIndividual(data.individualId);
    if (data.expoId) {
      if (data.exhibitorId) self.getExhibitor(data.expoId, data.exhibitorId);
      if (data.eventId) self.getEvent(data.expoId, data.eventId);
    }
    self.getRedirectUrl();
  };
  self.init();
};
