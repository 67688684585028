var MessagesApi = {
  get: function (id, fnSuccess) {
    var url = "/api/messages/" + id;
    ajax.get(url, fnSuccess);
  },

  preview: function (id, fnSuccess) {
    var url = "/api/messages/preview/" + id;
    ajax.get(url, fnSuccess);
  },

  find: function (expoId, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/messages/expo/" + expoId + query_string;
    ajax.get(url, fnSuccess);
  },

  save: function (expoId, data, fnSuccess) {
    var url = "/api/messages/expo/" + expoId;
    ajax.post(url, data, fnSuccess);
  },

  update: function (id, data, fnSuccess) {
    var url = "/api/messages/" + id;
    ajax.post(url, data, fnSuccess);
  },

  approve: function (id, fnSuccess) {
    var url = "/api/messages/" + id + "/approve";
    ajax.post(url, {}, fnSuccess);
  },

  send: function (id, data, fnSuccess) {
    var url = "/api/messages/send_message_to_queue/" + id;
    ajax.post(url, data, fnSuccess);
  },

  approve_schedule: function (id, data, fnSuccess) {
    var url = "/api/messages/approve_schedule/" + id;
    ajax.post(url, data, fnSuccess);
  },

  cancel_schedule: function (id, data, fnSuccess) {
    var url = "/api/messages/cancel_schedule/" + id;
    ajax.post(url, data, fnSuccess);
  },

  sendTestEmail: function (id, data, fnSuccess) {
    var url = "/api/messages/test_send/" + id;
    ajax.post(url, data, fnSuccess);
  },

  delete: function (id, fnSuccess) {
    var url = "/api/messages/" + id;
    ajax.delete(url, {}, fnSuccess);
  },

  getStats: function (id, fnSuccess) {
    var url = "https://expolytics.net/api/events/" + id;
    ajax.get(url, fnSuccess);
  },
  getCategorizedStats: function (id, type, event, fnSuccess) {
    var url =
      "https://expolytics.net/api/events/message/" + id + "/stats?type=" + type;
    ajax.get(url, fnSuccess);
  },

  emailLogSearch: function (data, fnSuccess) {
    var url = "/api/messages/backlog/search/";
    ajax.post(url, data, fnSuccess);
  },

  emailLogSearchWithCampaignName: function (data, fnSuccess) {
    var url = "/api/messages/backlog/search/withcampaign";
    ajax.post(url, data, fnSuccess);
  },

  getHistory: function (id, fnSuccess) {
    var url = "/api/messages/logs/" + id;
    ajax.get(url, fnSuccess);
  },

  sentEmailsSearch: function (data, fnSuccess) {
    var url = "/api/messages/backlog/emails/";
    ajax.post(url, data, fnSuccess);
  },

  sentEmailEvents: function (id, fnSuccess) {
    var url = `/api/messages/backlog/emails/${id}`;
    ajax.get(url, fnSuccess);
  },

  searchByIds: function (id, data, fnSuccess) {
    var url = `/api/messages/searchByIds/${id}`;
    ajax.post(url, data, fnSuccess);
  },

  removeBounce: function (data, fnSuccess) {
    var url = `/api/messages/emails/remove_bounce`;
    ajax.post(url, data, fnSuccess);
  },

  loadChangeHistory: function (id, fnSuccess) {
    var url = "/api/messages/change_history/" + id;
    ajax.get(url, fnSuccess);
  },
  getEngagementAnalytics: function (id, event, fnSuccess) {
    var url = "/api/messages/analytics/" + id + "/" + event;
    ajax.get(url, fnSuccess);
  },
  emailStatusReport: function (data, fnSuccess) {
    var url = `/api/messages/emails/email_status_report`;
    ajax.post(url, data, fnSuccess);
  },
  sendExportBacklogToEmail: function (data, fnSuccess) {
    var url = `/api/messages/emails/email_stats_report`;
    ajax.post(url, data, fnSuccess);
  },
};
