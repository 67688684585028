baseClientsViewModel = function () {
  var self = this;
  self.clientId = ko.observable();
  self.client = ko.observable();
  self.notesModel = ko.observable();
  self.histories = ko.observableArray([]);
  var historiesTable;
  self.cancelView = function () {
    location.href = "/services/clients/";
  };

  self.renderView = function (client, view) {
    location.href =
      "/services/clients/" +
      view +
      "/?id=" +
      client.id() +
      "&eid=" +
      client.expo_id();
  };

  self.editClient = function (client) {
    location.href =
      "/services/clients/edit/?id=" + client.id() + "&eid=" + client.expo_id();
  };

  self.cancelEdit = function (model) {
    var client = model.client();
    if (client.id())
      location.href =
        "/services/clients/view/?id=" +
        client.id() +
        "&eid=" +
        client.expo_id();
    else location.href = "/services/clients/";
  };

  self.getClient = function (id, fnSuccess) {
    ExhibitorsApi.get(id, function (data) {
      self.client(new Exposync.CompanyModel(data));

      if (typeof fnSuccess === "function") {
        fnSuccess(data);
      }
    });
  };

  self.deleteClient = function (model) {
    var client = model.client();
    ExhibitorsApi.delete(client.id(), function () {
      utils.showSuccess("Client was successfully deleted.");
      setTimeout(function () {
        location.href = "/services/clients/";
      }, 2000);
    });
  };

  self.isUserAssignedMarketer = function () {
    var company_marketers = self.client().company_marketers();
    var user = self.currentUser();
    return company_marketers.filter((cm) => cm.user.id === user.id).length > 0;
  };

  self.openWebsite = function (argument) {
    var client = self.client();
    if (client && client.website_url()) {
      var website = client.website_url();
      if (website.substring(0, 4) !== "http") website = "http://" + website;
      window.open(website, "_blank");
    }
  };

  self.showNotes = function () {
    self.notesModel(new NotesViewModel(self.clientId(), ExhibitorsApi));
    $("#notesModal").modal("show");
  };

  self.showHistory = function () {
    var customFields = null;
    ExhibitorsApi.loadChangeHistory(
      self.clientId(),
      utils.defaultQueryOptions,
      function (response) {
        // response.data.forEach(function(item) {
        //   customFields = []
        //   Object.keys(item.custom_fields).forEach(function(key) {
        //     if (item.custom_fields[key]) {
        //       customFields.push( {key: key, value: item.custom_fields[key]})
        //     }
        //   })
        //   item.customFields = customFields
        //   item.categories = item.categories.toString()
        // })

        self.histories(response.data);
        if (!historiesTable) {
          utils.dataTableOptions.order = [[0, "desc"]];
          historiesTable = $("#history-table").dataTable(
            utils.dataTableOptions
          );
        }
      }
    );

    $("#historyModal").modal("show");
  };
};
