var EmailTemplatesViewModel = function () {
  var self = this;
  self.error = ko.observable();
  self.template = ko.observable();
  self.exhibitor_id = ko.observable();
  self.templates = ko.observableArray([]);
  self.customTemplates = ko.observableArray([]);
  self.name = ko.observable();
  self.content = ko.observable();
  self.preheaderText = ko.observable();
  self.headerContent = ko.observable();
  self.clients = ko.observableArray([]);
  self.templateType = ko.observable("expo");
  self.editor_version = ko.observable(1);

  baseViewModel.call(self);

  self.loadTemplate = function (id, callback) {
    EmailTemplatesApi.get(id, function (data) {
      self.template(new Exposync.EmailTemplateModel(data));
      if (data.exhibitor_id) {
        self.templateType("client");
        self.exhibitor_id(data.exhibitor_id);
      }
      if (typeof callback === "function") {
        callback(data.exhibitor_id);
      }
    });
  };

  function initTemplateBuilder(expoId, clientId) {
    var $screenWrap = $(".template-container");
    var templateBuider = new EmailTemplateBuilder(
      expoId,
      clientId,
      utils.tinyMceConfig()
    );

    templateBuider.applyBlockControls($(".template-container"));
    templateBuider.addSorting($screenWrap);
    $screenWrap.find(".layout-list").sortable("refresh");

    templateBuider.colorPickInit($(".layout-blocks"));
  }

  self.expoTemplatesDataSource = function (options, callback) {
    EmailTemplatesApi.find(self.expoId(), options, function (data) {
      callback(data);
    });
  };

  self.expoTemplatesTableRowCallback = function (row, data) {};

  self.expoClientTemplatesDataSource = function (options, callback) {
    options.client_templates = 1;
    options.client_type = "expo";
    EmailTemplatesApi.find(self.expoId(), options, function (data) {
      console.log(data.data);
      callback(data);
    });
  };

  self.exhibitorClientTemplatesDataSource = function (options, callback) {
    options.client_templates = 1;
    options.client_type = "exhibitor";
    EmailTemplatesApi.find(self.expoId(), options, function (data) {
      callback(data);
    });
  };

  self.exhibitor_id.subscribe(function (value) {
    initTemplateBuilder(self.expoId(), value);
  });

  self.clientTemplatesTableRowCallback = function (row, data) {
    $(row).attr("style", "cursor: pointer");
    $(row).click(function (e) {
      console.log(e.target.type);
      if (e.target.type == "button") return;
      location.href =
        "/services/templates/edit/?id=" +
        data.id +
        "&eid=" +
        data.expo_id +
        "&v=" +
        data.editor_version;
    });
  };

  self.getClients = function (fnSuccess) {
    var options = utils.defaultQueryOptions;
    options.ps = 1000;
    options.status = "client";
    options.company_type = "exhibitor_company";
    ExhibitorsApi.find(self.expoId(), options, function (response) {
      self.clients(response.data.sort(exhibitorNameCompare));
      if (typeof fnSuccess === "function") {
        fnSuccess();
      }
    });
  };

  function exhibitorNameCompare(a, b) {
    if (a.exhibitor_name < b.exhibitor_name) return -1;
    if (a.exhibitor_name > b.exhibitor_name) return 1;
    return 0;
  }

  self.newTemplate = function () {
    location.href = "/services/templates/edit/?v=2&eid=" + self.expoId();
  };

  self.editTemplate = function (template) {
    location.href =
      "/services/templates/edit/?id=" +
      template.id +
      "&eid=" +
      template.expo_id +
      "&v=" +
      template.editor_version;
  };

  self.copyTemplate = function (template) {
    location.href =
      "/services/templates/edit/?eid=" +
      template.expo_id +
      "&copyFrom=" +
      template.id +
      "&v=" +
      template.editor_version;
  };

  self.copyFromTemplate = function (id, callback) {
    EmailTemplatesApi.get(id, function (data) {
      data.id = null;
      data.name = data.name + " (Copy)";
      self.template(new Exposync.EmailTemplateModel(data));
      if (typeof callback === "function") {
        callback();
      }
    });
  };

  self.cancelEdit = function () {
    location.href = "/services/templates/?" + self.expoId();
  };
  self.saveAndContinue = function (model) {
    saveTemplate(model, function (ret) {
      //Do nothing;
      if (ret) self.template().id(ret.id);
    });
  };

  self.saveTemplate = function (model) {
    saveTemplate(model, function () {
      setTimeout(function () {
        location.href = "/services/templates/";
      }, 3000);
    });
  };

  function saveTemplate(model, fnSuccess) {
    if (!$("#email-template-form").valid()) {
      return false;
    }

    var templateBuider = new EmailTemplateBuilder(
      self.expoId(),
      null,
      utils.tinyMceConfig()
    );
    var parsedContent = JSON.parse(templateBuider.getTemplateForSave());
    var template = model.template();
    var data = template.toJSON();
    data.expo_id = self.expoId();
    if (self.templateType() == "expo") data.exhibitor_id = null;
    else data.exhibitor_id = self.exhibitor_id();
    data.content = parsedContent.sections;

    if (template.id()) {
      EmailTemplatesApi.update(template.id(), data, function () {
        utils.showSuccess("Template was successfully updated.");
        fnSuccess();
      });
    } else {
      EmailTemplatesApi.save(self.expoId(), data, function (ret) {
        utils.showSuccess("Template was successfully created.");
        fnSuccess(ret);
      });
    }
  }

  self.deleteTemplate = function (model) {
    var template = model.template();
    EmailTemplatesApi.delete(template.id(), function () {
      utils.showSuccess("Template was successfully deleted.");
      setTimeout(function () {
        location.href = "/services/templates/";
      }, 3000);
    });
  };

  self.init = function () {
    self.template(new Exposync.EmailTemplateModel({}));
  };

  self.init();
};
