var DeactivateUserModel = function (data) {
  var self = this;
  self.user_id = ko.observable(data.id() || "");
  self.new_password = ko.observable();
  self.reassignments = ko.observableArray(
    data.permissions === undefined
      ? []
      : $.map(data.permissions(), function (p) {
          return new ReassignmentModel(p);
        })
  );
};

var ReassignmentModel = function (data) {
  var self = this;
  self.expoName = ko.observable(data.expo.expo_name || "");
  self.expoId = ko.observable(data.expo_id || "");
  self.userId = ko.observable();
  self.role = ko.observable(data.role || "");
};

var UsersViewModel = function () {
  var self = this;
  self.user = ko.observable();
  self.users = ko.observableArray([]);
  self.selectedUser = ko.observable();
  self.inviteFirstName = ko.observable();
  self.inviteLastName = ko.observable();
  self.inviteEmail = ko.observable("");
  self.invitePhone = ko.observable("");
  self.inviteExt = ko.observable("");
  self.inviteRoles = ko.observableArray();

  self.assignedExpoId = ko.observable();
  self.assignedRole = ko.observable();

  self.unassignedExpoId = ko.observable();
  self.unassignedUserId = ko.observable();
  self.unassignedRole = ko.observable();
  self.unassignedExpoName = ko.observable();
  self.unassignedTransferWorkload = ko.observable();
  self.deactivateViewModel = ko.observable();
  self.reassignUserList = ko.observableArray([]);

  self.error = ko.observable();

  baseViewModel.call(self);

  self.activeUsersDataSource = function (options, callback) {
    UsersApi.find(options, function (response) {
      var filtered = ko.utils.arrayFilter(response.data, function (item) {
        return item.deactivated == false;
      });
      response.data = filtered;
      callback(response);
    });
  };

  self.activeUsersTableRowCallback = function (row) {
    $(row).attr("style", "cursor: pointer");
  };

  self.deactivatedUsersDataSource = function (options, callback) {
    UsersApi.find(options, function (response) {
      var filtered = ko.utils.arrayFilter(response.data, function (item) {
        return item.deactivated == true;
      });
      response.data = filtered;
      callback(response);
    });
  };

  self.deactivatedUsersTableRowCallback = function (row) {};

  self.invitedUsersDataSource = function (options, callback) {
    options.invited = 1;

    UsersApi.find(options, function (response) {
      callback(response);
    });
  };

  self.invitedUsersTableRowCallback = function (row) {};

  ko.computed(function () {
    var user = self.user();
    if (user && user.roles().indexOf("organizer") > -1) {
      if (user.roles().length > 1) {
        utils.showError(
          "Organizer User can have only one system level role. Other role assignments were removed!"
        );
      }
      self.user().roles(["organizer"]);
    }

    if (self.inviteRoles() && self.inviteRoles().indexOf("organizer") > -1) {
      if (self.inviteRoles().length > 1) {
        utils.showError(
          "Organizer User can have only one system level role. Other role assignments were removed!"
        );
      }
      self.inviteRoles(["organizer"]);
    }
  });

  ko.computed(function () {
    var currentlyAssignedUserId = self.unassignedUserId();
    var role = self.unassignedRole();
    if (!role) {
      return;
    }
    var options = utils.defaultQueryOptions;
    options.ps = 1000;
    UsersApi.find(options, function (response) {
      var users = ko.utils.arrayFilter(response.data, function (item) {
        var mathcing_roles = item.roles.filter(function (item_role) {
          return (
            role.startsWith(item_role) && item.id != self.unassignedUserId()
          );
        });
        //return item.roles.indexOf(role) > -1 && item.id != self.unassignedUserId();
        return mathcing_roles && mathcing_roles.length > 0;
      });
      self.reassignUserList(users);
    });
  }, self);

  self.reassignUserListByRole = function (role) {
    return ko.utils.arrayFilter(self.users(), function (item) {
      return (
        item.deactivated == false &&
        item.roles.indexOf(role) > -1 &&
        item.id != self.user().id()
      );
    });
  };

  self.editUser = function (user) {
    location.href = "/admin/users/edit/?" + user.id;
  };

  self.cancelEdit = function () {
    location.href = "/admin/users/";
  };

  self.removeAssignment = function () {
    alert("not implemented yet.");
  };

  self.startDeactivateUserWizard = function () {
    self.deactivateViewModel(new DeactivateUserModel(self.user()));
    $("#deactivateUserModal").modal();
  };

  self.deactivateUser = function (model) {
    var userId = model.user_id();
    data = {};
    data.reassignments = $.map(model.reassignments(), function (p) {
      return {
        expo_id: p.expoId(),
        role: p.role(),
        user_id: p.userId(),
      };
    });

    ajax.post("/api/user/" + userId + "/deactivate", data, function (data) {
      utils.showSuccess("User has been deactivated");
      setTimeout(function () {
        location.href = "/admin/users";
      }, 2000);
    });
  };

  self.setExpoToUnassign = function (expoPerms) {
    self.unassignedRole(expoPerms.role);
    self.unassignedExpoName(expoPerms.expo.expo_name);
    self.unassignedExpoId(expoPerms.expo_id);
    self.unassignedUserId(expoPerms.user_id);

    $("#unassignExpoModal").modal();
  };

  self.unassingUserFromExpo = function () {
    var expoId = self.unassignedExpoId();
    var role = self.unassignedRole();
    var transferWorkloadTo = self.unassignedTransferWorkload();
    var userId = self.user().id();
    var url =
      "/api/expo_perms/user/" + userId + "/expo/" + expoId + "/role/" + role;

    var data = {};
    data.reassignments = [
      { expo_id: expoId, role: role, user_id: transferWorkloadTo },
    ];

    ajax.delete(url, data, function () {
      self.getUserExpoAssignments(userId);
      utils.showSuccess(
        "User has been unassigned from " + self.unassignedExpoName()
      );
    });
  };

  self.getUsers = function () {
    var options = { q: "", p: 0, ps: 1000 };
    UsersApi.find(options, function (response) {
      self.users(response.data);
    });
  };

  self.getUser = function (id) {
    url = "/api/user/" + id;
    ajax.get(url, function (result) {
      self.user(new Exposync.UserModel(result));
      self.getUserExpoAssignments(id);
    });
  };

  self.getUserExpoAssignments = function (id) {
    ajax.get("/api/expo_perms/user/" + id, function (result) {
      self.user().permissions(result);
    });
  };

  self.inviteUser = function () {
    if (!$("#invite-user").valid()) {
      return false;
    }
    var data = {};
    data.name_first = self.inviteFirstName();
    data.name_last = self.inviteLastName();
    data.email = self.inviteEmail();
    data.roles = self.inviteRoles();
    data.phone = self.invitePhone() + " " + self.inviteExt();

    ajax.post(
      "/api/invite",
      data,
      function () {
        $("#inviteModal").modal("hide");
        toastr.options.onHidden = function () {
          location.href = "/admin/users";
        };
        utils.showSuccess(
          "An invite email will be sent to the email address you entered."
        );
      },
      function (req, status, err) {
        utils.showError(req.responseText);
      }
    );
  };

  self.updateUser = function (model) {
    if (!$("#user_form").valid()) {
      return false;
    }
    var user = model.user();
    url = "/api/user/" + user.id();
    ajax.post(url, user.toJSON(), function (data) {
      utils.showSuccess("Successfully updated user.");
      setTimeout(function () {
        location.href = "/admin/users/";
      }, 2000);
    });
  };

  self.assignUserToExpo = function () {
    var expoId = self.assignedExpoId();
    var role = self.assignedRole();
    var userId = self.user().id();
    if (userId && expoId && role) {
      ajax.post(
        "/api/expo_perms/user/" + userId + "/expo/" + expoId + "/role/" + role,
        {},
        function () {
          utils.showSuccess("User has been successfully added.");
          self.getUserExpoAssignments(userId);
        },
        function (req, status, err) {},
        "PUT"
      );
    }
  };

  self.cancelInvitation = function (user) {
    if (user.canceled && user.canceled == true) {
      utils.showError("Invitation has been already canceled. Please refresh.");
      return;
    }
    UsersApi.cancelInvitation(user.id, function () {
      user.canceled = true;
      utils.showSuccess("Invitation has been successfully canceled.");
      setTimeout(function () {
        location.href = "/admin/users/";
      }, 5000);
    });
  };

  self.init = function () {
    self.user(new Exposync.UserModel({}));
  };
  self.init();
};
