var UnsubscribeViewModel = function () {
  var self = this;
  self.individual = ko.observable();
  self.individualId = ko.observable();
  self.expoId = ko.observable();
  self.email = ko.observable();
  self.messagedId = ko.observable();
  self.expo = ko.observable();
  self.expo_package = ko.observable();
  self.unsubscribe_options = ko.observable();
  self.message = ko.observable();
  self.company = ko.observable({
    exhibitor_name: "",
  });

  self.max_daily_emails = ko.computed(function () {
    if (self.expo_package() && self.expo_package().daily_max_count)
      return self.expo_package().daily_max_count;
    return 3;
  });

  self.getIndividual = function (id) {
    var url = "/public_api/buyers_guide/individual/" + id;
    ajax.get(
      url,
      function (data) {
        // success
        self.individual(new Exposync.IndividualModel(data));
        self.email(data.email_first);
        self.expoId(data.expo_id);
        if (self.messagedId()) {
          self.getEmailCampaign(self.messagedId(), function (message_data) {
            getExpoPackage(self.expoId(), function (package_data) {
              self.expo_package(package_data);
            });
            self.expo(new Exposync.ExpoModel(message_data.expo));
            self.message(new Exposync.MessageModel(message_data));
            self.company(message_data.client);
          });
        }
      },
      function (data) {
        // error
        utils.showError("No individual found with ID: " + id);
      }
    );
  };

  self.getEmailCampaign = function (id, fnSuccess) {
    var url = "/public_api/messages/" + id;

    ajax.get(
      url,
      function (data) {
        fnSuccess(data);
      },
      function (data) {
        // error
        utils.showError("No expo found with ID: " + id);
      }
    );
  };

  function getExpoPackage(id, fnSuccess) {
    var url = "/public_api/messages/" + id + "/packages/";

    ajax.get(url, fnSuccess);
  }

  self.updatePreferences = function () {
    var preferences = {};
    preferences.no_marketing_emails = {
      selected: false,
      company_id: self.company().id,
    };
    preferences.x_marketing_emails = {
      selected: false,
      count: 0,
    };
    preferences.no_marketing_email_for_expo = false;

    preferences.exhibitor_emails = true;

    if (self.unsubscribe_options() == "no_marketing_emails") {
      preferences.no_marketing_emails.selected = true;
    } else if (self.unsubscribe_options() == "x_marketing_emails") {
      preferences.x_marketing_emails = {
        selected: true,
        count: self.max_daily_emails(),
      };
    } else if (self.unsubscribe_options() == "no_marketing_email_for_expo") {
      preferences.no_marketing_email_for_expo = true;
    } else if (self.unsubscribe_options() == "x_exhibitor_emails") {
      preferences.exhibitor_emails = false;
    }

    var data = {};
    data.email = self.email();
    data.expo_id = self.expoId();
    data.individual_id = self.individualId();
    data.preferences = preferences;
    var url =
      "/public_api/messages/preferences/expo/" +
      self.expoId() +
      "/email/" +
      self.email();
    ajax.post(url, data, function (data) {
      if (data.success) {
        location.href =
          "/public/unsubscribe/success/?id=" +
          self.individualId() +
          "&cmp=" +
          self.messagedId();
      }
    });
  };

  self.cancelUpdate = function () {
    close();
  };

  self.openGuide = function () {
    window.open(
      "https://guides.thenexus.net/guide/update/" + self.individualId(),
      "_blank"
    );
  };
  self.init = function () {
    self.individual(Exposync.IndividualModel({}));
    self.expo(Exposync.ExpoModel({}));
  };
  self.init();
};
