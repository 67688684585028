this.Exposync = this.Exposync || {};
this.Exposync.DocumentModel = this.Exposync.DocumentModel || {};
this.Exposync.ExhibitorModel = this.Exposync.ExhibitorModel || {};
this.Exposync.ExpoModel = this.Exposync.ExpoModel || {};
this.Exposync.UserModel = this.Exposync.UserModel || {};
this.Exposync.EventModel = this.Exposync.EventModel || {};
this.Exposync.CompanyModel = this.Exposync.CompanyModel || {};
this.Exposync.CompanyHistoryModel = this.Exposync.CompanyHistoryModel || {};
this.Exposync.MessageTemplateModel = this.Exposync.MessageTemplateModel || {};
this.Exposync.IndividualModel = this.Exposync.IndividualModel || {};
this.Exposync.ViewModels = this.Exposync.ViewModels || {};
this.Exposync.ExpoPermissionModel = this.Exposync.ExpoPermissionModel || {};
this.Exposync.EmailTemplateModel = this.Exposync.EmailTemplateModel || {};
this.Exposync.ListModel = this.Exposync.ListModel || {};
this.Exposync.SearchModel = this.Exposync.SearchModel || {};
this.Exposync.CriteriaModel = this.Exposync.CriteriaModel || {};
this.Exposync.QuestionModel = this.Exposync.QuestionModel || {};
this.Exposync.ExpoPackagesModel = this.Exposync.ExpoPackagesModel || {};
this.Exposync.CompanyFinancialsModel =
  this.Exposync.CompanyFinancialsModel || {};

this.Exposync.MessageModel = this.Exposync.MessageModel || {};
this.Exposync.AttendanceDataModel = this.Exposync.AttendanceDataModel || {};
this.Exposync.NotesDataModel = this.Exposync.NotesDataModel || {};
this.Exposync.WebhookDataModel = this.Exposync.WebhookDataModel || {};
this.Exposync.IndividualNoteDataModel =
  this.Exposync.IndividualNoteDataModel || {};

Exposync.AttendanceDataModel = function (data, is_lead_data) {
  var self = this;

  if (is_lead_data) {
    self.registration_id = ko.observable(data.company.exhibitor_name || "");
    self.scan_id = ko.observable(
      data.company.booth_location + "/" + data.company.booth_number || ""
    );
    self.description = ko.observable("Badge Scan");
    self.scan_time = ko.observable(data.date || "");
    self.scan_type = ko.observable(data.scan_type || "");
    self.scan_notes = ko.observable(data.scan_notes || "");
    self.where = ko.observable(data.where || "");
    self.link = ko.observable(data.link || "");
  } else {
    self.registration_id = ko.observable(data.registration_id || "");
    self.scan_id = ko.observable(data.scan_id || "");
    self.description = ko.observable(data.description || "");
    self.scan_time = ko.observable(data.scan_time || "");
    self.scan_type = ko.observable(data.scan_type || "");
    self.scan_notes = ko.observable(data.scan_notes || "");
    self.where = ko.observable(data.where || "");
    self.link = ko.observable(data.link || "");
  }
};

Exposync.NotesDataModel = function (data) {
  var self = this;

  self.client_id = ko.observable(data.client_id || "");
  self.user_id = ko.observable(data.user_id || "");
  self.expo_id = ko.observable(data.expo_id || "");
  self.description = ko.observable(data.description || "");
  self.date = ko.observable(data.date || "");

  self.toJSON = function () {
    return {
      client_id: self.client_id(),
      user_id: self.user_id(),
      expo_id: self.expo_id(),
      description: self.description(),
      date: self.date(),
    };
  };
};

Exposync.IndividualNoteDataModel = function (data) {
  var self = this;

  self.individual_id = ko.observable(data.individual_id || "");
  self.user_id = ko.observable(data.user_id || "");
  self.expo_id = ko.observable(data.expo_id || "");
  self.description = ko.observable(data.description || "");
  self.date = ko.observable(data.date || "");

  self.toJSON = function () {
    return {
      individual_id: self.individual_id(),
      user_id: self.user_id(),
      expo_id: self.expo_id(),
      description: self.description(),
      date: self.date(),
    };
  };
};

Exposync.CompanyFinancialsModel = function (data) {
  var self = this;

  self.id = ko.observable(data.id || "");
  self.client_id = ko.observable(self.client_id | "");
  self.payment_status = ko.observable(data.payment_status || false);
  self.payment_amount = ko.observable(
    data.payment_amount ? data.payment_amount / 100 : 0
  );
  self.billed_amount = ko.observable(
    data.billed_amount ? data.billed_amount / 100 : 0
  );
  self.contract_signed = ko.observable(data.contract_signed || false);
  self.invoice_company_name = ko.observable(data.invoice_company_name || "");
  self.invoice_address_street1 = ko.observable(
    data.invoice_address_street1 || ""
  );
  self.invoice_address_street2 = ko.observable(
    data.invoice_address_street2 || ""
  );
  self.invoice_address_street3 = ko.observable(
    data.invoice_address_street3 || ""
  );
  self.invoice_address_city = ko.observable(data.invoice_address_city || "");
  self.invoice_address_state = ko.observable(data.invoice_address_state || "");
  self.invoice_address_zip = ko.observable(data.invoice_address_zip || "");
  self.invoice_address_country = ko.observable(
    data.invoice_address_country || ""
  );
  self.contact_name_first = ko.observable(data.contact_name_first || "");
  self.contact_name_last = ko.observable(data.contact_name_last || "");
  self.contact_name_email = ko.observable(data.contact_name_email || "");
  self.contact_name_phone = ko.observable(data.contact_name_phone || "");

  self.packages = ko.observable(data.packages || null);

  self.total_price = ko.observable(
    data.total_price ? data.total_price / 100 : 0
  );

  self.list_purchased = ko.observable(data.list_purchased || 0);

  self.image_purchased = ko.observable(data.image_purchased || 0);

  self.invite_template_purchased = ko.observable(
    data.invite_template_purchased || 0
  );
  self.invite_message_purchased = ko.observable(
    data.invite_message_purchased || 0
  );

  self.marketing_template_purchased = ko.observable(
    data.marketing_template_purchased || 0
  );
  self.marketing_message_purchased = ko.observable(
    data.marketing_message_purchased || 0
  );

  self.text_template_purchased = ko.observable(
    data.text_template_purchased || 0
  );
  self.text_message_purchased = ko.observable(data.text_message_purchased || 0);

  self.list_used = ko.observable(data.list_used || 0);
  self.image_used = ko.observable(data.image_used || 0);

  self.invite_template_used = ko.observable(data.invite_template_used || 0);
  self.invite_message_used = ko.observable(data.invite_message_used || 0);

  self.marketing_template_used = ko.observable(
    data.marketing_template_used || 0
  );
  self.marketing_message_used = ko.observable(data.marketing_message_used || 0);

  self.text_template_used = ko.observable(data.text_template_used || 0);
  self.text_message_used = ko.observable(data.text_message_used || 0);
};

Exposync.ExpoPackagesModel = function (data) {
  var self = this;

  const notNull = function (value) {
    return value != undefined && value != null;
  };
  self.id = ko.observable(data.id || "");

  //email1
  self.email1_package_name = ko.observable(
    data.email1_package_name || "Starter"
  );
  self.email1_list = ko.observable(
    notNull(data.email1_list) ? data.email1_list : 1
  );
  self.email1_image = ko.observable(
    notNull(data.email1_image) ? data.email1_image : 1
  );
  self.email1_invite_template = ko.observable(
    notNull(data.email1_invite_template) ? data.email1_invite_template : 1
  );
  self.email1_invite_message = ko.observable(
    notNull(data.email1_invite_message) ? data.email1_invite_message : 250
  );
  self.email1_marketing_template = ko.observable(
    notNull(data.email1_marketing_template) ? data.email1_marketing_template : 1
  );
  self.email1_marketing_message = ko.observable(
    notNull(data.email1_marketing_message) ? data.email1_marketing_message : 250
  );
  self.email1_price = ko.observable(
    notNull(data.email1_price) ? data.email1_price / 100 : 250
  );
  self.email1_quantity = ko.observable(
    notNull(data.email1_quantity) ? data.email1_quantity : 0
  );
  self.email1_cost = ko.computed(function () {
    return self.email1_price() * self.email1_quantity();
  });

  //email2
  self.email2_package_name = ko.observable(data.email2_package_name || "Basic");
  self.email2_list = ko.observable(
    notNull(data.email2_list) ? data.email2_list : 1
  );
  self.email2_image = ko.observable(
    notNull(data.email2_image) ? data.email2_image : 2
  );
  self.email2_invite_template = ko.observable(
    notNull(data.email2_invite_template) ? data.email2_invite_template : 1
  );
  self.email2_invite_message = ko.observable(
    notNull(data.email2_invite_message) ? data.email2_invite_message : 250
  );
  self.email2_marketing_template = ko.observable(
    notNull(data.email2_marketing_template) ? data.email2_marketing_template : 2
  );
  self.email2_marketing_message = ko.observable(
    notNull(data.email2_marketing_message) ? data.email2_marketing_message : 500
  );
  self.email2_price = ko.observable(
    notNull(data.email2_price) ? data.email2_price / 100 : 500
  );
  self.email2_quantity = ko.observable(
    notNull(data.email2_quantity) ? data.email2_quantity : 0
  );
  self.email2_cost = ko.computed(function () {
    return self.email2_price() * self.email2_quantity();
  });

  //eamil3
  self.email3_package_name = ko.observable(
    data.email3_package_name || "Standard"
  );
  self.email3_list = ko.observable(
    notNull(data.email3_list) ? data.email3_list : 1
  );
  self.email3_image = ko.observable(
    notNull(data.email3_image) ? data.email3_image : 3
  );
  self.email3_invite_template = ko.observable(
    notNull(data.email3_invite_template) ? data.email3_invite_template : 1
  );
  self.email3_invite_message = ko.observable(
    notNull(data.email3_invite_message) ? data.email3_invite_message : 500
  );
  self.email3_marketing_template = ko.observable(
    notNull(data.email3_marketing_template) ? data.email3_marketing_template : 3
  );
  self.email3_marketing_message = ko.observable(
    notNull(data.email3_marketing_message) ? data.email3_marketing_message : 750
  );
  self.email3_price = ko.observable(
    notNull(data.email3_price) ? data.email3_price / 100 : 750
  );
  self.email3_quantity = ko.observable(
    notNull(data.email3_quantity) ? data.email3_quantity : 0
  );
  self.email3_cost = ko.computed(function () {
    return self.email3_price() * self.email3_quantity();
  });

  //email4
  self.email4_package_name = ko.observable(
    data.email4_package_name || "Advanced"
  );
  self.email4_list = ko.observable(
    notNull(data.email4_list) ? data.email4_list : 2
  );
  self.email4_image = ko.observable(
    notNull(data.email4_image) ? data.email4_image : 4
  );
  self.email4_invite_template = ko.observable(
    notNull(data.email4_invite_template) ? data.email4_invite_template : 2
  );
  self.email4_invite_message = ko.observable(
    notNull(data.email4_invite_message) ? data.email4_invite_message : 500
  );
  self.email4_marketing_template = ko.observable(
    notNull(data.email4_marketing_template) ? data.email4_marketing_template : 4
  );
  self.email4_marketing_message = ko.observable(
    notNull(data.email4_marketing_message)
      ? data.email4_marketing_message
      : 1000
  );
  self.email4_price = ko.observable(
    notNull(data.email4_price) ? data.email4_price / 100 : 1000
  );
  self.email4_quantity = ko.observable(
    notNull(data.email4_quantity) ? data.email4_quantity : 0
  );
  self.email4_cost = ko.computed(function () {
    return self.email4_price() * self.email4_quantity();
  });

  //email5
  self.email5_package_name = ko.observable(
    data.email5_package_name || "Mighty"
  );
  self.email5_list = ko.observable(
    notNull(data.email5_list) ? data.email5_list : 2
  );
  self.email5_image = ko.observable(
    notNull(data.email5_image) ? data.email5_image : 5
  );
  self.email5_invite_template = ko.observable(
    notNull(data.email5_invite_template) ? data.email5_invite_template : 2
  );
  self.email5_invite_message = ko.observable(
    notNull(data.email5_invite_message) ? data.email5_invite_message : 1000
  );
  self.email5_marketing_template = ko.observable(
    notNull(data.email5_marketing_template) ? data.email5_marketing_template : 5
  );
  self.email5_marketing_message = ko.observable(
    notNull(data.email5_marketing_message)
      ? data.email5_marketing_message
      : 2000
  );
  self.email5_price = ko.observable(
    notNull(data.email5_price) ? data.email5_price / 100 : 2000
  );
  self.email5_quantity = ko.observable(
    notNull(data.email5_quantity) ? data.email5_quantity : 0
  );
  self.email5_cost = ko.computed(function () {
    return self.email5_price() * self.email5_quantity();
  });

  // self.text1_package_name = ko.observable(data.text1_package_name || 'Starter');
  // self.text1_text_template = ko.observable(data.text1_text_template || 1);
  // self.text1_text_message = ko.observable(data.text1_text_message || 250);
  // self.text1_price = ko.observable(data.text1_price / 100 || 250);
  // self.text1_quantity = ko.observable(data.text1_quantity || 0);
  // self.text1_cost = ko.computed(function() {
  //     return self.text1_price() * self.text1_quantity();
  // });

  // self.text2_package_name = ko.observable(data.text2_package_name || 'Basic');
  // self.text2_text_template = ko.observable(data.text2_text_template || 2);
  // self.text2_text_message = ko.observable(data.text2_text_message || 500);
  // self.text2_price = ko.observable(data.text2_price / 100 || 500);
  // self.text2_quantity = ko.observable(data.text2_quantity || 0);
  // self.text2_cost = ko.computed(function() {
  //     return self.text2_price() * self.text2_quantity();
  // });

  // self.text3_package_name = ko.observable(data.text3_package_name || 'Standard');
  // self.text3_text_template = ko.observable(data.text3_text_template || 3);
  // self.text3_text_message = ko.observable(data.text3_text_message || 750);
  // self.text3_price = ko.observable(data.text3_price / 100 || 750);
  // self.text3_quantity = ko.observable(data.text3_quantity || 0);
  // self.text3_cost = ko.computed(function() {
  //     return self.text3_price() * self.text3_quantity();
  // });

  // self.text4_package_name = ko.observable(data.text4_package_name || 'Advanced');
  // self.text4_text_template = ko.observable(data.text4_text_template || 4);
  // self.text4_text_message = ko.observable(data.text4_text_message || 1000);
  // self.text4_price = ko.observable(data.text4_price / 100 || 1000);
  // self.text4_quantity = ko.observable(data.text4_quantity || 0);
  // self.text4_cost = ko.computed(function() {
  //     return self.text4_price() * self.text4_quantity();
  // });

  // self.text5_package_name = ko.observable(data.text5_package_name || 'Mighty');
  // self.text5_text_template = ko.observable(data.text5_text_template || 5);
  // self.text5_text_message = ko.observable(data.text5_text_message || 2000);
  // self.text5_price = ko.observable(data.text5_price / 100 || 2000);
  // self.text5_quantity = ko.observable(data.text5_quantity || 0);
  // self.text5_cost = ko.computed(function() {
  //     return self.text5_price() * self.text5_quantity();
  // });

  self.list = ko.observable(notNull(data.list) ? data.list : 1);
  self.list_price = ko.observable(
    notNull(data.list_price) ? data.list_price / 100 : 250
  );
  self.list_quantity = ko.observable(
    notNull(data.list_quantity) ? data.list_quantity : 0
  );
  self.list_cost = ko.computed(function () {
    return self.list_price() * self.list_quantity();
  });

  self.image = ko.observable(notNull(data.image) ? data.image : 1);
  self.image_price = ko.observable(
    notNull(data.image_price) ? data.image_price / 100 : 200
  );
  self.image_quantity = ko.observable(
    notNull(data.image_quantity) ? data.image_quantity : 0
  );
  self.image_cost = ko.computed(function () {
    return self.image_price() * self.image_quantity();
  });

  self.marketing_template = ko.observable(
    notNull(data.marketing_template) ? data.marketing_template : 1
  );
  self.marketing_template_price = ko.observable(
    notNull(data.marketing_template_price)
      ? data.marketing_template_price / 100
      : 200
  );
  self.marketing_template_quantity = ko.observable(
    notNull(data.marketing_template_quantity)
      ? data.marketing_template_quantity
      : 0
  );
  self.marketing_template_cost = ko.computed(function () {
    return self.marketing_template_price() * self.marketing_template_quantity();
  });

  self.marketing_message = ko.observable(
    notNull(data.marketing_message) ? data.marketing_message : 100
  );
  self.marketing_message_price = ko.observable(
    notNull(data.marketing_message_price)
      ? data.marketing_message_price / 100
      : 50
  );
  self.marketing_message_quantity = ko.observable(
    notNull(data.marketing_message_quantity)
      ? data.marketing_message_quantity
      : 0
  );
  self.marketing_message_cost = ko.computed(function () {
    return self.marketing_message_price() * self.marketing_message_quantity();
  });

  // self.text_template = ko.observable(data.text_template || 1);
  // self.text_template_price = ko.observable(data.text_template_price / 100 || 200);
  // self.text_template_quantity = ko.observable(data.text_template_quantity || 0);
  // self.text_template_cost = ko.computed(function() {
  //     return self.text_template_price() * self.text_template_quantity();
  // });

  // self.text_message = ko.observable(data.text_message || 50);
  // self.text_message_price = ko.observable(data.text_message_price / 100 || 50);
  // self.text_message_quantity = ko.observable(data.text_message_quantity || 0);
  // self.text_message_cost = ko.computed(function() {
  //     return self.text_message_price() * self.text_message_quantity();
  // });

  self.payment_status_required = ko.observable(true);
  self.contract_status_required = ko.observable(true);
  self.available_required = ko.observable(true);

  self.manager_approval_required = ko.observable(true);
  self.client_approval_required = ko.observable(true);
  self.daily_max_condition = ko.observable(true);
  self.daily_max_count = ko.observable(3);

  this.payment_status_required.ForEditing = ko.computed({
    read: function () {
      return this.payment_status_required().toString();
    },
    write: function (newValue) {
      this.payment_status_required(newValue === "true");
    },
    owner: this,
  });

  this.contract_status_required.ForEditing = ko.computed({
    read: function () {
      return this.contract_status_required().toString();
    },
    write: function (newValue) {
      this.contract_status_required(newValue === "true");
    },
    owner: this,
  });

  this.available_required.ForEditing = ko.computed({
    read: function () {
      return this.available_required().toString();
    },
    write: function (newValue) {
      this.available_required(newValue === "true");
    },
    owner: this,
  });

  this.manager_approval_required.ForEditing = ko.computed({
    read: function () {
      return this.manager_approval_required().toString();
    },
    write: function (newValue) {
      this.manager_approval_required(newValue === "true");
    },
    owner: this,
  });

  this.client_approval_required.ForEditing = ko.computed({
    read: function () {
      return this.client_approval_required().toString();
    },
    write: function (newValue) {
      this.client_approval_required(newValue === "true");
    },
    owner: this,
  });

  this.daily_max_condition.ForEditing = ko.computed({
    read: function () {
      return this.daily_max_condition().toString();
    },
    write: function (newValue) {
      this.daily_max_condition(newValue === "true");
    },
    owner: this,
  });

  if (data.payment_status_required !== undefined)
    self.payment_status_required(data.payment_status_required);

  if (data.contract_status_required !== undefined)
    self.contract_status_required(data.contract_status_required);

  if (data.available_required !== undefined)
    self.available_required(data.available_required);

  if (data.manager_approval_required !== undefined)
    self.manager_approval_required(data.manager_approval_required);

  if (data.client_approval_required !== undefined)
    self.client_approval_required(data.client_approval_required);

  if (data.daily_max_condition !== undefined)
    self.daily_max_condition(data.daily_max_condition);

  if (data.daily_max_count !== undefined)
    self.daily_max_count(data.daily_max_count);

  self.total = ko.computed(function () {
    return (
      self.email1_cost() +
      self.email2_cost() +
      self.email3_cost() +
      self.email4_cost() +
      self.email5_cost() +
      //self.text1_cost() + self.text2_cost() + self.text3_cost() + self.text4_cost() + self.text5_cost() +
      self.list_cost() +
      self.image_cost() +
      self.marketing_template_cost() +
      self.marketing_message_cost()
    );
    //self.text_template_cost() + self.text_message_cost();
  });
  self.toJSON = function () {
    return {
      email1_package_name: self.email1_package_name(),
      email1_list: self.email1_list(),
      email1_image: self.email1_image(),
      email1_invite_template: self.email1_invite_template(),
      email1_invite_message: self.email1_invite_message(),
      email1_marketing_template: self.email1_marketing_template(),
      email1_marketing_message: self.email1_marketing_message(),
      email1_price: self.email1_price() * 100,

      email2_package_name: self.email2_package_name(),
      email2_list: self.email2_list(),
      email2_image: self.email2_image(),
      email2_invite_template: self.email2_invite_template(),
      email2_invite_message: self.email2_invite_message(),
      email2_marketing_template: self.email2_marketing_template(),
      email2_marketing_message: self.email2_marketing_message(),
      email2_price: self.email2_price() * 100,

      email3_package_name: self.email3_package_name(),
      email3_list: self.email3_list(),
      email3_image: self.email3_image(),
      email3_invite_template: self.email3_invite_template(),
      email3_invite_message: self.email3_invite_message(),
      email3_marketing_template: self.email3_marketing_template(),
      email3_marketing_message: self.email3_marketing_message(),
      email3_price: self.email3_price() * 100,

      email4_package_name: self.email4_package_name(),
      email4_list: self.email4_list(),
      email4_image: self.email4_image(),
      email4_invite_template: self.email4_invite_template(),
      email4_invite_message: self.email4_invite_message(),
      email4_marketing_template: self.email4_marketing_template(),
      email4_marketing_message: self.email4_marketing_message(),
      email4_price: self.email4_price() * 100,

      email5_package_name: self.email5_package_name(),
      email5_list: self.email5_list(),
      email5_image: self.email5_image(),
      email5_invite_template: self.email5_invite_template(),
      email5_invite_message: self.email5_invite_message(),
      email5_marketing_template: self.email5_marketing_template(),
      email5_marketing_message: self.email5_marketing_message(),
      email5_price: self.email5_price() * 100,

      // text1_package_name: self.text1_package_name(),
      // text1_text_template: self.text1_text_template(),
      // text1_text_message: self.text1_text_message(),
      // text1_price: self.text1_price() * 100,

      // text2_package_name: self.text2_package_name(),
      // text2_text_template: self.text2_text_template(),
      // text2_text_message: self.text2_text_message(),
      // text2_price: self.text2_price() * 100,

      // text3_package_name: self.text3_package_name(),
      // text3_text_template: self.text3_text_template(),
      // text3_text_message: self.text3_text_message(),
      // text3_price: self.text3_price() * 100,

      // text4_package_name: self.text4_package_name(),
      // text4_text_template: self.text4_text_template(),
      // text4_text_message: self.text4_text_message(),
      // text4_price: self.text4_price() * 100,

      // text5_package_name: self.text5_package_name(),
      // text5_text_template: self.text5_text_template(),
      // text5_text_message: self.text5_text_message(),
      // text5_price: self.text5_price() * 100,

      list: self.list(),
      list_price: self.list_price() * 100,

      image: self.image(),
      image_price: self.image_price() * 100,

      marketing_template: self.marketing_template(),
      marketing_template_price: self.marketing_template_price() * 100,

      marketing_message: self.marketing_message(),
      marketing_message_price: self.marketing_message_price() * 100,

      // text_template: self.text_template(),
      // text_template_price: self.text_template_price() * 100,

      // text_message: self.text_message(),
      // text_message_price: self.text_message_price() * 100,

      payment_status_required: self.payment_status_required(),
      contract_status_required: self.contract_status_required(),
      available_required: self.available_required(),
      manager_approval_required: self.manager_approval_required(),
      client_approval_required: self.client_approval_required(),
      daily_max_condition: self.daily_max_condition(),
      daily_max_count: self.daily_max_count(),
    };
  };
};

Exposync.ListModel = function (data, fields) {
  var self = this;

  self.id = ko.observable(data.id || "");
  self.name = ko.observable(data.name || "");
  self.auto_approve = ko.observable(data.auto_approve || false);
  self.expo_id = ko.observable(data.expo_id || "");
  self.exhibitor_id = ko.observable(data.exhibitor_id || "");
  self.search_id = ko.observable(data.search_id || "");
  self.search = ko.observable(
    data.search
      ? new Exposync.SearchModel(data.search, fields)
      : new Exposync.SearchModel({})
  );
  self.mode = ko.observable(); //new or update

  self.auto_approve.subscribe(function (value) {
    var data = {
      auto_approve: value,
    };
    if (self.id()) {
      ListApi.update(self.id(), data, function (result) {});
    }
  }, self);

  self.toJSON = function () {
    return {
      name: self.name(),
      auto_approve: self.auto_approve(),
      expo_id: self.expo_id(),
      exhibitor_id: self.exhibitor_id(),
      search_id: self.search_id(),
    };
  };
};

Exposync.SearchModel = function (data, fields) {
  var self = this;

  self.id = ko.observable(data.id || "");
  self.is_template = ko.observable(data.is_template || "");
  self.name = ko.observable(data.name || "");
  self.expo_id = ko.observable(data.expo_id || "");
  self.client_id = ko.observable(data.client_id || "");

  self.criteria = ko.observableArray(
    data.criteria === undefined || data.criteria === null
      ? []
      : $.map(data.criteria, function (value, index) {
          value.expo_id = data.expo_id;
          value.client_id = data.client_id;
          value.fields = fields;
          return new Exposync.CriteriaModel(value);
        })
  );

  self.engagement_criteria = ko.observableArray(
    data.engagement_criteria === undefined || data.engagement_criteria === null
      ? []
      : $.map(data.engagement_criteria, function (value, index) {
          value.message_id = value.message_id;
          value.client_id = data.client_id;
          value.fields = fields;

          var rows = $.map(
            value.criteria,
            function (criteria_value, criteria_index) {
              criteria_value.message_id = value.message_id;
              criteria_value.client_id = data.client_id;
              criteria_value.fields = fields;
              return new Exposync.CriteriaModel(criteria_value);
            }
          );

          return {
            message_id: ko.observable(value.message_id),
            client_id: ko.observable(data.client_id),
            criteria: ko.observableArray(rows),
          };
        })
  );

  self.mode = ko.observable(); //new or update

  self.toJSON = function () {
    return {
      is_template: self.is_template(),
      name: self.name(),
      expo_id: self.expo_id(),
      client_id: self.client_id(),
      criteria: $.map(self.criteria(), function (value, index) {
        return value.toJSON();
      }),
      engagement_criteria: $.map(
        self.engagement_criteria(),
        function (value, index) {
          return {
            message_id: value.message_id(),
            criteria: $.map(
              value.criteria(),
              function (criteria_value, criteria_index) {
                return criteria_value.toJSON();
              }
            ),
          };
        }
      ),
    };
  };
};

Exposync.ReplyDataModel = function (data) {
  var self = this;

  self.name = ko.observable(data.name || "");
  self.is_template = ko.observable(data.is_template || "");
  self.questions = ko.observableArray(
    ko.utils.arrayMap(data.field_mapping, function (item) {
      return new Exposync.QuestionModel(item);
    }) || []
  );
  self.expo_id = ko.observable(data.expo_id || "");
};

Exposync.QuestionModel = function (data) {
  var self = this;
  self.field = ko.observable(data.field || "");
  self.question = ko.observable(data.question || "");
  self.type = ko.observable(data.type || "");
  self.options = ko.observable(data.options || "");
  self.required = ko.observable(data.required || true);
  self.value = ko.observable();
  self.checkedValues = ko.observableArray([]);
  self.selections = ko.observableArray(
    data.options
      ? data.options.split(";").filter(function (item) {
          return item.length > 0;
        })
      : []
  );
  self.sourceDataColumn = ko.observable(data.sourceDataColumn || "");
  self.maximumSelections = ko.observable(data.maximumSelections || 0);

  self.isValid = ko.computed(function () {
    if (self.field() && self.type()) {
      if (
        self.type() == "single-selection" ||
        self.type == "multiple-selection"
      ) {
        if (self.options()) return true;
        else return false;
      }
      return true;
    } else {
      return false;
    }
  });
};

Exposync.CriteriaModel = function (data) {
  var self = this;

  self.expoId = ko.observable(data.expo_id || "");
  self.clientId = ko.observable(data.client_id || "");
  self.messageId = ko.observable(data.message_id || "");
  self.isEngagement = ko.observable(data.is_engagement || false);
  self.linkage = ko.observable(
    data.linkage ? data.linkage.toUpperCase() : "AND"
  );
  self.not = ko.observable(data.not || false);
  self.field = ko.observable(data.field || "");
  self.fields = ko.observableArray(data.fields || []);

  self.term = ko.observable(data.term || "term");
  self.termValue = ko.observable(data.value || "");
  self.termText = ko.observable(data.value || "");
  self.selectorValue = ko.observable(data.value || "");
  self.operators = ko.observableArray([]);
  self.terms = ko.observableArray([]);
  self.term_required = ko.observable(true);
  self.operator = ko.observable(setOperator(data));

  self.operatorObject = ko.observable(setOperatorObject(data));

  self.isExhibitorList = ko.observable(data.isExhibitorList || false);

  function setOperator(data) {
    if (data && data.operator) {
      var field = ko.utils.arrayFirst(data.fields, function (item) {
        return item.name == data.field;
      });
      if (!field) return "";
      self.operators(utils.getOperators(field.type));

      var operator = ko.utils.arrayFirst(self.operators(), function (item) {
        return item.system_operator == data.operator && item.not == data.not;
      });

      return operator.id;
    } else return "";
  }

  function setOperatorObject(data) {
    if (data && data.operator) {
      var field = ko.utils.arrayFirst(data.fields, function (item) {
        return item.name == data.field;
      });
      if (!field) return "";
      self.operators(utils.getOperators(field.type));

      var operator = ko.utils.arrayFirst(self.operators(), function (item) {
        return item.system_operator == data.operator && item.not == data.not;
      });

      return operator;
    } else return null;
  }

  function getOperator() {
    if (self.operator()) {
      var field = ko.utils.arrayFirst(self.fields(), function (item) {
        return item.name == self.field();
      });

      self.operators(utils.getOperators(field.type));

      return ko.utils.arrayFirst(self.operators(), function (item) {
        return item.id == self.operator();
      });
    }

    return null;
  }

  function getValue(operator) {
    if (operator) {
      if (operator.system_operator == "length") return "0";
      if (operator.term == false) return "";
      else
        return self.term() == "term" ? self.termValue() : self.selectorValue();
    }
  }

  ko.computed(function () {
    if (!self.operator()) return;
    if (!(self.operators() && self.operators().length > 0)) return;

    var operator = ko.utils.arrayFirst(self.operators(), function (item) {
      return item.id == self.operator();
    });

    self.term_required(operator.term);
  });

  ko.computed(function () {
    var field = self.field();
    var term = self.term();
    var expoId = self.expoId();
    var clientId = self.clientId();
    var messageId = self.messageId();

    if (
      [
        "exhibitors_clicked",
        "exhibitors_interested",
        "exhibitors_maybe",
        "exhibitors_notinterested",
      ].indexOf(field) >= 0
    ) {
      if (self.termValue() && self.termValue().length == 36) {
        ExhibitorsApi.get(self.termValue(), function (data) {
          self.termText(data.exhibitor_name);
        });
      }
    }
    if (expoId && field && term == "selector") {
      if (self.isExhibitorList()) {
        SearchApi.getExhibitorTerms(
          expoId,
          field,
          clientId,
          messageId,
          function (data) {
            if (data && data.length > 0) {
              if (typeof data[0] === "string") {
                var terms = data.map(function (item) {
                  return {
                    friendly: item,
                    value: item,
                  };
                });
              } else terms = data;

              self.terms(terms.sort(termCompare));
            } else self.terms([]);
          }
        );
      } else {
        SearchApi.getTerms(expoId, field, clientId, messageId, function (data) {
          if (data && data.length > 0) {
            if (typeof data[0] === "string") {
              var terms = data.map(function (item) {
                return {
                  friendly: item,
                  value: item,
                };
              });
            } else terms = data;

            self.terms(terms.sort(termCompare));
          } else self.terms([]);
        });
      }
    }
  });

  function termCompare(a, b) {
    if (a.friendly < b.friendly) return -1;
    if (a.friendly > b.friendly) return 1;
    return 0;
  }

  ko.computed(function () {
    var field = self.field();
    var expoId = self.expoId();
    self.operators([]);

    if (expoId && field) {
      var fieldObject = ko.utils.arrayFirst(self.fields(), function (item) {
        return item.name == field;
      });
      if (fieldObject) self.operators(utils.getOperators(fieldObject.type));
    } else {
      self.operators([]);
    }
  });

  self.isValid = ko.computed(function () {
    var operator = getOperator();
    if (
      self.expoId() &&
      self.linkage() &&
      self.operator() &&
      self.field() &&
      (self.termValue() || self.selectorValue() || !operator.term)
    )
      return true;
    else return false;
  });

  self.isEngagementCriteriaValid = ko.computed(function () {
    if (self.operator() && self.field()) return true;
    else return false;
  });

  self.toJSON = function () {
    var operator = getOperator();
    return {
      field: self.field(),
      linkage: self.linkage().toLowerCase(),
      not: operator.not,
      operator: operator.system_operator,
      value: getValue(operator),
    };
  };
};

Exposync.MessageModel = function (data) {
  var self = this;
  self.id = ko.observable(data.id || "");
  self.message_name = ko.observable(data.message_name || "");

  self.message_subject = ko.observable(data.message_subject || "");
  self.friendly_from = ko.observable(data.friendly_from || "");
  self.reply_to = ko.observable(data.reply_to || "");
  self.message_type = ko.observable(data.message_type || "email");
  self.send_suspended_emails = ko.observable(
    data.send_suspended_emails || false
  );
  self.message_options = ko.observable(data.message_options || "message");
  if (data.send_start_date)
    data.send_start_date = data.send_start_date.substr(
      0,
      data.send_start_date.length - 5
    );
  self.send_start_date = ko.observable(data.send_start_date || "");
  if (data.send_end_date)
    data.send_end_date = data.send_end_date.substr(
      0,
      data.send_end_date.length - 5
    );
  self.send_end_date = ko.observable(data.send_end_date || "");
  self.lists = ko.observable(data.list_ids || "");

  self.user_id = ko.observable(data.user_id || "");
  self.client_id = ko.observable(data.client_id || "");
  self.expo_id = ko.observable(data.expo_id || "");
  self.user_message_template_id = ko.observable(
    data.user_message_template_id || ""
  );
  self.reply_data_id = ko.observable(data.reply_data_id || null);

  self.client = ko.observable(data.client || null);
  self.user_message_template = ko.observable(
    data.user_message_template || null
  );

  self.sent_messages = ko.observable(data.sent_messages || 0);
  self.pending_messages = ko.observable(data.pending_messages || 0);
  self.approved_messages = ko.observable(data.approved_messages || 0);
  self.scheduled = ko.observable(data.scheduled || false);
  //custom JSONB object
  self.schedule_details = ko.observable(data.schedule_details || {});
  self.status = ko.observable(data.status || "DRAFT");

  //Approvals
  self.client_approved = ko.observable(data.client_approved || false);
  self.manager_approved = ko.observable(data.manager_approved || false);
  self.marketer_approved = ko.observable(true);
  self.schedule_approved = ko.observable(
    data.manager_schedule_approved || false
  );
  self.lists_approved = ko.observable(data.manager_lists_approved || false);
  self.template_approved = ko.observable(
    data.manager_template_approved || false
  );

  self.interval_type = ko.observable("once"); //repeat
  self.send_date = ko.observable(moment());
  self.schedule_start_date = ko.observable(data.send_start_date || "");
  self.schedule_end_date = ko.observable(data.send_end_date || "");

  self.interval = ko.observable("day");

  self.day_frequency = ko.observableArray(["MON", "TUE", "WED", "THU", "FRI"]);
  self.daily_send_time = ko.observable();

  self.hour_frequency = ko.observable(1);
  self.minute_frequency = ko.observable(35);

  self.timezone = ko.observable("America/Los_Angeles");
  self.exclude_weekends = ko.observable(true);
  self.dont_send_before_time = ko.observable();
  self.dont_send_after_time = ko.observable();
  self.sending_restrictions = ko.observable("no");
  self.scheduling_data = ko.observable(data.scheduling_data || {});
  var schedule = data.schedule_details;

  if (schedule) {
    //interval_type = once/recurring
    if (schedule.interval_type) self.interval_type(schedule.interval_type);

    if (schedule.send_date) self.send_date(moment(schedule.send_date));
    if (schedule.start_date)
      self.schedule_start_date(moment(schedule.start_date));
    if (schedule.end_date) self.schedule_end_date(moment(schedule.end_date));

    if (schedule.timezone) self.timezone(schedule.timezone);

    if (schedule.interval) self.interval(schedule.interval);

    if (schedule.day_frequency) self.day_frequency(schedule.day_frequency);

    if (schedule.daily_send_time)
      self.daily_send_time(moment(schedule.daily_send_time));

    if (schedule.hour_frequency) self.day_frequency(schedule.hour_frequency);
    if (schedule.minute_frequency)
      self.minute_frequency(schedule.minute_frequency);

    if (schedule.sending_restrictions)
      self.sending_restrictions(schedule.sending_restrictions);

    if (schedule.exclude_weekends)
      self.exclude_weekends(schedule.exclude_weekends);

    if (schedule.dont_send_before_time)
      self.dont_send_before_time(moment(schedule.dont_send_before_time));

    if (schedule.dont_send_after_time)
      self.dont_send_after_time(moment(schedule.dont_send_after_time));

    if (schedule.job_id) self.job_id(schedule.job_id);

    if (schedule.cron_schedule) self.cron_schedule(schedule.cron_schedule);

    if (schedule.last_run) self.last_run(schedule.last_run);

    if (schedule.next_run) self.next_run(schedule.next_run);

    if (schedule.finished) self.finished(schedule.finished);
  }

  self.client_approved.subscribe(function (value) {
    var data = {
      client_approved: value,
    };
    if (self.id()) {
      MessagesApi.update(self.id(), data, function (result) {});
    }
  }, self);

  //  self.manager_approved.subscribe(function(value) {
  //      var data = { manager_approved: value };
  //      if (self.id()) {
  //          MessagesApi.update(self.id(), data, function(result) {});
  //      }
  //  }, self);

  self.toJSON = function () {
    return {
      message_name: self.message_name(),
      message_subject: self.message_subject(),
      friendly_from: self.friendly_from(),
      reply_to: self.reply_to(),
      message_type: self.message_type(),
      send_suspended_emails: self.send_suspended_emails(),
      message_options: self.message_options(),
      send_start_date: self.send_start_date().isValid()
        ? self.send_start_date().format("MM/DD/YYYY h:mm a")
        : null,
      send_end_date: self.send_end_date().isValid()
        ? self.send_end_date().format("MM/DD/YYYY h:mm a")
        : null,
      //list_ids : self.lists(),
      client_id: self.client_id(),
      user_message_template_id: self.user_message_template_id(),
      marketer_approved: self.marketer_approved(),
      reply_data_id: self.reply_data_id() ? self.reply_data_id() : null,
    };
  };
};
Exposync.EmailTemplateModel = function (data) {
  var self = this;

  self.id = ko.observable(data.id || "");
  self.name = ko.observable(data.name || "");
  self.content = ko.observableArray(data.content || []);
  self.lockedContent = ko.observableArray(data.locked || []);
  self.backgroundColor = ko.observable(data.background_color || "#FFF");
  self.approved = ko.observable(data.approved || false);
  self.expo_id = ko.observable(data.expo_id || "");
  self.exhibitor_id = ko.observable(data.exhibitor_id || "");
  self.expo = ko.observable(data.expo || null);
  self.exhibitor = ko.observable(data.exhibitor || null);

  self.style_background_color = ko.observable(
    data.style_background_color || "#FFFFFF"
  );
  self.style_content_background_color = ko.observable(
    data.style_content_background_color || "#FFFFFF"
  );
  self.style_border_width = ko.observable(data.style_border_width || 0);
  self.style_border_color = ko.observable(data.style_border_color || "#FFFFFF");
  self.style_width = ko.observable(data.style_width || 650);
  self.header_content = ko.observable(data.header_content || "");
  self.pre_header_text = ko.observable(data.pre_header_text || "");

  self.toJSON = function () {
    return {
      name: self.name(),
      content: self.content(),
      locked: self.lockedContent(),
      background_color: self.backgroundColor(),
      approved: self.approved(),
      expo_id: self.expo_id(),
      exhibitor_id: self.exhibitor_id(),
      style_background_color: self.style_background_color(),
      style_content_background_color: self.style_content_background_color(),
      style_border_width: self.style_border_width(),
      style_border_color: self.style_border_color(),
      style_width: self.style_width(),
      header_content: self.header_content(),
      pre_header_text: self.pre_header_text(),
    };
  };
};

Exposync.ExhibitorModel = function (data) {
  var self = this;

  self.id = ko.observable(data.id || "");
  self.name = ko.observable(data.exhibitor_name || "");

  self.toJSON = function () {
    return {
      id: self.id(),
      name: self.name(),
    };
  };
};

Exposync.ExpoModel = function (data) {
  var self = this;

  self.id = ko.observable(data.id || "");
  self.name = ko.observable(data.expo_name || "");
  self.description = ko.observable(data.description || "");
  self.archived = ko.observable(data.archived || false);
  self.city = ko.observable(data.expo_city || "");
  self.timezone = ko.observable(data.timezone || "Etc/GMT-8");
  self.venue = ko.observable(data.expo_venue || "");
  self.start_date = ko.observable(data.start_date || "");
  self.end_date = ko.observable(data.end_date || "");
  self.website_url = ko.observable(data.website_url || "");
  self.floorplan_url = ko.observable(data.floorplan_url || "");
  self.agenda_url = ko.observable(data.agenda_url || "");
  self.transportation_url = ko.observable(data.transportation_url || "");
  self.expo_logo_url = ko.observable(data.expo_logo_url || "");
  self.expo_default_guide_image_url = ko.observable(
    data.expo_default_guide_image_url || ""
  );
  self.home_screen_icon_url = ko.observable(data.home_screen_icon_url || "");
  self.expo_images = ko.observableArray(data.expo_images || []);

  self.organizer = ko.observable(data.organizer || "");
  self.organizer_address1 = ko.observable(data.organizer_address1 || "");
  self.organizer_address2 = ko.observable(data.organizer_address2 || "");
  self.organizer_city = ko.observable(data.organizer_city || "");
  self.organizer_state = ko.observable(data.organizer_state || "");
  self.organizer_zip = ko.observable(data.organizer_zip || "");
  self.organizer_phone = ko.observable(data.organizer_phone || "");
  self.organizer_website = ko.observable(data.organizer_website || "");

  self.categories = ko.observableArray(data.categories || []);
  self.demographics = ko.observableArray(data.demographics || []);
  self.sending_email_prefix = ko.observable(data.sending_email_prefix || "");
  self.sending_email_account = ko.observable(data.sending_email_account || "");

  self.formattedAddress = ko.computed(function () {
    var address = self.organizer_address1();
    if (self.organizer_address2()) {
      address = address + ", " + self.organizer_address2();
    }
    address = address + "\n";
    if (self.organizer_city()) {
      address = address + self.organizer_city();
    }
    if (self.organizer_state()) {
      address = address + ", " + self.organizer_state();
    }

    if (self.organizer_zip()) {
      address = address + " " + self.organizer_zip();
    }

    return address;
  });

  self.formattedExpoName = ko.computed(function () {
    var name = self.name();
    if (self.start_date() && self.end_date()) {
      name =
        name +
        " " +
        utils.formatDate(self.start_date()) +
        " - " +
        utils.formatDate(self.end_date());
    }

    if (self.city()) {
      name = name + " - " + self.city();
    }
    return name;
  });

  self.formattedTimezone = ko.computed(function () {
    if (!self.timezone()) return "";

    if (self.timezone() == "Etc/GMT-9") {
      return "(GMT-09:00) Alaska";
    } else if (self.timezone() == "Etc/GMT-8") {
      return "(GMT-08:00) Pacific Time (US & Canada)";
    } else if (self.timezone() == "Etc/GMT-7") {
      return "(GMT-07:00) Mountain Time (US & Canada)";
    } else if (self.timezone() == "Etc/GMT-6") {
      return "(GMT-06:00) Central Time (US & Canada)";
    } else if (self.timezone() == "Etc/GMT-5") {
      return "(GMT-05:00) Eastern Time (US & Canada)";
    } else if (self.timezone() == "Etc/GMT-4") {
      return "(GMT-04:00) Atlantic Time (Canada)";
    }
    return "";
  });

  self.customCompanyFields = ko.observableArray(
    data.custom_company_fields === undefined ||
      data.custom_company_fields === null
      ? []
      : $.map(data.custom_company_fields, function (value, index) {
          return {
            value: value,
          };
        })
  );

  self.customIndividualFields = ko.observableArray(
    data.custom_individual_fields === undefined ||
      data.custom_individual_fields === null
      ? []
      : $.map(data.custom_individual_fields, function (value, index) {
          return {
            value: value,
          };
        })
  );

  self.customExpoFields = ko.observableArray(
    data.custom_expo_fields === undefined || data.custom_expo_fields === null
      ? []
      : $.map(data.custom_expo_fields, function (value, index) {
          return {
            key: index,
            value: value,
          };
        })
  );

  self.contacts = ko.observableArray([]);

  self.guide_settings = ko.observable(data.guide_settings || {});
  self.notes = ko.observable(data.notes || "");

  self.toJSON = function () {
    var customExpoFields = {};
    self.customExpoFields().forEach(function (item) {
      if (item && item.key) customExpoFields[item.key] = item.value;
    });
    var companyFields = [];
    var individualFields = [];
    self.customCompanyFields().forEach(function (item) {
      if (item && item.value) companyFields.push(item.value);
    });
    self.customIndividualFields().forEach(function (item) {
      if (item && item.value) individualFields.push(item.value);
    });

    return {
      start_date: self.start_date().isValid()
        ? self.start_date().format("MM/DD/YYYY")
        : null,
      end_date: self.end_date().isValid()
        ? self.end_date().format("MM/DD/YYYY")
        : null,
      expo_name: self.name(),
      description: self.description(),
      expo_city: self.city(),
      timezone: self.timezone(),
      expo_venue: self.venue(),
      website_url: self.website_url(),
      floorplan_url: self.floorplan_url(),
      agenda_url: self.agenda_url(),
      transportation_url: self.transportation_url(),
      expo_logo_url: self.expo_logo_url(),
      expo_default_guide_image_url: self.expo_default_guide_image_url(),
      expo_images: self.expo_images(),
      organizer: self.organizer(),
      organizer_address1: self.organizer_address1(),
      organizer_address2: self.organizer_address2(),
      organizer_city: self.organizer_city(),
      organizer_state: self.organizer_state(),
      organizer_zip: self.organizer_zip(),
      organizer_phone: self.organizer_phone(),
      organizer_website: self.organizer_website(),
      categories: self.categories(),
      demographics: self.demographics(),
      custom_expo_fields: customExpoFields,
      custom_company_fields: companyFields,
      custom_individual_fields: individualFields,
      notes: self.notes(),
    };
  };
};

Exposync.UserModel = function (data) {
  var self = this;

  self.id = ko.observable(data.id || "");
  self.email = ko.observable(data.email || "");
  self.first_name = ko.observable(data.name_first || "");
  self.last_name = ko.observable(data.name_last || "");
  self.phone = ko.observable(data.phone || "");
  self.roles = ko.observableArray(data.roles || "");
  self.deactivated = ko.observable(data.deactivated || false);
  self.permissions = ko.observableArray([]);

  self.toJSON = function () {
    return {
      name_first: self.first_name(),
      name_last: self.last_name(),
      phone: self.phone(),
      roles: self.roles(),
      deactivated: self.deactivated(),
    };
  };
};

Exposync.ExpoPermissionModel = function (data) {
  self.id = ko.observable(data.id || "");
  self.role = ko.observable(data.role || "");
  self.expoId = ko.observable(data.expo_id || "");
  self.userId = ko.observable(data.user_id || "");
  self.expo = ko.observable();
  self.user = ko.observable();
};

Exposync.EventModel = function (data) {
  var self = this;

  self.id = ko.observable(data.id || "");
  self.name = ko.observable(data.event_name || "");
  self.location = ko.observable(data.event_location || "");
  self.room = ko.observable(data.event_room || "");
  self.track = ko.observable(data.event_track || "");
  self.presenters = ko.observable(data.event_presenters || "");
  self.coordinators = ko.observable(data.event_coordinators || "");
  self.description = ko.observable(data.event_description || "");
  if (data.event_start)
    data.event_start = data.event_start.substr(0, data.event_start.length - 5);
  self.start = ko.observable(data.event_start || "");
  if (data.event_end)
    data.event_end = data.event_end.substr(0, data.event_end.length - 5);
  self.end = ko.observable(data.event_end || "");
  self.event_id = ko.observable(data.event_id || "");
  self.expo_id = ko.observable(data.expo_id || "");

  self.registered = ko.observableArray([]);
  self.attended = ko.observableArray([]);
  self.event_logo_url = ko.observable(data.event_logo_url || "");

  self.registration_enabled = ko.observable(
    data.registration_enabled !== false
  );
  self.event_email = ko.observable(data.event_email || "");
  self.event_platform = ko.observable(data.event_platform || "");
  self.event_view_link = ko.observable(data.event_view_link || "");
  self.event_purchase_link = ko.observable(data.event_purchase_link || "");
  self.event_suppressed = ko.observable(data.event_suppressed || false);
  self.event_sponsors = ko.observable(data.event_sponsors || "");
  self.sponsoring_exhibitor_ids = ko.observableArray(
    data.sponsoring_exhibitor_ids || []
  );
  self.event_documents = ko.observable(data.event_documents || "");

  self.toJSON = function () {
    var event_sponsors = [];
    if (typeof self.event_sponsors() === "string") {
      event_sponsors = self.event_sponsors().split(",");
      for (var i = 0; i < event_sponsors.length; i++) {
        event_sponsors[i] = event_sponsors[i].trim();
      }
    } else event_sponsors = self.event_sponsors();

    return {
      event_name: self.name(),
      event_location: self.location(),
      event_room: self.room(),
      event_track: self.track(),
      event_presenters: self.presenters(),
      event_coordinators: self.coordinators(),
      event_description: self.description(),
      event_start:
        self.start() && self.start().isValid()
          ? self.start().format("MM/DD/YYYY h:mm a")
          : null,
      event_end:
        self.end() && self.end().isValid()
          ? self.end().format("MM/DD/YYYY h:mm a")
          : null,
      event_id: self.event_id(),
      registration_enabled: self.registration_enabled(),
      event_email: self.event_email(),
      event_platform: self.event_platform(),
      event_view_link: self.event_view_link(),
      event_purchase_link: self.event_purchase_link(),
      event_suppressed: self.event_suppressed(),
      event_sponsors,
      sponsoring_exhibitor_ids: self.sponsoring_exhibitor_ids(),
    };
  };
};

Exposync.CompanyModel = function (data) {
  var self = this;

  self.id = ko.observable(data.id || "");
  self.name = ko.observable(data.exhibitor_name || "");
  self.website_url = ko.observable(data.exhibitor_website || "");
  self.description = ko.observable(data.exhibitor_description || "");
  self.nexus_status = ko.observable(data.nexus_status || "");
  self.client_type = ko.observable(data.client_type || "");
  self.company_marketers = ko.observableArray(data.company_marketers || []);
  self.company_marketers_ids = ko.observableArray(
    (data.company_marketers || []).map((cm) => cm.user.id)
  );
  self.categories = ko.observableArray(data.categories || []);
  self.demographics = ko.observableArray(data.demographics || []);
  self.primary_categories = ko.observableArray(data.primary_categories || []);
  self.exhibitor_id = ko.observable(data.exhibitor_id || "");
  self.guide_name_first = ko.observable(data.guide_name_first || "");
  self.guide_name_last = ko.observable(data.guide_name_last || "");
  self.guide_contact_title = ko.observable(data.guide_contact_title || "");
  self.guide_email = ko.observable(data.guide_email || "");
  self.guide_phone = ko.observable(data.guide_phone || "");
  self.guide_fax = ko.observable(data.guide_fax || "");
  self.booth_location = ko.observable(data.booth_location || "");
  self.booth_number = ko.observable(data.booth_number || "");
  self.expo_id = ko.observable(data.expo_id || "");
  self.exhibitor_id = ko.observable(data.exhibitor_id || "");
  self.contacts = ko.observableArray([]);

  self.exh_address_street1 = ko.observable(data.exh_address_street1 || "");
  self.exh_address_street2 = ko.observable(data.exh_address_street2 || "");
  self.exh_address_street3 = ko.observable(data.exh_address_street3 || "");
  self.exh_address_city = ko.observable(data.exh_address_city || "");
  self.exh_address_state = ko.observable(data.exh_address_state || "");
  self.exh_address_zip = ko.observable(data.exh_address_zip || "");
  self.exh_address_country = ko.observable(data.exh_address_country || "");
  self.suppressed = ko.observable(data.suppressed !== false);

  self.facebook_url = ko.observable(data.facebook_url || "");
  self.twitter_url = ko.observable(data.twitter_url || "");
  self.linkedin_url = ko.observable(data.linkedin_url || "");
  self.instagram_url = ko.observable(data.instagram_url || "");
  self.pinterest_url = ko.observable(data.pinterest_url || "");
  self.snapchat_url = ko.observable(data.snapchat_url || "");
  self.youtube_url = ko.observable(data.youtube_url || "");

  self.company_logo = ko.observable(data.company_logo || "");

  self.address = ko.computed(function () {
    var address = self.exh_address_street1();
    if (self.exh_address_street2())
      address = address + ", " + self.exh_address_street2();
    if (self.exh_address_city())
      address = address + "\n" + self.exh_address_city();
    if (self.exh_address_state())
      address = address + " " + self.exh_address_state();
    if (self.exh_address_zip())
      address = address + ", " + self.exh_address_zip();
  });

  self.companyMarketers = ko.computed(function () {
    if (!data.company_marketers || data.company_marketers.length === 0)
      return "None";
    return data.company_marketers
      .map((cm) => `${cm.user.name_first} ${cm.user.name_last}`)
      .join(", ");
  });

  self.photos = ko.observableArray(
    data.photos === undefined
      ? []
      : $.map(data.photos, function (p) {
          var array = p.split("/");
          var data = {};
          data.link = p;
          data.name = array[array.length - 1];
          return new Exposync.DocumentModel(data);
        })
  );

  self.documents = ko.observableArray(
    data.documents === undefined
      ? []
      : $.map(data.documents, function (p) {
          var array = p.split("/");
          var data = {};
          data.link = p;
          data.name = array[array.length - 1];
          return new Exposync.DocumentModel(data);
        })
  );

  self.customFields = ko.observableArray(
    data.custom_fields === undefined || data.custom_fields === null
      ? []
      : $.map(data.custom_fields, function (value, index) {
          return {
            key: index,
            value: value,
          };
        })
  );

  self.logo = ko.computed(function () {
    var photos = self.photos();
    var exhibitor_id = self.exhibitor_id();

    if (!exhibitor_id) return "";
    var searchPattern = new RegExp("^" + exhibitor_id, "i");
    var image = ko.utils.arrayFirst(photos, function (item) {
      return searchPattern.test(item.link());
    });
    if (image) return image.link();

    return "";
  });

  self.expo_history = ko.observable(data.expo_history || "");
  self.list_member = ko.observable(data.list_member || "");
  self.expo_status = ko.observableArray(data.expo_status || []);
  self.company_domains = ko.observable(data.company_domains || []);
  self.company_type = ko.observable(data.company_type || "");

  self.toJSON = function () {
    var customFields = {};
    self.customFields().forEach(function (item) {
      customFields[item.key] = item.value;
    });

    var expo_history = [];
    if (typeof self.expo_history() === "string") {
      expo_history = self.expo_history().split(",");
      for (var i = 0; i < expo_history.length; i++) {
        expo_history[i] = expo_history[i].trim();
      }
    } else expo_history = self.expo_history();

    var list_member = [];
    if (typeof self.list_member() === "string") {
      list_member = self.list_member().split(",");
      for (var i = 0; i < list_member.length; i++) {
        list_member[i] = list_member[i].trim();
      }
    } else list_member = self.list_member();

    var expo_status = [];
    if (typeof self.expo_status() === "string") {
      expo_status = self.expo_status().split(",");
      for (var i = 0; i < expo_status.length; i++) {
        expo_status[i] = expo_status[i].trim();
      }
    } else expo_status = self.expo_status();

    var company_domains = [];
    if (typeof self.company_domains() === "string") {
      company_domains = self.company_domains().split(",");
      for (var i = 0; i < company_domains.length; i++) {
        company_domains[i] = company_domains[i].trim();
      }
    } else company_domains = self.company_domains();

    return {
      exhibitor_name: self.name(),
      exhibitor_website: self.website_url(),
      exhibitor_description: self.description(),
      nexus_status: self.nexus_status(),
      client_type: self.client_type(),
      assigned_marketer_id: null,
      company_marketers: self.company_marketers_ids(),
      custom_fields: customFields,
      photos: $.map(self.photos(), function (p) {
        return p.link();
      }),
      documents: $.map(self.documents(), function (d) {
        return d.link();
      }),
      exhibitor_id: self.exhibitor_id(),
      guide_name_first: self.guide_name_first(),
      guide_name_last: self.guide_name_last(),
      guide_contact_title: self.guide_contact_title(),
      guide_email: self.guide_email(),
      guide_phone: self.guide_phone(),
      guide_fax: self.guide_fax(),
      booth_location: self.booth_location(),
      booth_number: self.booth_number(),
      exh_address_street1: self.exh_address_street1(),
      exh_address_street2: self.exh_address_street2(),
      exh_address_street3: self.exh_address_street3(),
      exh_address_city: self.exh_address_city(),
      exh_address_state: self.exh_address_state(),
      exh_address_zip: self.exh_address_zip(),
      exh_address_country: self.exh_address_country(),
      suppressed: self.suppressed(),
      facebook_url: self.facebook_url(),
      twitter_url: self.twitter_url(),
      linkedin_url: self.linkedin_url(),
      instagram_url: self.instagram_url(),
      pinterest_url: self.pinterest_url(),
      snapchat_url: self.snapchat_url(),
      youtube_url: self.youtube_url(),
      expo_history: expo_history.filter((x) => x),
      list_member: list_member.filter((x) => x),
      expo_status: expo_status.filter((x) => x),
      company_domains: company_domains.filter((x) => x),
      company_type: self.company_type(),
      primary_categories: self.primary_categories(),
    };
  };
};

Exposync.DocumentModel = function (data) {
  var self = this;
  self.name = ko.observable(data.name || "");
  self.link = ko.observable(data.link || "");
};

Exposync.MessageTemplateModel = function (data) {
  var self = this;

  self.id = ko.observable(data.id || "");
  self.name = ko.observable(data.name || "");
  self.subject = ko.observable(data.subject || "");
  self.type = ko.observable(data.type || "");
  self.text = ko.observable(data.text || "");
  self.html = ko.observable(data.html || "");
};

Exposync.IndividualModel = function (data) {
  var self = this;

  self.id = ko.observable(data.id || "");
  self.prefix = ko.observable(data.name_prefix || "");
  self.first_name = ko.observable(data.name_first || "");
  self.middle_name = ko.observable(data.name_middle || "");
  self.last_name = ko.observable(data.name_last || "");
  self.suffix = ko.observable(data.name_suffix || "");
  self.salutation = ko.observable(data.name_salutation || "");
  self.roles = ko.observableArray(data.expo_roles || []);
  self.keywords = ko.observableArray(data.keywords || []);
  self.categories = ko.observableArray(data.categories || []);
  self.demographics = ko.observableArray(data.demographics || []);

  self.address2_street1 = ko.observable(data.address2_street1 || "");
  self.address2_street2 = ko.observable(data.address2_street2 || "");
  self.address2_street3 = ko.observable(data.address2_street3 || "");
  self.address2_city = ko.observable(data.address2_city || "");
  self.address2_state = ko.observable(data.address2_state || "");
  self.address2_zip = ko.observable(data.address2_zip || "");
  self.address2_country = ko.observable(data.address2_country || "");

  self.phone_first = ko.observable(data.phone_first || "");
  self.phone_second = ko.observable(data.phone_second || "");
  self.phone_cell1 = ko.observable(data.phone_cell1 || "");
  self.phone_cell2 = ko.observable(data.phone_cell2 || "");
  self.phone_other = ko.observable(data.phone_other || "");
  self.phone_fax = ko.observable(data.phone_fax || "");
  self.email_first = ko.observable(data.email_first || "");
  self.email_second = ko.observable(data.email_second || "");

  self.facebook_url = ko.observable(data.facebook_url || "");
  self.twitter_url = ko.observable(data.twitter_url || "");
  self.linkedin_url = ko.observable(data.linkedin_url || "");
  self.instagram_url = ko.observable(data.instagram_url || "");
  self.pinterest_url = ko.observable(data.pinterest_url || "");
  self.snapchat_url = ko.observable(data.snapchat_url || "");
  self.youtube_url = ko.observable(data.youtube_url || "");
  self.description = ko.observable(data.description || "");

  // self.exhibitor_id = ko.observable(data.exhibitor_id || '');
  // self.exhibitor_roles = ko.observableArray(data.exhibitor_roles || []);
  self.business_name = ko.observable(data.business_name || "");
  self.business_name2 = ko.observable(data.business_name2 || "");
  self.business_title = ko.observable(data.business_title || "");
  self.business_role = ko.observable(data.business_role || "");
  self.business_website = ko.observable(data.business_website || "");

  self.address1_street1 = ko.observable(data.address1_street1 || "");
  self.address1_street2 = ko.observable(data.address1_street2 || "");
  self.address1_street3 = ko.observable(data.address1_street3 || "");
  self.address1_city = ko.observable(data.address1_city || "");
  self.address1_state = ko.observable(data.address1_state || "");
  self.address1_zip = ko.observable(data.address1_zip || "");
  self.address1_country = ko.observable(data.address1_country || "");

  self.registration_ids = ko.observable(data.registration_ids || "");
  self.failed_emails = ko.observable(data.failed_emails || "");
  self.expo_history = ko.observable(data.expo_history || "");
  self.list_member = ko.observable(data.list_member || "");
  // self.company_id = ko.observable(data.company_id || '');
  // self.company_type = ko.observable(data.company_type || '');

  self.housing_id = ko.observable(data.housing_id || "");
  self.hotel_name = ko.observable(data.hotel_name || "");
  self.housing_block = ko.observable(data.housing_block || "");
  if (data.hotel_check_in)
    data.hotel_check_in = data.hotel_check_in.substr(
      0,
      data.hotel_check_in.length - 5
    );
  self.hotel_check_in = ko.observable(data.hotel_check_in || "");

  if (data.hotel_check_out)
    data.hotel_check_out = data.hotel_check_out.substr(
      0,
      data.hotel_check_out.length - 5
    );
  self.hotel_check_out = ko.observable(data.hotel_check_out || "");

  self.hotel_rate = ko.observable(data.hotel_rate || 0);
  self.hotel_num_nights = ko.observable(data.hotel_num_nights || 0);
  self.expo_id = ko.observable(data.expo_id || "");
  self.email_unsubscribed = ko.observable(data.email_unsubscribed || false);
  self.exhibitor_emails = ko.observable(data.exhibitor_emails);
  self.guide_created = ko.observable(data.guide_created || false);
  self.email_status = ko.observable(data.email_status || "");
  self.email_domain = ko.observable(data.email_domain || "");

  self.customFields = ko.observableArray(
    data.custom_fields === undefined || data.custom_fields === null
      ? []
      : typeof data.custom_fields === "object"
      ? $.map(data.custom_fields, function (value, index) {
          return {
            key: index,
            value: value,
          };
        })
      : []
  );

  self.events_registered = ko.observableArray(data.events_registered || []);
  self.is_expo_contact = ko.observable(data.is_expo_contact || false);

  self.exhibitors_clicked = ko.observableArray(data.exhibitors_clicked || []);
  self.exhibitors_interested = ko.observableArray(
    data.exhibitors_interested || []
  );
  self.exhibitors_maybe = ko.observableArray(data.exhibitors_maybe || []);
  self.exhibitors_notinterested = ko.observableArray(
    data.exhibitors_notinterested || []
  );

  self.eu_contact = ko.observable(data.eu_contact || false);
  self.gdpr_last_update = ko.observable(data.gdpr_last_update || false);
  self.canada_contact = ko.observable(data.canada_contact || false);
  self.privacy_consent = ko.observable(data.privacy_consent || false);

  self.fullName = ko.computed(function () {
    var name = "";
    if (self.prefix()) name = self.prefix() + " ";

    if (self.first_name()) name = name + self.first_name() + " ";

    if (self.middle_name()) name = name + self.middle_name() + " ";

    if (self.last_name()) name = name + self.last_name();

    if (self.suffix()) name = name + ", " + self.suffix();

    return name;
  });

  self.toJSON = function () {
    var customFields = {};
    self.customFields().forEach(function (item) {
      customFields[item.key] = item.value;
    });

    var keywords = [];
    if (typeof self.keywords() === "string") {
      keywords = self.keywords().split(",");
      for (var i = 0; i < keywords.length; i++) {
        keywords[i] = keywords[i].trim();
      }
    } else keywords = self.keywords();

    var registration_ids = [];
    if (typeof self.registration_ids() === "string") {
      registration_ids = self.registration_ids().split(",");
      for (var i = 0; i < registration_ids.length; i++) {
        registration_ids[i] = registration_ids[i].trim();
      }
    } else registration_ids = self.registration_ids();

    var failed_emails = [];
    if (typeof self.failed_emails() === "string") {
      failed_emails = self.failed_emails().split(",");
      failed_emails = failed_emails.map((fe) => fe.trim());
    } else failed_emails = self.failed_emails();

    var expo_history = [];
    if (typeof self.expo_history() === "string") {
      expo_history = self.expo_history().split(",");
      for (var i = 0; i < expo_history.length; i++) {
        expo_history[i] = expo_history[i].trim();
      }
    } else expo_history = self.expo_history();

    var list_member = [];
    if (typeof self.list_member() === "string") {
      list_member = self.list_member().split(",");
      for (var i = 0; i < list_member.length; i++) {
        list_member[i] = list_member[i].trim();
      }
    } else list_member = self.list_member();

    return {
      name_prefix: self.prefix(),
      name_first: self.first_name(),
      name_last: self.last_name(),
      name_middle: self.middle_name(),
      name_suffix: self.suffix(),
      name_salutation: self.salutation(),
      expo_roles: self.roles(),
      custom_fields: customFields,
      keywords: keywords,
      email_unsubscribed: self.email_unsubscribed(),
      exhibitor_emails: self.exhibitor_emails(),
      guide_created: self.guide_created(),

      address2_street1: self.address2_street1(),
      address2_street2: self.address2_street2(),
      address2_street3: self.address2_street3(),
      address2_city: self.address2_city(),
      address2_state: self.address2_state() || "",
      address2_zip: self.address2_zip(),
      address2_country: self.address2_country() || "",

      phone_first: self.phone_first(),
      phone_second: self.phone_second(),
      phone_cell1: self.phone_cell1(),
      phone_cell2: self.phone_cell2(),
      phone_other: self.phone_other(),
      phone_fax: self.phone_fax(),
      email_first: self.email_first(),
      email_second: self.email_second(),
      email_status: self.email_status() || "",
      email_domain: self.email_domain(),

      facebook_url: self.facebook_url(),
      twitter_url: self.twitter_url(),
      linkedin_url: self.linkedin_url(),
      instagram_url: self.instagram_url(),
      pinterest_url: self.pinterest_url(),
      snapchat_url: self.snapchat_url(),
      youtube_url: self.youtube_url(),
      description: self.description(),

      business_name: self.business_name(),
      business_name2: self.business_name2(),
      business_title: self.business_title(),
      business_role: self.business_role(),
      business_website: self.business_website(),

      address1_street1: self.address1_street1(),
      address1_street2: self.address1_street2(),
      address1_street3: self.address1_street3(),
      address1_city: self.address1_city(),
      address1_state: self.address1_state() || "",
      address1_zip: self.address1_zip(),
      address1_country: self.address1_country() || "",

      registration_ids: registration_ids.filter((x) => x),
      failed_emails: failed_emails.filter((x) => x),
      expo_history: expo_history.filter((x) => x),
      list_member: list_member.filter((x) => x),
      housing_id: self.housing_id(),
      hotel_name: self.hotel_name(),
      housing_block: self.housing_block(),
      hotel_check_in: !self.hotel_check_in()
        ? null
        : self.hotel_check_in().isValid()
        ? self.hotel_check_in().format("MM/DD/YYYY")
        : null,
      hotel_check_out: !self.hotel_check_out()
        ? null
        : self.hotel_check_out().isValid()
        ? self.hotel_check_out().format("MM/DD/YYYY")
        : null,
      hotel_rate: self.hotel_rate(),
      hotel_num_nights: self.hotel_num_nights(),

      // exhibitor_id: self.exhibitor_id(),
      // exhibitor_roles: self.exhibitor_roles(),
      is_expo_contact: self.is_expo_contact(),
      eu_contact: self.eu_contact(),
      canada_contact: self.canada_contact(),
      privacy_consent: self.privacy_consent(),
    };
  };
};

Exposync.CompanyHistoryModel = function (data) {
  var self = this;

  self.hid = ko.observable(data.hid || "");
  self.id = ko.observable(data.id || "");
  self.name = ko.observable(data.exhibitor_name || "");
  self.website_url = ko.observable(data.exhibitor_website || "");
  self.description = ko.observable(data.exhibitor_description || "");
  self.nexus_status = ko.observable(data.nexus_status || "");
  self.client_type = ko.observable(data.client_type || "");
  self.company_marketers = ko.observable(data.company_marketers || []);
  self.company_marketers_ids = (data.company_marketers || []).map(
    (cm) => cm.user.id
  );
  self.categories = ko.observableArray(data.categories || []);
  self.demographics = ko.observableArray(data.demographics || []);
  self.exhibitor_id = ko.observable(data.exhibitor_id || "");
  self.guide_name_first = ko.observable(data.guide_name_first || "");
  self.guide_name_last = ko.observable(data.guide_name_last || "");
  self.guide_contact_title = ko.observable(data.guide_contact_title || "");
  self.guide_email = ko.observable(data.guide_email || "");
  self.guide_phone = ko.observable(data.guide_phone || "");
  self.guide_fax = ko.observable(data.guide_fax || "");
  self.booth_location = ko.observable(data.booth_location || "");
  self.booth_number = ko.observable(data.booth_number || "");
  self.expo_id = ko.observable(data.expo_id || "");
  self.exhibitor_id = ko.observable(data.exhibitor_id || "");
  self.contacts = ko.observableArray([]);
  self.created_at = ko.observable(data.created_at || "");
  self.updated_at = ko.observable(data.updated_at || "");
  self.archived_at = ko.observable(data.archived_at || "");

  self.exh_address_street1 = ko.observable(data.exh_address_street1 || "");
  self.exh_address_street2 = ko.observable(data.exh_address_street2 || "");
  self.exh_address_street3 = ko.observable(data.exh_address_street3 || "");
  self.exh_address_city = ko.observable(data.exh_address_city || "");
  self.exh_address_state = ko.observable(data.exh_address_state || "");
  self.exh_address_zip = ko.observable(data.exh_address_zip || "");
  self.exh_address_country = ko.observable(data.exh_address_country || "");
  self.suppressed = ko.observable(data.suppressed || false);

  self.photos = ko.observableArray(
    data.photos === undefined
      ? []
      : $.map(data.photos, function (p) {
          var array = p.split("/");
          var data = {};
          data.link = p;
          data.name = array[array.length - 1];
          return new Exposync.DocumentModel(data);
        })
  );

  self.documents = ko.observableArray(
    data.documents === undefined
      ? []
      : $.map(data.documents, function (p) {
          var array = p.split("/");
          var data = {};
          data.link = p;
          data.name = array[array.length - 1];
          return new Exposync.DocumentModel(data);
        })
  );

  self.customFields = ko.observableArray(
    data.custom_fields === undefined || data.custom_fields === null
      ? []
      : $.map(data.custom_fields, function (value, index) {
          return {
            key: index,
            value: value,
          };
        })
  );

  self.logo = ko.computed(function () {
    var photos = self.photos();
    var exhibitor_id = self.exhibitor_id();

    if (!exhibitor_id) return "";
    var searchPattern = new RegExp("^" + exhibitor_id, "i");
    var image = ko.utils.arrayFirst(photos, function (item) {
      return searchPattern.test(item.link());
    });
    if (image) return image.link();

    return "";
  });
};

Exposync.WebhookDataModel = function (data) {
  var self = this;

  self.id = ko.observable(data.id || "");
  self.expo_id = ko.observable(data.expo_id || "");
  self.name = ko.observable(data.name || "");
  self.payload_url = ko.observable(data.payload_url || "");
  self.payload_method = ko.observable(data.payload_method || "GET");
  self.content_type = ko.observable(data.content_type || "application/json");
  self.datatype = ko.observableArray(data.datatype || []);
  self.is_active = ko.observable(data.is_active || true);

  self.toJSON = function () {
    return {
      name: self.name(),
      payload_url: self.payload_url(),
      payload_method: self.payload_method(),
      content_type: self.content_type(),
      datatype: self.datatype(),
      is_active: self.is_active(),
      expo_id: self.expo_id(),
    };
  };
};
