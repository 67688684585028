var utils = {
  formatDate: function (value) {
    if (value) {
      return moment.utc(value).format("MM/DD/YYYY");
    }
    return "";
  },
  formatTime: function (value) {
    if (value) {
      return moment(value).format("h:mm a");
    }
    return "";
  },
  formatDateTime: function (value, tz) {
    if (value) {
      if (tz) return moment.tz(value, tz).format("MM/DD/YYYY h:mm a");
      return moment(value).format("MM/DD/YYYY h:mm a");
    }
    return "";
  },
  longFormatDateTime: function (value, tz) {
    if (value) {
      if (tz) return moment.tz(value, tz).format("LLLL");
      return moment(value).format("LLLL");
    }
    return "";
  },
  formatUnixDateTime: function (value, tz) {
    var timestamp = value,
      date = new Date(timestamp);
    if (date) {
      if (tz) return moment.unix(value).tz(tz).format("MM/DD/YYYY h:mm a");
      return moment.unix(value).format("MM/DD/YYYY h:mm a");
    }
    return "";
  },
  getYear: function (dateValue) {
    if (dateValue) {
      return dateValue.split("-")[0];
    }
    return "";
  },

  toTitleCase: function (str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },

  dataTableOptions: {
    info: true,
    paging: true,
    lengthChange: false,
    pageLength: 50,
    bAutoWidth: false,
    searchDelay: 1000,
  },

  defaultQueryOptions: {
    q: "",
    p: 0,
    ps: 50,
  },

  showError: function (message) {
    toastr.options = {
      closeButton: true,
      progressBar: true,
      timeOut: "10000",
      extendedTimeOut: "1000",
    };
    toastr.error(message, "Nexus");
  },
  showSuccess: function (message) {
    toastr.options = {
      closeButton: true,
      progressBar: true,
      timeOut: "5000",
      extendedTimeOut: "1000",
    };
    toastr.success(message, "Nexus");
  },
  showAlert: function (type, element) {},
  showLoader: function (selector) {
    $(selector).css("min-height", "100px");
    $(selector).addClass("csspinner load1");
  },
  hideLoader: function (selector) {
    $(selector).removeClass("csspinner");
    $(selector).css("min-height", "");
  },
  handleAPIError: function (req, status, err) {
    switch (req.status) {
      case 500:
        utils.showError(
          "Unexpected error has occured. We have notified our technical team. We apologize for the inconvenience."
        );
        break;
      case 401:
        authenticator.logout();
        break;
      default:
        utils.showError(req.responseText);
    }
  },
  getParameterByName: function (name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  },

  getUSStates: function () {
    return [
      {
        name: "Alabama",
        abbreviation: "AL",
      },
      {
        name: "Alaska",
        abbreviation: "AK",
      },
      {
        name: "American Samoa",
        abbreviation: "AS",
      },
      {
        name: "Arizona",
        abbreviation: "AZ",
      },
      {
        name: "Arkansas",
        abbreviation: "AR",
      },
      {
        name: "California",
        abbreviation: "CA",
      },
      {
        name: "Colorado",
        abbreviation: "CO",
      },
      {
        name: "Connecticut",
        abbreviation: "CT",
      },
      {
        name: "Delaware",
        abbreviation: "DE",
      },
      {
        name: "District Of Columbia",
        abbreviation: "DC",
      },
      {
        name: "Federated States Of Micronesia",
        abbreviation: "FM",
      },
      {
        name: "Florida",
        abbreviation: "FL",
      },
      {
        name: "Georgia",
        abbreviation: "GA",
      },
      {
        name: "Guam",
        abbreviation: "GU",
      },
      {
        name: "Hawaii",
        abbreviation: "HI",
      },
      {
        name: "Idaho",
        abbreviation: "ID",
      },
      {
        name: "Illinois",
        abbreviation: "IL",
      },
      {
        name: "Indiana",
        abbreviation: "IN",
      },
      {
        name: "Iowa",
        abbreviation: "IA",
      },
      {
        name: "Kansas",
        abbreviation: "KS",
      },
      {
        name: "Kentucky",
        abbreviation: "KY",
      },
      {
        name: "Louisiana",
        abbreviation: "LA",
      },
      {
        name: "Maine",
        abbreviation: "ME",
      },
      {
        name: "Marshall Islands",
        abbreviation: "MH",
      },
      {
        name: "Maryland",
        abbreviation: "MD",
      },
      {
        name: "Massachusetts",
        abbreviation: "MA",
      },
      {
        name: "Michigan",
        abbreviation: "MI",
      },
      {
        name: "Minnesota",
        abbreviation: "MN",
      },
      {
        name: "Mississippi",
        abbreviation: "MS",
      },
      {
        name: "Missouri",
        abbreviation: "MO",
      },
      {
        name: "Montana",
        abbreviation: "MT",
      },
      {
        name: "Nebraska",
        abbreviation: "NE",
      },
      {
        name: "Nevada",
        abbreviation: "NV",
      },
      {
        name: "New Hampshire",
        abbreviation: "NH",
      },
      {
        name: "New Jersey",
        abbreviation: "NJ",
      },
      {
        name: "New Mexico",
        abbreviation: "NM",
      },
      {
        name: "New York",
        abbreviation: "NY",
      },
      {
        name: "North Carolina",
        abbreviation: "NC",
      },
      {
        name: "North Dakota",
        abbreviation: "ND",
      },
      {
        name: "Northern Mariana Islands",
        abbreviation: "MP",
      },
      {
        name: "Ohio",
        abbreviation: "OH",
      },
      {
        name: "Oklahoma",
        abbreviation: "OK",
      },
      {
        name: "Oregon",
        abbreviation: "OR",
      },
      {
        name: "Palau",
        abbreviation: "PW",
      },
      {
        name: "Pennsylvania",
        abbreviation: "PA",
      },
      {
        name: "Puerto Rico",
        abbreviation: "PR",
      },
      {
        name: "Rhode Island",
        abbreviation: "RI",
      },
      {
        name: "South Carolina",
        abbreviation: "SC",
      },
      {
        name: "South Dakota",
        abbreviation: "SD",
      },
      {
        name: "Tennessee",
        abbreviation: "TN",
      },
      {
        name: "Texas",
        abbreviation: "TX",
      },
      {
        name: "Utah",
        abbreviation: "UT",
      },
      {
        name: "Vermont",
        abbreviation: "VT",
      },
      {
        name: "Virgin Islands",
        abbreviation: "VI",
      },
      {
        name: "Virginia",
        abbreviation: "VA",
      },
      {
        name: "Washington",
        abbreviation: "WA",
      },
      {
        name: "West Virginia",
        abbreviation: "WV",
      },
      {
        name: "Wisconsin",
        abbreviation: "WI",
      },
      {
        name: "Wyoming",
        abbreviation: "WY",
      },
    ];
  },

  getCountries: function () {
    return [
      "United States",
      "Canada",
      "Afghanistan",
      "Albania",
      "Algeria",
      "Andorra",
      "Angola",
      "Anguilla",
      "Antigua &amp; Barbuda",
      "Argentina",
      "Armenia",
      "Aruba",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bermuda",
      "Bhutan",
      "Bolivia",
      "Bosnia &amp; Herzegovina",
      "Botswana",
      "Brazil",
      "British Virgin Islands",
      "Brunei",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cambodia",
      "Cameroon",
      "Cape Verde",
      "Cayman Islands",
      "Chad",
      "Chile",
      "China",
      "Colombia",
      "Congo",
      "Cook Islands",
      "Costa Rica",
      "Cote D Ivoire",
      "Croatia",
      "Cruise Ship",
      "Cuba",
      "Cyprus",
      "Czech Republic",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Estonia",
      "Ethiopia",
      "Falkland Islands",
      "Faroe Islands",
      "Fiji",
      "Finland",
      "France",
      "French Polynesia",
      "French West Indies",
      "Gabon",
      "Gambia",
      "Georgia",
      "Germany",
      "Ghana",
      "Gibraltar",
      "Greece",
      "Greenland",
      "Grenada",
      "Guam",
      "Guatemala",
      "Guernsey",
      "Guinea",
      "Guinea Bissau",
      "Guyana",
      "Haiti",
      "Honduras",
      "Hong Kong",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran",
      "Iraq",
      "Ireland",
      "Isle of Man",
      "Israel",
      "Italy",
      "Jamaica",
      "Japan",
      "Jersey",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kuwait",
      "Kyrgyz Republic",
      "Laos",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macau",
      "Macedonia",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Mauritania",
      "Mauritius",
      "Mexico",
      "Moldova",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Montserrat",
      "Morocco",
      "Mozambique",
      "Namibia",
      "Nepal",
      "Netherlands",
      "Netherlands Antilles",
      "New Caledonia",
      "New Zealand",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "Norway",
      "Oman",
      "Pakistan",
      "Palestine",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines",
      "Poland",
      "Portugal",
      "Puerto Rico",
      "Qatar",
      "Reunion",
      "Romania",
      "Russia",
      "Rwanda",
      "Saint Pierre &amp; Miquelon",
      "Samoa",
      "San Marino",
      "Satellite",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Slovakia",
      "Slovenia",
      "South Africa",
      "South Korea",
      "Spain",
      "Sri Lanka",
      "St Kitts &amp; Nevis",
      "St Lucia",
      "St Vincent",
      "St. Lucia",
      "Sudan",
      "Suriname",
      "Swaziland",
      "Sweden",
      "Switzerland",
      "Syria",
      "Taiwan",
      "Tajikistan",
      "Tanzania",
      "Thailand",
      "Timor L'Este",
      "Togo",
      "Tonga",
      "Trinidad &amp; Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Turks &amp; Caicos",
      "Uganda",
      "Ukraine",
      "United Arab Emirates",
      "United Kingdom",
      "United States Minor Outlying Islands",
      "Uruguay",
      "Uzbekistan",
      "Venezuela",
      "Vietnam",
      "Virgin Islands (US)",
      "Yemen",
      "Zambia",
      "Zimbabwe",
    ];
  },

  getOperators: function (type) {
    switch (type) {
      case "string":
      case "number":
      default:
        return [
          {
            id: "contains",
            text: "Contains",
            term: true,
            system_operator: "contains",
            not: false,
          },
          {
            id: "does_not_contain",
            text: "Does Not Contain",
            term: true,
            system_operator: "contains",
            not: true,
          },
          {
            id: "equals",
            text: "Equals",
            term: true,
            system_operator: "equals",
            not: false,
          },
          {
            id: "greater_than",
            text: "Greater Than",
            term: true,
            system_operator: "greater_than",
            not: false,
          },
          {
            id: "greater_than_or_equals",
            text: "Greater Than Or Equals",
            term: true,
            system_operator: "less_than",
            not: true,
          },
          {
            id: "less_than",
            text: "Less Than",
            term: true,
            system_operator: "less_than",
            not: false,
          },
          {
            id: "less_than_or_equals",
            text: "Less Than Or Equals",
            term: true,
            system_operator: "greater_than",
            not: true,
          },
          {
            id: "contains_data",
            text: "Contains Data",
            term: false,
            system_operator: "equals",
            not: true,
          },
          {
            id: "does_not_contain_data",
            text: "Does Not Contain Data",
            term: false,
            system_operator: "equals",
            not: false,
          },
          {
            id: "starts_with",
            text: "Starts With",
            term: true,
            system_operator: "starts_with",
            not: false,
          },
          {
            id: "ends_with",
            text: "Ends With",
            term: true,
            system_operator: "ends_with",
            not: false,
          },
        ];
        break;
      case "boolean":
        return [
          {
            id: "boolean_equals",
            text: "Equals",
            term: true,
            system_operator: "equals",
            not: false,
          },
          {
            id: "boolean_does_not_equal",
            text: "Does Not Equal",
            term: true,
            system_operator: "equals",
            not: true,
          },
        ];
      case "array":
        return [
          {
            id: "array_contains",
            text: "Contains",
            term: true,
            system_operator: "includes",
            not: false,
          },
          {
            id: "array_does_not_contain",
            text: "Does Not Contain",
            term: true,
            system_operator: "includes",
            not: true,
          },
          {
            id: "contains_data",
            text: "Contains Data",
            term: false,
            system_operator: "length",
            not: true,
          },
          {
            id: "does_not_contain_data",
            text: "Does Not Contain Data",
            term: false,
            system_operator: "length",
            not: false,
          },
        ];
        break;
      case "date":
        return [
          {
            id: "equals",
            text: "Equals",
            term: true,
            system_operator: "equals",
            not: false,
          },
          {
            id: "greater_than",
            text: "Greater Than",
            term: true,
            system_operator: "greater_than",
            not: false,
          },
          {
            id: "less_than",
            text: "Less Than",
            term: true,
            system_operator: "less_than",
            not: false,
          },
          //{ id: 'between', text: 'Between', term: true, system_operator: 'between', not: false },
        ];
        break;
      case "mongo:string":
        return [
          {
            id: "equals",
            text: "Equals",
            term: true,
            system_operator: "equals",
            not: false,
          },
          {
            id: "not_equals",
            text: "Does not Equal",
            term: true,
            system_operator: "equals",
            not: true,
          },
        ];
        break;
      case "enum":
        return [
          {
            id: "equals",
            text: "Equals",
            term: true,
            system_operator: "equals",
            not: false,
          },
        ];
        break;
      case "activity:action":
        return [
          {
            id: "open_campaign",
            text: "Opened",
            term: false,
            system_operator: "open",
            not: false,
          },
          {
            id: "open_campaign_not",
            text: "Did Not Open",
            term: false,
            system_operator: "open",
            not: true,
          },
          {
            id: "click_campaign",
            text: "Clicked",
            term: true,
            system_operator: "click",
            not: false,
          },
          {
            id: "click_campaign_not",
            text: "Did Not Click",
            term: true,
            system_operator: "click",
            not: true,
          },
        ];
        break;
    }
  },

  getRoleBasedPermissions: function () {
    return [
      {
        module: "expo_detail",
        role: "admin",
        access: true,
      },
      {
        module: "expo_detail",
        role: "organizer_admin",
      },
      {
        module: "expo_detail",
        role: "manager",
        access: true,
      },
      {
        module: "expo_detail",
        role: "synchronizer",
        access: true,
      },
      {
        module: "expo_detail",
        role: "marketer",
        access: true,
      },
      {
        module: "expo_detail",
        role: "marketer-assigned",
        access: true,
      },
      {
        module: "expo_detail",
        role: "accounting",
        access: true,
      },
      {
        module: "expo_detail",
        role: "hr",
        access: true,
      },
      {
        module: "expo_dashboard",
        role: "admin",
        access: true,
      },
      {
        module: "expo_dashboard",
        role: "manager",
        access: true,
      },
      {
        module: "expo_detail_manage",
        role: "admin",
        access: true,
      },
      {
        module: "expo_detail_manage",
        role: "manager",
        access: true,
      },
      {
        module: "expo_detail_organizer",
        role: "admin",
        access: true,
      },
      {
        module: "expo_detail_organizer",
        role: "manager",
        access: true,
      },
      {
        module: "expo_detail_organizer",
        role: "synchronizer",
        access: true,
      },
      {
        module: "expo_detail_organizer",
        role: "marketer",
        access: true,
      },
      {
        module: "expo_detail_organizer",
        role: "marketer-assigned",
        access: true,
      },
      {
        module: "expo_detail_organizer",
        role: "accounting",
        access: true,
      },
      {
        module: "expo_detail_organizer",
        role: "hr",
        access: true,
      },
      {
        module: "expo_detail_organizer_edit",
        role: "admin",
        access: true,
      },
      {
        module: "expo_detail_organizer_edit",
        role: "manager",
        access: true,
      },
      {
        module: "expo_detail_contacts",
        role: "admin",
        access: true,
      },
      {
        module: "expo_detail_contacts",
        role: "manager",
        access: true,
      },
      {
        module: "expo_detail_contacts",
        role: "synchronizer",
        access: true,
      },
      {
        module: "expo_detail_contacts_edit",
        role: "admin",
        access: true,
      },
      {
        module: "expo_detail_contacts_edit",
        role: "manager",
        access: true,
      },
      {
        module: "expo_detail_contacts_edit",
        role: "synchronizer",
        access: true,
      },
      {
        module: "expo_events",
        role: "admin",
        access: true,
      },
      {
        module: "expo_events",
        role: "manager",
        access: true,
      },
      {
        module: "expo_events",
        role: "synchronizer",
        access: true,
      },
      {
        module: "expo_events",
        role: "marketer",
        access: true,
      },
      {
        module: "expo_events",
        role: "marketer-assigned",
        access: true,
      },
      {
        module: "expo_events",
        role: "accounting",
        access: true,
      },
      {
        module: "expo_events",
        role: "hr",
        access: true,
      },
      {
        module: "expo_events_manage",
        role: "admin",
        access: true,
      },
      {
        module: "expo_events_manage",
        role: "manager",
        access: true,
      },
      {
        module: "expo_events_detail",
        role: "admin",
        access: true,
      },
      {
        module: "expo_events_detail",
        role: "manager",
        access: true,
      },
      {
        module: "expo_events_detail",
        role: "synchronizer",
        access: true,
      },
      {
        module: "expo_events_detail",
        role: "marketer",
        access: true,
      },
      {
        module: "expo_events_detail",
        role: "marketer-assigned",
        access: true,
      },
      {
        module: "expo_events_view_registered",
        role: "admin",
        access: true,
      },
      {
        module: "expo_events_view_registered",
        role: "manager",
        access: true,
      },
      {
        module: "expo_events_view_registered",
        role: "synchronizer",
        access: true,
      },
      {
        module: "expo_events_view_registered",
        role: "marketer",
        access: true,
      },
      {
        module: "expo_events_view_registered",
        role: "marketer-assigned",
        access: true,
      },
      {
        module: "expo_exhibitors",
        role: "admin",
        access: true,
      },
      {
        module: "expo_exhibitors",
        role: "manager",
        access: true,
      },
      {
        module: "expo_exhibitors",
        role: "synchronizer",
        access: true,
      },
      {
        module: "expo_exhibitors",
        role: "marketer",
        access: true,
      },
      {
        module: "expo_exhibitors",
        role: "marketer-assigned",
        access: true,
      },
      {
        module: "expo_exhibitors",
        role: "accounting",
        access: true,
      },
      {
        module: "expo_exhibitors",
        role: "hr",
        access: true,
      },
      {
        module: "expo_exhibitors_manage",
        role: "admin",
        access: true,
      },
      {
        module: "expo_exhibitors_manage",
        role: "manager",
        access: true,
      },
      {
        module: "expo_exhibitors_detail",
        role: "admin",
        access: true,
      },
      {
        module: "expo_exhibitors_detail",
        role: "manager",
        access: true,
      },
      {
        module: "expo_exhibitors_detail",
        role: "synchronizer",
        access: true,
      },
      {
        module: "expo_exhibitors_detail",
        role: "marketer",
        access: true,
      },
      {
        module: "expo_exhibitors_detail",
        role: "marketer-assigned",
        access: true,
      },
      {
        module: "expo_exhibitors_detail_manage",
        role: "admin",
        access: true,
      },
      {
        module: "expo_exhibitors_detail_manage",
        role: "manager",
        access: true,
      },
      {
        module: "expo_exhibitors_detail_manage",
        role: "marketer-assigned",
        access: true,
      },

      {
        module: "expo_individuals",
        role: "admin",
        access: true,
      },
      {
        module: "expo_individuals",
        role: "manager",
        access: true,
      },
      {
        module: "expo_individuals",
        role: "synchronizer",
        access: true,
      },
      {
        module: "expo_individuals",
        role: "marketer",
        access: true,
      },
      {
        module: "expo_individuals",
        role: "marketer-assigned",
        access: true,
      },
      {
        module: "expo_individuals",
        role: "accounting",
        access: true,
      },
      {
        module: "expo_individuals",
        role: "hr",
        access: true,
      },
      {
        module: "expo_individuals_manage",
        role: "admin",
        access: true,
      },
      {
        module: "expo_individuals_manage",
        role: "manager",
        access: true,
      },
      {
        module: "expo_individuals_detail",
        role: "admin",
        access: true,
      },
      {
        module: "expo_individuals_detail",
        role: "manager",
        access: true,
      },
      {
        module: "expo_individuals_detail",
        role: "synchronizer",
        access: true,
      },
      //{ module: 'expo_individuals_detail_limited', role: 'marketer', access: true },
      //{ module: 'expo_individuals_detail_limited', role: 'marketer-assigned', access: true },

      {
        module: "expo_categories",
        role: "admin",
        access: true,
      },
      {
        module: "expo_categories",
        role: "manager",
        access: true,
      },
      {
        module: "expo_categories",
        role: "synchronizer",
        access: true,
      },
      {
        module: "expo_categories",
        role: "marketer",
        access: true,
      },
      {
        module: "expo_categories",
        role: "marketer-assigned",
        access: true,
      },
      {
        module: "expo_categories",
        role: "accounting",
        access: true,
      },
      {
        module: "expo_categories",
        role: "hr",
        access: true,
      },
      {
        module: "expo_categories_manage",
        role: "admin",
        access: true,
      },
      {
        module: "expo_categories_manage",
        role: "manager",
        access: true,
      },

      {
        module: "admin_users_view",
        role: "admin",
        access: true,
      },
      {
        module: "admin_users_view",
        role: "accounting",
        access: true,
      },
      {
        module: "admin_users_view",
        role: "hr",
        access: true,
      },
      {
        module: "admin_users_invite",
        role: "admin",
        access: true,
      },
      {
        module: "admin_users_invite",
        role: "hr",
        access: true,
      },
      {
        module: "admin_users_manage",
        role: "admin",
        access: true,
      },
      {
        module: "admin_users_manage",
        role: "hr",
        access: true,
      },

      {
        module: "admin_expos_view",
        role: "admin",
        access: true,
      },
      {
        module: "admin_expos_view",
        role: "accounting",
        access: true,
      },
      {
        module: "admin_expos_view",
        role: "hr",
        access: true,
      },
      {
        module: "admin_expos_manage",
        role: "admin",
        access: true,
      },
      {
        module: "admin_expos_manage_team",
        role: "admin",
        access: true,
      },
      {
        module: "admin_expos_manage_team",
        role: "hr",
        access: true,
      },

      {
        module: "expo_datasync",
        role: "admin",
        access: true,
      },
      {
        module: "expo_datasync",
        role: "manager",
        access: true,
      },
      {
        module: "expo_datasync",
        role: "synchronizer",
        access: true,
      },

      {
        module: "services_prospects",
        role: "admin",
        access: true,
      },
      {
        module: "services_prospects",
        role: "manager",
        access: true,
      },

      {
        module: "services_clients",
        role: "admin",
        access: true,
      },
      {
        module: "services_clients",
        role: "manager",
        access: true,
      },
      {
        module: "services_clients",
        role: "marketer",
        access: true,
      },

      {
        module: "services_clients_manage",
        role: "manager",
        access: true,
      },
      {
        module: "services_clients_manage",
        role: "admin",
        access: true,
      },
      ///
      {
        module: "services_clients_detail",
        role: "manager",
        access: true,
      },
      {
        module: "services_clients_detail",
        role: "admin",
        access: true,
      },
      {
        module: "services_clients_detail",
        role: "marketer",
        access: true,
      },

      {
        module: "services_templates",
        role: "admin",
        access: true,
      },
      {
        module: "services_templates",
        role: "manager",
        access: true,
      },
      {
        module: "services_templates",
        role: "marketer",
        access: true,
      },

      {
        module: "services_lists",
        role: "admin",
        access: true,
      },
      {
        module: "services_lists",
        role: "manager",
        access: true,
      },
      {
        module: "services_lists_manage",
        role: "admin",
        access: true,
      },
      {
        module: "services_lists_manage",
        role: "manager",
        access: true,
      },
      {
        module: "services_searches",
        role: "admin",
        access: true,
      },
      {
        module: "services_searches",
        role: "manager",
        access: true,
      },
      {
        module: "system_tools_sent_emails",
        role: "admin",
        access: true,
      },
      {
        module: "system_tools_sent_emails",
        role: "manager",
        access: true,
      },
    ];
  },

  getOrganizerPermissions: function () {
    return [
      {
        module: "expo_detail",
        role: "organizer",
        sub_roles: [
          "organizer_admin",
          "organizer_data_entry",
          "organizer_marketer",
        ],
        access: true,
      },
      {
        module: "expo_detail_manage",
        role: "organizer",
        sub_roles: ["organizer_admin"],
        access: false,
      },
      {
        module: "expo_detail_contacts",
        role: "organizer",
        sub_roles: ["organizer_admin"],
        access: true,
      },
      {
        module: "expo_detail_contacts_edit",
        role: "organizer",
        sub_roles: ["organizer_admin"],
        access: true,
      },
      {
        module: "expo_dashboard",
        role: "organizer",
        sub_roles: ["organizer_admin"],
        access: true,
      },
      {
        module: "expo_events",
        role: "organizer",
        sub_roles: [
          "organizer_admin",
          "organizer_data_entry",
          "organizer_marketer",
        ],
        access: true,
      },
      {
        module: "expo_events_manage",
        role: "organizer",
        sub_roles: ["organizer_admin"],
        access: true,
      },
      {
        module: "expo_events_detail",
        role: "organizer",
        sub_roles: ["organizer_admin"],
        access: true,
      },
      {
        module: "expo_events_view_registered",
        role: "organizer",
        sub_roles: ["organizer_admin"],
        access: true,
      },
      {
        module: "expo_exhibitors",
        role: "organizer",
        sub_roles: [
          "organizer_admin",
          "organizer_data_entry",
          "organizer_marketer",
        ],
        access: true,
      },
      {
        module: "expo_exhibitors_manage",
        role: "organizer",
        sub_roles: ["organizer_admin", "organizer_marketer"],
        access: true,
      },
      {
        module: "expo_exhibitors_detail",
        role: "organizer",
        sub_roles: ["organizer_admin", "organizer_marketer"],
        access: true,
      },
      {
        module: "expo_exhibitors_detail_manage",
        role: "organizer",
        sub_roles: ["organizer_admin", "organizer_marketer"],
        access: true,
      },
      {
        module: "expo_individuals",
        role: "organizer",
        sub_roles: [
          "organizer_admin",
          "organizer_data_entry",
          "organizer_marketer",
        ],
        access: true,
      },
      {
        module: "expo_individuals_manage",
        role: "organizer",
        sub_roles: [
          "organizer_admin",
          "organizer_data_entry",
          "organizer_marketer",
        ],
        access: true,
      },
      {
        module: "expo_individuals_detail",
        role: "organizer",
        sub_roles: [
          "organizer_admin",
          "organizer_data_entry",
          "organizer_marketer",
        ],
        access: true,
      },
      {
        module: "expo_categories",
        role: "organizer",
        sub_roles: [
          "organizer_admin",
          "organizer_data_entry",
          "organizer_marketer",
        ],
        access: true,
      },
      {
        module: "expo_categories_manage",
        role: "organizer",
        sub_roles: [
          "organizer_admin",
          "organizer_data_entry",
          "organizer_marketer",
        ],
        access: false,
      },
      {
        module: "services_prospects",
        role: "organizer",
        sub_roles: [
          "organizer_admin",
          "organizer_data_entry",
          "organizer_marketer",
        ],
        access: true,
      },
      {
        module: "services_clients",
        role: "organizer",
        sub_roles: [
          "organizer_admin",
          "organizer_data_entry",
          "organizer_marketer",
        ],
        access: true,
      },
      {
        module: "services_clients_manage",
        role: "organizer",
        sub_roles: [
          "organizer_admin",
          "organizer_data_entry",
          "organizer_marketer",
        ],
        access: true,
      },
      {
        module: "services_clients_detail",
        role: "organizer",
        sub_roles: ["organizer_admin", "organizer_data_entry"],
        access: true,
      },
      {
        module: "services_templates",
        role: "organizer",
        sub_roles: [
          "organizer_admin",
          "organizer_data_entry",
          "organizer_marketer",
        ],
        access: true,
      },
      {
        module: "services_lists",
        role: "organizer",
        sub_roles: [
          "organizer_admin",
          "organizer_data_entry",
          "organizer_marketer",
        ],
        access: true,
      },
      {
        module: "services_lists_manage",
        role: "organizer",
        sub_roles: [
          "organizer_admin",
          "organizer_data_entry",
          "organizer_marketer",
        ],
        access: true,
      },
      {
        module: "services_searches",
        role: "organizer",
        sub_roles: [
          "organizer_admin",
          "organizer_data_entry",
          "organizer_marketer",
        ],
        access: true,
      },
      {
        module: "system_tools_sent_emails",
        role: "organizer",
        sub_roles: ["organizer_admin"],
        access: true,
      },
    ];
  },

  initDataTable: function (el, url, fnSuccess) {
    var columns = [
      {
        title: "Exhibitor Name",
        data: "name",
      },
      {
        title: "Booth #",
        data: "booth_number",
      },
      {
        title: "Categories",
        data: "categories",
      },
    ];

    var table = $(el).DataTable({
      autoWidth: false,
      serverSide: true,
      lengthChange: false,
      pageLength: utils.dataTableOptions.pageLength,
      searchDelay: 1000,
      columns: columns,
      ajax: {
        url: url,
        data: function (d) {
          if (d) {
            d.q = d.search.value;
            d.p =
              (d.start + utils.dataTableOptions.pageLength) /
                utils.dataTableOptions.pageLength -
              1;
            d.ps = utils.dataTableOptions.pageLength;

            if (d.order) {
              if (d.order[0].dir == "asc") {
                d.sort = columns[d.order[0].column].data;
              } else {
                d.sort = "-" + columns[d.order[0].column].data;
              }
            } else {
              d.sort = "";
            }

            delete d.columns;
            delete d.length;
            delete d.order;
            delete d.search;
            delete d.start;
          }
        },
      },
      createdRow: function (row, data, index) {
        $(row).attr("style", "cursor:pointer");
        $(row).attr(
          "data-bind",
          "click: function(){ return viewExhibitor('" +
            data.id +
            "', '" +
            data.expo_id +
            "')} "
        );
      },
      fnDrawCallback: function (oSettings) {
        fnSuccess();
      },
    });
  },
  // formatString(str) {
  //     var formatted = str;
  //     for (var arg in arguments) {
  //         formatted = formatted.replace("{" + arg + "}", arguments[arg]);
  //     }
  //     return formatted;
  // },

  tinyMceConfig: function () {
    return {
      blocksFolder: "/templates/",
      emailBg: "#f5f5f5",
      fonts:
        "8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 19pt 20pt 21pt 22pt 23pt 24pt 36pt 77pt",
      allBlocks: [
        {
          file: "header.html",
          name: "Header",
          icon: "header",
          thumb: "",
        },
        {
          file: "navigation.html",
          name: "Navigation",
          icon: "navigation",
          thumb: "",
        },
        {
          file: "index1.html",
          name: "Text block with button",
          icon: "text-with-button",
          thumb: "",
        },
      ],
    };
  },

  datasync: {
    mapping: {
      exhibitor_contacts: {
        exhibitor_id: {
          mapping_mode: "update_with_behaviors",
          target_field: "exhibitor_id",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        name_first: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_first",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        name_last: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_last",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        email_first: {
          mapping_mode: "update_with_behaviors",
          target_field: "email_first",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
          behavior_elsewhere_field: "email_second",
        },

        email_second: {
          mapping_mode: "update_with_behaviors",
          target_field: "email_second",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        business_name: {
          mapping_mode: "update_with_behaviors",
          target_field: "business_name",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        business_title: {
          mapping_mode: "update_with_behaviors",
          target_field: "business_title",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        exhibitor_roles: {
          mapping_mode: "update",
          target_field: "exhibitor_roles",
        },
        expo_roles: {
          mapping_mode: "update",
          target_field: "expo_roles",
        },
        name_middle: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_middle",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        name_prefix: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_prefix",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        name_suffix: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_suffix",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        name_salutation: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_salutation",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },

        address1_street1: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_street1",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
          behavior_elsewhere_field: "address2_street1",
        },
        address1_street2: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_street2",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
          behbehavior_elsewhere_field: "address2_street2",
        },
        address1_street3: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_street3",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
          behavior_elsewhere_field: "address2_street3",
        },
        address1_city: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_city",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
          behavior_elsewhere_field: "address2_city",
        },
        address1_state: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_state",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
          behavior_elsewhere_field: "address2_state",
        },
        address1_zip: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_zip",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
          behavior_elsewhere_field: "address2_zip",
        },
        address1_country: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_country",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
          behavior_elsewhere_field: "address2_country",
        },
        phone_first: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_first",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
          behavior_elsewhere_field: "phone_second",
        },
        phone_second: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_second",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        phone_fax: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_fax",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        phone_cell1: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_cell1",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
          behavior_elsewhere_field: "phone_cell2",
        },
        phone_cell2: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_cell2",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
          behavior_elsewhere_field: "phone_other",
        },
        phone_other: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_other",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
      },

      client_customer_list: {
        name_first: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_first",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
        },
        name_last: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_last",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
        },
        email_first: {
          mapping_mode: "update_with_behaviors",
          target_field: "email_first",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "move_elsewhere",
          behavior_elsewhere_field: "email_second",
        },

        business_name: {
          mapping_mode: "update_with_behaviors",
          target_field: "business_name",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
        },
        business_title: {
          mapping_mode: "update_with_behaviors",
          target_field: "business_title",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
        },
        address1_city: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_city",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
          behavior_elsewhere_field: "address2_city",
        },
        address1_street1: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_street1",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
          behavior_elsewhere_field: "address2_street1",
        },

        customer_status: {
          mapping_mode: "update_with_behaviors",
          target_field: "customer_status",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        customer_salutation: {
          mapping_mode: "update_with_behaviors",
          target_field: "customer_salutation",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },

        name_middle: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_middle",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
        },
        name_prefix: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_prefix",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
        },
        name_suffix: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_suffix",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
        },
        name_salutation: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_salutation",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
        },

        address1_street2: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_street2",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
          behavior_elsewhere_field: "address2_street2",
        },
        address1_street3: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_street3",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
          behavior_elsewhere_field: "address2_street3",
        },
        address1_state: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_state",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
          behavior_elsewhere_field: "address2_state",
        },
        address1_zip: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_zip",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
          behavior_elsewhere_field: "address2_zip",
        },
        address1_country: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_country",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
          behavior_elsewhere_field: "address2_country",
        },

        phone_first: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_first",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
          behavior_elsewhere_field: "phone_second",
        },
        phone_second: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_second",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
        },
        phone_fax: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_fax",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
        },
        phone_cell1: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_cell1",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
          behavior_elsewhere_field: "phone_cell2",
        },
        phone_cell2: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_cell2",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
          behavior_elsewhere_field: "phone_other",
        },
        phone_other: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_other",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
        },
      },

      registration_data: {
        registration_id: {
          mapping_mode: "array_merge",
          target_field: "registration_ids",
        },
        name_first: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_first",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        name_last: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_last",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        email_first: {
          mapping_mode: "update_with_behaviors",
          target_field: "email_first",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "move_other_elsewhere",
          behavior_elsewhere_field: "email_second",
        },

        business_name: {
          mapping_mode: "update_with_behaviors",
          target_field: "business_name",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        business_title: {
          mapping_mode: "update_with_behaviors",
          target_field: "business_title",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        expo_roles: {
          mapping_mode: "update",
          target_field: "expo_roles",
        },

        address1_city: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_city",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "move_other_elsewhere",
          behavior_elsewhere_field: "address2_city",
        },
        address1_street1: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_street1",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "move_other_elsewhere",
          behavior_elsewhere_field: "address2_street1",
        },

        name_middle: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_middle",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        name_prefix: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_prefix",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        name_suffix: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_suffix",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        name_salutation: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_salutation",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },

        address1_street2: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_street2",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "move_other_elsewhere",
          behavior_elsewhere_field: "address2_street2",
        },
        address1_street3: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_street3",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "move_other_elsewhere",
          behavior_elsewhere_field: "address2_street3",
        },
        address1_state: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_state",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "move_other_elsewhere",
          behavior_elsewhere_field: "address2_state",
        },
        address1_zip: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_zip",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "move_other_elsewhere",
          behavior_elsewhere_field: "address2_zip",
        },
        address1_country: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_country",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "move_other_elsewhere",
          behavior_elsewhere_field: "address2_country",
        },

        phone_first: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_first",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "move_other_elsewhere",
          behavior_elsewhere_field: "phone_second",
        },
        phone_second: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_second",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        phone_fax: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_fax",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },

        phone_cell1: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_cell1",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "move_other_elsewhere",
          behavior_elsewhere_field: "phone_cell2",
        },
        phone_cell2: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_cell2",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "move_other_elsewhere",
          behavior_elsewhere_field: "phone_other",
        },
        phone_other: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_other",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },

        events_registered: {
          mapping_mode: "update",
          target_field: "events_registered",
        },
        categories: {
          mapping_mode: "ignore",
          target_field: "categories",
        },
        keywords: {
          mapping_mode: "ignore",
          target_field: "keywords",
        },
      },

      housing_data: {
        housing_id: {
          mapping_mode: "update_with_behaviors",
          target_field: "housing_id",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
        },
        name_first: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_first",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
        },
        name_last: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_last",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
        },
        email_first: {
          mapping_mode: "update_with_behaviors",
          target_field: "email_first",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
          behavior_elsewhere_field: "email_second",
        },

        business_name: {
          mapping_mode: "update_with_behaviors",
          target_field: "business_name",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
        },
        expo_roles: {
          mapping_mode: "update",
          target_field: "expo_roles",
        },

        address1_street1: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_street1",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
          behavior_elsewhere_field: "address2_street1",
        },
        address1_street2: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_street2",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
          behavior_elsewhere_field: "address2_street2",
        },
        address1_street3: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_street3",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
          behavior_elsewhere_field: "address2_street3",
        },
        address1_city: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_city",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
          behavior_elsewhere_field: "address2_city",
        },
        address1_state: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_state",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
          behavior_elsewhere_field: "address2_state",
        },
        address1_zip: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_zip",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
          behavior_elsewhere_field: "address2_zip",
        },
        address1_country: {
          mapping_mode: "update_with_behaviors",
          target_field: "address1_country",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
          behavior_elsewhere_field: "address2_country",
        },

        name_middle: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_middle",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
        },
        name_prefix: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_prefix",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
        },
        name_suffix: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_suffix",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        name_salutation: {
          mapping_mode: "update_with_behaviors",
          target_field: "name_salutation",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
        },

        phone_first: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_first",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
          behavior_elsewhere_field: "phone_second",
        },
        phone_second: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_second",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
        },
        phone_fax: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_fax",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
        },
        phone_cell1: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_cell1",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
          behavior_elsewhere_field: "phone_cell2",
        },
        phone_cell2: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_cell2",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
          behavior_elsewhere_field: "phone_other",
        },
        phone_other: {
          mapping_mode: "update_with_behaviors",
          target_field: "phone_other",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "ignore",
        },

        hotel_name: {
          mapping_mode: "update_with_behaviors",
          target_field: "hotel_name",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        housing_block: {
          mapping_mode: "update_with_behaviors",
          target_field: "housing_block",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        hotel_check_in: {
          mapping_mode: "update_with_behaviors",
          target_field: "hotel_check_in",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        hotel_check_out: {
          mapping_mode: "update_with_behaviors",
          target_field: "hotel_check_out",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        hotel_rate: {
          mapping_mode: "update_with_behaviors",
          target_field: "hotel_rate",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
        hotel_num_nights: {
          mapping_mode: "update_with_behaviors",
          target_field: "hotel_num_nights",
          behavior_empty_source: "ignore",
          behavior_full_to_empty: "fill",
          behavior_full_to_full: "overwrite",
        },
      },
    },

    matching: {
      exhibitors_data: {
        exhibitor_id: {
          field: "exhibitor_id",
        },
      },
      exhibitor_contacts: {
        name_first: {
          field: "name_first",
          operator: "",
        },
        name_last: {
          field: "name_last",
          operator: "and",
        },
        email_first: {
          field: "email_first",
          operator: "and",
        },
      },
      client_customer_list: {
        name_first: {
          field: "name_first",
          operator: "",
        },
        name_last: {
          field: "name_last",
          operator: "and",
        },
        email_first: {
          field: "email_first",
          operator: "or",
        },
        email_first: {
          field: "email_second",
          operator: "or",
        },
      },
      registration_data: {
        registration_id: {
          field: "registration_ids",
          operator: "",
        },
        name_first: {
          field: "name_first",
          operator: "or",
        },
        name_last: {
          field: "name_last",
          operator: "and",
        },
        email_first: {
          field: "email_first",
          operator: "or",
        },
      },
      housing_data: {
        housing_id: {
          field: "housing_id",
          operator: "",
        },
        name_first: {
          field: "name_first",
          operator: "or",
        },
        name_last: {
          field: "name_last",
          operator: "and",
        },
        email_first: {
          field: "email_first",
          operator: "or",
        },
        email_second: {
          field: "email_second",
          operator: "or",
        },
      },
      events: {
        event_id: {
          field: "event_id",
          operator: "",
        },
        event_name: {
          field: "event_name",
          operator: "or",
        },
      },
      attendancescans: {
        registration_id: {
          field: "registration_id",
          operator: "",
        },
      },
    },
  },
};
