var ProspectsViewModel = function () {
  var self = this;

  self.selectedProspectRows = ko.observableArray([]);
  self.selectedLeadsRows = ko.observableArray([]);
  self.selectedClosedRows = ko.observableArray([]);
  self.marketers = ko.observableArray([]);
  self.searchMarketersTerm = ko.observable();
  self.tab = ko.observable();
  self.status = ko.observable("all");

  baseViewModel.call(self);

  self.prospectsDataSource = function (options, callback) {
    if (self.status() !== "all") options.status = self.status();
    options.company_type = "exhibitor_company";
    dataSource(options, callback);
  };

  function dataSource(options, callback) {
    ExhibitorsApi.find(self.expoId(), options, function (result) {
      callback(result);
    });
  }

  self.prospectsTableRowCallback = function (row, data) {
    $(row).attr("style", "cursor: pointer");
    $(row).click(function (e) {
      if (e.target.type == "checkbox") return;
      else if ($(e.target).find('input[type="checkbox"]').length > 0) {
        var selectBox = $(e.target).find('input[type="checkbox"]')[0];
        $(selectBox).trigger("click");
        return;
      } else
        location.href =
          "/services/clients/view/?id=" + data.id + "&eid=" + data.expo_id;
    });
  };

  self.prospectsTableColumns = function () {
    var columns = [];
    columns.push({
      data: "id",
      bSortable: false,
      display: "checkbox",
      render: function (data, type, full, meta) {
        return '<input type="checkbox">';
      },
    });
    columns.push({
      width: "3",
      title: "Exhibitor Name",
      data: "exhibitor_name",
      bSortable: true,
    });
    columns.push({ title: "Categories", data: "categories", bSortable: true });
    self
      .expo()
      .customCompanyFields()
      .slice(0, 2)
      .forEach(function (item) {
        columns.push({
          width: "2",
          title: item.value,
          data: item.value.replace(/ /g, "__"),
          bSortable: false,
        });
      });

    return columns;
  };

  self.companyMarketers = function (company_marketers = []) {
    if (company_marketers.length === 0) return "None";
    return company_marketers
      .map((cm) => `${cm.user.name_first} ${cm.user.name_last}`)
      .join(", ");
  };

  self.leadsTableColumns = function () {
    var columns = [];
    columns.push({
      data: "id",
      bSortable: false,
      display: "checkbox",
      render: function (data, type, full, meta) {
        return '<input type="checkbox">';
      },
    });
    columns.push({
      width: "3",
      title: "Exhibitor Name",
      data: "exhibitor_name",
      bSortable: true,
    });
    columns.push({
      width: "2",
      title: "Marketer",
      data: companyMarketers(company_marketers),
      bSortable: true,
    });
    columns.push({ title: "Categories", data: "categories", bSortable: true });
    self
      .expo()
      .customCompanyFields()
      .slice(0, 2)
      .forEach(function (item) {
        columns.push({
          width: "2",
          title: item.value,
          data: item.value.replace(/ /g, "__"),
          bSortable: false,
        });
      });

    return columns;
  };

  // self.prospectsRowTemplate = function() {
  //   var columns = self.prospectsTableColumns();
  //   var columnTemplates = [];
  //   columns.forEach(function(item) {
  //     if (item.display && item.display == 'checkbox') {
  //       columnTemplates.push('<td style="text-align: center"><input type="checkbox" data-bind="checkedValue: id, checked: $root.selectedProspectRows" /></td>');
  //     } else {
  //       columnTemplates.push('<td data-bind=\"text: ' + item.data + '\"></td>');
  //     }
  //   });
  //   return columnTemplates.join('');
  // }

  self.leadsRowTemplate = function () {
    var columns = self.leadsTableColumns();
    var columnTemplates = [];
    columns.forEach(function (item) {
      if (item.display && item.display == "checkbox") {
        columnTemplates.push(
          '<td style="text-align: center"><input type="checkbox" data-bind="checkedValue: id, checked: $root.selectedLeadsRows" /></td>'
        );
      } else {
        columnTemplates.push('<td data-bind="text: ' + item.data + '"></td>');
        //columnTemplates.push('<td data-bind=\"text: ' + item.data.replace(/ /g, '__') + '\"></td>');
      }
    });
    return columnTemplates.join("");
  };

  // self.prospectsHeaderTemplate = function() {
  //   var columns = self.prospectsTableColumns();
  //   var headerTemplate = [];
  //   columns.forEach(function(item) {
  //     if (item.display && item.display == 'checkbox') {
  //       headerTemplate.push('<th style="width:65px; text-align: center"><input type="checkbox" name="select_all" /></th>');
  //     } else {
  //       if (item.width)
  //         headerTemplate.push('<th class="col-md-' + item.width + '">' + item.title + '</th>');
  //       else
  //         headerTemplate.push('<th>' + item.title + '</th>');
  //     }
  //   });
  //   return headerTemplate.join('');

  // }

  self.leadsHeaderTemplate = function () {
    var columns = self.leadsTableColumns();
    var headerTemplate = [];
    columns.forEach(function (item) {
      if (item.display && item.display == "checkbox") {
        headerTemplate.push(
          '<th style="width:65px; text-align: center"><input type="checkbox" name="select_all" /></th>'
        );
      } else {
        if (item.width)
          headerTemplate.push(
            '<th class="col-md-' + item.width + '">' + item.title + "</th>"
          );
        else headerTemplate.push("<th>" + item.title + "</th>");
      }
    });
    return headerTemplate.join("");
  };

  self.getExpo = function (id, fnSuccess) {
    ExpoApi.get(id, function (expo) {
      self.expo(expo);
      if (typeof fnSuccess === "function") {
        fnSuccess(expo);
      }
      self.getExpoMarketers(expo.id());
    });
  };

  self.getExpoMarketers = function (id) {
    ExpoApi.getExpoMarketers(id, function (data) {
      self.marketers(data);
    });
  };

  self.searchMarketers = function () {
    var expoId = self.expoId();
    var searchTerm = new RegExp(
      "\\b" + self.searchMarketersTerm() + "\\b",
      "i"
    );
    ExpoApi.getExpoMarketers(expoId, function (data) {
      var filtered = ko.utils.arrayFilter(data, function (item) {
        return (
          item.name_first.match(searchTerm) || item.name_last.match(searchTerm)
        );
      });
      self.marketers(filtered);
    });
  };

  self.showAssignMarketerModal = function (tab) {
    self.tab(tab);
    $("#assignMarketerModal").modal("show");
  };

  self.newExhibitor = function (expo) {
    location.href = "/services/clients/edit/?eid=" + self.expoId();
  };

  self.assignMarketer = function (user) {
    var exhibitors = [];
    if (self.tab() == "prospects") {
      exhibitors = self.selectedProspectRows();
    } else if (self.tab() == "leads") {
      exhibitors = self.selectedLeadsRows();
    }
    if (exhibitors.length > 0) {
      if (
        confirm(
          "Are you sure you want to assign this marketer to the selected exhibitors?",
          "Assign Marketer"
        )
      ) {
        UsersApi.assignAsMarketerToCompanies(user.id, exhibitors, function () {
          utils.showSuccess(
            "Marketer has been successfully assigned to selected exhibitors."
          );
          setTimeout(function () {
            location.href = "/services/prospects/";
          }, 2000);
          $("#assignMarketerModal").modal("hide");
        });
      }
    }
  };
};
