var ReplyDataApi = {
  get: function (id, fnSuccess) {
    var url = "/api/reply_data/" + id;
    ajax.get(url, fnSuccess);
  },

  find: function (expoId, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/reply_data/expo/" + expoId + query_string;
    ajax.get(url, fnSuccess);
  },

  getFields: function (expoId, fnSuccess) {
    var url = "/api/reply_data/expo/" + expoId + "/fields";
    ajax.get(url, fnSuccess);
  },

  save: function (expoId, data, fnSuccess) {
    // body...
    var url = "/api/reply_data/expo/" + expoId;
    ajax.post(url, data, fnSuccess);
  },

  update: function (id, data, fnSuccess) {
    // body...
    var url = "/api/reply_data/" + id;
    ajax.post(url, data, fnSuccess);
  },
};
