var PublicApi = {
  getExpo: function (id, fnSuccess) {
    var url = "/public_api/buyers_guide/expo/" + id;
    ajax.get(url, fnSuccess);
  },

  getIndividual: function (id, fnSuccess) {
    var url = "/public_api/reply_data/individual/" + id;
    ajax.get(url, fnSuccess);
  },

  getExhibitor: function (expoId, id, fnSuccess) {
    var url = "/public_api/reply_data/company/" + expoId + "/" + id;
    ajax.get(url, fnSuccess);
  },

  getReplyData: function (id, fnSuccess) {
    var url = "/public_api/reply_data/" + id;
    ajax.get(url, fnSuccess);
  },

  updateIndividual: function (id, data, fnSuccess) {
    var url = "/public_api/reply_data/individual/" + id;
    ajax.post(url, data, fnSuccess);
  },

  getExhibitorByExhibitorId: function (expoId, exhid, fnSuccess) {
    var url =
      "/public_api/reply_data/company_by_exhibitor_id/" + expoId + "/" + exhid;
    ajax.get(url, fnSuccess);
  },

  getEventByEventId: function (expoId, evid, fnSuccess) {
    var url = "/public_api/reply_data/event/" + expoId + "/" + evid;
    ajax.get(url, fnSuccess);
  },
};
