var ExhibitorsViewModel = function () {
  var self = this;
  self.exhibitor = ko.observable();
  self.exhibitorId = ko.observable();
  self.exhibitors = ko.observableArray([]);
  self.error = ko.observable();
  self.categoriesViewModel = ko.observable();
  self.editCategoriesViewModel = ko.observable();
  self.demographicsViewModel = ko.observable();
  self.editDemographicsViewModel = ko.observable();
  self.marketers = ko.observableArray([]);
  self.selectedCategoryOrDemographicToEdit = ko.observable();

  self.expo_status = ko.observableArray([]);

  baseViewModel.call(self);

  self.exhibitorsDataSource = function (options, callback) {
    options.company_type = "exhibitor_company";
    ExhibitorsApi.findAll(self.expoId(), options, function (data) {
      callback(data);
    });
  };

  self.exhibitorsTableRowCallback = function (row) {
    $(row).attr("style", "cursor: pointer");
  };

  self.viewExhibitor = function (exhibitor) {
    location.href =
      "/expo/exhibitors/view/?id=" + exhibitor.id + "&eid=" + exhibitor.expo_id;
  };

  self.editExhibitor = function (exhibitor) {
    location.href =
      "/expo/exhibitors/edit/?id=" +
      exhibitor.id() +
      "&eid=" +
      exhibitor.expo_id();
  };

  self.newExhibitor = function (expo) {
    location.href = "/expo/exhibitors/edit/?eid=" + self.expoId();
  };

  self.deleteExhibitor = function (model) {
    var exhibitor = model.exhibitor();
    ExhibitorsApi.delete(exhibitor.id(), function () {
      utils.showSuccess("Exhibitor was successfully deleted.");
      setTimeout(function () {
        location.href = "/expo/exhibitors/";
      }, 2000);
    });
  };

  self.openWebsite = function (argument) {
    var exhibitor = self.exhibitor();
    if (exhibitor && exhibitor.website_url()) {
      var website = exhibitor.website_url();
      if (website.substring(0, 4) !== "http") website = "http://" + website;
      window.open(website, "_blank");
    }
  };

  self.isUserAssignedMarketer = function () {
    var company_marketers = self.exhibitor().company_marketers();
    var user = self.currentUser();
    return company_marketers.filter((cm) => cm.user.id === user.id).length > 0;
  };

  self.getExhibitors = function (id) {
    url = "/api/companies/expo/" + id + "?q=&p=1&ps=50&sort=";
    ajax.get(url, function (data) {
      self.exhibitors(data.data);

      $("#exhibitors-table").dataTable(utils.dataTableOptions);
    });
  };

  self.getExpo = function (id, fnSuccess) {
    ExpoApi.get(id, function (expo) {
      self.expo(expo);
      self.categoriesViewModel().expo(expo);
      self.editCategoriesViewModel().expo(expo);
      self.demographicsViewModel().expo(expo);
      self.editDemographicsViewModel().expo(expo);
      if (typeof fnSuccess === "function") {
        fnSuccess(expo);
      }
    });
  };

  self.getExhibitor = function (id, fnSuccess) {
    var url = "/api/companies/" + id;
    ajax.get(url, function (result) {
      self.exhibitor(new Exposync.CompanyModel(result));
      self
        .categoriesViewModel()
        .selectedCategories(self.exhibitor().categories());
      self.categoriesViewModel().selectedCategoriesLoaded(true);
      self
        .editCategoriesViewModel()
        .selectedCategories(self.exhibitor().categories());
      self.editCategoriesViewModel().selectedCategoriesLoaded(true);
      self
        .demographicsViewModel()
        .selectedDemographics(self.exhibitor().demographics());
      self.demographicsViewModel().selectedDemographicsLoaded(true);
      self
        .editDemographicsViewModel()
        .selectedDemographics(self.exhibitor().demographics());
      self.editDemographicsViewModel().selectedDemographicsLoaded(true);

      if (typeof fnSuccess === "function") {
        fnSuccess(result);
      }
      self.exhibitor().contacts(result.company_contacts);
    });
  };

  self.updateExhibitor = function (model) {
    if (!$("#exhibitor_form").valid()) {
      return false;
    }

    var exhibitor = model.exhibitor();

    var url = "";
    var success = "";
    var expoId = model.expoId();

    if (exhibitor.id()) {
      url = "/api/companies/" + exhibitor.id();
      success = "Exhibitor was successfully updated";
    } else {
      url = "/api/companies/expo/" + expoId;
      success = "Exhibitor was successfully created.";
    }

    var data = exhibitor.toJSON();
    data.assigned_marketer_id = null;

    var redirectUrl =
      "/expo/exhibitors/view/?id=" +
      exhibitor.id() +
      "&eid=" +
      exhibitor.expo_id();

    ajax.post(url, data, function (data) {
      utils.showSuccess(success);
      setTimeout(function () {
        location.href = redirectUrl;
      }, 2000);
    });
  };

  self.updateEditedCategoryOrDemographic = function (
    selectedCategoryOrDemographicToEdit
  ) {
    var exhibitor = self.exhibitor();
    var data = {};

    const list = $(
      selectedCategoryOrDemographicToEdit.object.nestableListSelector
    ).nestable("serialize");
    const editList = NestableList.fromSelectableNestable(list);

    if (selectedCategoryOrDemographicToEdit.category)
      data.categories = editList;
    else data.demographics = editList;

    url = "/api/companies/" + exhibitor.id();
    var redirectUrl =
      "/expo/exhibitors/view/?id=" +
      exhibitor.id() +
      "&eid=" +
      exhibitor.expo_id();
    success =
      selectedCategoryOrDemographicToEdit.title + " were successfully updated";

    ajax.post(url, data, function (data) {
      utils.showSuccess(success);
      setTimeout(function () {
        location.href = redirectUrl;
      }, 2000);
    });
  };

  self.editCategories = function () {
    self.selectedCategoryOrDemographicToEdit({
      title: "Categories",
      object: self.editCategoriesViewModel(),
      category: true,
    });
    $("#editCategoriesDemographicsModal").modal("show");
  };

  self.editDemographics = function () {
    self.selectedCategoryOrDemographicToEdit({
      title: "Demographics",
      object: self.editDemographicsViewModel(),
    });
    $("#editCategoriesDemographicsModal").modal("show");
  };

  self.cancelEdit = function (model) {
    var exhibitor = model.exhibitor();
    if (exhibitor.id())
      location.href =
        "/expo/exhibitors/view/?id=" +
        exhibitor.id() +
        "&eid=" +
        exhibitor.expo_id();
    else location.href = "/expo/exhibitors/";
  };

  self.cancelView = function () {
    location.href = "/expo/exhibitors/";
  };

  self.categories = ko.computed(function () {
    if (self.exhibitor()) {
      return NestableList.toNestable(self.exhibitor().categories());
    } else return [];
  });

  self.demographics = ko.computed(function () {
    if (self.exhibitor()) {
      return NestableList.toNestable(self.exhibitor().demographics());
    } else return [];
  });

  self.expoId.subscribe(function (value) {
    ExpoApi.getExpoMarketers(value, function (list) {
      self.marketers(list);
    });
    ExhibitorsApi.getExpoStatuses(value, function (statuses) {
      self.expo_status(statuses);
    });
  });

  self.init = function () {
    self.exhibitor(new Exposync.CompanyModel({}));
    self.categoriesViewModel(new CategoriesViewModel());
    self.editCategoriesViewModel(new CategoriesViewModel());
    self.demographicsViewModel(new DemographicsViewModel());
    self.editDemographicsViewModel(new DemographicsViewModel());
    self.editDemographicsViewModel().selectableCategories =
      self.editDemographicsViewModel().selectableDemographics;
  };

  self.init();
};
