var JobsApi = {
  get: function (id, fnSuccess) {
    var url = "/api/jobs/" + id;
    ajax.get(url, fnSuccess);
  },

  save: function (data, fnSuccess) {
    var url = "/api/jobs/schedule";
    ajax.post(url, data, fnSuccess);
  },
};
