var NotificationApi = {
  get: function (fnSuccess, fnError) {
    var url = "/api/notification";
    ajax.get(url, fnSuccess, fnError);
  },

  post: function (id, data, fnSuccess) {
    var url = "/api/notification/" + id;
    ajax.post(url, data, fnSuccess);
  },
};
