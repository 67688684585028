var MessagesViewModel = function () {
  var self = this;

  self.messageId = ko.observable();
  self.messages = ko.observableArray([]);
  self.individuals = ko.observableArray([]);
  self.selectedIndividual = ko.observable(null);
  self.viewModel = ko.observable();
  self.lists = ko.observableArray([]);
  self.logs = ko.observableArray([]);
  self.templates = ko.observableArray([]);
  self.clients = ko.observableArray();
  self.selectedTemplate = ko.observable();

  self.list_ids = ko.observableArray([]);
  self.all_list_ids = ko.observableArray([]);
  self.lists_count = ko.observableArray([]);
  self.recipientCount = ko.observable(0);
  self.client_id = ko.observable();
  self.replyDataModel = ko.observable();
  self.send_history = ko.observableArray([]);
  self.template = ko.observable();

  self.interval_type = ko.observable("once");
  self.send_date = ko.observable();
  self.schedule_start_date = ko.observable();
  self.schedule_end_date = ko.observable();
  self.timezone = ko.observable("America/Los_Angeles");

  self.interval = ko.observable("day");
  self.day_frequency = ko.observableArray(["MON", "TUE", "WED", "THU", "FRI"]);
  self.daily_send_time = ko.observable();
  self.hour_frequency = ko.observable(1);
  self.minute_frequency = ko.observable(30);

  self.sending_restrictions = ko.observable("no");
  self.exclude_weekends = ko.observable(true);
  self.dont_send_before_time = ko.observable();
  self.dont_send_after_time = ko.observable();

  self.lists_approved = ko.observable();
  self.template_approved = ko.observable();
  self.schedule_approved = ko.observable();

  self.histories = ko.observableArray([]);
  self.analyticsModel = ko.observable();

  var historiesTable;

  self.selectedLog = ko.observable({
    console_log: "",
    viewLog: ko.observable(false),
  });

  self.selectedLog.subscribe(function (item) {
    if (item) item.viewLog(true);
  });
  self.approveAndSendButtonDisabled = ko.observable(true);

  var historyTable;
  baseViewModel.call(self);

  self.updateMessageType = function () {
    if (self.viewModel().message_options() == "reply_data") {
    }
    return true;
  };

  self.startNewReplyData = function () {
    // body...
    if (!self.replyDataModel()) self.replyDataModel(new ReplyDataViewModel({}));
    self.replyDataModel().expoId(self.expoId());
    self.replyDataModel().startNewReplyData();
  };

  self.individualsDataSource = function (options, callback) {
    if (options.q && options.q.length > 2)
      IndividualsApi.findContact(self.expoId(), options, function (ret) {
        let res = [];
        ret.data.forEach((individual) => {
          if (individual.company_info && individual.company_info.length)
            individual.company_info.forEach((contact) => {
              let company_name =
                contact.company.company_type === "attending_company"
                  ? "(AC) "
                  : "(EC) ";
              company_name += contact.company.exhibitor_name;
              res.push(
                Object.assign({}, individual, {
                  company_id: contact.company_id,
                  business_name: company_name,
                })
              );
            });
          else res.push(individual);
        });
        ret.data = res;
        callback(ret);
      });
    else
      callback({
        data: [],
        draw: 0,
        recordsFiltered: 0,
        recordsTotal: 0,
      });
  };

  self.individualsTableRowCallback = function (row) {
    $(row).attr("style", "cursor: pointer");
  };

  self.selectIndividual = function (individual) {
    self.selectedIndividual({
      individualId: individual.id,
      name: individual.name_first + " " + individual.name_last,
      email: individual.email_first,
      company_id: individual.company_id,
    });
  };

  self.saveReplyData = function () {
    self.replyDataModel().saveReplyData(function (data) {
      self.viewModel().reply_data_id(data.id);
    });
  };
  self.activeExpoMessagesDataSource = function (options, callback) {
    options.status = "active";
    options.client_type = "expo";
    MessagesApi.find(self.expoId(), options, function (response) {
      callback(response);
    });
  };

  self.activeExhibitorMessagesDataSource = function (options, callback) {
    options.status = "active";
    options.client_type = "exhibitor";
    MessagesApi.find(self.expoId(), options, function (response) {
      callback(response);
    });
  };

  self.inactiveExhibitorMessagesDataSource = function (options, callback) {
    options.status = "inactive";
    options.client_type = "exhibitor";
    console.log(options);
    MessagesApi.find(self.expoId(), options, function (data) {
      console.log(data.data);
      callback(data);
    });
  };

  self.inactiveExpoMessagesDataSource = function (options, callback) {
    options.status = "inactive";
    options.client_type = "expo";
    MessagesApi.find(self.expoId(), options, function (data) {
      callback(data);
    });
  };

  self.activeMessagesRowCallback = function (row, data) {
    $(row).attr("style", "cursor: pointer");
    $(row).click(function (e) {
      location.href =
        "/services/messages/view/?id=" + data.id + "&eid=" + data.expo_id;
    });
  };

  self.sendTestEmail = function () {
    var data = self.selectedIndividual();
    if (!data.email || !data.individualId) {
      utils.showError("Please select and individual and enter an email");
      return;
    }

    MessagesApi.sendTestEmail(self.messageId(), data, function () {
      utils.showSuccess("Email was successfully sent.");
      // $('#sendTestMessageModal').modal('hide');
    });
  };

  self.getMessage = function (id) {
    MessagesApi.get(id, function (data) {
      self.viewModel(new Exposync.MessageModel(data));
      setSchedulingValues();
      self.client_id(data.client_id);
      self.list_ids(data.list_ids);
      if (data.reply_data_id) {
        ReplyDataApi.get(data.reply_data_id, function (reply_data) {
          var model = new ReplyDataViewModel(reply_data);
          ReplyDataApi.getFields(self.expoId(), function (data) {
            var fields = $.map(data, function (type, name) {
              return {
                name: name,
                type: type,
              };
            });
            model.fields(fields);
            self.replyDataModel(model);
          });
        });
      }
    });
  };

  self.client_id.subscribe(function (newValue) {
    self.getLists(newValue);
    self.getTemplates(newValue);
  });

  self.editMessage = function (data) {
    location.href =
      "/services/messages/edit/?id=" + data.id() + "&eid=" + data.expo_id();
  };

  self.deleteMessage = function (model) {
    MessagesApi.delete(model.messageId(), function () {
      utils.showSuccess("Message has been deleted.");
      setTimeout(function () {
        location.href = "/services/messages/?" + self.expoId();
      }, 3000);
    });
  };

  self.newMessage = function () {
    location.href = "/services/messages/edit/?eid=" + self.expoId();
  };

  self.cancelMessageView = function () {
    location.href = "/services/messages/?" + self.expoId();
  };

  self.getLists = function (id) {
    var options = utils.defaultQueryOptions;
    options.ps = 1000;
    if (!id) return;
    options.exhibitor_id = id;

    ListApi.find(self.expoId(), options, function (response) {
      var lists = response.data;
      self.all_list_ids = ko.utils.arrayMap(response.data, function (item) {
        return item.id;
      });
      if (self.all_list_ids && self.all_list_ids.length > 0) {
        var input = {};
        input.ids = self.all_list_ids;
        ListApi.getListsCount(input, function (data) {
          var obj = {};
          ko.utils.arrayForEach(data, function (item) {
            if (!obj[item.list_id]) obj[item.list_id] = {};
            obj[item.list_id][item.status] = item.total_count;
          });
          self.lists_count(obj);
          ko.utils.arrayForEach(lists, function (item) {
            item.count =
              self.lists_count()[item.id] !== undefined
                ? self.lists_count()[item.id]["approved"] !== undefined
                  ? self.lists_count()[item.id]["approved"]
                  : 0
                : 0;
          });
          self.lists(lists);
        });
      } else {
        self.lists(lists);
      }
    });
  };

  self.getTemplates = function (id) {
    if (!id) return;
    var options = utils.defaultQueryOptions;
    options.ps = 1000;
    options.exhibitor_id = id;
    EmailTemplatesApi.find(self.expoId(), options, function (response) {
      self.templates(response.data);
    });
  };

  self.getClients = function (fnSuccess) {
    var options = utils.defaultQueryOptions;
    options.ps = 1000;
    options.status = "client";
    options.company_type = "exhibitor_company";
    ExhibitorsApi.findAll(self.expoId(), options, function (response) {
      self.clients(response.data.sort(exhibitorNameCompare));
      if (typeof fnSuccess === "function") {
        fnSuccess();
      }
    });
  };

  function exhibitorNameCompare(a, b) {
    if (a.exhibitor_name < b.exhibitor_name) return -1;
    if (a.exhibitor_name > b.exhibitor_name) return 1;
    return 0;
  }

  self.availableTemplates = ko.computed(function () {
    if (self.viewModel()) {
      var template_id = self.viewModel().user_message_template_id();
      return ko.utils.arrayMap(self.templates(), function (item) {
        if (item.id == template_id) self.selectedTemplate(item);
        return {
          selected: item.id == template_id,
          template: item,
        };
      });
    } else return [];
  });

  self.selectedLists = ko.computed(function () {
    if (self.viewModel()) {
      return ko.utils.arrayFilter(self.lists(), function (item) {
        return self.list_ids.indexOf(item.id) > -1;
      });
    } else return [];
  });

  self.availableLists = ko.computed(function () {
    if (self.viewModel()) {
      return ko.utils.arrayFilter(self.lists(), function (item) {
        return self.list_ids.indexOf(item.id) < 0;
      });
    } else return [];
  });

  self.countRecipients = ko.computed(function () {
    var count = 0;
    var list_ids = ko.utils.arrayMap(self.selectedLists(), function (item) {
      return item.id;
    });
    var data = {};
    data.list_ids = list_ids;
    data.expo_id = self.expoId();
    if (data.expo_id && data.list_ids && data.list_ids.length > 0)
      ListApi.getListsDistinctCount(data, function (response) {
        self.recipientCount(response.total);
      });
  });

  self.addListToMessage = function (list) {
    self.list_ids.push(list.id);
  };

  self.removeListFromMessage = function (list) {
    self.list_ids.remove(list.id);
  };

  self.selectTemplate = function (model) {
    self.viewModel().user_message_template_id(model.template.id);
    self.selectedTemplate(model.template);
  };

  self.unselectTemplate = function (model) {
    self.viewModel().user_message_template_id("");
    self.selectedTemplate(null);
  };

  self.updateMessage = function (model) {
    var data = model.toJSON();
    data.list_ids = self.list_ids();
    if (self.client_id()) data.client_id = self.client_id();
    var expoId = self.expoId();
    var id = model.id();

    if (id) {
      MessagesApi.update(id, data, function () {
        utils.showSuccess("Message has been updated.");
        setTimeout(function () {
          location.href =
            "/services/messages/view/?id=" + id + "&eid=" + expoId;
        }, 3000);
      });
    } else {
      MessagesApi.save(expoId, data, function () {
        utils.showSuccess("Message has been saved.");
        setTimeout(function () {
          location.href = "/services/messages/?" + expoId;
        }, 3000);
      });
    }
  };

  self.previewMessage = function (model) {
    var id = model.id();
    window.open("/api/messages/preview/" + id, "_blank");
  };

  self.sendMessage = function (model) {
    MessagesApi.send(model.messageId(), {}, function () {
      utils.showSuccess("Message has been successfully sent.");
    });
  };

  self.viewTemplate = function (model) {
    window.open(
      "/services/templates/edit/?id=" +
        model.template.id +
        "&eid=" +
        model.template.expo_id,
      "_blank"
    );
  };

  self.viewList = function (model) {
    window.open(
      "/services/lists/view/?id=" + model.id + "&eid=" + model.expo_id,
      "_blank"
    );
  };

  self.editTemplate = function (model) {
    window.open(
      "/services/templates/edit/?id=" +
        model.user_message_template_id() +
        "&eid=" +
        model.expo_id() +
        "&v=" +
        model.user_message_template().editor_version,
      "_blank"
    );
  };

  var refreshInterval = null;
  self.viewLogs = function () {
    self.logs([]);
    $("#sendHistoryModal").modal("show");
    $("#sendHistoryModal").on("hidden.bs.modal", function () {
      clearInterval(refreshInterval);
    });

    function loadLogs() {
      utils.showLoader("#email-logs-table");
      MessagesApi.getHistory(self.messageId(), function (data) {
        data.forEach(function (item) {
          item.sent = item.stats.sent;
          item.ignored = item.stats.ignored;
          item.sent = item.stats.sent;
          item.previously_sent = item.stats.previously_sent;
          item.failed_rules = item.stats.failed_rules;
          item.invalid = item.stats.invalid;
          item.bounced = item.stats.bounced;
          item.duplicates = item.stats.duplicates;
          if (!item.console_log) item.console_log = "";
          item.viewLog = ko.observable(false);
        });

        self.logs(data);
        if (!historyTable) {
          utils.dataTableOptions.order = [[0, "desc"]];
          historyTable = $("#email-logs-table").dataTable(
            utils.dataTableOptions
          );
        }
        utils.hideLoader("#email-logs-table");
      });
    }
    loadLogs();
    refreshInterval = setInterval(loadLogs, 5000);
  };

  self.showConsoleLog = (item) => {
    if (self.selectedLog()) self.selectedLog().viewLog(false);
    self.selectedLog(item);
  };

  self.showSchedule = function () {
    $("#schedulingModal").modal("show");
  };

  function setSchedulingValues() {
    var message = self.viewModel();
    self.interval_type(message.interval_type());
    self.send_date(message.send_date());
    self.schedule_start_date(message.schedule_start_date());
    self.schedule_end_date(message.schedule_end_date());
    self.timezone(message.timezone());
    self.interval(message.interval());
    self.day_frequency(message.day_frequency());
    self.daily_send_time(message.daily_send_time());
    self.hour_frequency(message.hour_frequency());
    self.minute_frequency(message.minute_frequency());
    self.sending_restrictions(message.sending_restrictions());
    self.exclude_weekends(message.exclude_weekends());
    self.dont_send_before_time(message.dont_send_before_time());
    self.dont_send_after_time(message.dont_send_after_time());
  }

  self.scheduleMessage = function () {
    var message = parseSchedulingOptions();
    MessagesApi.update(self.messageId(), message, function (ret) {
      $("#schedulingModal").modal("hide");
      self.getMessage(self.messageId());
    });
  };

  self.showApprovalBox = function () {
    self.lists_approved(self.viewModel().lists_approved());
    self.template_approved(self.viewModel().template_approved());
    self.schedule_approved(self.viewModel().schedule_approved());
    $("#approvalsModal").modal("show");
  };

  self.updateMessageApprovals = function () {
    self.approveAndSendButtonDisabled(false);
    var message = self.viewModel();
    var data = {
      manager_lists_approved: self.lists_approved(),
      manager_template_approved: self.template_approved(),
      manager_schedule_approved: self.schedule_approved(),
    };

    if (
      data.manager_schedule_approved &&
      !(
        data.manager_lists_approved &&
        data.manager_template_approved &&
        message.client_approved()
      )
    )
      utils.showError("You must select all approvals to schedule a message.");

    data.manager_schedule_approved =
      data.manager_lists_approved &&
      data.manager_template_approved &&
      data.manager_schedule_approved &&
      message.client_approved();
    data.manager_approved =
      data.manager_lists_approved &&
      data.manager_template_approved &&
      data.manager_schedule_approved &&
      message.client_approved();

    if (data.manager_approved) {
      MessagesApi.update(self.messageId(), data, function (result) {
        MessagesApi.approve_schedule(
          self.messageId(),
          message.schedule_details(),
          function (ret) {
            self.getMessage(self.messageId());
            self.approveAndSendButtonDisabled(true);
            $("#approvalsModal").modal("hide");
          }
        );
      });
    } else {
      MessagesApi.update(self.messageId(), data, function (result) {
        self.getMessage(self.messageId());
        self.approveAndSendButtonDisabled(true);
        $("#approvalsModal").modal("hide");
      });
    }
  };

  self.showCancelSchedule = function () {
    $("#confirmCancelMessageModal").modal("show");
  };

  self.cancelSchedule = function () {
    utils.showLoader("#confirmCancelMessageModal .modal-body");
    MessagesApi.cancel_schedule(self.messageId(), {}, function (ret) {
      self.getMessage(self.messageId());
      utils.hideLoader("#confirmCancelMessageModal .modal-body");
      $("#confirmCancelMessageModal").modal("hide");
    });
  };

  function parseSchedulingOptions() {
    var data = {};
    var message = self.viewModel();

    data.interval_type = self.interval_type();
    data.message_id = self.messageId();

    if (data.interval_type == "now") {
      //no other data needed.
    } else if (data.interval_type == "once") {
      data.send_date = moment
        .tz(self.send_date().format("YYYY-MM-DD HH:mm"), self.timezone())
        .format(); //self.send_date().format('YYYY-MM-DD h:mm a') //.isValid() ? self.send_date().format() : null
      data.timezone = self.timezone();
    } else if (data.interval_type == "repeat") {
      data.start_date = moment
        .tz(
          self.schedule_start_date().format("YYYY-MM-DD HH:mm"),
          self.timezone()
        )
        .format(); //self.schedule_start_date().isValid() ? self.schedule_start_date().format('MM/DD/YYYY h:mm a') : null //instanceof Object ? message.schedule_start_date().format('YYYY-MM-DD h:mm a') : message.schedule_start_date()
      data.end_date = moment
        .tz(
          self.schedule_end_date().format("YYYY-MM-DD HH:mm"),
          self.timezone()
        )
        .format(); //self.schedule_end_date().isValid() ? self.schedule_end_date().format('MM/DD/YYYY h:mm a') : null //instanceof Object ? message.schedule_end_date().format('YYYY-MM-DD h:mm a') : message.schedule_end_date()
      data.timezone = self.timezone();

      //Updates
      data.interval = self.interval();
      if (data.interval == "day") {
        data.day_frequency = self.day_frequency();
        data.daily_send_time = moment
          .tz(
            self.daily_send_time().format("YYYY-MM-DD HH:mm"),
            self.timezone()
          )
          .format(); //self.daily_send_time()
      } else if (data.interval == "hour") {
        data.hour_frequency = self.hour_frequency();
      } else if (data.interval == "minute") {
        data.minute_frequency = self.minute_frequency();
      }

      //Restrictions
      data.sending_restrictions = self.sending_restrictions();
      if (data.sending_restrictions == "yes") {
        data.exclude_weekends = self.exclude_weekends();
        data.dont_send_before_time = moment
          .tz(
            self.dont_send_before_time().format("YYYY-MM-DD HH:mm"),
            self.timezone()
          )
          .format(); // self.dont_send_before_time()
        data.dont_send_after_time = moment
          .tz(
            self.dont_send_after_time().format("YYYY-MM-DD HH:mm"),
            self.timezone()
          )
          .format(); // self.dont_send_after_time()
      }
    }

    var message = {};
    message.schedule_details = data;
    message.scheduled = true;
    message.manager_schedule_approved = false;
    message.manager_approved = false;

    return message;
  }

  self.showAnalytics = function () {
    self.analyticsModel(
      new AnalyticsViewModel(self.messageId(), self.viewModel().message_name())
    );
    $("#analyticsModal").modal("show");
  };

  self.showHistory = function (message) {
    const scheduleDetailStringify = (record, field) => {
      // fields are 'new_data' and 'old_data'
      let ret = "";
      switch (record[field].interval_type) {
        case "now":
          ret = "Scheduled to run upon schedule approval\n";
          break;
        case "once":
          ret =
            "Scheduled to run at " +
            utils.formatDateTime(
              record[field].send_date,
              record[field].timezone
            ) +
            "\n";
          break;
        case "repeat":
          ret =
            "Scheduled to run between " +
            utils.formatDateTime(
              record[field].start_date,
              record[field].timezone
            ) +
            " and " +
            utils.formatDateTime(
              record[field].end_date,
              record[field].timezone
            ) +
            " " +
            record[field].timezone +
            "\n";

          ret += "Message will update every ";
          if (record[field].day_frequency)
            ret +=
              record[field].day_frequency.join(", ") +
              " at " +
              utils.formatTime(
                record[field].daily_send_time,
                record[field].timezone
              ) +
              "\n";
          else if (record[field].hour_frequency)
            ret += record[field].hour_frequency + " hours\n";
          else ret += record[field].minute_frequency + " minutes\n";

          ret += "Sending Restrictions: ";
          if (record[field].sending_restrictions === "no") ret += "none\n";
          else {
            ret +=
              "Message will not be sent before " +
              utils.formatTime(
                record[field].dont_send_before_time,
                record[field].timezone
              ) +
              " and after " +
              utils.formatTime(
                record[field].dont_send_after_time,
                record[field].timezone
              );
            if (record[field].exclude_weekends) ret += " and on weekends\n";
            else ret += "\n";
          }
          break;
        default:
          break;
      }
      return ret;
    };
    MessagesApi.loadChangeHistory(message.id(), function (response) {
      // filter out manager_approve field and format schedule_details
      response.filter((r) => {
        if (r.field === "manager_approve") return false;
        if (r.field === "schedule_details") {
          const added = scheduleDetailStringify(r, "new_data");
          const removed = scheduleDetailStringify(r, "old_data");
          r.difference = "-- ADDED -- \n" + added;
          r.difference += "-- REMOVED -- \n" + removed;
          r.old_data = r.old_data.interval_type;
          r.new_data = r.new_data.interval_type;
        }
        return r;
      });
      self.histories(response);
      if (!historiesTable) {
        utils.dataTableOptions.order = [[0, "desc"]];
        historiesTable = $("#history-table").dataTable(utils.dataTableOptions);
      }
    });

    $("#historyModal").modal("show");
  };

  self.messageOptions = ko.computed(function () {
    if (self.viewModel()) {
      var vm = self.viewModel();
      if (vm.message_options() == "marketing_message")
        return "Marketing Message";
      if (vm.message_options() == "invite_message")
        return "Customer Invite Message";
      if (vm.message_options() == "reply_data") return "Reply Data Message";
      if (vm.message_options() == "send_leads_message")
        return "Send Leads Message";
    } else return "";
  });

  self.init = function () {
    self.viewModel(new Exposync.MessageModel({}));
    self.replyDataModel(new ReplyDataViewModel({}));
  };
};
