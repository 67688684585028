var ReplyDataViewModel = function (data) {
  var self = this;

  self.individualId = ko.observable();
  self.individual = ko.observable();
  self.expoId = ko.observable(data.expo_id || "");
  self.expo = ko.observable();
  self.fields = ko.observableArray([]);
  self.fieldTypes = ko.observableArray([]);
  self.questions = ko.observableArray(
    ko.utils.arrayMap(data.field_mapping, function (item) {
      return new Exposync.QuestionModel(item);
    }) || []
  );

  self.start_from = ko.observable("blank");
  self.reply_data_template = ko.observable();

  self.reply_data_id = ko.observable(data.id);
  self.existing_reply_data_templates = ko.observableArray([]);
  self.save_as_template = ko.observable(
    data.is_template ? data.is_template.toString() : "false"
  );
  self.save_as_template_name = ko.observable(data.name || "");

  self.expoId.subscribe(function (value) {
    self.getReplyDataFields();
  }, self);

  self.getIndividual = function (id, fnSuccess) {
    BuyersGuideApi.getIndividual(id, function (data) {
      self.individual(new Exposync.IndividualModel(data));
      if (typeof fnSuccess === "function") {
        fnSuccess(data);
      }
    });
  };

  self.getReplyDataFields = function () {
    ReplyDataApi.getFields(self.expoId(), function (data) {
      var fields = $.map(data, function (type, name) {
        return { name: name, type: type };
      });
      self.fields(fields);
    });
  };

  self.getExpo = function (id, fnSuccess) {
    BuyersGuideApi.getExpo(id, function (expo) {
      self.expo(expo);
      if (typeof fnSuccess === "function") {
        fnSuccess(expo);
      }
    });
  };

  self.startNewReplyData = function () {
    var options = utils.defaultQueryOptions;
    options.ps = 1000;

    if (self.reply_data_id()) {
      $("#replyDataBuilderModal").modal("show");
    } else {
      ReplyDataApi.find(self.expoId(), options, function (response) {
        self.existing_reply_data_templates(response.data);
      });
      $("#startReplyDataModal").modal("show");
    }
  };

  self.showReplyDataBuilder = function () {
    if (self.start_from() == "blank") {
      $("#startReplyDataModal").modal("hide");
      $("#replyDataBuilderModal").modal("show");
    } else if (self.start_from() == "existing") {
      if (self.reply_data_template()) {
        $("#startCreateListModal").modal("hide");
        var template = ko.utils.arrayFirst(
          self.existing_reply_data_templates(),
          function (item) {
            return item.id == self.reply_data_template();
          }
        );
        if (template) {
          self.questions(
            ko.utils.arrayMap(template.field_mapping, function (item) {
              return new Exposync.QuestionModel(item);
            })
          );
          self.save_as_template_name(template.name + " - copy");
          self.save_as_template(template.is_template.toString());
        }
        $("#startReplyDataModal").modal("hide");
        $("#replyDataBuilderModal").modal("show");
      }
    }
  };

  self.saveReplyData = function (fnSuccess) {
    // save or update
    var model = {};
    model.field_mapping = [];
    ko.utils.arrayForEach(self.questions(), function (item) {
      if (item.isValid()) {
        model.field_mapping.push({
          field: item.field(),
          question: item.question(),
          type: item.type(),
          options: item.options(),
          required: item.required(),
          sourceDataColumn: item.sourceDataColumn(),
          maximumSelections: item.maximumSelections(),
        });
      }
    });

    if (
      model.field_mapping.filter(function (item) {
        return item.type == "tree";
      }).length > 1
    ) {
      utils.showError("Only one row can be of type tree!");
      return;
    }

    if (model.field_mapping.length == 0) {
      utils.showError("Please enter at least one question row!");
      return;
    }
    model.is_template = self.save_as_template();
    model.name = self.save_as_template_name();

    if (self.reply_data_id()) {
      ReplyDataApi.update(self.reply_data_id(), model, function () {
        $("#replyDataBuilderModal").modal("hide");
      });
    } else {
      ReplyDataApi.save(self.expoId(), model, function (data) {
        self.reply_data_id(data.id);
        $("#replyDataBuilderModal").modal("hide");
        fnSuccess(data);
      });
    }
  };

  self.addQuestion = function () {
    var expoId = self.expoId();
    self.questions.push(new Exposync.QuestionModel({}));
  };

  self.removeQuestion = function (item) {
    self.questions.remove(item);
  };

  self.init = function () {
    self.fieldTypes([
      "text",
      "date",
      "number",
      "currency",
      "multiple-line",
      "single-selection",
      "multiple-selection",
      "tree",
      "url",
      "multiple-items",
      "image",
    ]);
    // body...
    if (!self.reply_data_id())
      self.questions().push(
        new Exposync.QuestionModel({
          field: "",
          value: "",
          question: "",
          type: "text",
          options: "",
          sourceDataColumn: "",
          maximumSelections: 0,
        })
      );

    if (self.expoId()) self.getReplyDataFields();
  };
  self.init();
};
