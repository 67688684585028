var CompanyContactsApi = {
  getContactsByCompanyId: function (id, fnSuccess) {
    var url = "/api/company_contacts/" + id + "/company";
    ajax.get(url, function (data) {
      fnSuccess(data);
    });
  },

  getContactsByIndividualId: function (id, fnSuccess) {
    var url = "/api/company_contacts/" + id + "/individual";
    ajax.get(url, function (data) {
      fnSuccess(data);
    });
  },

  getExhibitorRolesByExpoId: function (id, fnSuccess) {
    var url = "/api/company_contacts/roles/expo/" + id;
    ajax.get(url, function (data) {
      fnSuccess(data);
    });
  },

  createContact: function (data, fnSuccess) {
    var url = "/api/company_contacts/";
    ajax.post(url, data, fnSuccess);
  },

  updateContact: function (id, data, fnSuccess) {
    var url = "/api/company_contacts/" + id;
    ajax.post(url, data, fnSuccess);
  },

  deleteContact: function (id, fnSuccess) {
    var url = "/api/company_contacts/" + id;
    ajax.delete(url, {}, fnSuccess);
  },
};
