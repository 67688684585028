var authenticator = {
  removeState: function () {
    var items = ["person", "editUser", "editExpo", "editEvent", "perms"];
    for (var i = 0; i < items.length; i++) {
      localStorage.removeItem(items[i]);
    }
    $.removeCookie("connect.sid", {
      path: "/",
    });
    $.removeCookie("logged_in", {
      path: "/",
    });
  },
  logout: function () {
    $.ajax({
      type: "POST",
      url: "/api/logout",
      data: {},
    })
      .done(function () {
        authenticator.removeState();
        location.href = "/login";
      })
      .fail(function (req, status, err) {
        authenticator.removeState();
        location.href = "/login";
      });
  },
  login: function (email, password) {
    $.ajax({
      type: "POST",
      url: "/api/login",
      data: {
        email: email,
        password: password,
      },
    })
      .done(function (data) {
        localStorage.setItem("person", JSON.stringify(data));
        location.href = "/home/";
      })
      .fail(function (req, status, err) {
        switch (req.status) {
          case 500:
            utils.showError(
              "Unexpected error has occured. We have notified our technical team. We apologize for the inconvenience."
            );
            break;
          case 401:
            utils.showError("Incorrect email or password. Please try again.");
            break;
          case 403:
            location.href = "/home";
            break;
          case 404:
            utils.showError("Email not found. Please try again.");
            break;
          default:
            utils.showError(
              "Unexpected error has occured. We have notified our technical team. We apologize for the inconvenience."
            );
        }
      });
  },
  forgotPassword: function (email) {
    ajax.post(
      "/api/forgot_password",
      {
        email: email,
      },
      function () {
        utils.showSuccess(
          "We will send you an email with instructions for resetting your password.\n You'll be redirected in 5 seconds."
        );
        setTimeout(function () {
          location.href = "/login";
        }, 5000);
      }
    );
  },
  completeRegistration: function (email, password, code) {
    ajax.post(
      "/api/invitation_redeem",
      {
        email: email,
        code: code,
        password: password,
      },
      function () {
        utils.showSuccess(
          "You successfully activated your account.\n You'll be redirected in 5 seconds."
        );
        setTimeout(function () {
          location.href = "/login";
        }, 5000);
      }
    );
  },
};
