var ExhibitorsApi = {
  get: function (id, fnSuccess) {
    var url = "/api/companies/" + id;
    ajax.get(url, function (data) {
      fnSuccess(data);
    });
  },

  getClientCustomerList: function (id, fnSuccess) {
    var url = "/api/companies/" + id + "/client_customer_list";
    ajax.get(url, function (data) {
      fnSuccess(data);
    });
  },

  find: function (expoId, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/companies/expo/" + expoId + query_string;
    ajax.get(url, fnSuccess);
  },

  findAll: function (expoId, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/companies/all/expo/" + expoId + query_string;
    ajax.get(url, fnSuccess);
  },

  update: function (id, data, fnSuccess) {
    var url = "/api/companies/" + id;
    ajax.post(url, data, fnSuccess);
  },

  delete: function (id, fnSuccess) {
    var url = "/api/companies/" + id;
    ajax.delete(url, {}, fnSuccess);
  },

  getMany: function (data, fnSuccess, by_exhibitor_id = false, expo_id = null) {
    var url = "/api/companies/many";
    if (by_exhibitor_id)
      url = `${url}?by_exhibitor_id=${by_exhibitor_id}&expo_id=${expo_id}`;
    ajax.post(url, data, fnSuccess);
  },
  getFinancials: function (id, fnSuccess, fnError) {
    var url = "/api/companies/" + id + "/financials";
    ajax.get(url, fnSuccess, fnError);
  },
  findFinancials: function (expoId, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/companies/financials" + query_string;
    ajax.get(url, fnSuccess);
  },
  updateFinancials: function (id, data, fnSuccess) {
    var url = "/api/companies/" + id + "/financials";
    ajax.post(url, data, fnSuccess);
  },

  addNote: function (id, data, fnSuccess) {
    var url = "/api/companies/notes/client/" + id;
    ajax.post(url, data, fnSuccess);
  },

  updateNote: function (id, data, fnSuccess) {
    var url = "/api/companies/" + id + "/financials";
    ajax.post(url, data, fnSuccess);
  },
  deleteNote: function (id, data, fnSuccess) {
    var url = "/api/companies/" + id + "/financials";
    ajax.post(url, data, fnSuccess);
  },
  getNote: function (id, fnSuccess) {
    var url = "/api/companies/notes/" + id;
    ajax.get(url, function (data) {
      fnSuccess(data);
    });
  },
  findNotes: function (clientId, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/companies/" + clientId + "/notes/" + query_string;
    ajax.get(url, fnSuccess);
  },
  loadChangeHistory: function (clientId, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/companies/" + clientId + "/history/" + query_string;
    ajax.get(url, fnSuccess);
  },
  getExhibitorsByCategory: function (expoId, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url =
      "/api/companies/expo/" + expoId + "/search_by_category" + query_string;
    ajax.get(url, fnSuccess);
  },
  getExhibitorsByDemographics: function (expoId, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url =
      "/api/companies/expo/" +
      expoId +
      "/search_by_demographics" +
      query_string;
    ajax.get(url, fnSuccess);
  },

  getLeads: function (id, expoId, fnSuccess) {
    var url = "/public_api/leads/get_exhibitor_leads/" + id + "/" + expoId;
    ajax.get(url, fnSuccess);
  },

  findByExhibitorId: function (expoId, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    const url = "/api/companies/exhibitor_id/" + expoId + query_string;
    ajax.get(url, fnSuccess);
  },

  getExhibitorsByExpoId: function (id, fnSuccess) {
    var url = "/api/companies/exhibitors/expo/" + id;
    ajax.get(url, fnSuccess);
  },

  getExpoStatuses: function (id, fnSuccess) {
    var url = `/api/companies/expo/${id}/distinct_expo_statuses`;
    ajax.get(url, fnSuccess);
  },
};
