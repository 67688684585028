var ajax = {
  load: function (url) {
    var scriptPath = root + url;
    $.ajax({
      url: scriptPath,
      timeout: 120000,
      dataType: "script",
      async: false,
      success: function () {
        console.log("script " + scriptPath + " loaded sucessfully.");
      },
      error: function () {
        console.log("script " + scriptPath + " did not load!!!");
      },
    });
  },
  post: function (url, params, fnSuccess, fnError, method) {
    if (!params) params = {};
    $.ajax({
      type: method != null && method != "undefined" ? method : "POST",
      url: url,
      timeout: 240000,
      data: JSON.stringify(params),
      contentType: "application/json",
    })
      .done(function (data) {
        if (typeof fnSuccess === "function") {
          fnSuccess(data);
        }
      })
      .fail(function (req, status, err) {
        console.log(req);
        utils.handleAPIError(req, status, err);
        if (typeof fnError === "function") {
          fnError(req, status, err);
        }
        $.unblockUI();
      });
  },
  get: function (url, fnSuccess, fnError) {
    $.ajax({
      type: "GET",
      url: url,
      timeout: 120000,
    })
      .done(fnSuccess)
      .fail(function (req, status, err) {
        if (typeof fnError === "function") {
          fnError(req, status, err);
        } else {
          console.log(req);
          utils.handleAPIError(req, status, err);
          $.unblockUI();
        }
      });
  },
  delete: function (url, data, fnSuccess) {
    $.ajax({
      type: "DELETE",
      data: data,
      url: url,
      timeout: 120000,
      success: fnSuccess,
      error: function (req, status, err) {
        console.log(req);
        utils.handleAPIError(req, status, err);
        $.unblockUI();
      },
    });
  },
};
