var ExpoReferenceViewModel = function () {
  var self = this;
  self.error = ko.observable();
  self.expoPackages = ko.observable();

  baseViewModel.call(self);

  self.getExpoPackages = function () {
    ExpoPackagesApi.get(
      self.expoId(),
      function (data) {
        self.expoPackages(new Exposync.ExpoPackagesModel(data));
      },
      function (req, status, err) {
        if (req.status == 404) {
          //self.expoPackages(new Exposync.ExpoPackagesModel({}));
          //self.expoPackagesExist(false);
        }
      }
    );
  };
};
