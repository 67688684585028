var CompaniesViewModel = function (clientId) {
  var self = this;
  self.companies = ko.observableArray([]);
  self.company = ko.observable();
  self.expoId = ko.observable();
  self.companyId = ko.observable();
  self.error = ko.observable();
  self.mode = ko.observable(); // new or update
  self.uploadType = ko.observable();
  self.uploadProgress = ko.observable(0);
  self.document_to_delete = ko.observable();
  self.image_to_delete = ko.observable();

  self.company_type_text = ko.pureComputed(function () {
    switch (self.company().company_type()) {
      case "exhibitor_company":
        return "Exhibitor Company";
      case "attending_company":
        return "Attending Company";
      default:
        return "";
    }
  }, self);

  self.categoriesViewModel = ko.observable();
  self.editCategoriesViewModel = ko.observable();
  self.demographicsViewModel = ko.observable();
  self.editDemographicsViewModel = ko.observable();
  self.selectedCategoryOrDemographicToEdit = ko.observable();

  self.newIndividualToAdd = ko.observable();
  self.contacts = ko.observableArray([]);
  self.contact_id = ko.observable();
  self.contact_search_term = ko.observable();
  self.contact_to_remove = ko.observable();
  self.contact_to_view = ko.observable();
  self.image_to_delete = ko.observable();

  self.expo_exhibitor_roles = ko.observableArray([]);
  self.selected_exhibitor_roles = ko.observableArray([]);

  self.notesModel = ko.observable();
  self.histories = ko.observableArray([]);
  var historiesTable;

  baseViewModel.call(self);

  self.companiesDataSource = function (options, callback) {
    options.company_type = "attending_company";
    ExhibitorsApi.find(self.expoId(), options, function (data) {
      callback(data);
    });
  };

  self.companiesTableRowCallback = function (row) {
    $(row).attr("style", "cursor: pointer");
  };

  self.viewCompany = function (client) {
    location.href =
      "/services/attending-companies/view/?id=" +
      client.id +
      "&eid=" +
      client.expo_id;
  };

  self.updateContactModal = function (item) {
    self.selected_exhibitor_roles([]);
    $("#addContactModal").modal("show");
  };

  self.searchContacts = function () {
    if (!self.contact_search_term()) {
      utils.showError("Please enter a search term.");
      return;
    }
    var expoId = self.expoId();
    var options = utils.defaultQueryOptions;
    options.q = self.contact_search_term();
    IndividualsApi.find(expoId, options, function (result) {
      self.contacts(result.data);
    });
  };

  self.addContact = function (contact) {
    if (
      self.selected_exhibitor_roles() &&
      self.selected_exhibitor_roles().length > 0
    ) {
      var exhibitor_roles = self.selected_exhibitor_roles();
      const individual_id = contact.id;
      const company_id = self.company().id();
      var data = {};

      data.exhibitor_roles = exhibitor_roles;
      data.individual_id = individual_id;
      data.company_id = company_id;

      CompanyContactsApi.createContact(data, function () {
        utils.showSuccess("Contact was successfully added.");
        self.getClient(self.companyId());
        setTimeout(() => {
          $("#addContactModal").modal("hide");
        }, 1000);
      });
    } else {
      utils.showError(
        "Please select at least on exhibitor role for the contact."
      );
    }
  };

  self.removeContactConfirmModal = function (contact) {
    self.contact_to_remove(contact);
    $("#confirmRemoveContactModal").modal("show");
  };

  self.showAddNewIndividualModal = function () {
    self.newIndividualToAdd({
      name_first: "",
      name_last: "",
      email_first: "",
      phone_first: "",
    });
    $("#addContactModal").modal("hide");
    $("#addNewIndividualToCompanyModal").modal("show");
  };

  self.addNewIndividualToCompany = function () {
    if (!$("#add-new-individual-form").valid()) {
      return false;
    }

    if (
      self.selected_exhibitor_roles() &&
      self.selected_exhibitor_roles().length > 0
    ) {
      url = "/api/individuals/expo/" + self.expoId();
      ajax.post(url, self.newIndividualToAdd(), (ret) => {
        $("#addNewIndividualToCompanyModal").modal("hide");
        self.addContact(ret);
      });
    } else {
      utils.showError(
        "Please select at least on exhibitor role for the contact."
      );
    }
  };

  self.removeContact = function (contact) {
    var contact = self.contact_to_remove();

    var data = {};
    var exhibitor_roles = contact.exhibitor_roles;
    var index = exhibitor_roles.indexOf(contact.role);
    if (index > -1) {
      exhibitor_roles.splice(index, 1);
    }
    data.exhibitor_roles = exhibitor_roles;
    data.individual_id = contact.individual_id;
    data.company_id = contact.company_id;

    if (exhibitor_roles.length) {
      CompanyContactsApi.updateContact(contact.id, data, function () {
        utils.showSuccess("Contact was successfully removed.");
        self.getClient(self.companyId());
      });
    } else {
      CompanyContactsApi.deleteContact(contact.id, () => {
        utils.showSuccess("Contact was successfully removed.");
        self.getClient(self.companyId());
      });
    }
  };

  self.getClientContacts = function (company_contacts) {
    self.company().contacts([]);
    var contacts = [];
    company_contacts
      .filter((c) => c.individual)
      .map((contact) => {
        const normalized = contact.exhibitor_roles.map((role) =>
          Object.assign(
            {},
            contact,
            { exhibitor: contact.exhibitor_roles },
            { role: role }
          )
        );
        contacts = [...contacts, ...normalized];
      });
    self
      .company()
      .contacts(
        contacts.sort((a, b) =>
          a.role.toLowerCase() < b.role.toLowerCase() ? -1 : 1
        )
      );
  };

  self.getClient = function (id, fnSuccess) {
    ExhibitorsApi.get(id, function (data) {
      self.company(new Exposync.CompanyModel(data));
      self
        .categoriesViewModel()
        .selectedCategories(self.company().categories());
      self.categoriesViewModel().selectedCategoriesLoaded(true);
      self
        .editCategoriesViewModel()
        .selectedCategories(self.company().categories());
      self.editCategoriesViewModel().selectedCategoriesLoaded(true);
      self
        .demographicsViewModel()
        .selectedDemographics(self.company().demographics());
      self.demographicsViewModel().selectedDemographicsLoaded(true);
      self
        .editDemographicsViewModel()
        .selectedDemographics(self.company().demographics());
      self.editDemographicsViewModel().selectedDemographicsLoaded(true);
      if (typeof fnSuccess === "function") {
        fnSuccess(data);
      }
      self.getClientContacts(data.company_contacts);
    });
  };

  self.getExpo = function (id, fnSuccess) {
    ExpoApi.get(id, function (expo) {
      self.expo(expo);
      self.categoriesViewModel().expo(expo);
      self.editCategoriesViewModel().expo(expo);
      self.demographicsViewModel().expo(expo);
      self.editDemographicsViewModel().expo(expo);
      if (typeof fnSuccess === "function") {
        fnSuccess(expo);
      }
    });
    CompanyContactsApi.getExhibitorRolesByExpoId(id, (roles) => {
      self.expo_exhibitor_roles(roles);
    });
  };

  self.showNotes = function () {
    self.notesModel(new NotesViewModel(self.companyId(), ExhibitorsApi));
    $("#notesModal").modal("show");
  };

  self.showHistory = function () {
    var customFields = null;
    ExhibitorsApi.loadChangeHistory(
      self.companyId(),
      utils.defaultQueryOptions,
      function (response) {
        self.histories(response.data);
        if (!historiesTable) {
          utils.dataTableOptions.order = [[0, "desc"]];
          historiesTable = $("#history-table").dataTable(
            utils.dataTableOptions
          );
        }
      }
    );

    $("#historyModal").modal("show");
  };

  self.categories = ko.computed(function () {
    if (self.company()) {
      return NestableList.toNestable(self.company().categories());
    } else return [];
  });

  self.demographics = ko.computed(function () {
    if (self.company()) {
      return NestableList.toNestable(self.company().demographics());
    } else return [];
  });

  self.updateEditedCategoryOrDemographic = function (
    selectedCategoryOrDemographicToEdit
  ) {
    var exhibitor = self.company();
    var data = {};

    const list = $(
      selectedCategoryOrDemographicToEdit.object.nestableListSelector
    ).nestable("serialize");
    const editList = NestableList.fromSelectableNestable(list);

    if (selectedCategoryOrDemographicToEdit.category)
      data.categories = editList;
    else data.demographics = editList;

    url = "/api/companies/" + exhibitor.id();
    var redirectUrl =
      "/services/attending-companies/view/?id=" +
      exhibitor.id() +
      "&eid=" +
      exhibitor.expo_id();
    success =
      selectedCategoryOrDemographicToEdit.title + " were successfully updated";

    ajax.post(url, data, function (data) {
      utils.showSuccess(success);
      setTimeout(function () {
        location.href = redirectUrl;
      }, 2000);
    });
  };

  self.editCategories = function () {
    self.selectedCategoryOrDemographicToEdit({
      title: "Categories",
      object: self.editCategoriesViewModel(),
      category: true,
    });
    $("#editCategoriesDemographicsModal").modal("show");
  };

  self.editDemographics = function () {
    self.selectedCategoryOrDemographicToEdit({
      title: "Demographics",
      object: self.editDemographicsViewModel(),
    });
    $("#editCategoriesDemographicsModal").modal("show");
  };

  self.updateCompany = function (model) {
    if (!$("#exhibitor_form").valid()) {
      return false;
    }

    var company = model.company();

    var url = "";
    var returnUrl = "";
    var success = "";
    var expoId = model.expoId();

    if (company.id()) {
      url = "/api/companies/" + company.id();
      returnUrl =
        "/services/attending-companies/view/?id=" +
        company.id() +
        "&eid=" +
        company.expo_id();
      success = "Company was successfully updated";
    } else {
      url = "/api/companies/expo/" + expoId;
      returnUrl = "/services/attending-companies/?" + company.expo_id();
      success = "Company was successfully created.";
    }

    const options = { exhibitor_id: company.exhibitor_id() };

    var data = company.toJSON();
    if (data.nexus_status !== "client") data.client_type = null;

    data.assigned_marketer_id = null;

    if (self.mode() === "new")
      ExhibitorsApi.findByExhibitorId(expoId, options, function (exhibitor) {
        if (exhibitor) {
          utils.showError(
            `Company with Exhibitor ID: ${options.exhibitor_id} already exists!`
          );
        } else {
          ajax.post(url, data, function (data) {
            utils.showSuccess(success);
            setTimeout(function () {
              location.href = returnUrl;
            }, 2000);
          });
        }
      });
    else if (self.mode() === "update") {
      ajax.post(url, data, function (data) {
        utils.showSuccess(success);
        setTimeout(function () {
          location.href = returnUrl;
        }, 2000);
      });
    }
  };

  self.viewContact = function (entry) {
    //get expo role
    if (
      self.isInRole("admin,manager") ||
      self.isInExpoRole("organizer_admin")
    ) {
      window.open(
        "/expo/individuals/view/?id=" +
          entry.individual.id +
          "&eid=" +
          self.expoId(),
        "_blank"
      );
    } else if (self.isInRole("marketer")) {
      IndividualsApi.get(entry.individual.id, function (data) {
        self.contact_to_view(new Exposync.IndividualModel(data));
        $("#viewClientContactModal").modal("show");
      });
    }
  };

  self.cancelView = function () {
    location.href = "/services/attending-companies/";
  };

  self.renderView = function (company, view) {
    location.href =
      "/services/attending-companies/" +
      view +
      "/?id=" +
      company.id() +
      "&eid=" +
      company.expo_id();
  };

  self.editCompany = function (company) {
    location.href =
      "/services/attending-companies/edit/?id=" +
      company.id() +
      "&eid=" +
      company.expo_id();
  };

  self.cancelEdit = function (model) {
    var company = model.company();
    if (company.id())
      location.href =
        "/services/attending-companies/view/?id=" +
        company.id() +
        "&eid=" +
        company.expo_id();
    else location.href = "/services/attending-companies/";
  };

  self.deleteCompany = function (model) {
    var company = model.company();
    ExhibitorsApi.delete(company.id(), function () {
      utils.showSuccess("Company was successfully deleted.");
      setTimeout(function () {
        location.href = "/services/attending-companies/";
      }, 2000);
    });
  };

  self.newCompany = function (expo) {
    location.href = "/services/attending-companies/edit/?eid=" + self.expoId();
  };

  self.uploadDocument = function () {
    $.blockUI({ baseZ: 9999, message: $("#upload-message") });

    var file = document.getElementById("photoUpload").files[0];
    if (file != undefined && file != null) {
      //var name = 'uploads_' + Math.floor(1000000000 * Math.random()) + '_' + file.name;
      var name = file.name;

      UploadsApi.upload(
        file,
        name,
        function () {
          $.unblockUI();

          var data = {};
          data.link = name;
          data.name = name;
          var doc = new Exposync.DocumentModel(data);
          if (self.uploadType() == "document")
            self.company().documents.push(doc);
          else self.company().photos.push(doc);

          var company = {
            photos: [],
            documents: [],
            company_marketers: self.company().company_marketers_ids(),
          };
          company.photos = ko.utils.arrayMap(
            self.company().photos(),
            function (p) {
              return p.link();
            }
          );
          company.documents = ko.utils.arrayMap(
            self.company().documents(),
            function (p) {
              return p.link();
            }
          );
          ExhibitorsApi.update(self.company().id(), company, function (data) {
            utils.showSuccess("File have been uploaded successfully.");
            $("#uploadDocumentModal").modal("hide");
          });
        },
        function (msg) {
          $.unblockUI();
          utils.showError(msg);
        },
        function (progress) {
          self.uploadProgress(Math.round(progress * 100));
        }
      );
    } else {
      utils.showError(
        "Please select a photo before clicking the upload button."
      );
      $.unblockUI();
    }
  };

  self.removeDocumentConfirmModal = function (document) {
    self.document_to_delete(document);
    $("#confirmRemoveDocumentModal").modal("show");
  };

  self.removeImageConfirmModal = function (image) {
    self.image_to_delete(image);
    $("#confirmRemoveImageModal").modal("show");
  };

  self.removeDocument = function () {
    var document = self.document_to_delete();

    var documents = ko.utils.arrayFilter(
      self.company().documents(),
      function (item) {
        return document.name() !== item.name();
      }
    );
    var data = {
      company_marketers: self.company().company_marketers_ids(),
    };
    data.documents = ko.utils.arrayMap(documents, function (p) {
      return p.link();
    });
    ExhibitorsApi.update(self.company().id(), data, function (data) {
      utils.showSuccess("Document has been successfully deleted.");
      self.company().documents(documents);
      $("#confirmRemoveDocumentModal").modal("hide");
    });
  };

  self.removeImage = function () {
    var image = self.image_to_delete();

    var images = ko.utils.arrayFilter(self.company().photos(), function (item) {
      return image.name() !== item.name();
    });
    var data = {
      company_marketers: self.company().company_marketers_ids(),
    };
    data.photos = ko.utils.arrayMap(images, function (p) {
      return p.link();
    });
    ExhibitorsApi.update(self.company().id(), data, function (data) {
      utils.showSuccess("Image has been successfully deleted.");
      self.company().photos(images);
      $("#confirmRemoveImageModal").modal("hide");
    });
  };

  self.init = function (clientId) {
    self.company(new Exposync.CompanyModel({}));
    self.categoriesViewModel(new CategoriesViewModel());
    self.editCategoriesViewModel(new CategoriesViewModel());
    self.demographicsViewModel(new DemographicsViewModel());
    self.editDemographicsViewModel(new DemographicsViewModel());
    self.editDemographicsViewModel().selectableCategories =
      self.editDemographicsViewModel().selectableDemographics;
  };

  self.init();
};
