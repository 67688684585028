(function () {
  var format = function (value) {
    toks = value.toFixed(2).replace("-", "").split(".");
    var display =
      "$" +
      $.map(toks[0].split("").reverse(), function (elm, i) {
        return [i % 3 === 0 && i > 0 ? "," : "", elm];
      })
        .reverse()
        .join("") +
      "." +
      toks[1];

    return value < 0 ? "-" + display : display;
  };

  ko.subscribable.fn.money = function () {
    var target = this;

    var writeTarget = function (value) {
      var stripped = value.toString().replace(/[^0-9.-]/g, "");

      target(parseFloat(stripped));
    };

    var result = ko.computed({
      read: function () {
        return target();
      },
      write: writeTarget,
    });

    result.formatted = ko.computed({
      read: function () {
        return format(target());
      },
      write: writeTarget,
    });

    result.isNegative = ko.computed(function () {
      return target() < 0;
    });

    return result;
  };
})();

ko.bindingHandlers.datepicker = {
  init: function (
    element,
    valueAccessor,
    allBindingsAccessor,
    viewModel,
    bindingContext
  ) {
    //initialize datepicker with some optional options
    var options = {
      format: "MM/DD/YYYY",
      defaultDate: valueAccessor()(),
    };

    if (allBindingsAccessor() !== undefined) {
      if (allBindingsAccessor().datepickerOptions !== undefined) {
        options.format =
          allBindingsAccessor().datepickerOptions.format !== undefined
            ? allBindingsAccessor().datepickerOptions.format
            : options.format;
      }
    }

    $(element).datetimepicker(options);

    //when a user changes the date, update the view model
    ko.utils.registerEventHandler(element, "dp.change", function (event) {
      var value = valueAccessor();
      if (ko.isObservable(value)) {
        value(event.date);
      }
    });

    var defaultVal = $(element).val();
    var value = valueAccessor();
    value(moment(defaultVal, options.format));
  },
  update: function (
    element,
    valueAccessor,
    allBindingsAccessor,
    viewModel,
    bindingContext
  ) {
    var thisFormat = "MM/DD/YYYY";

    if (allBindingsAccessor() !== undefined) {
      if (allBindingsAccessor().datepickerOptions !== undefined) {
        thisFormat =
          allBindingsAccessor().datepickerOptions.format !== undefined
            ? allBindingsAccessor().datepickerOptions.format
            : thisFormat;
      }
    }

    var value = valueAccessor();
    var unwrapped = ko.utils.unwrapObservable(value());

    if (unwrapped === undefined || unwrapped === null) {
      element.value = new moment(new Date());
      //console.log("undefined");
    } else {
      if (
        unwrapped instanceof Object &&
        unwrapped.format(thisFormat) != "Invalid date"
      )
        element.value = unwrapped.format(thisFormat);
    }
  },
};
//ko.mapping.defaultOptions().ignore = ["id"];

ko.bindingHandlers.datetimepicker = {
  init: function (
    element,
    valueAccessor,
    allBindingsAccessor,
    viewModel,
    bindingContext
  ) {
    //initialize datepicker with some optional options
    var options = {
      format: "MM/DD/YYYY h:mm a",
      defaultDate: valueAccessor()(),
    };

    if (allBindingsAccessor() !== undefined) {
      if (allBindingsAccessor().datepickerOptions !== undefined) {
        options.format =
          allBindingsAccessor().datepickerOptions.format !== undefined
            ? allBindingsAccessor().datepickerOptions.format
            : options.format;
      }
    }

    $(element).datetimepicker(options);

    //when a user changes the date, update the view model
    ko.utils.registerEventHandler(element, "dp.change", function (event) {
      var value = valueAccessor();
      if (ko.isObservable(value)) {
        value(event.date);
      }
    });

    var defaultVal = $(element).val();
    var value = valueAccessor();
    value(moment(defaultVal, options.format));
  },
  update: function (
    element,
    valueAccessor,
    allBindingsAccessor,
    viewModel,
    bindingContext
  ) {
    var thisFormat = "MM/DD/YYYY h:mm a";

    if (allBindingsAccessor() !== undefined) {
      if (allBindingsAccessor().datepickerOptions !== undefined) {
        thisFormat =
          allBindingsAccessor().datepickerOptions.format !== undefined
            ? allBindingsAccessor().datepickerOptions.format
            : thisFormat;
      }
    }

    var value = valueAccessor();
    var unwrapped = ko.utils.unwrapObservable(value());

    if (unwrapped === undefined || unwrapped === null) {
      element.value = new moment(new Date());
      //console.log("undefined");
    } else {
      if (
        unwrapped instanceof Object &&
        unwrapped.format(thisFormat) != "Invalid date"
      )
        element.value = unwrapped.format(thisFormat);
    }
  },
};

ko.bindingHandlers.timepicker = {
  init: function (
    element,
    valueAccessor,
    allBindingsAccessor,
    viewModel,
    bindingContext
  ) {
    //initialize datepicker with some optional options
    var options = {
      format: "h:mm a",
      defaultDate: valueAccessor()(),
    };

    if (allBindingsAccessor() !== undefined) {
      if (allBindingsAccessor().datepickerOptions !== undefined) {
        options.format =
          allBindingsAccessor().datepickerOptions.format !== undefined
            ? allBindingsAccessor().datepickerOptions.format
            : options.format;
      }
    }

    $(element).datetimepicker(options);

    //when a user changes the date, update the view model
    ko.utils.registerEventHandler(element, "dp.change", function (event) {
      var value = valueAccessor();
      if (ko.isObservable(value)) {
        value(event.date);
      }
    });

    var defaultVal = $(element).val();
    var value = valueAccessor();
    value(moment(defaultVal, options.format));
  },
  update: function (
    element,
    valueAccessor,
    allBindingsAccessor,
    viewModel,
    bindingContext
  ) {
    var thisFormat = "h:mm a";

    if (allBindingsAccessor() !== undefined) {
      if (allBindingsAccessor().datepickerOptions !== undefined) {
        thisFormat =
          allBindingsAccessor().datepickerOptions.format !== undefined
            ? allBindingsAccessor().datepickerOptions.format
            : thisFormat;
      }
    }

    var value = valueAccessor();
    var unwrapped = ko.utils.unwrapObservable(value());

    if (unwrapped === undefined || unwrapped === null) {
      element.value = new moment(new Date());
      //console.log("undefined");
    } else {
      if (
        unwrapped instanceof Object &&
        unwrapped.format(thisFormat) != "Invalid date"
      )
        element.value = unwrapped.format(thisFormat);
    }
  },
};

// Attaches a popover to the element.
// Use:
//   <span data-bind="popover: 'This is some text'">Link1</span>
//   <span data-bind="popover: '.popover-content'">Link2</span>
//   <span class="popover-content">This is some other text</span>
// NOTE: Content will be re-read each time popover is shown.  Will not live-update w/ knockout changes.
ko.bindingHandlers.popover = {
  init: function (element, valueAccessor) {
    var $trigger = $(element);

    // This function dynamically gets the content each time the tooltip is shown.
    // It will not update live w/ knockout values, but will show the most recent at the time the popover is shown.
    function getContent() {
      var content = valueAccessor();
      if (content.length && content[0] == ".") {
        // Treat as a CSS class selector
        var contentElem = getNearestSibling($trigger, content);
        if (!contentElem) {
          console.error(
            "Could not find a sibling of %o that matches %o.",
            $trigger,
            content
          );
          return;
        }

        // NOTE: Need to clone, wrap, then get HTML so we get the container w/ all its classes as well.
        return contentElem.clone().wrap("<div/>").html();
      }
      return content;
    }

    $trigger
      .popover({
        animation: false,
        trigger: "hover",
        html: true,
        content: getContent,
        placement: "top",
      })
      .on("touchstart", function () {
        // On tablets, allow clicking on trigger to close popover.
        var popover = $trigger.data("popover");
        popover.toggle();
      });
  },
};

ko.bindingHandlers.masked = {
  init: function (element, valueAccessor, allBindingsAccessor) {
    var mask = allBindingsAccessor().mask || {};
    $(element).mask(mask);
    ko.utils.registerEventHandler(element, "blur", function () {
      var observable = valueAccessor();
      observable($(element).val());
    });
  },
  update: function (element, valueAccessor) {
    var value = ko.utils.unwrapObservable(valueAccessor());
    $(element).val(value);
  },
};

ko.bindingHandlers.readmore = {
  init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
    var readmoreContent = ko.utils.unwrapObservable(valueAccessor());

    if (readmoreContent) {
      ko.applyBindingsToNode(
        element,
        {
          html: readmoreContent.replace(/(?:\r\n|\r|\n)/g, "<br />"),
        },
        viewModel
      );
      $(element).readmore();
    }
  },
};

ko.bindingHandlers.autosize = {
  init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
    var content = ko.utils.unwrapObservable(valueAccessor());

    ko.applyBindingsToNode(
      element,
      {
        value: content,
      },
      viewModel
    );
    autosize($(element));
    ko.utils.registerEventHandler(element, "blur", function () {
      var observable = valueAccessor();
      observable($(element).val());
    });
  },
  update: function (element, valueAccessor) {
    var value = ko.utils.unwrapObservable(valueAccessor());
    $(element).val(value);
  },
};

ko.bindingHandlers.select2 = {
  init: function (el, valueAccessor, allBindingsAccessor, viewModel) {
    ko.utils.domNodeDisposal.addDisposeCallback(el, function () {
      $(el).select2("destroy");
    });

    var allBindings = allBindingsAccessor(),
      select2 = ko.utils.unwrapObservable(allBindings.select2);

    $(el).select2(select2);
  },
  update: function (el, valueAccessor, allBindingsAccessor, viewModel) {
    var allBindings = allBindingsAccessor();

    if ("value" in allBindings) {
      if (
        allBindings.select2.multiple &&
        allBindings.value() &&
        allBindings.value().constructor != Array
      ) {
        $(el).select2("val", allBindings.value().split(","));
      } else {
        $(el).select2("val", allBindings.value());
      }
    } else if ("selectedOptions" in allBindings) {
      var converted = [];
      var textAccessor = function (value) {
        return value;
      };
      if ("optionsText" in allBindings) {
        textAccessor = function (value) {
          var valueAccessor = function (item) {
            return item;
          };
          if ("optionsValue" in allBindings) {
            valueAccessor = function (item) {
              return item[allBindings.optionsValue];
            };
          }
          var items = $.grep(allBindings.options, function (e) {
            return valueAccessor(e) == value;
          });
          if (items.length == 0 || items.length > 1) {
            return "UNKNOWN";
          }
          return items[0][allBindings.optionsText];
        };
      }
      $.each(allBindings.selectedOptions(), function (key, value) {
        converted.push({ id: value, text: textAccessor(value) });
      });
      $(el).select2("data", converted);
    }
  },
};
ko.bindingHandlers.trimLengthText = {};
ko.bindingHandlers.trimText = {
  init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
    var trimmedText = ko.computed(function () {
      var untrimmedText = ko.utils.unwrapObservable(valueAccessor());
      if (!untrimmedText) return "";
      var defaultMaxLength = 20;
      var minLength = 5;
      var maxLength =
        ko.utils.unwrapObservable(allBindingsAccessor().trimTextLength) ||
        defaultMaxLength;
      if (maxLength < minLength) maxLength = minLength;
      var text =
        untrimmedText.length > maxLength
          ? untrimmedText.substring(0, maxLength - 1) + "..."
          : untrimmedText;
      return text;
    });
    ko.applyBindingsToNode(
      element,
      {
        text: trimmedText,
      },
      viewModel
    );

    return {
      controlsDescendantBindings: true,
    };
  },
};

ko.bindingHandlers.formatPhoneNumber = {
  update: function (element, valueAccessor) {
    var phone = ko.utils.unwrapObservable(valueAccessor());
    var vphone = phone.replace(/\D/g, "");
    var formatPhone = function () {
      // if (vphone.length > 10)
      //     return vphone.replace(/(\d{3})(\d{3})(\d{4})($|\d+)/, "($1) $2-$3 x$4");
      // else if (vphone.length == 10)
      //     return vphone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      // else
      return phone;
    };
    ko.bindingHandlers.text.update(element, formatPhone);
  },
};

ko.bindingHandlers.jstree = {
  buildTree: function (element, treeData, options) {
    var expanded = options.expanded !== "undefined" ? options.expanded : true;
    var checkbox_visible =
      options.checkbox_visible !== "undefined"
        ? options.checkbox_visible
        : true;

    $(element).jstree("destroy");
    $(element).jstree({
      core: {
        themes: { icons: false },
        data: treeData,
        expand_selected_onload: expanded,
      },
      checkbox: {
        keep_selected_style: false,
        visible: checkbox_visible,
      },
      plugins: ["wholerow", "checkbox"],
    });
  },
  update: function (element, valueAccessor) {
    var treeData = ko.unwrap(valueAccessor());
    var options = ko.unwrap(valueAccessor());
    delete options.data;

    ko.bindingHandlers.jstree.buildTree(element, treeData.data(), options);
  },
};

ko.extenders.async = function (computedDeferred, initialValue) {
  var plainObservable = ko.observable(initialValue),
    currentDeferred;
  plainObservable.inProgress = ko.observable(false);

  ko.computed(function () {
    if (currentDeferred) {
      currentDeferred.reject();
      currentDeferred = null;
    }

    var newDeferred = computedDeferred();
    if (newDeferred && typeof newDeferred.done == "function") {
      // It's a deferred
      plainObservable.inProgress(true);

      // Create our own wrapper so we can reject
      currentDeferred = $.Deferred().done(function (data) {
        plainObservable.inProgress(false);
        plainObservable(data);
      });
      newDeferred.done(currentDeferred.resolve);
    } else {
      // A real value, so just publish it immediately
      plainObservable(newDeferred);
    }
  });

  return plainObservable;
};
