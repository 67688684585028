var InterestedViewModel = function () {
  var self = this;
  self.exhibitor = ko.observable();
  self.individual = ko.observable();
  self.individualId = ko.observable();
  self.expo = ko.observable();

  self.getExhibitor = function (id, fnSuccess) {
    var url = "/public_api/lead_conversion/exhibitor/" + id;
    ajax.get(
      url,
      function (data) {
        // success
        self.exhibitor(new Exposync.CompanyModel(data));
        self.expo(new Exposync.ExpoModel(data.expo));
        self.getIndividual(self.individualId());
      },
      function (data) {
        // error
        utils.showError("No exhibitor found with ID: " + id);
      }
    );
  };

  self.getIndividual = function (id) {
    var url = "/public_api/buyers_guide/individual/" + id;
    ajax.get(
      url,
      function (data) {
        // success
        self.individual(new Exposync.IndividualModel(data));
      },
      function (data) {
        // error
        utils.showError("No individual found with ID: " + id);
      }
    );
  };

  self.init = function () {
    self.exhibitor(Exposync.CompanyModel({}));
    self.expo(Exposync.ExpoModel({}));
    self.individual(Exposync.IndividualModel({}));
  };
  self.init();
};
