var ExpoApi = {
  get: function (id, fnSuccess) {
    var url = "/api/expo/" + id;
    ajax.get(url, function (data) {
      fnSuccess(new Exposync.ExpoModel(data));
    });
  },
  getContacts: function (id, fnSuccess) {
    var url = "/api/expo/" + id + "/contacts";
    ajax.get(url, function (data) {
      fnSuccess(data);
    });
  },
  find: function (options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/expo" + query_string;
    ajax.get(url, fnSuccess);
  },

  update: function (id, data, fnSuccess) {
    var url = "/api/expo/" + id;
    ajax.post(url, data, fnSuccess);
  },

  delete: function (id, fnSuccess) {
    var url = "/api/expo/" + id;
    ajax.delete(url, {}, fnSuccess);
  },

  getMarketers: function (q, fnSuccess) {
    var url = "/api/user/?ps=10000&q=";
    ajax.get(url, function (data) {
      var filtered = data.data.filter(function (item) {
        return (
          item.roles.indexOf("marketer") > -1 && item.deactivated === false
        );
      });
      fnSuccess(filtered);
    });
  },

  getManagers: function (q, fnSuccess) {
    var url = "/api/user/?ps=10000&q=";
    ajax.get(url, function (data) {
      var filtered = data.data.filter(function (item) {
        return item.roles.indexOf("manager") > -1 && item.deactivated === false;
      });
      fnSuccess(filtered);
    });
  },

  getSynchronizers: function (q, fnSuccess) {
    var url = "/api/user/?ps=10000&q=";
    ajax.get(url, function (data) {
      var filtered = data.data.filter(function (item) {
        return (
          item.roles.indexOf("synchronizer") > -1 && item.deactivated === false
        );
      });
      fnSuccess(filtered);
    });
  },

  getExpoMarketers: function (id, fnSuccess) {
    var url = "/api/expo_perms/expo/" + id + "?q=&p=0&ps=10000&sort=";
    var users = [];
    ajax.get(url, function (data) {
      ko.utils.arrayForEach(data, function (item) {
        if (item.role == "marketer")
          users.push({
            id: item.user_id,
            name_first: item.user.name_first,
            name_last: item.user.name_last,
          });
      });
      fnSuccess(users);
    });
  },
  getDashboardData: function (id, fnSuccess) {
    var url = "/api/expo/" + id + "/dashboard";
    ajax.get(url, fnSuccess);
  },
  getLastCampaignsData: function (id, fnSuccess) {
    var url = "/api/expo/" + id + "/email_campaign_summary";
    ajax.get(url, fnSuccess);
  },
  createWebhook: function (expo_id, data, fnSuccess) {
    var url = "/api/webhooks/expo/" + expo_id;
    ajax.post(url, data, fnSuccess);
  },
  updateWebhook: function (id, data, fnSuccess) {
    var url = "/api/webhooks/" + id;
    ajax.post(url, data, fnSuccess);
  },
  findWebhooks: function (id, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/webhooks/expo/" + id + query_string;
    ajax.get(url, fnSuccess);
  },
  findJobs: function (fnSuccess) {
    var url = "/api/jobs/";
    ajax.get(url, fnSuccess);
  },
};
