var CategoriesViewModel = function () {
  var self = this;
  self.error = ko.observable();
  self.nestableListSelector = ".dd";
  self.nestableListEditable = false;
  self.nestableItemsSelectable = false;
  self.selectedCategories = ko.observableArray();
  self.selectedCategoriesLoaded = ko.observable(false);

  baseViewModel.call(self);

  self.categories = function () {
    var categories = [];
    if (self.expo()) {
      categories = self.expo().categories();
      return NestableList.toNestable(categories);
    } else return [];
  };

  self.selectableCategories = ko.computed(function () {
    var categories = [];

    var selected = self.selectedCategories();
    var selectedLoaded = self.selectedCategoriesLoaded();
    if (self.expo() && selectedLoaded) {
      categories = self.expo().categories();
      if (categories && selected) {
        return NestableList.toNestable(categories, selected);
      }
    } else return [];
  });

  self.updateExpo = function () {
    var list = NestableList.fromNestable(
      $(self.nestableListSelector).nestable("serialize")
    );
    var expo = self.expo();
    url = "/api/expo/" + expo.id();
    data = {};
    data.categories = list;
    ajax.post(url, data, function (result) {
      utils.showSuccess("Successfully updated expo.");
      setTimeout(function () {
        location.href = "/expo/categories/?" + expo.id();
      }, 2000);
    });
  };

  self.hasChildren = function (item) {
    if (item.children == undefined) return false;
    return true;
  };

  self.initNestable = function () {
    if (self.categories().length > 0) {
      $(self.nestableListSelector).nestable({
        editable: self.nestableListEditable,
        itemsSelectable: self.nestableItemsSelectable,
      });
    }
  };

  self.initSelectableNestable = function () {
    if (self.categories().length > 0 && self.selectedCategoriesLoaded()) {
      $(self.nestableListSelector).nestable({
        editable: self.nestableListEditable,
        itemsSelectable: self.nestableItemsSelectable,
      });
    }
  };

  self.editCategories = function (model) {
    location.href = "/expo/categories/edit/?" + model.expo().id();
  };

  self.cancelEdit = function (model) {
    location.href = "/expo/categories/?" + model.expo().id();
  };

  self.init = function (id) {
    ExpoApi.get(id, function (data) {
      self.expo(data);
      self.initNestable();
      $(self.nestableListSelector).trigger("change");
    });
  };
};
