var SearchApi = {
  get: function (id, fnSuccess) {
    var url = "/api/searches/" + id;
    ajax.get(url, fnSuccess);
  },

  find: function (expoId, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/searches/expo/" + expoId + query_string;
    ajax.get(url, fnSuccess);
  },

  getFields: function (expoId, fnSuccess) {
    var url = "/api/searches/expo/" + expoId + "/fields";
    ajax.get(url, fnSuccess);
  },

  getExhibitorFields: function (expoId, fnSuccess) {
    var url = "/api/searches/expo/" + expoId + "/exhibitor_fields";
    ajax.get(url, fnSuccess);
  },

  getTerms: function (expoId, field, clientId, messageId, fnSuccess) {
    var url = "/api/searches/expo/" + expoId + "/terms/" + field;
    if (!clientId) clientId = "";
    if (!messageId) messageId = "";

    url = url + "?client_id=" + clientId + "&message_id=" + messageId;

    ajax.get(url, fnSuccess);
  },

  getExhibitorTerms: function (expoId, field, clientId, messageId, fnSuccess) {
    var url = "/api/searches/expo/" + expoId + "/exhibitor_terms/" + field;
    if (!clientId) clientId = "";
    if (!messageId) messageId = "";

    url = url + "?client_id=" + clientId + "&message_id=" + messageId;

    ajax.get(url, fnSuccess);
  },

  preview: function (expoId, data, fnSuccess, fnError) {
    var url = "/api/searches/expo/" + expoId + "/preview";
    ajax.post(url, data, fnSuccess, fnError);
  },

  previewExhibitorSearch: function (expoId, data, fnSuccess, fnError) {
    var url = "/api/searches/expo/" + expoId + "/preview_exhibitor";
    ajax.post(url, data, fnSuccess, fnError);
  },

  save: function (expoId, data, fnSuccess) {
    var url = "/api/searches/expo/" + expoId;
    ajax.post(url, data, fnSuccess);
  },

  update: function (id, data, fnSuccess) {
    var url = "/api/searches/" + id;
    ajax.post(url, data, fnSuccess);
  },

  delete: function (id, fnSuccess) {
    var url = "/api/searches/" + id;
    ajax.delete(url, fnSuccess);
  },
};
