var HomepageViewModel = function () {
  var self = this;
  self.error = ko.observable();
  self.expos = ko.observableArray([]);

  baseViewModel.call(self);

  self.setExpo = function (expo) {
    var id = expo.id();
    $.cookie("current-expo", id, { path: "/" });
    $(".main-navigation-menu a.expo").attr("href", function () {
      return $(this).data("url") + "?" + id;
    });
    location.href = "/expo/information/?" + id;
  };

  self.init = function (id) {};
};
