baseViewModel = function () {
  var self = this;
  self.currentExpo = ko.observable();
  self.currentUser = ko.observable();
  self.expos = ko.observableArray([]);
  self.error = ko.observable();
  self.permissions = ko.observableArray(utils.getRoleBasedPermissions());
  self.organizer_permissions = ko.observableArray(
    utils.getOrganizerPermissions()
  );
  self.expoName = ko.observable();
  self.expoId = ko.observable();
  self.expo = ko.observable();

  self.notifications = ko.observableArray();
  self.newNotificationCount = ko.observable(0);

  self.activeExpos = ko.computed(function () {
    return ko.utils.arrayFilter(self.expos(), function (item) {
      return item.archived() == false;
    });
  });

  self.logout = function () {
    authenticator.logout();
  };

  self.loadExpos = function () {
    var options = { q: "", p: 0, ps: 1000 };
    ExpoApi.find(options, function (response) {
      var expos = $.map(response.data, function (item) {
        return new Exposync.ExpoModel(item);
      });
      self.expos(expos.sort(expoCompare));
      Main.setInitialExpoValue();
    });
  };

  self.openModal = function () {
    $("#notificationModal").modal("show");
  };
  
  self.isInRole = function (arg) {
    var roles = arg.split(",");
    var success = false;
    if (self.currentUser().roles) {
      self.currentUser().roles.forEach(function (item) {
        if (roles.indexOf(item) > -1) success = true;
      });
    }
    return success;
  };

  self.isInExpoRole = function (arg) {
    var roles = arg.split(",");
    var success = false;
    var user = self.currentUser();
    var expo = self.currentExpo();
    var expo_id = null;
    if (expo) {
      expo_id = expo.id;
    } else {
      expo_id = $.cookie("current-expo");
    }

    if (user.expo_perms) {
      user.expo_perms.forEach(function (item) {
        if (roles.indexOf(item.role) > -1 && item.expo_id == expo_id)
          success = true;
      });
    }
    return success;
  };

  self.hasAccess = function (module) {
    var user = self.currentUser();
    var expo = self.currentExpo();
    var expo_id = null;
    if (expo) {
      expo_id = expo.id;
    } else {
      expo_id = $.cookie("current-expo");
    }

    var system_roles = user.roles;
    var expo_roles = user.expo_perms;

    var modules = [];

    if (typeof module === "string") {
      modules.push(module);
    } else modules = module;

    if (system_roles.indexOf("organizer") > -1) {
      //if organizer
      var expo_role = expo_roles.find(function (item) {
        return (item.expo_id = expo_id);
      });

      var hasAccess = self.organizer_permissions().filter(function (item) {
        return (
          item.sub_roles.indexOf(expo_role.role) > -1 &&
          item.access == true &&
          modules.indexOf(item.module) > -1
        );
      });

      if (hasAccess.length > 0) return true;
      return false;
    } else {
      //else
      var rolePermissions = self.permissions().filter(function (item) {
        return system_roles.indexOf(item.role) > -1;
      });
      var hasAccess = rolePermissions.filter(function (item) {
        return modules.indexOf(item.module) > -1 && item.access == true;
      });
      if (hasAccess.length > 0) return true;
      return false;
    }
  };

  self.markAndRedirect = function (data) {
    const url = data.href
    NotificationApi.post(data._id, data, function (response) {
      console.log(url)
      location.href = url;
    });
  };

  self.checkAccessAndRedirect = function (module) {
    if (!self.hasAccess(module)) location.href = "/not-authorized/";
  };
 
  self.baseInit = function () {
    self.loadExpos();
    self.currentUser(App.getCurrentUser());

    if(!self.isInRole('admin')) return 

    NotificationApi.get(function (data) {
      const newNotification = data.filter((item) =>  !item.isViewed);
      self.newNotificationCount(newNotification.length)
      self.notifications(data);
    });
  };

  function expoCompare(a, b) {
    if (a.name() < b.name()) return -1;
    if (a.name() > b.name()) return 1;
    return 0;
  }

  self.baseInit();
};

var DialogModelView = function (data) {
  self = this;

  self.title = ko.observable();
  self.content = ko.observable();
  self.OkContent = ko.observable();
  self.CancelContent = ko.observable();
};
