"use strict";
var App = (function () {
  var checkLogin = function () {
    var person = localStorage.getItem("person");
    var loggedIn = $.cookie("logged_in");

    if (!person || !loggedIn) {
      logout();
    } else {
      person = JSON.parse(localStorage.getItem("person")).user;
    }
  };

  var checkLoginAndRedirect = function () {
    var person = localStorage.getItem("person");
    var loggedIn = $.cookie("logged_in");

    if (!person || !loggedIn) {
      logout();
    } else {
      person = JSON.parse(localStorage.getItem("person")).user;
      location.href = "/expo/information";
    }
  };

  var getCurrentUser = function () {
    var person = localStorage.getItem("person");
    var loggedIn = $.cookie("logged_in");

    if (person && loggedIn) {
      return JSON.parse(localStorage.getItem("person")).user;
    }
  };

  var logout = function () {
    authenticator.logout();
  };

  return {
    init: function () {},
    checkLogin: checkLogin,
    getCurrentUser: getCurrentUser,
    checkLoginAndRedirect: checkLoginAndRedirect,
    logout: logout,
  };
})();
