var IndividualsViewModel = function () {
  var self = this;
  self.individualId = ko.observable();
  self.individual = ko.observable();
  self.individuals = ko.observableArray([]);
  self.error = ko.observable();
  self.categoriesViewModel = ko.observable();
  self.editCategoriesViewModel = ko.observable();
  self.demographicsViewModel = ko.observable();
  self.editDemographicsViewModel = ko.observable();
  self.countries = ko.observableArray([]);
  self.USStates = ko.observableArray([]);
  self.events_registered = ko.observableArray([]);
  self.clientCustomerList = ko.observableArray([]);
  self.isExpoContact = ko.observable(false);
  self.subscriptionPreferences = ko.observable(null);

  self.selectedCategoryOrDemographicToEdit = ko.observable(null);

  self.exhibitors_clicked = ko.observableArray([]);
  self.exhibitors_interested = ko.observableArray([]);
  self.exhibitors_maybe = ko.observableArray([]);
  self.exhibitors_notinterested = ko.observableArray([]);
  self.attendance_data = ko.observableArray([]);
  self.expoRoles = ko.observableArray([]);
  self.otherExpoRoles = ko.observableArray([]);

  self.expo_exhibitor_roles = ko.observableArray([]);
  self.selected_exhibitor_roles = ko.observableArray([]);

  self.contacts = ko.observableArray([]);
  self.contact_to_edit = ko.observable(null);
  self.contact_to_remove = ko.observable(null);
  self.companyContacts = ko.observableArray([]);
  self.contact_search_term = ko.observable();
  self.companyRoles = ko.observableArray([]);
  self.otherCompanyRoles = ko.observableArray([]);
  self.histories = ko.observableArray([]);
  self.notesModel = ko.observable();
  self.emailStatuses = ko.observableArray([
    "processed",
    "delivered",
    "opened",
    "clicked",
    "suspended",
    "soft_bounced",
    "bounced",
  ]);

  var historiesTable;
  baseViewModel.call(self);

  self.duplicates = ko.observableArray([]);
  self.searchText = ko.observable("");
  self.selectedIndividuals = ko.observableArray([]);
  self.individualIdsToMerge = ko.observableArray([]);
  self.showMergeView = ko.observable(false);
  self.individualsToMerge = ko.observableArray([]);
  self.fields = ko.observableArray([]);
  self.mergeCompanyContacts = ko.observable({});
  self.email_history = ko.observableArray([]);
  self.by_email_history = ko.observableArray([]);
  self.email_stats = ko.observable({
    last_open: null,
    last_bounced: null,
    hard_bounce: 0,
    soft_bounce: 0,
    open: 0,
    sent: 0,
    processed: 0,
    delivered: 0,
    delivered_percent: "0%",
    opened_percent: "0%",
  });

  self.expoId.subscribe(function (value) {
    SearchApi.getTerms(
      self.expoId(),
      "expo_roles",
      null,
      null,
      function (data) {
        self.expoRoles(data.sort());
      }
    );

    SearchApi.getTerms(
      self.expoId(),
      "exhibitor_roles",
      null,
      null,
      function (data) {
        self.companyRoles(data);
      }
    );
  }, self);

  self.individualsDataSource = function (options, callback) {
    IndividualsApi.find(self.expoId(), options, function (data) {
      callback(data);
    });
  };

  self.individualsTableRowCallback = function (row) {
    $(row).attr("style", "cursor: pointer");
  };

  self.viewBuyersGuide = function (model) {
    if (window.location.hostname == "staging.thenexus.net") {
      window.open(
        "https://guides-staging.thenexus.net/guide/update/" +
          model.individual().id(),
        "_blank"
      );
    } else {
      window.open(
        "https://guides.thenexus.net/guide/update/" + model.individual().id(),
        "_blank"
      );
    }
  };

  self.searchCompanyContacts = function () {
    if (!self.contact_search_term()) {
      utils.showError("Please enter a search term.");
      return;
    }
    var expoId = self.expoId();
    var options = utils.defaultQueryOptions;
    options.q = self.contact_search_term();
    ExhibitorsApi.find(expoId, options, function (result) {
      self.contacts(result.data);
    });
  };

  self.editCompanyContactModal = function (contact) {
    self.contact_to_edit(contact);
    self.selected_exhibitor_roles(contact.exhibitor_roles);
    $("#company_roles_select").trigger("change");
    $("#editCompanyContactModal").modal("show");
  };

  self.editCompanyContact = function (contact) {
    if (self.selected_exhibitor_roles().length) {
      const data = {
        individual_id: contact.individual_id,
        company_id: contact.company_id,
        exhibitor_roles: self.selected_exhibitor_roles(),
      };
      CompanyContactsApi.updateContact(contact.id, data, () => {
        utils.showSuccess("Contact was successfully updated.");
        self.getIndividual(self.individual().id());
        $("#editCompanyContactModal").modal("hide");
      });
    } else {
      CompanyContactsApi.deleteContact(contact.id, () => {
        utils.showSuccess("Contact was successfully removed.");
        self.getIndividual(self.individual().id());
        $("#editCompanyContactModal").modal("hide");
      });
    }
  };

  self.removeContactConfirmModal = function (contact) {
    self.contact_to_remove(contact);
    $("#confirmRemoveContactModal").modal("show");
  };

  self.removeContact = function (contact) {
    var contact = self.contact_to_remove();

    CompanyContactsApi.deleteContact(contact.id, () => {
      utils.showSuccess("Contact was successfully removed.");
      self.getIndividual(self.individual().id());
    });
  };

  self.addCompanyContactModal = () => {
    $("#addCompanyContactModal").modal("show");
  };

  self.addCompanyContact = function (contact) {
    if (
      self.selected_exhibitor_roles() &&
      self.selected_exhibitor_roles().length > 0
    ) {
      const exhibitor_roles = self.selected_exhibitor_roles();
      const individual_id = self.individual().id();
      const company_id = contact.id;
      let data = {};

      data.exhibitor_roles = exhibitor_roles;
      data.individual_id = individual_id;
      data.company_id = company_id;

      CompanyContactsApi.createContact(data, function () {
        utils.showSuccess("Contact was successfully added.");
        self.getIndividual(self.individual().id());
        setTimeout(() => {
          $("#addCompanyContactModal").modal("hide");
        }, 1000);
      });
    } else {
      utils.showError(
        "Please select at least on exhibitor role for the contact."
      );
    }
  };

  self.viewEmailLogs = function (model) {
    $("#emailHistoryModal").modal("show");
    self.email_history([]);
    self.by_email_history([]);
    utils.showLoader($("#history-table"));
    utils.showLoader($("#byemail-history-table"));
    IndividualsApi.getEmailHistory(
      self.individualId(),
      null,
      null,
      function (data) {
        var mapped = data.map((r) => {
          var events = r.events.map((e) => {
            let event = e.event;
            if (event === "bounce") {
              event = e.type === "bounce" ? "hard_bounce" : "soft_bounce";
            }
            return {
              date: e.date,
              event,
              reason: e.reason || "",
            };
          });
          var maxDate = events.reduce(function (a, b) {
            return a.date > b.date ? a.date : b.date;
          });
          return {
            email: r.email,
            subject: r.subject,
            _id: r._id,
            events: events,
            maxDate,
          };
        });

        self.email_history(
          mapped.sort(function (a, b) {
            return b.maxDate - a.maxDate;
          })
        );
        utils.hideLoader($("#history-table"));
      }
    );

    IndividualsApi.getEmailHistory(
      self.individualId(),
      self.individual().email_first(),
      self.expoId(),
      function (data) {
        var mapped = data.map((r) => {
          var events = r.events.map((e) => {
            let event = e.event;
            if (event === "bounce") {
              event = e.type === "bounce" ? "hard_bounce" : "soft_bounce";
            }
            return {
              date: e.date,
              event,
              reason: e.reason || "",
            };
          });
          var maxDate = events.reduce(function (a, b) {
            return a.date > b.date ? a.date : b.date;
          });
          return {
            email: r.email,
            subject: r.subject,
            _id: r._id,
            events: events,
            maxDate,
          };
        });

        self.by_email_history(
          mapped.sort(function (a, b) {
            return b.maxDate - a.maxDate;
          })
        );
        utils.hideLoader($("#byemail-history-table"));
      }
    );
  };

  self.showHistory = function (individual) {
    var customFields = null;
    IndividualsApi.loadChangeHistory(individual.id(), function (response) {
      self.histories(response);
      if (!historiesTable) {
        utils.dataTableOptions.order = [[0, "desc"]];
        historiesTable = $("#history-table").dataTable(utils.dataTableOptions);
      }
    });

    $("#historyModal").modal("show");
  };

  self.viewIndividual = function (individual) {
    window.open(
      "/expo/individuals/view/?id=" +
        individual.id +
        "&eid=" +
        individual.expo_id,
      "_blank"
    );
  };

  self.editIndividual = function (individual) {
    location.href =
      "/expo/individuals/edit/?id=" +
      individual.id() +
      "&eid=" +
      individual.expo_id();
  };

  self.newindividual = function () {
    location.href = "/expo/individuals/edit/?eid=" + self.expoId();
  };

  self.cancelEdit = function (model) {
    var individual = model.individual();
    if (individual.id())
      location.href =
        "/expo/individuals/view/?id=" +
        individual.id() +
        "&eid=" +
        individual.expo_id();
    else {
      if (self.isExpoContact())
        location.href = "/expo/information/?" + self.expoId();
      else location.href = "/expo/individuals/";
    }
  };

  self.cancelView = function () {
    location.href = "/expo/individuals/";
  };

  self.getExpo = function (id, fnSuccess) {
    ExpoApi.get(id, function (expo) {
      self.expo(expo);
      self.categoriesViewModel().expo(expo);
      self.editCategoriesViewModel().expo(expo);
      self.demographicsViewModel().expo(expo);
      self.editDemographicsViewModel().expo(expo);
      if (typeof fnSuccess === "function") {
        fnSuccess(expo);
      }
    });
    CompanyContactsApi.getExhibitorRolesByExpoId(id, (roles) => {
      self.expo_exhibitor_roles(roles);
    });
  };

  self.viewExhibitor = function (contact) {
    var individual = self.individual();
    if (contact.company_id) {
      if (
        contact.company.company_type &&
        contact.company.company_type == "attending_company"
      )
        window.open(
          "/services/attending-companies/view/?id=" +
            contact.company_id +
            "&eid=" +
            individual.expo_id(),
          "_blank"
        );
      else
        window.open(
          "/services/clients/view/?id=" +
            contact.company_id +
            "&eid=" +
            individual.expo_id(),
          "_blank"
        );
    } else {
      utils.showSuccess(
        "Company not found! Use System Tools -> Utilities to update individual company ids."
      );
    }
  };

  self.getIndividual = function (id, fnSuccess) {
    var url = "/api/individuals/" + id;
    ajax.get(url, function (result) {
      self.individual(new Exposync.IndividualModel(result));
      var isExpoContact =
        result.is_expo_contact && result.is_expo_contact == true ? true : false;
      //self.isExpoContact(isExpoContact);
      self
        .categoriesViewModel()
        .selectedCategories(self.individual().categories());
      self.categoriesViewModel().selectedCategoriesLoaded(true);
      self
        .editCategoriesViewModel()
        .selectedCategories(self.individual().categories());
      self.editCategoriesViewModel().selectedCategoriesLoaded(true);
      self
        .demographicsViewModel()
        .selectedDemographics(self.individual().demographics());
      self.demographicsViewModel().selectedDemographicsLoaded(true);
      self
        .editDemographicsViewModel()
        .selectedDemographics(self.individual().demographics());
      self.editDemographicsViewModel().selectedDemographicsLoaded(true);
      self.companyContacts(result.company_info);
      if (typeof fnSuccess === "function") {
        fnSuccess(result);
      }
      if (
        self.individual().events_registered() &&
        self.individual().events_registered().length > 0
      ) {
        EventsApi.getManyByCode(
          self.expoId(),
          self.individual().events_registered(),
          function (data) {
            self.events_registered(data);
          }
        );
      }

      if (
        self.individual().exhibitors_clicked() &&
        self.individual().exhibitors_clicked().length > 0
      ) {
        ExhibitorsApi.getMany(
          self.individual().exhibitors_clicked(),
          function (data) {
            self.exhibitors_clicked(data);
          }
        );
      }

      if (
        self.individual().exhibitors_interested() &&
        self.individual().exhibitors_interested().length > 0
      ) {
        ExhibitorsApi.getMany(
          self.individual().exhibitors_interested(),
          function (data) {
            self.exhibitors_interested(data);
          }
        );
      }

      if (
        self.individual().exhibitors_maybe() &&
        self.individual().exhibitors_maybe().length > 0
      ) {
        ExhibitorsApi.getMany(
          self.individual().exhibitors_maybe(),
          function (data) {
            self.exhibitors_maybe(data);
          }
        );
      }

      if (
        self.individual().exhibitors_notinterested() &&
        self.individual().exhibitors_notinterested().length > 0
      ) {
        ExhibitorsApi.getMany(
          self.individual().exhibitors_notinterested(),
          function (data) {
            self.exhibitors_notinterested(data);
          }
        );
      }

      if (!self.isInRole("organizer")) {
        self.getIndividualClientCustomerList(id, function (data) {
          self.clientCustomerList(data);
        });
      }

      IndividualsApi.getAttendanceData(self.individual().id(), function (data) {
        let sortedData;
        try {
          sortedData = data.sort(
            (a, b) => new Date(b.scan_time) - new Date(a.scan_time)
          );
        } catch (error) {
          sortedData = data;
        }

        self.attendance_data(
          ko.utils.arrayMap(sortedData, function (item) {
            return new Exposync.AttendanceDataModel(item);
          })
        );
        const exhibitorIds = sortedData
          .filter((attendanceScan) => attendanceScan.scan_type == "exhibitor")
          .map((x) => x.scan_id);
        const eventIds = sortedData
          .filter((attendanceScan) => attendanceScan.scan_type == "event")
          .map((x) => x.scan_id);

        function toObject(arr, key) {
          var rv = {};
          for (var i = 0; i < arr.length; ++i) rv[arr[i][key]] = arr[i];
          return rv;
        }

        if (exhibitorIds.length)
          ExhibitorsApi.getMany(
            exhibitorIds,
            function (exhibitors) {
              const exhibitorsByExhibitorId = toObject(
                exhibitors,
                "exhibitor_id"
              );
              self.attendance_data().forEach((item) => {
                if (item.scan_type() == "exhibitor") {
                  const exhibitor = exhibitorsByExhibitorId[item.scan_id()];
                  item.where(exhibitor.booth_number);
                  item.link(
                    `/services/clients/view/?id=${
                      exhibitor.id
                    }&eid=${self.expoId()}`
                  );
                  item.description(exhibitor.exhibitor_name);
                }
              });
            },
            true,
            self.expoId()
          );

        if (eventIds.length)
          EventsApi.getManyByCode(self.expoId(), eventIds, function (events) {
            const eventsByEventId = toObject(events, "event_id");
            self.attendance_data().forEach((item) => {
              if (item.scan_type() == "event") {
                const event = eventsByEventId[item.scan_id()];
                item.where(event.event_room);
                item.link(
                  `/expo/events/view/?id=${event.id}&eid=${self.expoId()}`
                );
                item.description(event.event_name);
              }
            });
          });
      });
    });

    IndividualsApi.getEmailStats(id, function (ret) {
      var email_stats = {
        last_open: "",
        last_bounced: "",
        hard_bounce: 0,
        soft_bounce: 0,
        open: 0,
        sent: 0,
        processed: 0,
        delivered: 0,
        unique_opens: 0,
        unique_clicks: 0,
        delivered_percent: "0%",
        opened_percent: "0%",
      };
      ret.forEach(function (item) {
        email_stats[item._id] = item.count || 0;
        if (item._id === "open") email_stats.last_open = item.latest;
        if (item._id === "bounce") email_stats.last_bounced = item.latest;
      });

      if (email_stats.processed !== 0 && email_stats.delivered !== 0)
        email_stats.delivered_percent =
          ((email_stats.delivered / email_stats.processed) * 100).toFixed(2) +
          "%";
      if (email_stats.unique_opens !== 0 && email_stats.delivered !== 0)
        email_stats.opened_percent =
          ((email_stats.unique_opens / email_stats.delivered) * 100).toFixed(
            2
          ) + "%";

      self.email_stats(email_stats);
    });

    IndividualsApi.getSubscriptionPreferences(id, function (response) {
      self.subscriptionPreferences(response.preferences);
    });
  };

  self.getIndividualClientCustomerList = function (id, fnSuccess) {
    IndividualsApi.getClientCustomerList(id, fnSuccess);
  };

  addRoles = function (individual) {
    let otherRoles = ko.utils.arrayFilter(
      self.otherExpoRoles(),
      function (item) {
        return item.val() && item.val().trim().length > 0;
      }
    );

    let otherCompanyRoles = ko.utils.arrayFilter(
      self.otherCompanyRoles(),
      function (item) {
        return item.val() && item.val().trim().length > 0;
      }
    );

    if (otherRoles && otherRoles.length > 0) {
      ko.utils.arrayForEach(otherRoles, function (item) {
        individual.roles.push(item.val().trim());
      });
    }

    if (otherCompanyRoles && otherCompanyRoles.length > 0) {
      ko.utils.arrayForEach(otherCompanyRoles, function (item) {
        individual.exhibitor_roles.push(item.val().trim());
      });
    }
  };

  self.updateInterest = function (exhibitor) {
    const url = "/api/individuals/" + self.individual().id();
    ajax.post(
      url,
      {
        exhibitors_interested: self
          .individual()
          .exhibitors_interested()
          .filter((id) => id !== exhibitor.id),
      },
      function (data) {
        self.exhibitors_interested(
          self.exhibitors_interested().filter((e) => e.id !== exhibitor.id)
        );
        utils.showSuccess("Individual updated!");
      }
    );
  };

  self.updateClicks = function (exhibitor) {
    const url = "/api/individuals/" + self.individual().id();
    ajax.post(
      url,
      {
        exhibitors_clicked: self
          .individual()
          .exhibitors_clicked()
          .filter((id) => id !== exhibitor.id),
      },
      function (data) {
        self.exhibitors_clicked(
          self.exhibitors_clicked().filter((e) => e.id !== exhibitor.id)
        );
        utils.showSuccess("Individual updated!");
      }
    );
  };

  self.updateMaybe = function (exhibitor) {
    const url = "/api/individuals/" + self.individual().id();
    ajax.post(
      url,
      {
        exhibitors_maybe: self
          .individual()
          .exhibitors_maybe()
          .filter((id) => id !== exhibitor.id),
      },
      function (data) {
        self.exhibitors_maybe(
          self.exhibitors_maybe().filter((e) => e.id !== exhibitor.id)
        );
        utils.showSuccess("Individual updated!");
      }
    );
  };

  self.updateNotInterested = function (exhibitor) {
    const url = "/api/individuals/" + self.individual().id();
    ajax.post(
      url,
      {
        exhibitors_notinterested: self
          .individual()
          .exhibitors_notinterested()
          .filter((id) => id !== exhibitor.id),
      },
      function (data) {
        self.exhibitors_notinterested(
          self.exhibitors_notinterested().filter((e) => e.id !== exhibitor.id)
        );
        utils.showSuccess("Individual updated!");
      }
    );
    const individual = self.individual();
    const data = {
      email: individual.email_first(),
      expo_id: individual.expo_id(),
      company_id: exhibitor.id,
    };
    IndividualsApi.setSubscriptionPreferences(individual.id(), data);
  };

  self.updateIndividual = function (model) {
    if (!$("#individual_form").valid()) {
      return false;
    }
    var individual = model.individual();

    var url = "";
    var redirectUrl =
      "/expo/individuals/view/?id=" +
      individual.id() +
      "&eid=" +
      individual.expo_id();
    var success = "";
    var expoId = model.expoId();

    addRoles(individual);

    var data = individual.toJSON();

    if (individual.id()) {
      url = "/api/individuals/" + individual.id();
      success = "Individual was successfully updated";
    } else {
      url = "/api/individuals/expo/" + expoId;
      redirectUrl = "/expo/individuals/?" + expoId;
      data.is_expo_contact = self.isExpoContact() == true ? true : false;
      if (data.is_expo_contact) {
        redirectUrl = "/expo/information/?" + expoId;
      }
      success = "Individual was successfully created.";
    }

    ajax.post(url, data, function (data) {
      utils.showSuccess(success);
      setTimeout(function () {
        location.href = redirectUrl;
      }, 2000);
    });
  };

  self.updateEditedCategoryOrDemographic = function (
    selectedCategoryOrDemographicToEdit
  ) {
    var individual = self.individual();
    let data = {};

    const list = $(
      selectedCategoryOrDemographicToEdit.object.nestableListSelector
    ).nestable("serialize");
    const editList = NestableList.fromSelectableNestable(list);

    if (selectedCategoryOrDemographicToEdit.category)
      data.categories = editList;
    else data.demographics = editList;

    url = "/api/individuals/" + individual.id();
    var redirectUrl =
      "/expo/individuals/view/?id=" +
      individual.id() +
      "&eid=" +
      individual.expo_id();
    success =
      selectedCategoryOrDemographicToEdit.title + " were successfully updated";

    ajax.post(url, data, function (data) {
      utils.showSuccess(success);
      setTimeout(function () {
        location.href = redirectUrl;
      }, 2000);
    });
  };

  self.editCategories = function () {
    self.selectedCategoryOrDemographicToEdit({
      title: "Categories",
      object: self.editCategoriesViewModel(),
      category: true,
    });
    $("#editCategoriesDemographicsModal").modal("show");
  };

  self.editDemographics = function () {
    self.selectedCategoryOrDemographicToEdit({
      title: "Demographics",
      object: self.editDemographicsViewModel(),
    });
    $("#editCategoriesDemographicsModal").modal("show");
  };

  self.getIndividuals = function (id) {
    url = "/api/individuals/expo/" + id + "?q=&p=0&ps=10000&sort=";
    ajax.get(url, function (data) {
      self.individuals(data.data);
      $("#individuals-table").dataTable(utils.dataTableOptions);
    });
  };

  self.categories = ko.computed(function () {
    if (self.individual()) {
      return NestableList.toNestable(self.individual().categories());
    } else return [];
  });

  self.demographics = ko.computed(function () {
    if (self.individual()) {
      return NestableList.toNestable(self.individual().demographics());
    } else return [];
  });

  self.deleteIndividual = function () {
    IndividualsApi.delete(self.individualId(), function () {
      utils.showSuccess("Individual was successfully deleted.");
      setTimeout(function () {
        location.href = "/expo/individuals/";
      }, 2000);
    });
  };

  self.showDuplicateFinderModal = function () {
    $("#duplicateFinderModal").modal("show");
  };

  $("#duplicateFinderModal").on("hidden.bs.modal", function (e) {
    self.duplicates([]);
    self.searchText("");
    self.showMergeView(false);
    self.selectedIndividuals([]);
    self.individualsToMerge([]);
    self.individualIdsToMerge([]);
  });

  self.findDuplicates = function () {
    const search = self.searchText();
    self.selectedIndividuals([]);
    self.duplicates([]);
    utils.showLoader("#duplicate-finder-modal-body");
    IndividualsApi.findDuplicates(self.expoId(), search, function (response) {
      utils.hideLoader("#duplicate-finder-modal-body");
      self.duplicates(response);
    });
  };

  self.search = function (model) {
    var options = {
      name_first: model.name_first,
      name_last: model.name_last,
    };
    utils.showLoader("#duplicate-finder-modal-body");
    IndividualsApi.search(self.expoId(), options, function (response) {
      self.individualsToMerge([]);
      self.individualIdsToMerge([]);
      self.selectedIndividuals(response);
      utils.hideLoader("#duplicate-finder-modal-body");
    });
  };

  self.showMergeIndividualsModal = function () {
    self.individualsToMerge([]);
    self.fields([]);
    self.mergeCompanyContacts({});

    ko.utils.arrayForEach(self.individualIdsToMerge(), function (i) {
      var individual = ko.utils.arrayFilter(
        self.selectedIndividuals(),
        function (x) {
          return x.id == i;
        }
      );
      self.individualsToMerge.push(individual[0]);
    });
    var first = self.individualsToMerge()[0];
    var second = self.individualsToMerge()[1];
    var allKeys = Object.keys(self.individualsToMerge()[0]);
    var removeKeys = [
      "id",
      "created_at",
      "updated_at",
      "custom_fields",
      "expo_id",
      "company_id",
      "is_expo_contact",
      "reply_data_row_id",
      "reply_data_code_hash",
    ];
    var arrayKeys = [
      "exhibitors_interested",
      "exhibitors_notinterested",
      "exhibitors_maybe",
      ,
      "exhibitors_clicked",
      "registration_ids",
      "expo_roles",
      "exhibitor_roles",
      "events_registered",
      "categories",
      "demographics",
      "keywords",
      "list_member",
      "expo_history",
    ];

    var keys = allKeys.filter(function (item) {
      return removeKeys.indexOf(item) === -1;
    });

    keys.map(function (value, index) {
      var data = {
        field: ko.observable(value),
        is_array: ko.observable(false),
      };

      data.first = {
        selected: ko.observable(true),
        data: ko.observable(first[value]),
      };
      data.second = {
        selected: ko.observable(false),
        data: ko.observable(second[value]),
      };
      data.merge = ko.observable(first[value]);
      if (!first[value] && second[value]) {
        data.first.selected(false);
        data.second.selected(true);
        data.merge(second[value]);
      }
      if (arrayKeys.indexOf(value) > -1) {
        data.is_array(true);
        data.first.selected(true);
        data.second.selected(true);
        var mergedArray = [];
        mergedArray = mergedArray.concat(first[value]);
        mergedArray = mergedArray.concat(second[value]);
        data.merge(mergedArray.unique());
      }
      self.fields.push(data);
    });

    //custom_fields
    var custom_fields = self.expo().customIndividualFields();
    custom_fields.map(function (value, index) {
      value = value.value;
      var data = {
        field: ko.observable("custom:" + value),
        is_array: ko.observable(false),
      };
      if (!first.custom_fields) {
        first.custom_fields = {};
      }
      if (!second.custom_fields) {
        second.custom_fields = {};
      }

      data.first = {
        selected: ko.observable(true),
        data: ko.observable(first.custom_fields[value] || ""),
      };
      data.second = {
        selected: ko.observable(false),
        data: ko.observable(second.custom_fields[value] || ""),
      };
      data.merge = ko.observable(first.custom_fields[value] || "");
      if (!first[value] && second[value]) {
        data.first.selected(false);
        data.second.selected(true);
        data.merge(second.custom_fields[value] || "");
      }

      self.fields.push(data);
    });

    let companyContacts = {};
    CompanyContactsApi.getContactsByIndividualId(first.id, (contacts) => {
      reformatContactsToKeyValue(contacts, companyContacts, true);
      CompanyContactsApi.getContactsByIndividualId(second.id, (contacts) => {
        reformatContactsToKeyValue(contacts, companyContacts);
        self.mergeCompanyContacts(companyContacts);
        self.showMergeView(true);
      });
    });
  };

  /*
  Reformat as:
  {
    "exh_id_1": {
      "company_id": "company_id",
      "first": {
        "contact_id": "contact_id",
        "exhibitor_roles": [...],
        "selected": true/false
      },
      "second": {
        "contact_id": "contact_id",
        "exhibitor_roles": [...],
        "selected": true/false
      },
      "merge": [if selected true then first.exhibitor_roles + second.exhibitor_roles]
    },
    "exh_id_2": {
      ...
    },
    ...
  }
  */
  const reformatContactsToKeyValue = (contacts, ret, first) => {
    contacts.map((contact) => {
      if (!ret[contact.company.exhibitor_id])
        ret[contact.company.exhibitor_id] = {
          company_id: contact.company_id,
          first: {
            selected: ko.observable(true),
            exhibitor_roles: ko.observableArray([]),
          },
          second: {
            selected: ko.observable(true),
            exhibitor_roles: ko.observableArray([]),
          },
          merge: ko.observableArray([]),
        };
      if (first) {
        ret[contact.company.exhibitor_id].first.exhibitor_roles(
          contact.exhibitor_roles
        );
        ret[contact.company.exhibitor_id].first.contact_id = contact.id;
      } else {
        ret[contact.company.exhibitor_id].second.exhibitor_roles(
          contact.exhibitor_roles
        );
        ret[contact.company.exhibitor_id].second.contact_id = contact.id;
      }
      ret[contact.company.exhibitor_id].merge([
        ...new Set([
          ...ret[contact.company.exhibitor_id].first.exhibitor_roles(),
          ...ret[contact.company.exhibitor_id].second.exhibitor_roles(),
        ]),
      ]);
    });
  };

  self.selectFirstValue = function (model) {
    var idx = self.fields().indexOf(model);
    if (idx > -1) {
      if (self.fields()[idx].is_array()) {
        self.fields()[idx].first.selected(!self.fields()[idx].first.selected());
        var mergedArray = [];
        if (self.fields()[idx].first.selected()) {
          mergedArray = mergedArray.concat(self.fields()[idx].first.data());
        }
        if (self.fields()[idx].second.selected()) {
          mergedArray = mergedArray.concat(self.fields()[idx].second.data());
        }
        self.fields()[idx].merge(mergedArray.unique());
      } else {
        self.fields()[idx].second.selected(false);
        self.fields()[idx].first.selected(true);
        self.fields()[idx].merge(self.fields()[idx].first.data());
      }
    }
  };
  self.selectSecondValue = function (model) {
    var idx = self.fields().indexOf(model);
    if (idx > -1) {
      if (self.fields()[idx].is_array()) {
        self
          .fields()
          [idx].second.selected(!self.fields()[idx].second.selected());
        var mergedArray = [];
        if (self.fields()[idx].first.selected()) {
          mergedArray = mergedArray.concat(self.fields()[idx].first.data());
        }
        if (self.fields()[idx].second.selected()) {
          mergedArray = mergedArray.concat(self.fields()[idx].second.data());
        }
        self.fields()[idx].merge(mergedArray.unique());
      } else {
        self.fields()[idx].first.selected(false);
        self.fields()[idx].second.selected(true);
        self.fields()[idx].merge(self.fields()[idx].second.data());
      }
    }
  };

  self.changeEmailSubscription = function (model) {
    IndividualsApi.changeSubscriptionStatus(model.id(), function () {
      utils.showSuccess(
        "Individual email subscription was successfully updated."
      );
      location.href =
        "/expo/individuals/view/?id=" +
        self.individual().id() +
        "&eid=" +
        self.individual().expo_id();
    });
  };

  self.changeExhibitorEmailSubscription = function (model) {
    IndividualsApi.changeExhibitorEmailsSubscriptionStatus(
      model.id(),
      function () {
        utils.showSuccess(
          "Individual email subscription was successfully updated."
        );
        location.href =
          "/expo/individuals/view/?id=" +
          self.individual().id() +
          "&eid=" +
          self.individual().expo_id();
      }
    );
  };

  self.selectFirstCompanyRoles = function (model) {
    self
      .mergeCompanyContacts()
      [model].first.selected(
        !self.mergeCompanyContacts()[model].first.selected()
      );
    let firstArray = [],
      secondArray = [];
    if (self.mergeCompanyContacts()[model].first.selected()) {
      firstArray = self.mergeCompanyContacts()[model].first.exhibitor_roles();
    }
    if (self.mergeCompanyContacts()[model].second.selected()) {
      secondArray = self.mergeCompanyContacts()[model].second.exhibitor_roles();
    }
    self
      .mergeCompanyContacts()
      [model].merge([...new Set([...firstArray, ...secondArray])]);
  };

  self.selectSecondCompanyRoles = function (model) {
    self
      .mergeCompanyContacts()
      [model].second.selected(
        !self.mergeCompanyContacts()[model].second.selected()
      );
    let firstArray = [],
      secondArray = [];
    if (self.mergeCompanyContacts()[model].first.selected()) {
      firstArray = self.mergeCompanyContacts()[model].first.exhibitor_roles();
    }
    if (self.mergeCompanyContacts()[model].second.selected()) {
      secondArray = self.mergeCompanyContacts()[model].second.exhibitor_roles();
    }
    self
      .mergeCompanyContacts()
      [model].merge([...new Set([...firstArray, ...secondArray])]);
  };

  self.goBackToSearch = function () {
    self.showMergeView(false);
    self.selectedIndividuals([]);
    self.individualsToMerge([]);
    self.individualIdsToMerge([]);
  };

  self.saveMergedIndividual = function () {
    var data = {
      custom_fields: {},
    };
    ko.utils.arrayForEach(self.fields(), function (item) {
      if (item.field().indexOf("custom:") < 0) {
        data[item.field()] = item.merge();
      } else {
        data.custom_fields[item.field().substr("custom:".length)] =
          item.merge();
      }
    });

    let contacts_to_update = [],
      contacts_to_delete = [];
    Object.values(self.mergeCompanyContacts()).map((item) => {
      if (item.merge().length === 0) {
        if (item.first.contact_id)
          contacts_to_delete.push(item.first.contact_id);
        if (item.second.contact_id)
          contacts_to_delete.push(item.second.contact_id);
      } else {
        if (item.first.contact_id) {
          contacts_to_update.push({
            id: item.first.contact_id,
            data: {
              company_id: item.company_id,
              individual_id: self.individualIdsToMerge()[0],
              exhibitor_roles: item.merge(),
            },
          });
          if (item.second.contact_id)
            contacts_to_delete.push(item.second.contact_id);
        } else {
          contacts_to_update.push({
            id: item.second.contact_id,
            data: {
              company_id: item.company_id,
              individual_id: self.individualIdsToMerge()[0],
              exhibitor_roles: item.merge(),
            },
          });
        }
      }
    });

    contacts_to_delete.forEach((id) => CompanyContactsApi.deleteContact(id));
    contacts_to_update.forEach((contact) =>
      CompanyContactsApi.updateContact(contact.id, contact.data)
    );

    IndividualsApi.update(self.individualIdsToMerge()[0], data, function () {
      IndividualsApi.delete(self.individualIdsToMerge()[1], function () {
        utils.showSuccess("Individuals were successfully merged.");
        self.showMergeView(false);
        self.selectedIndividuals([]);
        self.individualsToMerge([]);
        self.individualIdsToMerge([]);
      });
    });
  };

  self.showNotes = function () {
    self.notesModel(
      new NotesViewModel(self.individualId(), IndividualsApi, "individual")
    );
    $("#notesModal").modal("show");
  };

  self.init = function () {
    self.individual(new Exposync.IndividualModel({}));
    self.categoriesViewModel(new CategoriesViewModel());
    self.editCategoriesViewModel(new CategoriesViewModel());
    self.demographicsViewModel(new DemographicsViewModel());
    self.editDemographicsViewModel(new DemographicsViewModel());
    self.editDemographicsViewModel().selectableCategories =
      self.editDemographicsViewModel().selectableDemographics;

    self.countries(utils.getCountries());
    self.USStates(utils.getUSStates());

    for (var i = 1; i <= 5; i++) {
      self.otherExpoRoles.push({ val: ko.observable() });
    }
    for (var i = 1; i <= 5; i++) {
      self.otherCompanyRoles.push({ val: ko.observable() });
    }
  };

  self.init();
};

Array.prototype.unique = function () {
  var a = this.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }
  return a;
};
