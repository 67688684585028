var AnalyticsViewModel = function (messageId, messageName) {
  var self = this;

  self.expoId = ko.observable();
  self.messageId = ko.observable(messageId || "");
  self.messageName = ko.observable(messageName || "");

  self.browser_table_data = ko.observableArray([]);
  self.domain_table_data = ko.observableArray([]);
  self.device_table_data = ko.observableArray([]);

  self.currentViewData = ko.observableArray([]);
  self.viewOptions = ko.observableArray([
    {
      value: "click",
      label: "Clicked",
    },
    {
      value: "open",
      label: "Opened",
    },
    {
      value: "bounce",
      label: "Bounced",
    },
    {
      value: "links",
      label: "Links",
    },
  ]);
  self.currentView = ko.observable();

  self.getAnalytics = function () {
    var id = self.messageId();

    utils.showLoader("#analyticsModal .modal-body");

    /// DELIVERABILITY BY NUMBERS
    getDeliverabilityStats(id);

    /// DEVICE COMPARISON
    getDeviceStats(id);

    /// BROWSER COMPARISON
    getBrowserStats(id);

    /// DEVICE COMPARISON
    getDomainStats(id);
  };

  self.currentView.subscribe(function (value) {
    self.getEngagementAnalytics(value);
  });
  const tables = {
    clickTable: null,
    openTable: null,
    bounceTable: null,
    linksTable: null,
  };
  self.getEngagementAnalytics = function (value) {
    if (!value) return;
    self.currentViewData([]);
    utils.showLoader("#analyticsModal .modal-body");

    MessagesApi.getEngagementAnalytics(
      self.messageId(),
      value,
      function (data) {
        let table = tables[value + "Table"];
        const mapped = (data || []).map((row) => ({
          name:
            row.name_first || row.name_last
              ? (row.name_first || "") + " " + (row.name_last || "")
              : row.individual_id,
          email: row.email || "",
          individual_id: row.individual_id || "",
          timestamp: row.timestamp || "",
          url: row.url || "",
          clicks: row.clicks || 0,
        }));

        self.currentViewData(mapped);
        if (!table) {
          //utils.dataTableOptions.sDom = '<"top"f><"pull-right"i>rt<"bottom"lp><"clear">'
          var options = utils.dataTableOptions;
          options.searchDelay = 100;
          options.buttons = [
            {
              extend: "csvHtml5",
              text: "Export to CSV",
            },
          ];
          options.dom = "Bfrtip";
          var tableName = "#" + value + "-table";
          table = $(tableName).dataTable(options);
        }
        utils.hideLoader("#analyticsModal .modal-body");
      }
    );
  };
  function getDeliverabilityStats(id) {
    MessagesApi.getStats(id, function (data) {
      var chart_data_object = {
        processed: 0,
        delivered: 0,
        hard_bounce: 0,
        soft_bounce: 0,
        dropped: 0,
        spamreport: 0,
        open: 0,
        unique_opens: 0,
        click: 0,
        unique_clicks: 0,
      };

      var chart_data = [];
      data = ko.utils.arrayFilter(data, function (item) {
        return item._id !== "deferred";
      });

      ko.utils.arrayMap(data, function (item) {
        chart_data_object[item._id] = item.count;
      });

      Object.keys(chart_data_object).forEach(function (item) {
        chart_data.push([item, chart_data_object[item]]);
      });

      utils.hideLoader("#analyticsModal .modal-body");

      var chart = c3.generate({
        bindto: "#chart",
        data: {
          columns: chart_data,
          type: "bar",
          labels: true,
        },
        bar: { width: { ratio: 1 } },
      });
    });
  }

  function getDeviceStats(id) {
    MessagesApi.getCategorizedStats(id, "device", "open", function (data) {
      var chart_data = [];
      var chart_table_data = [];
      var total = 0;
      var index = 0;
      var remaining = 0;
      ko.utils.arrayMap(data, function (item) {
        if (item._id === null) item._id = "desktop";
        total += item.count;
        if (index > 4) {
          remaining += item.count;
        }
        index += 1;
      });
      index = 0;
      var finished = false;
      ko.utils.arrayMap(data, function (item) {
        if (index < 5) {
          chart_data.push([item._id, item.count]);
          chart_table_data.push({
            id: item._id,
            count: item.count,
            pct: ((item.count / total) * 100).toFixed(1) + "%",
          });
        } else {
          if (!finished) {
            chart_data.push(["remaining", remaining]);
            chart_table_data.push({
              id: "remaining",
              count: remaining,
              pct: ((remaining / total) * 100).toFixed(1) + "%",
            });
            finished = true;
          }
        }
        index += 1;
      });
      self.device_table_data(chart_table_data);

      var deviceStatsChart = c3.generate({
        bindto: "#deviceStatsChart",
        data: {
          columns: chart_data,
          type: "donut",
          onclick: function (d, i) {
            console.log("onclick", d, i);
          },
        },
        donut: {
          title: "Device Comparison",
        },
      });
    });
  }

  function getBrowserStats(id) {
    MessagesApi.getCategorizedStats(id, "browser", "click", function (data) {
      var chart_data = [];
      var chart_table_data = [];
      var total = 0;
      var index = 0;
      var remaining = 0;
      ko.utils.arrayMap(data, function (item) {
        if (item._id === null) item._id = "Other";
        if (item._id === "WebKit") item._id = "Mobile Browser";
        total += item.count;
        if (index > 4) {
          remaining += item.count;
        }
        index += 1;
      });

      index = 0;
      var finished = false;
      ko.utils.arrayMap(data, function (item) {
        if (index < 5) {
          chart_data.push([item._id, item.count]);
          chart_table_data.push({
            id: item._id,
            count: item.count,
            pct: ((item.count / total) * 100).toFixed(1) + "%",
          });
        } else {
          if (!finished) {
            chart_data.push(["remaining", remaining]);
            chart_table_data.push({
              id: "remaining",
              count: remaining,
              pct: ((remaining / total) * 100).toFixed(1) + "%",
            });
            finished = true;
          }
        }
        index += 1;
      });
      self.browser_table_data(chart_table_data);

      var deviceStatsChart = c3.generate({
        bindto: "#browserStatsChart",
        data: {
          columns: chart_data,
          type: "donut",
          onclick: function (d, i) {
            console.log("onclick", d, i);
          },
        },
        donut: {
          title: "Browser Comparison",
        },
      });
    });
  }

  function getDomainStats(id) {
    MessagesApi.getCategorizedStats(id, "domain", "open", function (data) {
      var chart_data = [];
      var chart_table_data = [];
      var total = 0;
      var index = 0;
      var remaining = 0;

      ko.utils.arrayMap(data, function (item) {
        if (item._id === null) item._id = "Other";
        total += item.count;
        if (index > 4) {
          remaining += item.count;
        }
        index += 1;
      });

      index = 0;
      var finished = false;
      ko.utils.arrayMap(data, function (item) {
        if (index < 5) {
          chart_data.push([item._id, item.count]);
          chart_table_data.push({
            id: item._id,
            count: item.count,
            pct: ((item.count / total) * 100).toFixed(1) + "%",
          });
        } else {
          if (!finished) {
            chart_data.push(["remaining", remaining]);
            chart_table_data.push({
              id: "remaining",
              count: remaining,
              pct: ((remaining / total) * 100).toFixed(1) + "%",
            });
            finished = true;
          }
        }
        index += 1;
      });
      self.domain_table_data(chart_table_data);

      var deviceStatsChart = c3.generate({
        bindto: "#domainStatsChart",
        data: {
          columns: chart_data,
          type: "donut",
          onclick: function (d, i) {
            console.log("onclick", d, i);
          },
        },
        donut: {
          title: "Top 5 Domains",
        },
      });
      utils.hideLoader("#analyticsModal .modal-body");
    });
  }

  function init() {
    self.getAnalytics();
  }

  init();
};

window.chartColors = {
  red: "rgb(255, 99, 132)",
  orange: "rgb(255, 159, 64)",
  yellow: "rgb(255, 205, 86)",
  green: "rgb(75, 192, 192)",
  blue: "rgb(54, 162, 235)",
  purple: "rgb(153, 102, 255)",
  grey: "rgb(201, 203, 207)",
};
