var DemographicsViewModel = function () {
  var self = this;
  self.error = ko.observable();
  self.nestableListSelector = ".dd";
  self.nestableListEditable = false;
  self.nestableItemsSelectable = false;
  self.selectedDemographics = ko.observableArray();
  self.selectedDemographicsLoaded = ko.observable(false);

  baseViewModel.call(self);

  self.demographics = function () {
    let demographics = [];
    if (self.expo()) {
      demographics = self.expo().demographics();
      return NestableList.toNestable(demographics);
    } else return [];
  };

  self.selectableDemographics = ko.computed(function () {
    let demographics = [];

    const selected = self.selectedDemographics();
    const selectedLoaded = self.selectedDemographicsLoaded();
    if (self.expo() && selectedLoaded) {
      demographics = self.expo().demographics();
      if (demographics && selected) {
        return NestableList.toNestable(demographics, selected);
      }
    } else return [];
  });

  self.updateExpo = function () {
    var list = NestableList.fromNestable(
      $(self.nestableListSelector).nestable("serialize")
    );
    var expo = self.expo();
    url = "/api/expo/" + expo.id();
    data = {};
    data.demographics = list;
    ajax.post(url, data, function (result) {
      utils.showSuccess("Successfully updated expo.");
      setTimeout(function () {
        location.href = "/expo/demographics/?" + expo.id();
      }, 2000);
    });
  };

  self.hasChildren = function (item) {
    if (item.children == undefined) return false;
    return true;
  };

  self.initNestable = function () {
    if (self.demographics().length > 0) {
      $(self.nestableListSelector).nestable({
        editable: self.nestableListEditable,
        itemsSelectable: self.nestableItemsSelectable,
      });
    }
  };

  self.initSelectableNestable = function () {
    if (self.demographics().length > 0 && self.selectedDemographicsLoaded()) {
      $(self.nestableListSelector).nestable({
        editable: self.nestableListEditable,
        itemsSelectable: self.nestableItemsSelectable,
      });
    }
  };

  self.editDemographics = function (model) {
    location.href = "/expo/demographics/edit/?" + model.expo().id();
  };

  self.cancelEdit = function (model) {
    location.href = "/expo/demographics/?" + model.expo().id();
  };

  self.init = function (id) {
    ExpoApi.get(id, function (data) {
      self.expo(data);
      self.initNestable();
      $(self.nestableListSelector).trigger("change");
    });
  };
};
