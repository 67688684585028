var LoginViewModel = function () {
  var self = this;
  self.email = ko.observable();
  self.password = ko.observable();
  self.confirmPassword = ko.observable();
  self.activationCode = ko.observable();
  self.resetCode = ko.observable();

  self.login = function () {
    if (!$(".form-login").valid()) {
      return false;
    }
    authenticator.login(self.email(), self.password());
  };

  self.forgotPassword = function () {
    if (!$(".form-forgot").valid()) {
      return false;
    }
    authenticator.forgotPassword(self.email());
  };

  self.completeRegistration = function () {
    if (!$(".form-complete").valid()) {
      return false;
    }
    authenticator.completeRegistration(
      self.email(),
      self.password(),
      self.activationCode()
    );
  };

  self.resetPassword = function (model) {
    if (!$("#resetPassword-form").valid()) {
      return false;
    }
    var data = {
      email: self.email(),
      code: self.resetCode(),
      new_password: self.password(),
    };
    ajax.post("/api/reset_password", data, function () {
      toastr.options.onHidden = function () {
        authenticator.logout();
      };
      utils.showSuccess(
        "You successfully resetted your password. Please login again, to use the new password."
      );
    });
  };
};
