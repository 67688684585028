var ExpoPackagesApi = {
  get: function (id, fnSuccess, fnError) {
    var url = "/api/expo/" + id + "/packages";
    ajax.get(url, fnSuccess, fnError);
  },
  update: function (expoId, data, fnSuccess) {
    var url = "/api/expo/" + expoId + "/packages";
    ajax.post(url, data, fnSuccess);
  },

  delete: function (id, fnSuccess) {
    var url = "/api/expo/" + id + "/packages";
    ajax.delete(url, {}, fnSuccess);
  },
};
