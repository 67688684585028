var BuyersGuideApi = {
  getExpo: function (id, fnSuccess) {
    var url = "/public_api/buyers_guide/expo/" + id;
    ajax.get(url, fnSuccess);
  },

  getIndividual: function (id, fnSuccess) {
    var url = "/public_api/buyers_guide/individual/" + id;
    ajax.get(url, fnSuccess);
  },

  updateIndividual: function (id, data, fnSuccess, fnError) {
    var url = "/public_api/buyers_guide/individual/" + id;
    ajax.post(url, data, fnSuccess, fnError);
  },

  getSelectedExhibitors: function (id, fnSuccess) {
    var url = "/public_api/buyers_guide/selected_exhibitors/" + id;
    ajax.get(url, fnSuccess);
  },
};
