var SystemReportsViewModel = function () {
  var self = this;

  self.reports = ko.observableArray([]);
  baseViewModel.call(self);

  self.report = ko.observable();

  self.expoId.subscribe(function (value) {
    var report = null;
    if (location.href.indexOf("account-summary") > -1) {
      report = new AccountSummaryReport({ expo_id: value });
    } else if (location.href.indexOf("category-count") > -1) {
      report = new CategoryCountReport({ expo_id: value });
    } else if (location.href.indexOf("demographics-count") > -1) {
      report = new DemographicsCountReport({ expo_id: value });
    } else if (location.href.indexOf("guide-insights") > -1) {
      report = new GuideInsightsReport({ expo_id: value });
    } else if (location.href.indexOf("email-stats") > -1) {
      report = new EmailStatsReport({ expo_id: value });
    } else if (location.href.indexOf("email-domain-stats") > -1) {
      report = new EmailDomainStatsReport({ expo_id: value });
    } else if (location.href.indexOf("leads") > -1) {
      report = new LeadsReport({ expo_id: value });
    }
    if (report) {
      $("#exposSelectionDropdown").show();
      report.run();
      self.report(report);
    }
  });

  self.viewReport = function (report) {
    location.href = report.link;
  };
  self.initReports = function () {
    if (!self.isInRole("organizer")) {
      self.reports.push({
        id: 1,
        name: "Account Summary",
        description: "Show client financial summary.",
        link: "/services/system-tools/reports/account-summary",
      });
    }
    self.reports.push({
      id: 2,
      name: "Category Count",
      description: "Expo Category Count.",
      link: "/services/system-tools/reports/category-count",
    });
    self.reports.push({
      id: 3,
      name: "Demographics Count",
      description: "Expo Demographics Count.",
      link: "/services/system-tools/reports/demographics-count",
    });
    self.reports.push({
      id: 4,
      name: "Guide Insights",
      description: "Guide Insights",
      link: "/services/system-tools/reports/guide-insights",
    });
    self.reports.push({
      id: 5,
      name: "Leads Report",
      description: "Leads Report",
      link: "/services/system-tools/reports/leads",
    });
    self.reports.push({
      id: 6,
      name: "Email Stats Report",
      description: "Email Stats Report",
      link: "/services/system-tools/reports/email-stats",
    });
    self.reports.push({
      id: 7,
      name: "Email Stats By Domain Report",
      description: "Email Stats By Domain Report",
      link: "/services/system-tools/reports/email-domain-stats",
    });
  };

  self.updateFinancials = function (client) {
    location.href =
      "/services/clients/financials/?id=" + client.id + "&eid=" + self.expoId();
  };

  function init() {}

  init();
};

var AccountSummaryReport = function (data) {
  var self = this;
  self.expoId = ko.observable(data.expo_id);
  self.clients = ko.observableArray([]);

  self.run = function () {
    var purchased,
      billed,
      paid,
      balance,
      unbilled_balance = 0;

    ReportsApi.getAccountSummary(self.expoId(), function (response) {
      var clients = ko.utils.arrayMap(response.data, function (item) {
        purchased = item.total_price ? item.total_price / 100 : 0;
        billed = item.billed_amount ? item.billed_amount / 100 : 0;
        paid = item.payment_amount ? item.payment_amount / 100 : 0;
        balance = paid - purchased;
        unbilled_balance = billed - purchased;

        return {
          id: item.client.id,
          name: item.client.exhibitor_name,
          purchased: ko.observable(purchased).money(),
          billed: ko.observable(billed).money(),
          paid: ko.observable(paid).money(),
          unbilled_balance: ko.observable(unbilled_balance).money(),
          balance: ko.observable(balance).money(),
        };
      });
      self.clients(clients);
      var options = utils.dataTableOptions;
      options.searchDelay = 100;
      options.buttons = [];
      options.dom = "Bfrtip";
      var table = $("#account_summary_table").DataTable(options);

      table.buttons().container().insertAfter("#account_summary_table_filter");
    });
  };
};

var exhibitorsTable;
var individualsTable;

function clientCompare(a, b) {
  if (a.name() < b.name()) return -1;
  if (a.name() > b.name()) return 1;
  return 0;
}

function listCompare(a, b) {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
}

var CategoryCountReport = function (data) {
  var self = this;
  self.expoId = ko.observable(data.expo_id);
  self.categories = ko.observableArray([]);
  self.view = ko.observable("none"); // exhibitor, individual, none
  self.exhibitors = ko.observableArray([]);
  self.clients = ko.observableArray([]);
  self.selectedClient = ko.observable();
  self.lists = ko.observableArray([]);
  self.selectedList = ko.observable();

  self.selectedClient.subscribe(function (client_id) {
    options.exhibitor_id = client_id;
    utils.showLoader("#search_list");
    self.getLists(options);
  });

  let table;

  let options = {
    q: "",
    p: 0,
    ps: 1000,
  };

  self.searchCategoryCount = function () {
    options.list_id = self.selectedList();

    self.categories([]);
    table.clear();
    table.destroy();

    utils.showLoader("#category_count_table");
    self.getCategories(options);
  };

  self.getCategories = function (options) {
    ReportsApi.getCategoryCount(self.expoId(), options, function (response) {
      self.categories(response);

      let tableOptions = utils.dataTableOptions;
      tableOptions.searchDelay = 100;
      tableOptions.buttons = [
        {
          extend: "csvHtml5",
          text: "Export to CSV",
        },
      ];
      tableOptions.dom = "Bfrtip";
      table = $("#category_count_table").DataTable(tableOptions);
      table.buttons().container().insertAfter("#category_count_table_filter");
      utils.hideLoader("#category_count_table");
    });
  };

  self.getAllClients = function (options) {
    options.status = "client";
    options.company_type = "exhibitor_company";
    ExhibitorsApi.find(self.expoId(), options, function (response) {
      var clients = $.map(response.data, function (item) {
        return new Exposync.ExhibitorModel(item);
      });
      self.clients(clients.sort(clientCompare));
    });
  };

  self.getLists = function (options, callback) {
    ListApi.find(self.expoId(), options, function (response) {
      var lists = response.data;

      if (lists.length > 0) {
        var ids = ko.utils.arrayMap(lists, function (item) {
          return item.id;
        });

        ListApi.getListsCount({ ids: ids }, function (data) {
          var counts = {};
          ko.utils.arrayForEach(data, function (item) {
            if (!counts[item.list_id]) counts[item.list_id] = {};
            counts[item.list_id][item.status] = item.total_count;
          });

          ko.utils.arrayForEach(lists, function (item) {
            item["approved"] =
              counts[item.id] !== undefined
                ? counts[item.id]["approved"] !== undefined
                  ? counts[item.id]["approved"]
                  : 0
                : 0;
            item["new_matches"] =
              counts[item.id] !== undefined
                ? counts[item.id]["new"] !== undefined
                  ? counts[item.id]["new"]
                  : 0
                : 0;
            item["omitted"] =
              counts[item.id] !== undefined
                ? counts[item.id]["omitted"] !== undefined
                  ? counts[item.id]["omitted"]
                  : 0
                : 0;
          });
          self.lists(lists.sort(listCompare));
          utils.hideLoader("#search_list");
        });
      } else {
        self.lists(lists.sort(listCompare));
        utils.hideLoader("#search_list");
      }
    });
  };

  self.run = function () {
    utils.showLoader("#category_count_table");
    self.getCategories(options);
    self.getAllClients(options);
    self.getLists(options);
  };

  self.viewExhibitors = function (category, company_type) {
    self.view("exhibitor");
    let options = {};
    options.category = category;
    options.company_type = company_type;
    if (self.selectedList()) options.list_id = self.selectedList();
    ExhibitorsApi.getExhibitorsByCategory(
      self.expoId(),
      options,
      function (data) {
        //self.exhibitors(data);
        if (!exhibitorsTable) {
          exhibitorsTable = $("#exhibitors-table").DataTable({
            data: data,
            info: true,
            paging: true,
            lengthChange: false,
            pageLength: 50,
            bAutoWidth: false,
            dom: '<"top"f><"pull-right"i>rt<"bottom"lp><"clear">',
            columns: [{ data: "exhibitor_name" }, { data: "categories" }],
          });
        } else {
          exhibitorsTable.clear();
          exhibitorsTable.rows.add(data).draw();
        }
      }
    );
  };

  self.viewIndividuals = function (category) {
    self.view("individual");
    IndividualsApi.getIndividualsByCategory(
      self.expoId(),
      category.category,
      function (data) {
        if (!individualsTable) {
          individualsTable = $("#individuals-table").DataTable({
            data: data,
            info: true,
            paging: true,
            lengthChange: false,
            pageLength: 50,
            bAutoWidth: false,
            dom: '<"top"f><"pull-right"i>rt<"bottom"lp><"clear">',
            columns: [
              { data: "name_first" },
              { data: "name_last" },
              { data: "categories" },
            ],
          });
        } else {
          individualsTable.clear();
          individualsTable.rows.add(data).draw();
        }
      }
    );
  };

  self.exhibitorsDataSource = function (options, callback) {
    options.company_type = "exhibitor_company";
    ExhibitorsApi.findAll(self.expoId(), options, function (data) {
      callback(data);
    });
  };

  self.exhibitorsTableRowCallback = function (row) {
    $(row).attr("style", "cursor: pointer");
  };

  self.individualsDataSource = function (options, callback) {
    IndividualsApi.find(self.expoId(), options, function (data) {
      callback(data);
    });
  };

  self.individualsTableRowCallback = function (row) {
    $(row).attr("style", "cursor: pointer");
  };
};

var DemographicsCountReport = function (data) {
  var self = this;
  self.expoId = ko.observable(data.expo_id);
  self.demographics = ko.observableArray([]);
  self.view = ko.observable("none"); // exhibitor, individual, none
  self.exhibitors = ko.observableArray([]);
  self.clients = ko.observableArray([]);
  self.selectedClient = ko.observable();
  self.lists = ko.observableArray([]);
  self.selectedList = ko.observable();

  self.selectedClient.subscribe(function (client_id) {
    options.exhibitor_id = client_id;
    utils.showLoader("#search_list");
    self.getLists(options);
  });

  let table;

  let options = {
    q: "",
    p: 0,
    ps: 1000,
  };

  self.searchDemographicsCount = function () {
    options.list_id = self.selectedList();

    self.demographics([]);
    table.clear();
    table.destroy();

    utils.showLoader("#demographics_count_table");
    self.getDemographics(options);
  };

  self.getDemographics = function (options) {
    ReportsApi.getDemographicsCount(
      self.expoId(),
      options,
      function (response) {
        self.demographics(response);

        let tableOptions = utils.dataTableOptions;
        tableOptions.searchDelay = 100;
        tableOptions.buttons = [
          {
            extend: "csvHtml5",
            text: "Export to CSV",
          },
        ];
        tableOptions.dom = "Bfrtip";
        table = $("#demographics_count_table").DataTable(tableOptions);
        table
          .buttons()
          .container()
          .insertAfter("#demographics_count_table_filter");

        utils.hideLoader("#demographics_count_table");
      }
    );
  };

  self.getAllClients = function (options) {
    options.status = "client";
    options.company_type = "exhibitor_company";
    ExhibitorsApi.find(self.expoId(), options, function (response) {
      var clients = $.map(response.data, function (item) {
        return new Exposync.ExhibitorModel(item);
      });
      self.clients(clients.sort(clientCompare));
    });
  };

  self.getLists = function (options, callback) {
    ListApi.find(self.expoId(), options, function (response) {
      var lists = response.data;

      if (lists.length > 0) {
        var ids = ko.utils.arrayMap(lists, function (item) {
          return item.id;
        });

        ListApi.getListsCount({ ids: ids }, function (data) {
          var counts = {};
          ko.utils.arrayForEach(data, function (item) {
            if (!counts[item.list_id]) counts[item.list_id] = {};
            counts[item.list_id][item.status] = item.total_count;
          });

          ko.utils.arrayForEach(lists, function (item) {
            item["approved"] =
              counts[item.id] !== undefined
                ? counts[item.id]["approved"] !== undefined
                  ? counts[item.id]["approved"]
                  : 0
                : 0;
            item["new_matches"] =
              counts[item.id] !== undefined
                ? counts[item.id]["new"] !== undefined
                  ? counts[item.id]["new"]
                  : 0
                : 0;
            item["omitted"] =
              counts[item.id] !== undefined
                ? counts[item.id]["omitted"] !== undefined
                  ? counts[item.id]["omitted"]
                  : 0
                : 0;
          });
          self.lists(lists.sort(listCompare));
          utils.hideLoader("#search_list");
        });
      } else {
        self.lists(lists.sort(listCompare));
        utils.hideLoader("#search_list");
      }
    });
  };

  self.run = function () {
    utils.showLoader("#demographics_count_table");
    self.getDemographics(options);
    self.getAllClients(options);
    self.getLists(options);
  };

  self.viewExhibitors = function (demographics, company_type) {
    self.view("exhibitor");
    let options = {};
    options.demographics = demographics;
    options.company_type = company_type;
    if (self.selectedList()) options.list_id = self.selectedList();
    ExhibitorsApi.getExhibitorsByDemographics(
      self.expoId(),
      options,
      function (data) {
        //self.exhibitors(data);
        if (!exhibitorsTable) {
          exhibitorsTable = $("#exhibitors-table").DataTable({
            data: data,
            info: true,
            paging: true,
            lengthChange: false,
            pageLength: 50,
            bAutoWidth: false,
            dom: '<"top"f><"pull-right"i>rt<"bottom"lp><"clear">',
            columns: [{ data: "exhibitor_name" }, { data: "demographics" }],
          });
        } else {
          exhibitorsTable.clear();
          exhibitorsTable.rows.add(data).draw();
        }
      }
    );
  };

  self.viewIndividuals = function (demographics) {
    self.view("individual");
    IndividualsApi.getIndividualsByDemographics(
      self.expoId(),
      demographics.demographics,
      function (data) {
        if (!individualsTable) {
          individualsTable = $("#individuals-table").DataTable({
            data: data,
            info: true,
            paging: true,
            lengthChange: false,
            pageLength: 50,
            bAutoWidth: false,
            dom: '<"top"f><"pull-right"i>rt<"bottom"lp><"clear">',
            columns: [
              { data: "name_first" },
              { data: "name_last" },
              { data: "demographics" },
            ],
          });
        } else {
          individualsTable.clear();
          individualsTable.rows.add(data).draw();
        }
      }
    );
  };

  self.exhibitorsDataSource = function (options, callback) {
    options.company_type = "exhibitor_company";
    ExhibitorsApi.findAll(self.expoId(), options, function (data) {
      callback(data);
    });
  };

  self.exhibitorsTableRowCallback = function (row) {
    $(row).attr("style", "cursor: pointer");
  };

  self.individualsDataSource = function (options, callback) {
    IndividualsApi.find(self.expoId(), options, function (data) {
      callback(data);
    });
  };

  self.individualsTableRowCallback = function (row) {
    $(row).attr("style", "cursor: pointer");
  };
};

var GuideInsightsReport = function (data) {
  var self = this;
  self.expoId = ko.observable(data.expo_id);
  self.prospects = ko.observableArray([]);
  self.exhibitors = ko.observableArray([]);
  self.interested = ko.observable(0);
  self.clicked = ko.observable(0);
  self.notinterested = ko.observable(0);
  self.maybe = ko.observable(0);

  self.run = function () {
    utils.showLoader("#guide_insights_table");

    ReportsApi.getGuideInsights(self.expoId(), function (data) {
      var all_exhibitors = {};
      var ids = [];
      var interested = 0,
        clicked = 0,
        notinterested = 0,
        maybe = 0;

      ko.utils.arrayForEach(data.interested, function (item) {
        all_exhibitors[item.id] = {
          exhibitor_name: item.exhibitor_name,
          nexus_status: item.nexus_status,
          interested: item.total,
          notinterested: 0,
          clicked: 0,
          maybe: 0,
        };
        interested += parseInt(item.total);
      });

      ko.utils.arrayForEach(data.notinterested, function (item) {
        if (all_exhibitors[item.id]) {
          all_exhibitors[item.id].notinterested = item.total;
        } else {
          all_exhibitors[item.id] = {
            exhibitor_name: item.exhibitor_name,
            nexus_status: item.nexus_status,
            interested: 0,
            notinterested: item.total,
            clicked: 0,
            maybe: 0,
          };
        }
        notinterested += parseInt(item.total);
      });

      ko.utils.arrayForEach(data.clicked, function (item) {
        if (all_exhibitors[item.id]) {
          all_exhibitors[item.id].clicked = item.total;
        } else {
          all_exhibitors[item.id] = {
            exhibitor_name: item.exhibitor_name,
            nexus_status: item.nexus_status,
            interested: 0,
            notinterested: 0,
            clicked: item.total,
            maybe: 0,
          };
        }
        clicked += parseInt(item.total);
      });

      ko.utils.arrayForEach(data.maybe, function (item) {
        if (all_exhibitors[item.id]) {
          all_exhibitors[item.id].maybe = item.total;
        } else {
          all_exhibitors[item.id] = {
            exhibitor_name: item.exhibitor_name,
            nexus_status: item.nexus_status,
            interested: 0,
            notinterested: 0,
            clicked: 0,
            maybe: item.total,
          };
        }
        maybe += parseInt(item.total);
      });

      var exhibitors = [];
      Object.keys(all_exhibitors).map(function (value, index) {
        exhibitors.push({
          id: value,
          exhibitor_name: all_exhibitors[value].exhibitor_name,
          nexus_status: all_exhibitors[value].nexus_status,
          interested: all_exhibitors[value].interested,
          notinterested: all_exhibitors[value].notinterested,
          clicked: all_exhibitors[value].clicked,
          maybe: all_exhibitors[value].maybe,
        });
      });
      self.exhibitors(exhibitors);
      self.interested(interested);
      self.notinterested(notinterested);
      self.clicked(clicked);
      self.maybe(maybe);

      var options = utils.dataTableOptions;
      options.searchDelay = 100;
      options.buttons = [
        {
          extend: "csvHtml5",
          text: "Export to CSV",
        },
      ];
      options.dom = "Bfrtip";
      var table = $("#guide_insights_table").DataTable(options);

      table.buttons().container().insertAfter("#guide_insights_table_filter");

      utils.hideLoader("#guide_insights_table");
    });
  };

  self.viewProspects = function (exhibitor) {
    $("#guideProspectsModal").modal("show");
    self.prospects([]);
    utils.showLoader($("#guide-prospects-table"));
    ReportsApi.guideProspects(exhibitor.id, function (data) {
      self.prospects(data);
      utils.hideLoader($("#guide-prospects-table"));
    });
  };

  self.downloadProspects = function (exhibitor) {
    window.open("/public_api/leads/download_prospect_report/" + exhibitor.id);
  };
  self.viewExhibitor = function (exhibitor) {
    window.open(
      "/services/clients/view/?id=" + exhibitor.id + "&eid=" + self.expoId(),
      "_blank"
    );
  };
};

var LeadsReport = function (data) {
  var self = this;
  self.expoId = ko.observable(data.expo_id);
  self.leads = ko.observableArray([]);

  self.run = function () {
    var purchased,
      billed,
      paid,
      balance,
      unbilled_balance = 0;

    ReportsApi.getLeads(self.expoId(), function (response) {
      self.leads(response.data);
      var options = utils.dataTableOptions;
      options.searchDelay = 100;
      options.buttons = [];
      options.dom = "Bfrtip";
      var table = $("#leads_table").DataTable(options);

      table.buttons().container().insertAfter("#leads_table_filter");
    });
  };
};

var EmailStatsReport = function (data) {
  var self = this;
  self.expoId = ko.observable(data.expo_id);
  self.emailStats = ko.observableArray([]);
  self.analyticsModel = ko.observable();

  var _options = utils.defaultQueryOptions;
  _options.ps = 100000;
  var tempStats = [];

  self.run = function () {
    utils.showLoader("#email_stats_table");
    ReportsApi.getExpoStats(self.expoId(), function (response) {
      var expo_stats = response;
      MessagesApi.find(self.expoId(), _options, function (response) {
        var messages = response.data;
        var entry = {};

        expo_stats.forEach(function (stat) {
          entry = tempStats.find((x) => x.message_id === stat.message_id);
          if (!entry) {
            entry = {
              client_id: 0,
              client: "",
              message_name: "",
              delivered: "",
              open_unique: 0,
              open_percent: "0%",
              open: 0,
              click_unique: 0,
              click_percent: "0%",
              click: 0,
              first: null,
              last: null,
            };
            entry.message_id = stat.message_id;
            entry[stat.event] = stat.count;
            entry[stat.event + "_unique"] = stat.uniqueCount;
            entry.first = stat.first;
            entry.last = stat.last;
            var message = messages.find((x) => x.id === stat.message_id);
            if (message) {
              entry.client_id = message.client.id;
              entry.client = message.client.exhibitor_name;
              entry.message_name = message.message_name;
              tempStats.push(entry);
            }
          } else {
            entry[stat.event] = stat.count;
            entry[stat.event + "_unique"] = stat.uniqueCount;
            if (stat.event == "delivered") {
              entry.first = stat.first;
              entry.last = stat.last;
            }
            entry.open_percent =
              ((entry.open_unique / entry.delivered) * 100).toFixed(2) + "%";
            entry.click_percent =
              ((entry.click_unique / entry.open_unique) * 100).toFixed(2) + "%";
          }
        });
        self.emailStats(tempStats);

        var options = utils.dataTableOptions;
        options.searchDelay = 100;
        options.buttons = [];
        options.dom = "Bfrtip";
        var table = $("#email_stats_table").DataTable(options);

        table.buttons().container().insertAfter("#email_stats_table_filter");
        utils.hideLoader("#email_stats_table");
      });
    });
  };

  self.showAnalytics = function (model) {
    self.analyticsModel(
      new AnalyticsViewModel(model.message_id, model.message_name)
    );
    $("#analyticsModal").modal("show");
  };
};

var EmailDomainStatsReport = function (data) {
  var self = this;
  self.expoId = ko.observable(data.expo_id);
  self.message_id = ko.observable("");
  self.campaigns = ko.observableArray([]);
  self.emailStats = ko.observableArray([]);

  self.message_id.subscribe(function (value) {
    getEmailDomainStats(value);
  });

  var table;

  const red = "#cd5749";
  const yellow = "#f0c052";
  const green = "#659e5a";

  var tableOptions = utils.dataTableOptions;
  tableOptions.order = [[1, "desc"]];
  tableOptions.rowCallback = function (row, data) {
    // delivered %
    if (data[3] > 90) {
      if (data[3] >= 96) {
        $("td:eq(3)", row).css("background-color", green);
        $("td:eq(3)", row).attr("style", function (i, s) {
          return (s || "") + "color: #FFF !important;";
        });
      } else $("td:eq(3)", row).css("background-color", yellow);
    } else {
      $("td:eq(3)", row).css("background-color", red);
      $("td:eq(3)", row).attr("style", function (i, s) {
        return (s || "") + "color: #FFF !important;";
      });
    }

    // unique open %
    // if (data[8] >= 5) {
    //   if (data[8] >= 10) {
    //     $('td:eq(8)', row).css('background-color', green);
    //     $('td:eq(8)', row).attr('style', function (i, s) { return (s || '') + 'color: #FFF !important;' });
    //   }
    //   else $('td:eq(8)', row).css('background-color', yellow);
    // }
    // else {
    //   $('td:eq(8)', row).css('background-color', red);
    //   $('td:eq(8)', row).attr('style', function (i, s) { return (s || '') + 'color: #FFF !important;' });
    // }

    // hard bounce %
    if (data[10] <= 4) {
      if (data[10] <= 2) {
        $("td:eq(10)", row).css("background-color", green);
        $("td:eq(10)", row).attr("style", function (i, s) {
          return (s || "") + "color: #FFF !important;";
        });
      } else $("td:eq(10)", row).css("background-color", yellow);
    } else {
      $("td:eq(10)", row).css("background-color", red);
      $("td:eq(10)", row).attr("style", function (i, s) {
        return (s || "") + "color: #FFF !important;";
      });
    }

    // soft bounce %
    if (data[12] <= 4) {
      if (data[12] <= 2) {
        $("td:eq(12)", row).css("background-color", green);
        $("td:eq(12)", row).attr("style", function (i, s) {
          return (s || "") + "color: #FFF !important;";
        });
      } else $("td:eq(12)", row).css("background-color", yellow);
    } else {
      $("td:eq(12)", row).css("background-color", red);
      $("td:eq(12)", row).attr("style", function (i, s) {
        return (s || "") + "color: #FFF !important;";
      });
    }
  };

  tableOptions.columnDefs = [
    { targets: [0], className: "text-left" },
    { targets: "_all", className: "text-right" },
  ];
  tableOptions.responsive = true;
  tableOptions.searchDelay = 100;
  tableOptions.buttons = [
    {
      extend: "csvHtml5",
      text: "Export to CSV",
    },
  ];
  tableOptions.dom = "Bfrtip";

  function getExpoMessages() {
    var options = {
      q: "",
      p: 0,
      ps: 1000,
    };
    MessagesApi.find(self.expoId(), options, function (response) {
      self.campaigns(response.data);
    });
  }

  function getEmailDomainStats(message_id) {
    utils.showLoader("#email_domain_stats_table");

    ReportsApi.emailDomainStats(
      self.expoId(),
      { message_id },
      function (response) {
        if (table) {
          table.clear();
          table.destroy();
        }

        tableOptions.columnDefs = [
          { targets: [0], className: "text-left" },
          { targets: "_all", className: "text-right" },
        ];
        tableOptions.responsive = true;
        tableOptions.searchDelay = 100;
        tableOptions.buttons = [
          {
            extend: "csvHtml5",
            text: "Export to CSV",
          },
        ];
        tableOptions.dom = "Bfrtip";

        self.emailStats(response);
        table = $("#email_domain_stats_table").DataTable(tableOptions);
        table
          .buttons()
          .container()
          .insertAfter("#email_domain_stats_table_filter");
        utils.hideLoader("#email_domain_stats_table");
      }
    );
  }

  self.run = function () {
    getExpoMessages();
  };
};
