var NotesViewModel = function (id, api, type = "exhibitor") {
  var self = this;
  self.viewNoteModel = ko.observable();
  self.editNoteModel = ko.observable();
  self.notes = ko.observableArray([]);
  self.error = ko.observable();

  self.expoId = ko.observable();
  self.ownerId = ko.observable(id || "");
  self.userId = ko.observable();

  self.mode = ko.observable("list"); //view/edit

  var notesTable;
  self.saveNote = function (model) {
    var data = model.toJSON();
    data.date = moment.utc().format("MM/DD/YYYY h:mm a");

    api.addNote(self.ownerId(), data, function (argument) {
      $("#notesModal").modal("hide");
    });
  };

  self.viewNote = function (argument) {
    // body...
  };

  self.editNote = function (argument) {
    // body...
  };

  self.newNote = function (argument) {
    // body...
    self.mode("edit");
    if (type === "individual") {
      self.editNoteModel(
        new Exposync.IndividualNoteDataModel({ individual_id: self.ownerId() })
      );
    } else {
      self.editNoteModel(
        new Exposync.NotesDataModel({ client_id: self.ownerId() })
      );
    }
  };

  self.cancelNewNote = function () {
    // body...
    self.mode("list");
  };

  self.loadNotes = function () {
    api.findNotes(
      self.ownerId(),
      utils.defaultQueryOptions,
      function (response) {
        self.notes(response.data);
        if (!notesTable) {
          utils.dataTableOptions.order = [[0, "desc"]];
          notesTable = $("#notes-table").dataTable(utils.dataTableOptions);
        }
      }
    );
  };

  self.selectNote = function (note) {
    // body...
    self.mode("view");
    self.viewNoteModel(note);
  };

  function init() {
    self.loadNotes();
  }

  init();
};
