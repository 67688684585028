var FormWizard = (function () {
  "use strict";
  var wizardContent = $("#wizard");
  var wizardForm = $("#deactivate-wizard-form");
  var numberOfSteps = $(".swMain > ul > li").length;
  var initWizard = function () {
    // function to initiate Wizard Form
    wizardContent.smartWizard({
      selected: 0,
      keyNavigation: false,
      onLeaveStep: leaveAStepCallback,
      onShowStep: onShowStep,
    });
    var numberOfSteps = 0;
    initValidator();
  };

  var initValidator = function () {
    $.validator.setDefaults({
      errorElement: "span", // contain the error msg in a span tag
      errorClass: "help-block",
      ignore: ":hidden",
      rules: {
        password: {
          minlength: 6,
          required: true,
        },
        confirm_password: {
          required: true,
          equalTo: "#password",
        },
      },
      messages: {
        firstName: "First Name is required",
        lastName: "Last Name is required",
      },
      highlight: function (element) {
        $(element).closest(".help-block").removeClass("valid");
        // display OK icon
        $(element)
          .closest(".form-group")
          .removeClass("has-success")
          .addClass("has-error")
          .find(".symbol")
          .removeClass("ok")
          .addClass("required");
        // add the Bootstrap error class to the control group
      },
      unhighlight: function (element) {
        // revert the change done by hightlight
        $(element).closest(".form-group").removeClass("has-error");
        // set error class to the control group
      },
      success: function (label, element) {
        label.addClass("help-block valid");
        // mark the current input as valid and display OK icon
        $(element)
          .closest(".form-group")
          .removeClass("has-error")
          .addClass("has-success")
          .find(".symbol")
          .removeClass("required")
          .addClass("ok");
      },
    });
  };
  var onShowStep = function (obj, context) {
    if (context.toStep == numberOfSteps) {
      $(".anchor")
        .children("li:nth-child(" + context.toStep + ")")
        .children("a")
        .removeClass("wait");
    }
    $(".next-step")
      .unbind("click")
      .click(function (e) {
        e.preventDefault();
        wizardContent.smartWizard("goForward");
      });
    $(".back-step")
      .unbind("click")
      .click(function (e) {
        e.preventDefault();
        wizardContent.smartWizard("goBackward");
      });
    $(".go-first")
      .unbind("click")
      .click(function (e) {
        e.preventDefault();
        wizardContent.smartWizard("goToStep", 1);
      });
    $(".finish-step")
      .unbind("click")
      .click(function (e) {
        e.preventDefault();
        onFinish(obj, context);
      });
  };
  var leaveAStepCallback = function (obj, context) {
    return validateSteps(context.fromStep, context.toStep);
    // return false to stay on step and true to continue navigation
  };
  var onFinish = function (obj, context) {
    if (validateAllSteps()) {
      alert("form submit function");
      $(".anchor")
        .children("li")
        .last()
        .children("a")
        .removeClass("wait")
        .removeClass("selected")
        .addClass("done")
        .children(".stepNumber")
        .addClass("animated tada");
      //wizardForm.submit();
    }
  };
  var validateSteps = function (stepnumber, nextstep) {
    var isStepValid = false;

    if (numberOfSteps >= nextstep && nextstep > stepnumber) {
      // cache the form element selector
      if (wizardForm.valid()) {
        // validate the form
        wizardForm.validate().focusInvalid();
        for (var i = stepnumber; i <= nextstep; i++) {
          $(".anchor")
            .children("li:nth-child(" + i + ")")
            .not("li:nth-child(" + nextstep + ")")
            .children("a")
            .removeClass("wait")
            .addClass("done")
            .children(".stepNumber")
            .addClass("animated tada");
        }
        //focus the invalid fields
        isStepValid = true;
        return true;
      }
    } else if (nextstep < stepnumber) {
      for (i = nextstep; i <= stepnumber; i++) {
        $(".anchor")
          .children("li:nth-child(" + i + ")")
          .children("a")
          .addClass("wait")
          .children(".stepNumber")
          .removeClass("animated tada");
      }

      return true;
    }
  };
  var validateAllSteps = function () {
    var isStepValid = true;
    // all step validation logic
    return isStepValid;
  };
  return {
    init: function () {
      initWizard();
    },
  };
})();
