var ClientFinancialsViewModel = function () {
  var self = this;
  self.expoPackages = ko.observable();
  self.clientFinancials = ko.observable();
  baseViewModel.call(self);
  baseClientsViewModel.call(self);

  self.updated_payment_status = ko.observable(false);
  self.total_amount = ko.observable(0).money();
  self.updated_payment_amount = ko.observable(0).money();
  self.updated_billed_amount = ko.observable(0).money();

  self.updated_contract_status = ko.observable(false);

  self.expo_packages_set = ko.observable(true);
  self.client_financials_set = ko.observable(false);

  this.updated_payment_status.ForEditing = ko.computed({
    read: function () {
      return this.updated_payment_status().toString();
    },
    write: function (newValue) {
      this.updated_payment_status(newValue === "true");
    },
    owner: this,
  });

  this.updated_contract_status.ForEditing = ko.computed({
    read: function () {
      return this.updated_contract_status().toString();
    },
    write: function (newValue) {
      this.updated_contract_status(newValue === "true");
    },
    owner: this,
  });

  self.purchasedPackages = ko.computed(function () {
    var expoPackages = self.expoPackages();
    var clientFinancials = self.clientFinancials();
    if (expoPackages && clientFinancials) {
      if (clientFinancials.packages) {
        var packages = $.map(
          clientFinancials.packages(),
          function (value, item) {
            return { key: item, quantity: value };
          }
        );
        var purchased = ko.utils.arrayFilter(packages, function (item) {
          return item.quantity > 0;
        });

        ko.utils.arrayForEach(purchased, function (item) {
          item.package_name = expoPackages[item.key + "_package_name"]();
        });
        return purchased;
      }
    }
    return [];
  });

  self.editFinancials = function () {
    location.href =
      "/services/clients/financials/edit/?id=" +
      self.clientId() +
      "&eid=" +
      self.expoId();
  };

  self.cancelEdit = function () {
    location.href =
      "/services/clients/financials/?id=" +
      self.clientId() +
      "&eid=" +
      self.expoId();
  };

  self.getExpoPackages = function (fnSuccess) {
    ExpoPackagesApi.get(
      self.expoId(),
      function (data) {
        self.expo_packages_set(true);
        self.expoPackages(new Exposync.ExpoPackagesModel(data));
        if (typeof fnSuccess === "function") {
          fnSuccess(data);
        }
      },
      function (req, status, err) {
        if (req.status == 404) {
          self.expo_packages_set(false);
        }
      }
    );
  };

  self.updatePaymentInformation = function () {
    var data = {};
    data.payment_status = self.updated_payment_status();
    data.contract_signed = self.updated_contract_status();
    data.payment_amount = self.updated_payment_amount() * 100;
    data.billed_amount = self.updated_billed_amount() * 100;

    ExhibitorsApi.updateFinancials(self.clientId(), data, function (response) {
      utils.showSuccess("Payment Information has been successfully updated.");
      self.getCompanyFinancials();
    });
  };

  self.getCompanyFinancials = function () {
    ExhibitorsApi.getFinancials(
      self.clientId(),
      function (data) {
        self.client_financials_set(true);
        self.clientFinancials(new Exposync.CompanyFinancialsModel(data));
        var total,
          billed,
          paid = 0;
        total = data.total_price ? data.total_price / 100 : 0;
        billed = data.billed_amount ? data.billed_amount / 100 : 0;
        paid = data.payment_amount ? data.payment_amount / 100 : 0;

        self.updated_payment_status(data.payment_status);
        self.updated_payment_amount(paid);
        self.updated_billed_amount(billed);
        self.total_amount(total);

        self.updated_contract_status(data.contract_signed);

        if (data.packages && self.expoPackages()) {
          self.expoPackages().email1_quantity(data.packages.email1 || 0);
          self.expoPackages().email2_quantity(data.packages.email2 || 0);
          self.expoPackages().email3_quantity(data.packages.email3 || 0);
          self.expoPackages().email4_quantity(data.packages.email4 || 0);
          self.expoPackages().email5_quantity(data.packages.email5 || 0);

          // self.expoPackages().text1_quantity(data.packages.text1 || 0);
          // self.expoPackages().text2_quantity(data.packages.text2 || 0);
          // self.expoPackages().text3_quantity(data.packages.text3 || 0);
          // self.expoPackages().text4_quantity(data.packages.text4 || 0);
          // self.expoPackages().text5_quantity(data.packages.text5 || 0);

          self.expoPackages().list_quantity(data.packages.list || 0);
          self.expoPackages().image_quantity(data.packages.image || 0);

          self
            .expoPackages()
            .marketing_template_quantity(data.packages.marketing_template || 0);
          self
            .expoPackages()
            .marketing_message_quantity(data.packages.marketing_message || 0);

          // self.expoPackages().text_template_quantity(data.packages.text_template || 0);
          // self.expoPackages().text_message_quantity(data.packages.text_message || 0);
        }
      },
      function (req, status, err) {
        if (req.status == 404) {
          self.client_financials_set(false);
        }
      }
    );
  };

  self.updateFinancials = function () {
    var expoPackages = self.expoPackages();
    var packages = {};
    var data = {};
    packages.email1 = expoPackages.email1_quantity();
    packages.email2 = expoPackages.email2_quantity();
    packages.email3 = expoPackages.email3_quantity();
    packages.email4 = expoPackages.email4_quantity();
    packages.email5 = expoPackages.email5_quantity();
    // packages.text1 = expoPackages.text1_quantity()
    // packages.text2 = expoPackages.text2_quantity()
    // packages.text3 = expoPackages.text3_quantity()
    // packages.text4 = expoPackages.text4_quantity()
    // packages.text5 = expoPackages.text5_quantity()

    packages.list = expoPackages.list_quantity();
    packages.image = expoPackages.image_quantity();
    packages.marketing_template = expoPackages.marketing_template_quantity();
    packages.marketing_message = expoPackages.marketing_message_quantity();
    // packages.text_template = expoPackages.text_template_quantity()
    // packages.text_message = expoPackages.text_message_quantity()

    data.packages = packages;

    ExhibitorsApi.updateFinancials(self.clientId(), data, function (response) {
      utils.showSuccess("Client financials were updated successfully.");
      setTimeout(function () {
        location.href =
          "/services/clients/financials/?id=" +
          self.clientId() +
          "&eid=" +
          self.expoId();
      }, 2000);
    });
  };
};
