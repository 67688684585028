var ExpoSetupViewModel = function () {
  var self = this;
  self.uploadProgress = ko.observable(0);
  self.uploadedGuideImage = ko.observable();
  self.uploadedHomeScreenIcon = ko.observable();
  self.expoPackages = ko.observable();
  self.expoPackagesExist = ko.observable(true);
  self.error = ko.observable();
  self.image_to_delete = ko.observable();

  self.guideSettingsModel = ko.observable();
  self.webhookModel = ko.observable(new Exposync.WebhookDataModel({}));
  baseViewModel.call(self);

  self.getExpo = function (id) {
    if (!id) return;
    ExpoApi.get(id, function (expo) {
      self.expo(expo);
    });
  };

  self.uploadImages = function () {
    $.blockUI({ baseZ: 9999, message: $("#upload-message") });
    var filenames = [];
    var expo_images = self.expo().expo_images();
    var file = document.getElementById("imagesUpload").files[0];
    var files = document.getElementById("imagesUpload").files;

    if (file != undefined && file != null) {
      UploadsApi.uploadMultiple(
        files,
        function (name) {
          expo_images.push(name);
          filenames.push(name);
          if (filenames.length == files.length) {
            $.unblockUI();
            var data = {};
            data.expo_images = expo_images;
            ExpoApi.update(self.expoId(), data, function () {
              utils.showSuccess("Expo was successfully updated.");
              self.expo().expo_images(expo_images);
              $("#uploadDocumentModal").modal("hide");
            });
          }
        },
        function (msg) {
          $.unblockUI();
          if (filenames.length > 0) {
            var data = {};
            data.expo_images = expo_images;
            ExpoApi.update(self.expoId(), data, function () {
              utils.showSuccess("Expo was successfully updated.");
            });
          }
          utils.showError(msg);
        },
        function (progress) {
          self.uploadProgress(Math.round(progress * 100));
        }
      );
    } else {
      utils.showError(
        "Please select a photo before clicking the upload button."
      );
      $.unblockUI();
    }
  };

  self.removeImageConfirmModal = function (image) {
    self.image_to_delete(image);
    $("#confirmRemoveImageModal").modal("show");
  };

  self.removeImage = function () {
    var image = self.image_to_delete();

    var images = ko.utils.arrayFilter(
      self.expo().expo_images(),
      function (item) {
        return image !== item;
      }
    );
    var data = {};
    data.expo_images = images;

    ExpoApi.update(self.expoId(), data, function (data) {
      utils.showSuccess("Image has been successfully deleted.");
      self.expo().expo_images(images);
      $("#confirmRemoveImageModal").modal("hide");
    });
  };
  self.uploadExhibitorImages = function () {
    var filenames = [];
    var expo_images = self.expo().expo_images();
    var files = document.getElementById("exhibitorImagesUpload").files;
    var length = files.length;
    var processed = 0;
    $.blockUI({ baseZ: 9999, message: $("#upload-message") });
    var url = null;

    for (var i = 0; i < length; i++) {
      var filename = files[i].name;
      $.blockUI({ baseZ: 9999, message: $("#upload-message") });

      (function (i, filename) {
        UploadsApi.upload(
          files[i],
          filename,
          function () {
            var exhibitor_id = files[i].name.split(".")[0];
            var url =
              "/api/companies/get_by_exhibitor_id/" +
              exhibitor_id +
              "/" +
              self.expoId();

            $.ajax({
              type: "GET",
              cache: false,
              url: url,
            }).then(
              function (data) {
                var client = new Exposync.CompanyModel(data);
                (function (client) {
                  var obj = {};
                  obj.photos = $.map(client.photos(), function (item) {
                    return item.link();
                  });
                  obj.photos.push(filename);
                  obj.company_logo =
                    "https://s3.amazonaws.com/exposync-uploads/" + filename;
                  ExhibitorsApi.update(client.id(), obj, function () {
                    processed += 1;
                    if (processed == files.length) {
                      $.unblockUI();
                      $("#uploadExhibitorImagesModal").modal("hide");
                    }
                  });
                })(client);
              },
              function (req, status, err) {
                processed += 1;
                if (processed == files.length) {
                  $.unblockUI();
                  $("#uploadExhibitorImagesModal").modal("hide");
                }
              }
            );
          },
          function (msg) {
            $.unblockUI();
            utils.showError(msg);
          },
          function (progress) {
            self.uploadProgress(Math.round(progress * 100));
          }
        );
      })(i, filename);
    }
  };

  self.uploadEventImages = function () {
    var files = document.getElementById("eventImagesUpload").files;
    var length = files.length;
    var processed = 0;
    $.blockUI({ baseZ: 9999, message: $("#upload-message") });
    var url = null;

    for (var i = 0; i < length; i++) {
      var filename = files[i].name;
      $.blockUI({ baseZ: 9999, message: $("#upload-message") });

      (function (i, filename) {
        UploadsApi.upload(
          files[i],
          filename,
          function () {
            var event_id = files[i].name.split(".")[0];
            var url =
              "/api/events/get_by_event_id/" + event_id + "/" + self.expoId();

            $.ajax({
              type: "GET",
              cache: false,
              url: url,
            }).then(
              function (data) {
                var event = new Exposync.CompanyModel(data);
                (function (event) {
                  var obj = {};
                  obj.event_logo_url =
                    "https://s3.amazonaws.com/exposync-uploads/" + filename;
                  EventsApi.update(event.id(), obj, function () {
                    processed += 1;
                    if (processed == files.length) {
                      $.unblockUI();
                      $("#uploadEventImagesModal").modal("hide");
                    }
                  });
                })(event);
              },
              function (req, status, err) {
                processed += 1;
                if (processed == files.length) {
                  $.unblockUI();
                  $("#uploadEventImagesModal").modal("hide");
                }
              }
            );
          },
          function (msg) {
            $.unblockUI();
            utils.showError(msg);
          },
          function (progress) {
            self.uploadProgress(Math.round(progress * 100));
          }
        );
      })(i, filename);
    }
  };

  self.uploadGuideImage = function () {
    $.blockUI({ baseZ: 9999, message: $("#upload-message") });

    var file = document.getElementById("upload-guide-image-file").files[0];
    if (file != undefined && file != null) {
      var name =
        "expo_" + Math.floor(1000000000 * Math.random()) + "_" + file.name;
      UploadsApi.upload(
        file,
        name,
        function () {
          $.unblockUI();
          self.uploadedGuideImage(name);
        },
        function (msg) {
          $.unblockUI();
          utils.showError(msg);
        },
        function (progress) {
          self.uploadProgress(Math.round(progress * 100));
        }
      );
    } else {
      utils.showError(
        "Please select an image before clicking the upload button."
      );
      $.unblockUI();
    }
  };

  self.updateGuideImage = function () {
    var expoId = self.expo().id();
    var data = {};
    data.expo_default_guide_image_url = self.uploadedGuideImage();
    ExpoApi.update(expoId, data, function () {
      self
        .expo()
        .expo_default_guide_image_url(data.expo_default_guide_image_url);
      self.uploadedGuideImage("");
      $("#guideImageModal").modal("hide");
    });
  };

  self.uploadHomeScreenIcon = function () {
    $.blockUI({ baseZ: 9999, message: $("#upload-message") });

    var file = document.getElementById("upload-home-screen-icon").files[0];
    if (file != undefined && file != null) {
      var name =
        "expo_" + Math.floor(1000000000 * Math.random()) + "_" + file.name;
      UploadsApi.upload(
        file,
        name,
        function () {
          $.unblockUI();
          self.uploadedHomeScreenIcon(name);
        },
        function (msg) {
          $.unblockUI();
          utils.showError(msg);
        },
        function (progress) {
          self.uploadProgress(Math.round(progress * 100));
        }
      );
    } else {
      utils.showError(
        "Please select an image before clicking the upload button."
      );
      $.unblockUI();
    }
  };

  self.updateHomeScreenIcon = function () {
    var expoId = self.expo().id();
    var data = {};
    data.home_screen_icon_url = self.uploadedHomeScreenIcon();
    ExpoApi.update(expoId, data, function () {
      self.expo().home_screen_icon_url(data.home_screen_icon_url);
      self.uploadedHomeScreenIcon("");
      $("#expoGuideIconModal").modal("hide");
    });
  };

  self.getExpoPackages = function () {
    ExpoPackagesApi.get(
      self.expoId(),
      function (data) {
        self.expoPackages(new Exposync.ExpoPackagesModel(data));
      },
      function (req, status, err) {
        if (req.status == 404) {
          self.expoPackages(new Exposync.ExpoPackagesModel({}));
          self.expoPackagesExist(false);
        }
      }
    );
  };

  self.updateExpoPackages = function () {
    var data = self.expoPackages().toJSON();
    ExpoPackagesApi.update(self.expoId(), data, function (response) {
      const updated_record = {
        sending_email_prefix: self.expo().sending_email_prefix(),
        sending_email_account: self.expo().sending_email_account()
          ? self.expo().sending_email_account()
          : null,
      };
      ExpoApi.update(self.expoId(), updated_record, function (response) {
        utils.showSuccess("Message Rules were successfully updated.");
      });
    });
  };

  self.loadGuideSettings = function () {
    self.guideSettingsModel(
      new GuideSettingsViewModel(self.expoId(), self.expo())
    );
    $("#guideSettingsModal").modal("show");
  };

  self.loadExpoNotes = function () {
    $("#expoNotesModal").modal("show");
  };

  self.saveExpoNotes = function () {
    var data = {};
    data.notes = self.expo().notes();
    ExpoApi.update(self.expoId(), data, function () {
      utils.showSuccess("Expo was successfully updated.");
      $("#expoNotesModal").modal("hide");
    });
  };

  self.webhooksDataSource = function (options, callback) {
    ExpoApi.findWebhooks(self.expoId(), options, function (data) {
      callback(data);
    });
  };

  self.webhooksTableRowCallback = function (row) {
    $(row).attr("style", "cursor: pointer");
  };

  self.webhookUpdateModal = function (model) {
    if (model.id) self.webhookModel(new Exposync.WebhookDataModel(model));
    else
      self.webhookModel(
        new Exposync.WebhookDataModel({ expo_id: self.expoId() })
      );
    $("#webhookModal").modal("show");
  };

  self.saveWebhook = function (webhook) {
    var model = self.webhookModel();
    var data = model.toJSON();
    if (model.id()) {
      ExpoApi.updateWebhook(model.id(), data, function () {
        $("#webhookModal").modal("hide");
      });
    } else {
      ExpoApi.createWebhook(self.expoId(), data, function () {
        $("#webhookModal").modal("hide");
      });
    }
  };
  self.init = function () {
    self.expo(new Exposync.ExpoModel({}));
  };
  self.init();
};
