var UploadsApi = {
  getContentType: function (filename) {
    var extension = "";

    try {
      if (typeof filename === "string" || filename instanceof String) {
        extension = filename.split(".").pop().toLowerCase();
      } else if (typeof filename === "object" || filename instanceof Object) {
        extension = filename.name.split(".").pop().toLowerCase();
      } else {
        return "binary/octet-stream";
      }

      if (extension == "jpg" || extension == "jpeg") {
        return "image/jpeg";
      } else if (
        extension == "png" ||
        extension == "bmp" ||
        extension == "gif" ||
        extension == "tiff"
      ) {
        return "image/" + extension;
      } else if (extension == "pdf" || extension == "zip") {
        return "application/" + extension;
      } else {
        return "binary/octet-stream";
      }
    } catch (e) {
      return "binary/octet-stream";
    }
  },

  uploadPrivate: function (file, name, fnSuccess, fnError, fnProgress) {
    var _e_ = new Evaporate({
      signerUrl: "/api/sign_auth",
      aws_key: "AKIAJ7QRP6P4IGPOIPJA",
      bucket: "exposync-uploads",
      logging: false,
    });

    if (file) {
      _e_.add({
        name: name,
        file: file,
        notSignedHeadersAtInitiate: {
          "Cache-Control": "max-age=3600",
        },
        xAmzHeadersAtInitiate: {
          "x-amz-acl": "private",
        },
        beforeSigner: function (xhr) {
          var requestDate = new Date().toISOString();
          xhr.setRequestHeader("Request-Header", requestDate);
        },
        complete: function (data) {
          fnSuccess();
        },
        error: function (msg) {
          fnError(msg);
        },
        progress: function (progress) {
          if (typeof fnProgress === "function") {
            fnProgress(progress);
          }
        },
      });
    }
  },

  upload: function (file, name, fnSuccess, fnError, fnProgress) {
    var _e_ = new Evaporate({
      signerUrl: "/api/sign_auth",
      aws_key: "AKIAJ7QRP6P4IGPOIPJA",
      bucket: "exposync-uploads",
      logging: false,
    });

    var contentType = this.getContentType(file);

    if (file) {
      _e_.add({
        name: name,
        file: file,
        notSignedHeadersAtInitiate: {
          "Cache-Control": "max-age=0",
        },
        xAmzHeadersAtInitiate: {
          "x-amz-acl": "public-read",
        },
        contentType: contentType,
        beforeSigner: function (xhr) {
          var requestDate = new Date().toISOString();
          xhr.setRequestHeader("Request-Header", requestDate);
        },
        complete: function (data) {
          fnSuccess();
        },
        error: function (msg) {
          fnError(msg);
        },
        progress: function (progress) {
          if (typeof fnProgress === "function") {
            fnProgress(progress);
          }
        },
      });
    }
  },

  publicUpload: function (file, name, fnSuccess, fnError, fnProgress) {
    var _e_ = new Evaporate({
      signerUrl: "/public_api/sign_auth",
      aws_key: "AKIAJ7QRP6P4IGPOIPJA",
      bucket: "exposync-uploads",
      logging: false,
    });

    var contentType = this.getContentType(file);

    if (file) {
      _e_.add({
        name: name,
        file: file,
        notSignedHeadersAtInitiate: {
          "Cache-Control": "max-age=0",
        },
        xAmzHeadersAtInitiate: {
          "x-amz-acl": "public-read",
        },
        contentType: contentType,
        beforeSigner: function (xhr) {
          var requestDate = new Date().toISOString();
          xhr.setRequestHeader("Request-Header", requestDate);
        },
        complete: function (data) {
          fnSuccess();
        },
        error: function (msg) {
          fnError(msg);
        },
        progress: function (progress) {
          if (typeof fnProgress === "function") {
            fnProgress(progress);
          }
        },
      });
    }
  },

  uploadMultiple: function (files, fnSuccess, fnError, fnProgress, fnComplete) {
    var _e_ = new Evaporate({
      signerUrl: "/api/sign_auth",
      aws_key: "AKIAJ7QRP6P4IGPOIPJA",
      bucket: "exposync-uploads",
      logging: false,
    });

    for (var i = 0; i < files.length; i++) {
      var filename = null;
      var contentType = this.getContentType(files[i].name);

      filename =
        "uploads_" + Math.floor(100000 * Math.random()) + "_" + files[i].name;
      _e_.add({
        name: filename,
        file: files[i],
        notSignedHeadersAtInitiate: {
          "Cache-Control": "max-age=0",
        },
        xAmzHeadersAtInitiate: {
          "x-amz-acl": "public-read",
        },
        contentType: contentType,
        beforeSigner: function (xhr) {
          var requestDate = new Date().toISOString();
          xhr.setRequestHeader("Request-Header", requestDate);
        },
        complete: function (data) {
          (xmlDoc = $.parseXML(data.responseText)),
            ($xml = $(xmlDoc)),
            ($title = $xml.find("Key"));
          fnSuccess($title[0].innerHTML);
        },
        error: function (msg) {
          fnError(msg);
        },
        progress: function (progress) {
          if (typeof fnProgress === "function") {
            fnProgress(progress);
          }
        },
      });
    }
  },
};
