var BuyersGuideViewModel = function () {
  var self = this;

  self.individualId = ko.observable();
  self.individual = ko.observable();
  self.expoId = ko.observable();
  self.expo = ko.observable();
  self.categories = ko.observableArray([]);
  self.keywords = ko.observableArray([]);
  self.selectedCategories = ko.observableArray([]);
  self.exhibitors = ko.observableArray();
  self.all_exhibitors = ko.observableArray([]);
  self.exhibitors_clicked = ko.observableArray([]);
  self.exhibitors_interested = ko.observableArray([]);
  self.exhibitors_maybe = ko.observableArray([]);
  self.exhibitors_notinterested = ko.observableArray([]);
  self.sortedBy = ko.observable("booth_number");
  self.showExcluded = ko.observable(false);
  self.error = ko.observable();
  self.printing = ko.observable(false);
  self.loading = ko.observable(false);
  self.page = ko.observable(1);
  self.selectedExhibitors = ko.observableArray([]);
  self.excludedExhibitors = ko.observableArray([]);
  self.isAuthenticated = ko.observable(false);
  self.submitted = ko.observable(false);
  self.isPrintingEnabled = ko.observable(false);

  self.tree = $("#categories_tree").delegate(
    "a",
    "click",
    function (event, data) {
      $.jstree.reference(this).open_all(this, false, false);
      $(event.target).find(".jstree-checkbox").click();
    }
  );

  self.tree.on("changed.jstree", function (e, data) {
    var selectedNodes = self.tree.jstree().get_selected();
    self.selectedCategories(selectedNodes);
  });

  self.sortedByText = ko.computed(function () {
    if (self.sortedBy() == "alphabetical")
      return "Sorted in alphabetical order.";
    else return "Sorted by booth location/number.";
  });

  self.loadMoreExhibitors = function () {
    self.page(self.page() + 1);
  };

  self.setMaximumPageNumber = function () {
    var exhibitors = self.exhibitors();

    var filtered = ko.utils.arrayFilter(exhibitors, function (item) {
      return item.interest() != "notinterested";
    });
    var max = Math.ceil(filtered.length / 25);
    self.page(max);
  };

  ko.computed(function () {
    // body...
    var p = self.page();
    var exhibitors = self.exhibitors();

    var filtered = ko.utils.arrayFilter(exhibitors, function (item) {
      return item.interest() != "notinterested";
    });

    self.selectedExhibitors(filtered.slice(0, 25 * p));
    self.all_exhibitors(filtered);

    self.excludedExhibitors(
      ko.utils.arrayFilter(self.exhibitors(), function (item) {
        return item.interest() == "notinterested";
      })
    );
  });

  ko.computed(function () {
    var sortedBy = self.sortedBy();
    if (sortedBy == "alphabetical")
      self.exhibitors(self.exhibitors().sort(exhibitorNameCompare));
    if (sortedBy == "booth_number")
      self.exhibitors(self.exhibitors().sort(exhibitorBoothNumberCompare));
  });

  ko.computed(function () {
    if (self.individual()) {
      var keywords = ko.utils.arrayMap(
        self.individual().keywords(),
        function (item) {
          return ko.observable(item);
        }
      );

      if (keywords.length < 11) {
        for (var i = keywords.length; i < 10; i++) {
          keywords.push(ko.observable(""));
        }
      }
      self.keywords(keywords);
    } else return self.keywords([]);
  });

  self.categoriesTree = ko.computed(function () {
    if (self.individual()) {
      return NestableList.toTreeFormat(
        self.individual().categories(),
        self.individual().categories()
      );
    } else return [];
  });

  self.editGuide = function () {
    location.href = "/bg/rd/?" + self.individualId();
  };

  self.viewUserTips = function () {
    $("#userTipsModal").modal("show");
  };

  self.printGuide = function () {
    //window.print();
    window.open("/bg/print/?" + self.individualId(), "_blank");
  };

  self.print = function () {
    window.print();
  };
  self.showHideExludedExhibitors = function () {
    self.showExcluded(!self.showExcluded());
  };

  self.all_selected_exhibitors_count = ko.computed(function () {
    // body...
    var all_selected_exhibitors = ko.utils.arrayFilter(
      self.exhibitors(),
      function (item) {
        return item.interest() != "notinterested";
      }
    );
    return all_selected_exhibitors.length;
  });

  self.showElement = function (elem) {
    if (elem.nodeType === 1) $(elem).hide().slideDown();
  };
  self.hideElement = function (elem) {
    if (elem.nodeType === 1)
      $(elem).fadeOut("slow", function () {
        $(elem).remove();
      });
  };

  self.renderCompleteGuide = function (elements, data) {
    if (
      $("#exhibitors_container").children().length ===
      self.selectedExhibitors().length * 2
    ) {
      self.loading(false);
    }
  };

  self.renderComplete = function (elements, data) {
    if (
      $("#exhibitors_container").children().length ===
      self.all_exhibitors().length
    ) {
      setTimeout(function () {
        window.print();
      }, 1000);
      self.isPrintingEnabled(true);
      self.loading(false);
    }
  };

  self.getIndividual = function (id, fnSuccess) {
    BuyersGuideApi.getIndividual(id, function (data) {
      self.individual(new Exposync.IndividualModel(data));

      self.exhibitors_clicked(data.exhibitors_clicked);
      self.exhibitors_interested(data.exhibitors_interested);
      self.exhibitors_maybe(data.exhibitors_maybe);
      self.exhibitors_notinterested(data.exhibitors_notinterested);

      if (typeof fnSuccess === "function") {
        fnSuccess(data);
      }
    });
  };

  self.selectionChanged = function (value) {
    if (self.selectedCategories().indexOf(value.item.path()) > -1) {
      self.selectedCategories.remove(value.item.path());
    } else self.selectedCategories.push(value.item.path());
  };

  self.getExpo = function (id, fnSuccess) {
    BuyersGuideApi.getExpo(id, function (expo) {
      self.expo(expo);
      self.categories(expo.categories);
      if (typeof fnSuccess === "function") {
        fnSuccess(expo);
      }
    });
  };

  self.generateCategoriesTree = function () {
    var checkboxHtml = '<input type="checkbox" />';
    var categories = NestableList.toNestable(self.categories());
    var html = generateTree(categories, 0);

    if (typeof html == "string") return html;
    else return html.html();
  };

  function generateTree(categories, level) {
    var html = "<div></div>";

    categories.forEach(function (item, index) {
      var div = $('<div class="checkbox clip-check check-primary"></div>');
      div = $(div).append(
        '<input class="form-control" type="checkbox" value="' +
          item.category +
          '" name="category_' +
          level.toString() +
          "_" +
          index.toString() +
          '" id="category_' +
          level.toString() +
          "_" +
          index.toString() +
          '" />'
      );

      if (level == 0) {
        div = $(div).append(
          '<label class="text-bold" for="category_' +
            level.toString() +
            "_" +
            index.toString() +
            '">' +
            item.id +
            "</label>"
        );
        div = $('<div class="col-md-2"></div>').append(div);
      } else {
        div = $(div).append(
          '<label for="category_' +
            level.toString() +
            "_" +
            index.toString() +
            '">' +
            item.id +
            "</label>"
        );
      }

      if (level > 0) $(div).addClass("margin-left-" + (level * 10).toString());
      if (item.children && item.children.length > 0) {
        $(div).append(generateTree(item.children, level + 1));
      }
      html = $(html).append(div);
    });

    return html;
  }

  self.groupedCategories = ko.computed(function () {
    if (self.individual()) {
      return NestableList.toTreeFormat(
        self.categories(),
        self.individual().categories()
      );
    } else {
      return NestableList.toTreeFormat(self.categories());
    }
  });

  self.getExhibitors = function () {
    self.loading(true);
    var options = utils.defaultQueryOptions;
    BuyersGuideApi.getSelectedExhibitors(self.individualId(), function (data) {
      var exhibitors = ko.utils.arrayMap(data, function (item) {
        var exhibitor = new Exposync.CompanyModel(item);
        exhibitor.interest = ko.observable("");
        exhibitor.matched_categories = item.matched_categories || [];
        exhibitor.matched_keywords = item.matched_keywords || [];
        exhibitor.matched_ranked = item.matched_ranked || 0;
        exhibitor.interest(self.setInitialInterest(exhibitor.id()));

        exhibitor.interest.subscribe(function (value) {
          self["exhibitors_interested"].remove(exhibitor.id());
          self["exhibitors_maybe"].remove(exhibitor.id());
          self["exhibitors_notinterested"].remove(exhibitor.id());
          self["exhibitors_" + value].push(exhibitor.id());
          data = {};
          data["exhibitors_interested"] = self["exhibitors_interested"]();
          data["exhibitors_maybe"] = self["exhibitors_maybe"]();
          data["exhibitors_notinterested"] = self["exhibitors_notinterested"]();
          BuyersGuideApi.updateIndividual(
            self.individualId(),
            data,
            function () {}
          );
        });
        return exhibitor;
      });
      self.exhibitors(exhibitors.sort(exhibitorNameCompare));
    });
  };

  self.setInitialInterest = function (id) {
    if (self.exhibitors_interested().indexOf(id) > -1) return "interested";
    if (self.exhibitors_maybe().indexOf(id) > -1) return "maybe";
    if (self.exhibitors_notinterested().indexOf(id) > -1)
      return "notinterested";
    return "";
  };

  self.openExhibitorWebsite = function (exhibitor) {
    window.open(exhibitor.website_url(), "_blank");
    if (!(self.exhibitors_clicked().indexOf(exhibitor.id()) > -1)) {
      self.exhibitors_clicked.push(exhibitor.id());
      data = {};
      data.exhibitors_clicked = self.exhibitors_clicked();
      BuyersGuideApi.updateIndividual(
        self.individualId(),
        data,
        function () {}
      );
    }
  };

  self.init = function () {};

  self.updateGuideInfo = function () {
    var keywords = [];
    self.error("");
    ko.utils.arrayForEach(self.keywords(), function (item) {
      if (item() && item().length > 0) keywords.push(item());
    });

    var categories = self.tree.jstree().get_selected();
    var data = {};
    data.categories = categories;
    data.keywords = keywords;

    if (data.categories.length == 0) {
      self.error(
        "Please select at least one category to create your Personal Guide."
      );
      return;
    }
    self.submitted(true);
    ga("send", {
      hitType: "event",
      eventCategory: "Personal Guide",
      eventAction: "create_guide",
    });

    BuyersGuideApi.updateIndividual(
      self.individualId(),
      data,
      function () {
        location.href = "/bg/?" + self.individualId();
      },
      function () {
        self.submitted(false);
      }
    );
  };

  self.viewGuide = function () {
    ga("send", {
      hitType: "event",
      eventCategory: "Personal Guide",
      eventAction: "view_guide",
    });
    location.href = "/bg/?" + self.individualId();
  };

  function exhibitorNameCompare(a, b) {
    if (a.name().toLowerCase() < b.name().toLowerCase()) return -1;
    if (a.name().toLowerCase() > b.name().toLowerCase()) return 1;
    return 0;
  }

  function exhibitorBoothNumberCompare(a, b) {
    if (
      a.booth_location().toLowerCase() +
        " - " +
        a.booth_number().toLowerCase() <
      b.booth_location().toLowerCase() + " - " + b.booth_number().toLowerCase()
    )
      return -1;
    if (
      a.booth_location().toLowerCase() +
        " - " +
        a.booth_number().toLowerCase() >
      b.booth_location().toLowerCase() + " - " + b.booth_number().toLowerCase()
    )
      return 1;
    return 0;
  }

  self.expoId.subscribe(function (value) {
    var url = "/api/expo/" + value;
    ajax.get(url, function (data) {
      self.isAuthenticated(true);
    });
  });

  self.loadAllExhibitors = function (argument) {
    var filtered = ko.utils.arrayFilter(self.exhibitors(), function (item) {
      return item.interest() != "notinterested";
    });
    var max = Math.ceil(filtered.length / 25);
    self.page(max);
  };
};

var NodeModel = function (data) {
  var self = this;
  self.name = ko.observable(data.id || "");
  self.path = ko.observable(data.category || "");
  self.selected = ko.observable(data.selected || false);
  self.children = ko.observable(
    ko.utils.arrayMap(data.children, function (item) {
      return new NodeModel(item);
    })
  );
};
