var ClientsViewModel = function (clientId) {
  var self = this;
  self.client = ko.observable();
  self.clients = ko.observableArray([]);
  self.error = ko.observable();
  self.client = ko.observable();
  self.mode = ko.observable(); // new or update

  self.selectedCategoryOrDemographicToEdit = ko.observable();
  self.categoriesViewModel = ko.observable();
  self.editCategoriesViewModel = ko.observable();
  self.demographicsViewModel = ko.observable();
  self.editDemographicsViewModel = ko.observable();

  self.clientCustomerList = ko.observableArray([]);
  self.clientLeads = ko.observableArray([]);
  self.marketers = ko.observableArray([]);
  self.uploadType = ko.observable();
  self.uploadProgress = ko.observable(0);

  self.newIndividualToAdd = ko.observable();
  self.contacts = ko.observableArray([]);
  self.contact_id = ko.observable();
  self.contact_search_term = ko.observable();
  self.contact_to_remove = ko.observable();
  self.contact_to_view = ko.observable();
  self.image_to_delete = ko.observable();
  self.document_to_delete = ko.observable();

  self.expo_exhibitor_roles = ko.observableArray([]);
  self.selected_exhibitor_roles = ko.observableArray([]);

  self.uploadedCompanyLogo = ko.observable();

  // TODO: change to non hard coded fields
  self.associationFields = ko.observable({
    owner: ko.observableArray(["IPE", "AFIA", "NAMI"]),
    member: ko.observableArray(["USPOULTRY", "AFIA", "NAMI"]),
  });
  self.selectedAssnOwners = ko.observableArray([]);
  self.selectedAssnMembers = ko.observableArray([]);

  self.expo_status = ko.observableArray([]);
  baseViewModel.call(self);
  baseClientsViewModel.call(self);

  self.company_type_text = ko.pureComputed(function () {
    switch (self.client().company_type()) {
      case "exhibitor_company":
        return "Exhibitor Company";
      case "attending_company":
        return "Attending Company";
      default:
        return "";
    }
  }, self);

  self.ExpoClientsDataSource = function (options, callback) {
    options.status = "client";
    options.client_type = "expo";
    options.company_type = "exhibitor_company";
    ExhibitorsApi.find(self.expoId(), options, function (data) {
      callback(data);
    });
  };

  self.ExhibitorClientsDataSource = function (options, callback) {
    options.status = "client";
    options.client_type = "exhibitor";
    options.company_type = "exhibitor_company";
    ExhibitorsApi.find(self.expoId(), options, function (data) {
      callback(data);
    });
  };

  self.clientsTableRowCallback = function (row) {
    $(row).attr("style", "cursor: pointer");
  };

  self.leadsDataSource = function (options, callback) {
    options.status = "lead";
    options.company_type = "exhibitor_company";
    ExhibitorsApi.find(self.expoId(), options, function (data) {
      callback(data);
    });
  };

  self.leadsTableRowCallback = function (row) {
    $(row).attr("style", "cursor: pointer");
  };

  self.viewClient = function (client) {
    location.href =
      "/services/clients/view/?id=" + client.id + "&eid=" + client.expo_id;
  };

  self.updateContactModal = function (item) {
    $("#addContactModal").modal("show");
  };

  self.searchContacts = function () {
    if (!self.contact_search_term()) {
      utils.showError("Please enter a search term.");
      return;
    }
    var expoId = self.expoId();
    var options = utils.defaultQueryOptions;
    options.q = self.contact_search_term();
    IndividualsApi.find(expoId, options, function (result) {
      self.contacts(result.data);
    });
  };

  self.addContact = function (contact) {
    if (
      self.selected_exhibitor_roles() &&
      self.selected_exhibitor_roles().length > 0
    ) {
      var exhibitor_roles = self.selected_exhibitor_roles();
      const individual_id = contact.id;
      const company_id = self.client().id();
      var data = {};

      data.exhibitor_roles = exhibitor_roles;
      data.individual_id = individual_id;
      data.company_id = company_id;

      CompanyContactsApi.createContact(data, function () {
        utils.showSuccess("Contact was successfully added.");
        self.getClient(self.client().id());
        setTimeout(() => {
          $("#addContactModal").modal("hide");
        }, 1000);
      });
    } else {
      utils.showError(
        "Please select at least on exhibitor role for the contact."
      );
    }
  };

  self.showAddNewIndividualModal = function () {
    self.newIndividualToAdd({
      name_first: "",
      name_last: "",
      email_first: "",
      phone_first: "",
    });
    $("#addContactModal").modal("hide");
    $("#addNewIndividualToCompanyModal").modal("show");
  };

  self.addNewIndividualToCompany = function () {
    if (!$("#add-new-individual-form").valid()) {
      return false;
    }

    if (
      self.selected_exhibitor_roles() &&
      self.selected_exhibitor_roles().length > 0
    ) {
      url = "/api/individuals/expo/" + self.expoId();
      ajax.post(url, self.newIndividualToAdd(), (ret) => {
        $("#addNewIndividualToCompanyModal").modal("hide");
        self.addContact(ret);
      });
    } else {
      utils.showError(
        "Please select at least on exhibitor role for the contact."
      );
    }
  };

  self.removeContactConfirmModal = function (contact) {
    self.contact_to_remove(contact);
    $("#confirmRemoveContactModal").modal("show");
  };

  self.removeImageConfirmModal = function (image) {
    self.image_to_delete(image);
    $("#confirmRemoveImageModal").modal("show");
  };

  self.removeContact = function (contact) {
    var contact = self.contact_to_remove();

    var data = {};
    var exhibitor_roles = contact.exhibitor_roles;
    var index = exhibitor_roles.indexOf(contact.role);
    if (index > -1) {
      exhibitor_roles.splice(index, 1);
    }
    data.exhibitor_roles = exhibitor_roles;
    data.individual_id = contact.individual_id;
    data.company_id = contact.company_id;

    if (exhibitor_roles.length) {
      CompanyContactsApi.updateContact(contact.id, data, function () {
        utils.showSuccess("Contact was successfully removed.");
        self.getClient(self.client().id());
      });
    } else {
      CompanyContactsApi.deleteContact(contact.id, () => {
        utils.showSuccess("Contact was successfully removed.");
        self.getClient(self.client().id());
      });
    }
  };

  self.removeDocumentConfirmModal = function (document) {
    self.document_to_delete(document);
    $("#confirmRemoveDocumentModal").modal("show");
  };

  self.removeDocument = function () {
    var document = self.document_to_delete();

    var documents = ko.utils.arrayFilter(
      self.client().documents(),
      function (item) {
        return document.name() !== item.name();
      }
    );
    var data = {
      company_marketers: self.client().company_marketers_ids(),
    };
    data.documents = ko.utils.arrayMap(documents, function (p) {
      return p.link();
    });
    ExhibitorsApi.update(self.client().id(), data, function (data) {
      utils.showSuccess("Document has been successfully deleted.");
      self.client().documents(documents);
      $("#confirmRemoveDocumentModal").modal("hide");
    });
  };

  self.removeImage = function () {
    var image = self.image_to_delete();
    var images = self.client().photos();

    var images = ko.utils.arrayFilter(self.client().photos(), function (item) {
      return image.name() !== item.name();
    });
    var data = {
      company_marketers: self.client().company_marketers_ids(),
    };
    data.photos = ko.utils.arrayMap(images, function (p) {
      return p.link();
    });
    ExhibitorsApi.update(self.client().id(), data, function (data) {
      utils.showSuccess("Image has been successfully deleted.");
      self.client().photos(images);
      $("#confirmRemoveImageModal").modal("hide");
    });
  };

  self.isUserAssignedMarketer = function () {
    var company_marketers = self.client().company_marketers();
    var user = self.currentUser();
    return company_marketers.filter((cm) => cm.user.id === user.id).length > 0;
  };

  self.getExpo = function (id, fnSuccess) {
    ExpoApi.get(id, function (expo) {
      self.expo(expo);
      self.categoriesViewModel().expo(expo);
      self.editCategoriesViewModel().expo(expo);
      self.demographicsViewModel().expo(expo);
      self.editDemographicsViewModel().expo(expo);
      if (typeof fnSuccess === "function") {
        fnSuccess(expo);
      }
    });
    CompanyContactsApi.getExhibitorRolesByExpoId(id, (roles) => {
      self.expo_exhibitor_roles(roles);
    });
  };

  self.getClientContacts = function (company_contacts) {
    self.client().contacts([]);
    var contacts = [];
    company_contacts
      .filter((c) => c.individual)
      .map((contact) => {
        const normalized = contact.exhibitor_roles.map((role) =>
          Object.assign(
            {},
            contact,
            { exhibitor: contact.exhibitor_roles },
            { role: role }
          )
        );
        contacts = [...contacts, ...normalized];
      });
    self
      .client()
      .contacts(
        contacts.sort((a, b) =>
          a.role.toLowerCase() < b.role.toLowerCase() ? -1 : 1
        )
      );
  };

  self.getClient = function (id, fnSuccess) {
    ExhibitorsApi.get(id, function (data) {
      self.client(new Exposync.CompanyModel(data));
      self.categoriesViewModel().selectedCategories(self.client().categories());
      self.categoriesViewModel().selectedCategoriesLoaded(true);
      self
        .editCategoriesViewModel()
        .selectedCategories(self.client().categories());
      self.editCategoriesViewModel().selectedCategoriesLoaded(true);
      self
        .demographicsViewModel()
        .selectedDemographics(self.client().demographics());
      self.demographicsViewModel().selectedDemographicsLoaded(true);
      self
        .editDemographicsViewModel()
        .selectedDemographics(self.client().demographics());
      self.editDemographicsViewModel().selectedDemographicsLoaded(true);

      if (typeof fnSuccess === "function") {
        fnSuccess(data);
      }

      self.getClientContacts(data.company_contacts);

      ExhibitorsApi.getClientCustomerList(id, function (data) {
        self.clientCustomerList(data);
      });

      ExhibitorsApi.getLeads(data.exhibitor_id, self.expoId(), function (data) {
        self.clientLeads(data);
      });
    });
  };

  self.uploadDocument = function () {
    $.blockUI({ baseZ: 9999, message: $("#upload-message") });

    var file = document.getElementById("photoUpload").files[0];
    if (file != undefined && file != null) {
      //var name = 'uploads_' + Math.floor(1000000000 * Math.random()) + '_' + file.name;
      var name = file.name;

      UploadsApi.upload(
        file,
        name,
        function () {
          $.unblockUI();

          var data = {};
          data.link = name;
          data.name = name;
          var doc = new Exposync.DocumentModel(data);
          if (self.uploadType() == "document")
            self.client().documents.push(doc);
          else self.client().photos.push(doc);

          var client = {
            photos: [],
            documents: [],
            company_marketers: self.client().company_marketers_ids(),
          };
          client.photos = ko.utils.arrayMap(
            self.client().photos(),
            function (p) {
              return p.link();
            }
          );
          client.documents = ko.utils.arrayMap(
            self.client().documents(),
            function (p) {
              return p.link();
            }
          );
          ExhibitorsApi.update(self.client().id(), client, function (data) {
            utils.showSuccess("File have been uploaded successfully.");
            $("#uploadDocumentModal").modal("hide");
          });
        },
        function (msg) {
          $.unblockUI();
          utils.showError(msg);
        },
        function (progress) {
          self.uploadProgress(Math.round(progress * 100));
        }
      );
    } else {
      utils.showError(
        "Please select a photo before clicking the upload button."
      );
      $.unblockUI();
    }
  };

  self.updateClient = function (model) {
    if (!$("#exhibitor_form").valid()) {
      return false;
    }

    var client = model.client();

    var url = "";
    var returnUrl = "";
    var success = "";
    var expoId = model.expoId();

    if (client.id()) {
      url = "/api/companies/" + client.id();
      returnUrl =
        "/services/clients/view/?id=" +
        client.id() +
        "&eid=" +
        client.expo_id();
      success = "Client was successfully updated";
    } else {
      url = "/api/companies/expo/" + expoId;
      returnUrl = "/services/clients/view/?eid=" + expoId;
      success = "Client was successfully created.";
    }

    const options = { exhibitor_id: client.exhibitor_id() };
    var data = client.toJSON();
    data.assigned_marketer_id = null;
    if (data.nexus_status !== "client") data.client_type = null;

    if (self.mode() === "new")
      ExhibitorsApi.findByExhibitorId(expoId, options, function (exhibitor) {
        if (exhibitor) {
          utils.showError(
            `Exhibitor with Exhibitor ID: ${options.exhibitor_id} already exists!`
          );
        } else {
          ajax.post(url, data, function (data) {
            utils.showSuccess(success);
            setTimeout(function () {
              location.href = returnUrl + `&id=${data.id}`;
            }, 2000);
          });
        }
      });
    else if (self.mode() === "update") {
      ajax.post(url, data, function (data) {
        utils.showSuccess(success);
        setTimeout(function () {
          location.href = returnUrl;
        }, 2000);
      });
    }
  };

  self.createLead = function (model) {
    if (!$("#lead_form").valid()) {
      return false;
    }

    var client = model.client();
    let data = client.toJSON();
    const individual = self.newIndividualToAdd();

    if (individual.name_first || individual.name_last) {
      if (!$("#individual_form").valid()) {
        return false;
      }
      if (
        !(
          self.selected_exhibitor_roles() &&
          self.selected_exhibitor_roles().length > 0
        )
      ) {
        utils.showError(
          "Please select at least on exhibitor role for the contact."
        );
        return false;
      }
      data.individual = individual;
      data.exhibitor_roles = self.selected_exhibitor_roles();
    }

    var url = "";
    var returnUrl = "";
    var success = "";
    var expoId = model.expoId();

    url = "/api/companies/marketer/expo/" + expoId;
    returnUrl = "/services/clients/view/?eid=" + expoId;
    success = "Lead was successfully created.";

    const userFullName = `${self.currentUser().name_first} ${
      self.currentUser().name_last
    }`;

    data.assigned_marketer_id = self.currentUser().id;
    data.nexus_status = "lead";
    data.custom_fields = {
      Account_Owner: userFullName,
      Account_Created_By: userFullName,
      Account_Created_Date: moment().format("MM/DD/YYYY"),
      Assn_Owner: self.selectedAssnOwners().join("/"),
      Assn_Member: self.selectedAssnMembers().join("/"),
    };
    data.demographics = [
      ...self.selectedAssnMembers().map((x) => `Association Member:${x}`),
      ...self.selectedAssnOwners().map((x) => `Association Owner:${x}`),
    ];

    ajax.post(url, data, function (ret) {
      utils.showSuccess(success);
      setTimeout(function () {
        location.href = returnUrl + `&id=${ret.id}`;
      }, 2000);
    });
  };

  self.newLead = function () {
    location.href = "/services/leads/edit/?eid=" + self.expoId();
  };

  self.cancelEditLeads = function (model) {
    location.href = "/services/leads/";
  };

  self.updateEditedCategoryOrDemographic = function (
    selectedCategoryOrDemographicToEdit
  ) {
    var client = self.client();
    var data = {};

    const list = $(
      selectedCategoryOrDemographicToEdit.object.nestableListSelector
    ).nestable("serialize");
    const editList = NestableList.fromSelectableNestable(list);

    if (selectedCategoryOrDemographicToEdit.category)
      data.categories = editList;
    else data.demographics = editList;

    url = "/api/companies/" + client.id();
    var redirectUrl =
      "/services/clients/view/?id=" + client.id() + "&eid=" + client.expo_id();
    success =
      selectedCategoryOrDemographicToEdit.title + " were successfully updated";

    ajax.post(url, data, function (data) {
      utils.showSuccess(success);
      setTimeout(function () {
        location.href = redirectUrl;
      }, 2000);
    });
  };

  self.editCategories = function () {
    self.selectedCategoryOrDemographicToEdit({
      title: "Categories",
      object: self.editCategoriesViewModel(),
      category: true,
    });
    $("#editCategoriesDemographicsModal").modal("show");
  };

  self.editDemographics = function () {
    self.selectedCategoryOrDemographicToEdit({
      title: "Demographics",
      object: self.editDemographicsViewModel(),
    });
    $("#editCategoriesDemographicsModal").modal("show");
  };

  self.viewLead = function (entry) {
    //get expo role
    if (
      self.isInRole("admin,manager") ||
      self.isInExpoRole("organizer_admin")
    ) {
      window.open(
        "/expo/individuals/view/?id=" +
          entry.individual.id +
          "&eid=" +
          self.expoId(),
        "_blank"
      );
    } else if (self.isInRole("marketer")) {
      IndividualsApi.get(entry.individual.id, function (data) {
        self.contact_to_view(new Exposync.IndividualModel(data));
        $("#viewClientContactModal").modal("show");
      });
    }
  };

  self.viewCustomerListEntry = function (entry) {
    //get expo role
    if (
      self.isInRole("admin,manager") ||
      self.isInExpoRole("organizer_admin")
    ) {
      window.open(
        "/expo/individuals/view/?id=" +
          entry.individual.id +
          "&eid=" +
          self.expoId(),
        "_blank"
      );
    } else if (self.isInRole("marketer")) {
      IndividualsApi.get(entry.individual.id, function (data) {
        self.contact_to_view(new Exposync.IndividualModel(data));
        $("#viewClientContactModal").modal("show");
      });
    }
  };

  self.categories = ko.computed(function () {
    if (self.client()) {
      return NestableList.toNestable(self.client().categories());
    } else return [];
  });

  self.demographics = ko.computed(function () {
    if (self.client()) {
      return NestableList.toNestable(self.client().demographics());
    } else return [];
  });

  self.expoId.subscribe(function (value) {
    ExpoApi.getExpoMarketers(value, function (list) {
      self.marketers(list);
    });
    ExhibitorsApi.getExpoStatuses(value, function (statuses) {
      self.expo_status(statuses);
    });
  });

  self.uploadCompanyLogo = function () {
    $.blockUI({ baseZ: 9999, message: $("#upload-message") });

    var file = document.getElementById("upload-file").files[0];
    if (file != undefined && file != null) {
      var name = "logo_" + self.client().exhibitor_id() + "_" + file.name;
      UploadsApi.upload(
        file,
        name,
        function () {
          $.unblockUI();
          self.uploadedCompanyLogo(name);
        },
        function (msg) {
          $.unblockUI();
          utils.showError(msg);
        },
        function (progress) {
          self.uploadProgress(Math.round(progress * 100));
        }
      );
    } else {
      utils.showError(
        "Please select an image before clicking the upload button."
      );
      $.unblockUI();
    }
  };

  self.updateCompanyLogo = function () {
    var data = {};
    data.company_logo =
      "https://s3.amazonaws.com/exposync-uploads/" + self.uploadedCompanyLogo();
    ExhibitorsApi.update(self.client().id(), data, function () {
      self.client().company_logo(data.company_logo);
      self.uploadedCompanyLogo("");
      $("#uploadLogoModal").modal("hide");
    });
  };

  self.init = function (clientId) {
    self.client(new Exposync.CompanyModel({}));
    self.categoriesViewModel(new CategoriesViewModel());
    self.editCategoriesViewModel(new CategoriesViewModel());
    self.demographicsViewModel(new DemographicsViewModel());
    self.editDemographicsViewModel(new DemographicsViewModel());
    self.editDemographicsViewModel().selectableCategories =
      self.editDemographicsViewModel().selectableDemographics;
  };

  self.init();
};
