var DataSyncApi = {
  start: function (expoId, data, fnSuccess) {
    var url = "/api/datasync/start/" + expoId;
    ajax.post(url, data, fnSuccess);
  },

  getSampleRows: function (jobId, fnSuccess) {
    var url = "/api/datasync/get_sample_rows/" + jobId;
    ajax.get(url, fnSuccess);
  },

  getTargetFields: function (jobId, fnSuccess) {
    var url = "/api/datasync/get_target_fields/" + jobId;
    ajax.get(url, fnSuccess);
  },

  saveColumnMapping: function (jobId, data, fnSuccess, fnError) {
    var url = "/api/datasync/save_import_mapping/" + jobId;
    ajax.post(url, data, fnSuccess, fnError);
  },

  saveColumnMatching: function (jobId, data, fnSuccess, fnError) {
    var url = "/api/datasync/save_match_mapping/" + jobId;
    ajax.post(url, data, fnSuccess, fnError);
  },

  previewMatches: function (jobId, page, fnSuccess) {
    var url = "/api/datasync/preview_matches/" + jobId + "/" + page;
    ajax.post(url, [], fnSuccess);
  },

  saveMatchDecisions: function (
    jobId,
    page,
    applyForAllPages,
    applyNewRecordsForAllPages,
    data,
    fnSuccess,
    fnError
  ) {
    var url = "/api/datasync/save_match_decisions/" + jobId + "/" + page;
    // optional parameter
    if (applyForAllPages || applyNewRecordsForAllPages) {
      url = url + "?";
    }

    if (applyForAllPages == true) {
      url = url + "applyForAllPages=1";
    }

    if (applyNewRecordsForAllPages == true) {
      if (applyForAllPages) {
        url = url + "&";
      }
      url = url + "applyNewRecordsForAllPages=1";
    }

    ajax.post(url, data, fnSuccess, fnError);
  },

  finalize: function (jobId, fnSuccess) {
    var url = "/api/datasync/finalize/" + jobId;
    ajax.post(url, {}, fnSuccess);
  },

  find: function (expoId, options, fnSuccess) {
    var query_string = "?" + $.param(options);
    var url = "/api/datasync/expo/" + expoId + query_string;
    ajax.get(url, fnSuccess);
  },

  getDatasyncJob: function (jobId, fnSuccess) {
    var url = "/api/datasync/job/" + jobId;
    ajax.get(url, fnSuccess);
  },
  delete: function (id, fnSuccess) {
    var url = "/api/datasync/job/" + id;
    ajax.delete(url, {}, fnSuccess);
  },
  removeDuplicates: function (id, fnSuccess) {
    var url = "/api/datasync/remove_duplicates/" + id;
    ajax.post(url, {}, fnSuccess);
  },
  updateIndividualsCompanyId: function (id, fnSuccess) {
    var url = "/api/datasync/update_company_id/" + id;
    ajax.post(url, {}, fnSuccess);
  },
};
