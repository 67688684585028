var DataSyncViewModel = function () {
  var self = this;
  self.fileName = ko.observable();
  self.hasHeaders = ko.observable(true);
  self.matchingDataType = ko.observable();
  self.client = ko.observable();
  self.clients = ko.observableArray([]);
  self.jobId = ko.observable();
  self.numberOfRows = ko.observable();
  self.S3Key = ko.observable();
  self.sampleRows = ko.observableArray([]);
  self.headers = ko.observableArray([]);
  self.targetFields = ko.observableArray([]);
  self.matches = ko.observableArray([]);
  self.finalList = ko.observableArray([]);
  self.newJob = ko.observable(true);
  self.page_size = ko.observable(25);
  self.num_pages_completed = ko.observable(0);
  self.num_pages_total = ko.observable(0);
  self.num_new = ko.observable();
  self.num_updated = ko.observable();
  self.num_leftover = ko.observable();
  self.match_mapping = ko.observableArray([]);
  self.import_mapping = ko.observableArray([]);
  self.pagePreviewed = ko.observable(false);
  self.fields = ko.observable();
  self.columns = ko.observableArray([]);
  self.selectedMatchesCopy = ko.observableArray([]);

  self.name = ko.observable();

  self.canChangeMatchingMapping = ko.computed(function () {
    return self.num_pages_completed() == 0;
  });

  self.canChangeMapping = ko.computed(function () {
    return self.num_pages_completed() == 0;
  });

  self.uploadProgress = ko.observable(0);

  self.matchingComplete = ko.observable(false);
  self.applyForAllPages = ko.observable(false);
  self.applyNewRecordsForAllPages = ko.observable(false);
  self.mappingComplete = ko.observable(false);
  self.matchDecisionsSaved = ko.observable(false);
  self.selectAllMatches = ko.observable(false);
  self.selectAllAmbiguities = ko.observable(false);
  self.selectAllNewRecords = ko.observable(false);

  self.newRowCount = ko.observable(0);
  self.updatedRowCount = ko.observable(0);
  self.numLeftOvers = ko.observable(0);

  baseViewModel.call(self);

  self.deleteJobId = ko.observable();
  self.downloadFilename = ko.observable();
  self.downloadJobId = ko.observable();

  self.dataType = ko.computed(function () {
    var matchType = self.matchingDataType();
    switch (self.matchingDataType()) {
      case "exhibitors_data":
      case "other_exhibitors_data":
        return "companies";
      case "exhibitor_contacts":
        return "company_contacts";
      case "registration_data":
      case "housing_data":
      case "individuals_data":
        return "individuals";
      default:
        return matchType;
    }
  });

  self.datasyncJobsDataSource = function (options, callback) {
    options.status = "active";
    DataSyncApi.find(self.expoId(), options, function (data) {
      callback(data);
    });
  };

  self.datasyncCompletedJobsDataSource = function (options, callback) {
    options.status = "completed";
    DataSyncApi.find(self.expoId(), options, function (data) {
      callback(data);
    });
  };

  self.datasyncJobsTableRowCallback = function (row, data) {
    $(row).attr("style", "cursor: pointer");
    $(row).click(function (e) {
      if ($(e.target).hasClass("fa-trash-o")) {
        self.deleteJobId(data.id);
        return;
      } else {
        location.href =
          "/expo/datasync/edit/?id=" + data.id + "&eid=" + data.expo_id;
      }
    });
  };
  self.datasyncCompletedJobsTableRowCallback = function (row, data) {
    $(row).click(function (e) {
      if ($(e.target).hasClass("fa-trash-o")) {
        self.deleteJobId(data.id);
        return;
      } else if ($(e.target).hasClass("fa-download")) {
        self.downloadFilename(data.s3_key);
        self.downloadJobId(data.id);
        return;
      }
    });
  };

  self.newDatasyncJob = function () {
    location.href = "/expo/datasync/edit/?eid=" + self.expoId();
  };

  self.mapDatasyncJobToModel = function (data, resumeJob) {
    self.jobId(data.id);

    self.S3Key(data.s3_key);
    self.hasHeaders(data.has_headers);
    self.matchingDataType(data.front_end_mode);
    self.numberOfRows(data.row_count);
    self.fileName(data.s3_key);
    self.newJob(!resumeJob);
    self.page_size(data.page_size);
    self.num_pages_total(data.num_pages_total);
    self.num_pages_completed(data.num_pages_completed);
    self.match_mapping(data.match_mapping);
    self.import_mapping(data.import_mapping);

    if (data.match_mapping && data.match_mapping.length > 0) {
      self.matchingComplete(true);
    }

    if (data.import_mapping && data.import_mapping.length > 0) {
      self.mappingComplete(true);
    }
  };

  self.getDatasyncJob = function (id) {
    DataSyncApi.getDatasyncJob(id, function (data) {
      self.mapDatasyncJobToModel(data, true);
      self.getTargetFields(function () {
        DataSyncApi.getSampleRows(id, function (sampleRowsData) {
          self.headers(sampleRowsData.headers);
          self.sampleRows(sampleRowsData.rows.slice(0, 3));
          self.mapColumns();
        });
      });
    });
  };

  self.deleteDatasyncJob = function () {
    DataSyncApi.delete(self.deleteJobId(), function () {
      utils.showSuccess("Datasync Job was successfully deleted.");
      setTimeout(function () {
        location.href = "/expo/datasync/";
      }, 2000);
    });
  };

  self.mapColumns = function () {
    var result = [];
    var i = 0;
    var default_matching = utils.datasync.matching[self.matchingDataType()];
    var default_mapping = utils.datasync.mapping[self.matchingDataType()];

    ko.utils.arrayForEach(self.sampleRows(), function (obj) {
      i = 0;
      obj.forEach(function (item) {
        if (!result[i]) {
          result[i] = {
            match_connector: ko.observable("or"),
            header: self.headers()[i].toLowerCase(),
            matching_mode: ko.observable("show"),
            mapping_mode: ko.observable("ignore"),
            matching_target_field: ko.observable(),
            target_field: ko.observable(),
            target_field_type: ko.observable("scalar_field"),
            behavior_empty_source: ko.observable("ignore"),
            behavior_full_to_empty: ko.observable("fill"),
            behavior_full_to_full: ko.observable("overwrite"),
            behavior_elsewhere_field: ko.observable(),
            items: ko.observableArray([]),
          };

          result[i].target_field.subscribe(function (value) {
            var field = ko.utils.arrayFirst(self.fields(), function (item) {
              return item.name == value;
            });
            if (field && field.type == "array") {
              this.target_field_type("array_field");
              if (this.mapping_mode() == "update_with_behaviors")
                this.mapping_mode("array_merge");
            } else {
              this.target_field_type("scalar_field");
              if (this.mapping_mode() == "update")
                this.mapping_mode("update_with_behaviors");
            }
          }, result[i]);

          //default - not mapped or not defaultly mapped
          if (result[i].target_field()) {
            result[i].matching_mode("show");
          }

          /// MATCHING ///
          if (self.matchingComplete()) {
            result[i].match_connector(self.match_mapping()[i].match_connector);
            result[i].matching_mode(self.match_mapping()[i].match_mode);
            result[i].matching_target_field(
              self.match_mapping()[i].target_field
            );
          } else if (default_matching) {
            if (default_matching[result[i].header]) {
              result[i].matching_mode("match");
              result[i].match_connector(
                default_matching[result[i].header].operator
              );
              result[i].matching_target_field(
                default_matching[result[i].header].field
              );
            }
          }

          var exists = ko.utils.arrayFirst(
            self.targetFields(),
            function (targetFieldName) {
              return (
                self.headers()[i].toLowerCase() == targetFieldName.toLowerCase()
              );
            }
          );
          if (exists) {
            result[i].target_field(exists);
            result[i].matching_target_field(exists);
          }

          /// MAPPING ///
          if (self.mappingComplete()) {
            result[i].mapping_mode(self.import_mapping()[i].import_mode);
            result[i].target_field(self.import_mapping()[i].target_field);
            result[i].behavior_empty_source(
              self.import_mapping()[i].behavior_empty_source
            );
            result[i].behavior_full_to_empty(
              self.import_mapping()[i].behavior_full_to_empty
            );
            result[i].behavior_full_to_full(
              self.import_mapping()[i].behavior_full_to_full
            );
            if (self.import_mapping()[i].behavior_elsewhere_field)
              result[i].behavior_elsewhere_field(
                self.import_mapping()[i].behavior_elsewhere_field
              );
          } else if (default_mapping) {
            if (default_mapping[result[i].header]) {
              result[i].target_field(
                default_mapping[result[i].header].target_field
              );

              if (result[i].target_field_type() == "scalar_field") {
                result[i].mapping_mode("update_with_behaviors");
                if (default_mapping[result[i].header].behavior_empty_source)
                  result[i].behavior_empty_source(
                    default_mapping[result[i].header].behavior_empty_source
                  );

                if (default_mapping[result[i].header].behavior_full_to_empty)
                  result[i].behavior_full_to_empty(
                    default_mapping[result[i].header].behavior_full_to_empty
                  );

                if (default_mapping[result[i].header].behavior_full_to_full)
                  result[i].behavior_full_to_full(
                    default_mapping[result[i].header].behavior_full_to_full
                  );
                if (default_mapping[result[i].header].behavior_elsewhere_field)
                  result[i].behavior_elsewhere_field(
                    default_mapping[result[i].header].behavior_elsewhere_field
                  );
              } else {
                result[i].mapping_mode("array_merge");
              }
            } else {
              result[i].mapping_mode("ignore");
            }
          } else {
            if (result[i].target_field()) {
              if (result[i].target_field_type() == "scalar_field") {
                result[i].mapping_mode("update_with_behaviors");
                result[i].behavior_empty_source("ignore");
                result[i].behavior_full_to_empty("fill");
                result[i].behavior_full_to_full("overwrite");
              } else {
                result[i].mapping_mode("array_merge");
              }
            }
          }

          //default - not mapped or not defaultly mapped
        }
        result[i].items().push(item);
        i++;
      });
    });

    self.columns(result);
  };

  self.matchingColumns = ko.computed(function () {
    return ko.utils.arrayFilter(self.columns(), function (item) {
      return item.matching_mode() == "match";
    });
  });

  self.displayedColumns = ko.computed(function () {
    return ko.utils.arrayFilter(self.columns(), function (item) {
      return item.matching_mode() == "match" || item.matching_mode() == "show";
    });
  });

  self.ignoredColumns = ko.computed(function () {
    return ko.utils.arrayFilter(self.columns(), function (item) {
      return item.matching_mode() == "ignore" || item.matching_mode() == "show";
    });
  });

  self.singleMatches = ko.computed(function () {
    return ko.utils.arrayFilter(self.matches(), function (item) {
      return item.matchType() == "updated";
    });
  });

  self.multipleMatches = ko.computed(function () {
    return ko.utils.arrayFilter(self.matches(), function (item) {
      return item.matchType() == "ambiguous";
    });
  });

  self.updatedMatches = ko.computed(function () {
    return ko.utils.arrayFilter(self.matches(), function (item) {
      return item.matchType() == "ambiguous" || item.matchType() == "updated";
    });
  });

  self.newRecords = ko.computed(function () {
    return ko.utils.arrayFilter(self.matches(), function (item) {
      return item.matchType() == "new";
    });
  });

  self.selectedNewRecordCount = ko.computed(function () {
    var filtered = ko.utils.arrayFilter(self.newRecords(), function (item) {
      return item.selectedMatches().length > 0;
    });
    return filtered.length;
  });

  self.selectedSingleRecordCount = ko.computed(function () {
    var filtered = ko.utils.arrayFilter(self.singleMatches(), function (item) {
      return item.selectedMatches().length > 0;
    });
    return filtered.length;
  });

  self.selectedMultipleRecordCount = ko.computed(function () {
    var filtered = ko.utils.arrayFilter(
      self.multipleMatches(),
      function (item) {
        return item.selectedMatches().length > 0;
      }
    );
    return filtered.length;
  });

  self.updatedCount = ko.computed(function () {
    var filtered = [];
    filtered = ko.utils.arrayFilter(self.matches(), function (item) {
      return item.matchType() == "ambiguous" || item.matchType() == "updated";
    });
    return filtered.length;
  });

  self.newCount = ko.computed(function () {
    var filtered = [];
    filtered = ko.utils.arrayFilter(self.matches(), function (item) {
      return item.matchType() == "new";
    });
    return filtered.length;
  });

  self.toggleMatchConnector = function (column) {
    if (column.match_connector() == "or") column.match_connector("and");
    else column.match_connector("or");
  };

  self.getClients = function (id) {
    var options = utils.defaultQueryOptions;
    options.ps = 1000;
    options.status = "client";
    ExhibitorsApi.findAll(id, options, function (response) {
      self.clients(response.data.sort(exhibitorNameCompare));
    });
  };

  function exhibitorNameCompare(a, b) {
    if (a.exhibitor_name < b.exhibitor_name) return -1;
    if (a.exhibitor_name > b.exhibitor_name) return 1;
    return 0;
  }

  self.startDataSyncJob = function () {
    $.blockUI({
      message:
        '<h4 class="text-primary padding-top-10"><img width="30px" src="/assets/images/ajax-loader.gif" /> Please wait... Loading sample rows.</h4>',
    });
    var data = {};
    data.s3_key = self.S3Key();
    data.has_headers = self.hasHeaders() === undefined ? false : true;
    data.mode = self.dataType();
    data.front_end_mode = self.matchingDataType();
    data.name = self.name();
    data.page_size = self.page_size();

    if (data.mode == "client_customer_list") {
      data.exhibitor_id = self.client();
    }

    DataSyncApi.start(self.expoId(), data, function (data) {
      self.mapDatasyncJobToModel(data, false);

      self.getTargetFields(function () {
        DataSyncApi.getSampleRows(self.jobId(), function (sampleRowsData) {
          self.headers(sampleRowsData.headers);
          self.sampleRows(sampleRowsData.rows.slice(0, 3));
          self.mapColumns();
          $.unblockUI();
        });
      });
    });
  };

  self.getTargetFields = function (fnSuccess) {
    var fields = [];
    var targetFields = [];
    if (self.dataType() == "categories") {
      fields.push({ name: "category", type: "array" });
      targetFields.push("category");
      self.fields(fields);
      self.targetFields(targetFields);

      if (typeof fnSuccess === "function") fnSuccess();
    } else if (self.dataType() == "demographics") {
      fields.push({ name: "demographics", type: "array" });
      targetFields.push("demographics");
      self.fields(fields);
      self.targetFields(targetFields);

      if (typeof fnSuccess === "function") fnSuccess();
    } else {
      DataSyncApi.getTargetFields(self.jobId(), function (data) {
        fields = $.map(data, function (type, name) {
          return { name: name, type: type };
        });
        self.fields(fields);

        targetFields = $.map(data, function (type, name) {
          return name;
        });
        self.targetFields(targetFields.sort());

        if (typeof fnSuccess === "function") fnSuccess();
      });
    }
  };

  self.saveColumnMatching = function (model) {
    $.blockUI({
      message:
        '<h4 class="text-primary padding-top-10"><img width="30px" src="/assets/images/ajax-loader.gif" /> Please wait... Applying column matching... </h4>',
    });
    var jobId = self.jobId();
    var data = [];

    ko.utils.arrayForEach(self.columns(), function (item) {
      if (
        item.matching_target_field() === undefined ||
        item.matching_target_field() == ""
      )
        item.matching_mode("ignore");
    });

    data = $.map(self.columns(), function (value, index) {
      return {
        match_mode: value.matching_mode(),
        target_field:
          value.matching_target_field() === undefined ||
          value.matching_target_field() == "ignore"
            ? ""
            : value.matching_target_field(),
        match_connector: value.match_connector(),
      };
    });

    DataSyncApi.saveColumnMatching(
      jobId,
      data,
      function () {
        self.match_mapping(data);
        self.matchingComplete(true);
        $.unblockUI();
        self.previewMatches();
      },
      function () {
        $.unblockUI();
      }
    );
  };

  self.moveNext = function (step) {
    if (step == 2) {
      if (!self.pagePreviewed()) {
        self.previewMatches();
      }
    } else if (step == 3) {
    }
    $("#step-" + step + "-next").click();
  };

  self.saveColumnMapping = function (model) {
    $("#mappingModal").block({
      baseZ: 9999,
      message:
        '<h4 class="text-primary padding-top-10"><img width="30px" src="/assets/images/ajax-loader.gif" /> Please wait... Applying column mapping... </h4>',
    });
    var jobId = self.jobId();
    var data = [];

    data = $.map(self.columns(), function (value, index) {
      return {
        import_mode: value.mapping_mode(),
        target_field:
          value.target_field() === undefined || value.mapping_mode() == "ignore"
            ? ""
            : value.target_field(),
        behavior_empty_source: value.behavior_empty_source(),
        behavior_full_to_empty: value.behavior_full_to_empty(),
        behavior_full_to_full: value.behavior_full_to_full(),
        behavior_elsewhere_field:
          value.behavior_full_to_full() == "move_other_elsewhere" ||
          value.behavior_full_to_full() == "move_elsewhere"
            ? value.behavior_elsewhere_field()
            : "",
      };
    });

    DataSyncApi.saveColumnMapping(
      jobId,
      data,
      function () {
        self.import_mapping(data);
        self.mappingComplete(true);
        $("#mappingModal").unblock();
        $("#mappingModal").modal("hide");
      },
      function () {
        $("#mappingModal").unblock();
      }
    );
  };

  self.previewMatches = function () {
    $.blockUI({
      message:
        '<h4 class="text-primary padding-top-10"><img width="30px" src="/assets/images/ajax-loader.gif" /> Please wait... Loading preview.</h4>',
    });
    var jobId = self.jobId();
    var page = self.num_pages_completed();

    DataSyncApi.previewMatches(jobId, page, function (data) {
      var matches = [];
      matches = $.map(data, function (r) {
        return new MatchModel(r, self);
      });

      self.matches(matches);
      self.pagePreviewed(true);
      $.unblockUI();
    });
  };

  self.uploadList = function () {
    var file = document.getElementById("upload-file").files[0];
    if (file != undefined && file != null) {
      $.blockUI({ message: $("#upload-message") });
      var name =
        "datasync_" + Math.floor(1000000000 * Math.random()) + "_" + file.name;
      UploadsApi.uploadPrivate(
        file,
        name,
        function () {
          $.unblockUI();
          self.fileName(file.name);
          self.S3Key(name);
          self.startDataSyncJob();
        },
        function (msg) {
          $.unblockUI();
          utils.showError(msg);
        },
        function (progress) {
          self.uploadProgress(Math.round(progress * 100));
        }
      );
    } else {
      utils.showError(
        "Please select a upload file before clicking the upload button."
      );
      $.unblockUI();
    }
  };

  self.saveMatchDecisions = function () {
    $.blockUI({
      message:
        '<h4 class="text-primary padding-top-10"><img width="30px" src="/assets/images/ajax-loader.gif" /> Please wait... Saving matches.</h4>',
    });

    var data = [];

    var ambiguities = ko.utils.arrayFilter(
      self.updatedMatches(),
      function (item) {
        return item.selectedMatches().length > 0;
      }
    );

    var selectedMatchesAsNewRecords = ko.utils.arrayFilter(
      self.updatedMatches(),
      function (item) {
        return item.selectedMatchesAsNewRecords().length > 0;
      }
    );

    var selectedNewRecords = ko.utils.arrayFilter(
      self.newRecords(),
      function (item) {
        return item.selectedMatches().length > 0;
      }
    );

    selectedMatchesAsNewRecords.forEach(function (item) {
      data.push({ row_number: item.rowNumber(), id: null });
    });

    selectedNewRecords.forEach(function (item) {
      data.push({ row_number: item.rowNumber(), id: null });
    });

    ambiguities.forEach(function (item) {
      data.push({
        row_number: item.rowNumber(),
        id: item.selectedMatches()[0],
      });
    });

    var page = self.num_pages_completed();

    DataSyncApi.saveMatchDecisions(
      self.jobId(),
      page,
      self.applyForAllPages(),
      self.applyNewRecordsForAllPages(),
      data,
      function () {
        if (
          page < self.num_pages_total() - 1 &&
          !self.applyForAllPages() &&
          !self.applyNewRecordsForAllPages()
        ) {
          self.num_pages_completed(page + 1);
          self.pagePreviewed(false);
          self.selectAllNewRecords(false);
          self.selectAllMatches(false);
          self.selectAllAmbiguities(false);
          self.previewMatches();
        } else {
          if (self.matchingDataType() == "exhibitor_contacts") {
            DataSyncApi.updateIndividualsCompanyId(
              self.expoId(),
              function (data) {
                console.log("Updated record count: " + data.updated);
              }
            );
          }
          self.loadSummary();
          self.moveNext(3);
          $.unblockUI();
        }
      },
      function () {
        $.unblockUI();
      }
    );
  };

  self.loadSummary = function () {
    DataSyncApi.getDatasyncJob(self.jobId(), function (data) {
      self.num_new(data.num_new);
      self.num_updated(data.num_updated);
      self.num_leftover(data.num_leftover);
      $.unblockUI();
    });
  };
  self.getFinalList = function () {
    $.blockUI({
      message:
        '<h4 class="text-primary padding-top-10"><img width="30px" src="/assets/images/ajax-loader.gif" /> Please wait... Loading final preview.</h4>',
    });
    var jobId = self.jobId();

    DataSyncApi.previewMatches(jobId, 0, function (data) {
      var matches = [];
      matches = $.map(data, function (r) {
        return new MatchModel(r);
      });

      self.finalList(matches);
      $.unblockUI();
      $("#step-3-next").trigger("click");
    });
  };

  self.finalize = function () {
    $.blockUI({
      message:
        '<h4 class="text-primary padding-top-10"><img width="30px" src="/assets/images/ajax-loader.gif" /> Please wait... Importing data.</h4>',
    });
    DataSyncApi.finalize(self.jobId(), function (data) {
      utils.showSuccess("The rows has been successfully imported.");
      self.newRowCount(data.num_new);
      self.updatedRowCount(data.num_updated);
      self.numLeftOvers(data.num_leftover);
      $.unblockUI();
    });
  };

  self.removeDuplicateData = function () {
    $.blockUI({
      message:
        '<h4 class="text-primary padding-top-10"><img width="30px" src="/assets/images/ajax-loader.gif" /> Please wait... Removing duplicate data.</h4>',
    });
    DataSyncApi.removeDuplicates(self.expoId(), function (data) {
      $.unblockUI();
      alert(
        "Update Summary\nEmails: " +
          data.emails_updated +
          "\nPhones: " +
          data.phones_updated +
          "\nCell Phones: " +
          data.cellphones_updated +
          "\nAddresses: " +
          data.addresses_updated
      );
    });
  };

  self.startOver = function () {
    location.href = "/expo/datasync";
  };

  self.selectIgnoreAllNewRecords = function () {
    self.selectAllNewRecords(!self.selectAllNewRecords());
  };

  self.selectIgnoreAllMatches = function () {
    self.selectAllMatches(!self.selectAllMatches());
  };

  self.selectIgnoreAllAmbiguities = function () {
    self.selectAllAmbiguities(!self.selectAllAmbiguities());
  };

  self.selectAllMatches.subscribe(function (value) {
    if (value) {
      ko.utils.arrayForEach(self.singleMatches(), function (item) {
        item.selectedMatches([]);
        item.selectedMatches.push(item.ambiguities()[0].id());
      });
    } else {
      ko.utils.arrayForEach(self.singleMatches(), function (item) {
        item.selectedMatches([]);
      });
    }
  });

  self.selectAllAmbiguities.subscribe(function (value) {
    if (value) {
      ko.utils.arrayForEach(self.multipleMatches(), function (item) {
        item.selectedMatches([]);
        item.selectedMatches.push(item.ambiguities()[0].id());
      });
    } else {
      ko.utils.arrayForEach(self.multipleMatches(), function (item) {
        item.selectedMatches([]);
      });
    }
  });

  self.selectAllNewRecords.subscribe(function (value) {
    if (value) {
      ko.utils.arrayForEach(self.newRecords(), function (item) {
        item.selectedMatches([]);
        item.selectedMatches.push(item.rowNumber());
      });
    } else {
      ko.utils.arrayForEach(self.newRecords(), function (item) {
        item.selectedMatches([]);
      });
    }
  });

  function compareFields(a, b) {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  }
};

var MatchModel = function (data, viewModel) {
  var self = this;
  self.rowNumber = ko.observable(data.row_number || 0);
  self.matchType = ko.observable(data.match_type || "");
  self.rowData = ko.observableArray(data.row_data || []);

  self.ambiguities = ko.observableArray(
    data.matches === undefined
      ? []
      : $.map(data.matches, function (a) {
          return new AmbiguityModel(a);
        })
  );

  self.selectedAmbiguity = ko.observable();
  self.selectedMatches = ko.observableArray([]);
  self.selectedMatchesAsNewRecords = ko.observableArray([]);

  self.selectedMatches.subscribe(function (value) {
    var currentList = viewModel.selectedMatchesCopy();
    var matches = ko.utils.arrayFilter(this.updatedMatches(), function (item) {
      return item.selectedMatches().length > 0;
    });
    var selectedMatchesIds = ko.utils.arrayMap(matches, function (item) {
      return item.selectedMatches()[0];
    });

    if (value && value.length > 0) {
      var val = value[0];
      if (currentList.indexOf(val) > -1) {
        self.selectedMatches([]);
        alert(
          "The individual has already been selected for update on a previous row. Please ignore or select another option."
        );
      } else {
        viewModel.selectedMatchesCopy.push(val);
      }
    } else {
      var difference = currentList.diff(selectedMatchesIds);
      viewModel.selectedMatchesCopy.remove(difference[0]);
    }
  }, viewModel);
};

var AmbiguityModel = function (data) {
  var self = this;
  self.id = ko.observable(data.id || "");
  self.data = ko.observable(data.data || null);
  //   self.data = ko.observableArray(data.data === undefined || data.data === null ? [] : $.map(data.data, function(value) {
  //     return [value];
  // }));
};

Array.prototype.diff = function (a) {
  return this.filter(function (i) {
    return a.indexOf(i) < 0;
  });
};
